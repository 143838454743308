import React, { useEffect, useState } from "react";
import { Button, Divider, Form, Modal, Row, Spin } from "antd";
import RichtextEditor from "../RichtextEditor";
import { LoadingOutlined } from "@ant-design/icons";

const TextEditorModal = ({
  isEditable,
  isModalOpen,
  setIsModalOpen,
  action,
  editabledata,
  title = "Edit Setting",
  isLoading,
}) => {
  const [richtextValue, setRichtextValue] = useState(editabledata?.value || ""); // Set default value if editabledata?.value is undefined or null

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = async (values) => {
    action(richtextValue);
  };

  const [form] = Form.useForm();
  useEffect(() => {
    setRichtextValue(editabledata?.value || ""); // Update richtextValue when editabledata changes
    if (isEditable) {
      const fieldsData = {
        instructions: editabledata?.name,
      };
      form.setFieldsValue(fieldsData);
    }
  }, [editabledata, form]);

  return (
    <>
      <Modal
        title={title}
        visible={isModalOpen} // Changed "open" to "visible"
        footer={false}
        onCancel={handleCancel}
        width={1000}
      >
        <Divider />
        <div>
          <Row gutter={12}>
            <Form
              form={form}
              name="basic"
              layout="vertical"
              className="w-full"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                label={editabledata?.name}
                name="instructions"
                className="w-full px-2"
              >
                <RichtextEditor
                  richtextValue={richtextValue}
                  setRichtextValue={setRichtextValue}
                />
              </Form.Item>

              <Form.Item className="flex justify-end w-full mt-10">
                <Button size="large" htmlType="submit">
                  {isLoading ? (
                    <div className="flex items-center mt-2">
                      <Spin
                        className="mr-2"
                        indicator={
                          <LoadingOutlined
                            style={{
                              fontSize: 18,
                            }}
                            spin
                          />
                        }
                      />
                      Save
                    </div>
                  ) : (
                    "Save"
                  )}
                </Button>
              </Form.Item>
            </Form>
          </Row>
        </div>
      </Modal>
    </>
  );
};
export default TextEditorModal;
