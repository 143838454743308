import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Progress,
  Row,
  Skeleton,
  Spin,
  Typography,
} from "antd";
import RadarGraph from "../../components/Graphs/Radar";
import { StyledLoader } from "../../components/loader";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useEffect, useState } from "react";
import {
  createAssessmentsMapping,
  getAssessmentById,
  getAssessmentResultsAssessmentMappingId,
  startMyAssessment,
} from "../../services/api";
import BrandLogo from "../../assets/icons/brand-logo.svg";
import { LoadingOutlined, UserOutlined } from "@ant-design/icons";
import CandidateDashboardLayout from "../../layout/CandidateDashboardLayout";
import { connect } from "react-redux";
import "./Candidate.css";
import DocumentIcon from "../../assets/icons/document.svg";
import QuestionMark from "../../assets/icons/question-mark.svg";
import AssessmentCounter from "./assessmentCounter";

const AssessmentDetails = ({ user }) => {
  const [isLoading, setisLoading] = useState(true);
  const [assessmentDetails, setAssessmentDetails] = useState(null);
  const [assessment, setAssessment] = useState(null);
  const [assessmentId, setAssessmentId] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [searchParams] = useSearchParams();
  const [mappingIds, setMappingId] = useState(null);
  const from = searchParams.get("from");
  const mappingId = searchParams.get("mappingId");
  const navigate = useNavigate();
  localStorage.removeItem("assessmentToken");

  const { id, compId } = useParams();

  // let id = searchParams.get("id");
  // let compId = searchParams.get("companyId");

  const validateMyToken = async () => {
    setisLoading(true);
    try {
      const userId = user._id;

      const assessmentId = localStorage.getItem("assessmentId");
      const companyId = localStorage.getItem("companyIdAssessment");

      await assessmentsMapping(userId, assessmentId, companyId);
      getAssessment(assessmentId);
      setAssessmentId(assessmentId);
      setCompanyId(companyId);
    } catch (error) {
    } finally {
      setisLoading(false);
      localStorage.removeItem("assessmentId");
      localStorage.removeItem("companyIdAssessment");
    }
  };

  const assessmentsMapping = async (userId, assessmentId, companyId) => {
    try {
      const isOpenUrl = localStorage.getItem("isOpenURL");
      let data = {
        userId,
        assessmentId: assessmentId || id,
        companyId: companyId || compId,
      };
      if (isOpenUrl) {
        data.type = "openUrl";
      } else {
        if (localStorage.getItem("tokenInviteId")) {
          data.inviteId = localStorage.getItem("tokenInviteId");
        }
      }

      let assessment = {};
      if (from !== "listing") {
        assessment = await createAssessmentsMapping(data);
        setMappingId(assessment?.data?.assessment?._id);
      }

      await getAssessmentDetails(
        userId,
        assessmentId || id,
        companyId || compId,
        from !== "listing" ? assessment?.data?.assessment?._id : mappingId
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getAssessment = async (id) => {
    try {
      const { data } = await getAssessmentById(id);
      setAssessment(data);
    } catch (error) {}
  };

  const getAssessmentDetails = async (
    userId,
    assessmentId,
    companyId,
    resultId
  ) => {
    try {
      const { data } = await getAssessmentResultsAssessmentMappingId(
        userId,
        assessmentId,
        companyId,
        resultId
      );

      setAssessmentDetails(data);
      setisLoading(false);
    } catch (error) {
      setisLoading(false);
    }
  };

  useEffect(() => {
    // if (!id) {
    validateMyToken();
    // }
  }, []);

  useEffect(() => {
    // if (id) {
    //   getAssessment(id)
    //     .then(() => {
    //       getAssessmentDetails(user._id, id, compId || companyId);
    //     })
    //     .catch((error) => {
    //       console.error("Error occurred during getAssessment:", error);
    //     });
    // }
  }, []);

  const startAssessment = async () => {
    if (assessmentDetails?.status === "UNATTAMPTED") {
      await startMyAssessment({
        assessmentId: id || assessmentId,
        companyId: compId || companyId,
        userId: user._id,
      });
    }
    let mapId = mappingIds;
    if (from === "listing") {
      mapId = mappingId;
    }

    navigate(
      `/user/challenge/${mapId}/${id || assessmentId}/${user._id}/${
        compId || companyId
      }`
    );
  };
  return (
    <>
      {isLoading ? (
        <StyledLoader />
      ) : (
        <div className="bg-[#EBEBEB] h-screen overflow-x-hidden">
          <CandidateDashboardLayout
            title={
              assessmentDetails?.assessmentTitle ? (
                assessmentDetails?.assessmentTitle
              ) : (
                <Skeleton.Button size="small" active />
              )
            }
            button={
              <div className="flex flex-col gap-10 items-center mt-10">
                <Button
                  size="large"
                  htmlType="submit"
                  style={{
                    height: "44px",
                    fontSize: "16px",
                    fontWeight: 500,
                    border: "none",
                    color: "#1E1D1D",
                    width: "412px",
                  }}
                  onClick={
                    assessmentDetails?.isCompleted ? () => {} : startAssessment
                  }
                  type="primary"
                  className="mt-6"
                >
                  {assessmentDetails?.isCompleted
                    ? "Assessment Completed"
                    : "Start Challenge"}
                </Button>
                <div className="flex justify-center">
                  <AssessmentCounter
                    isCompleted={assessmentDetails?.isCompleted}
                    duration={assessmentDetails?.expiresAt || 0}
                  />
                </div>
              </div>
            }
          >
            <div className="w-full flex justify-center">
              <div className="w-[1200px] flex justify-between gap-5 mt-6">
                <div className="w-1/3 pl-5">
                  <Card className="shadow-lg text-left h-full">
                    <div className="mb- 2 flex justify-between">
                      <p className="card-title" style={{ fontWeight: 600 }}>
                        Modules Overview
                      </p>
                    </div>
                    <Divider />
                    <div className="flex items-center">
                      <div className="flex gap-2 items-start">
                        <img src={DocumentIcon} alt="document-icon" />
                        <div className="flex flex-col gap-2">
                          <p
                            className="challenge-title"
                            style={{ fontWeight: 600 }}
                          >
                            SOC Skills
                          </p>
                          <p className="challenge-stats">
                            {isLoading ? (
                              <Skeleton.Button size="small" active />
                            ) : (
                              `${
                                assessmentDetails?.numberOfChallenges || 0
                              } Challenges • ${
                                assessmentDetails?.numberOfQuestions || 0
                              } Questions • ${
                                assessmentDetails?.completionPercentage || 0
                              } Done`
                            )}
                          </p>
                          <p className="challenge-overview">
                            Demonstrate skills used within security operations
                            center
                          </p>
                        </div>
                      </div>

                      {/* <TbTriangleSquareCircle size={30} /> */}
                      {/* <div className="flex flex-col ml-3"> */}
                      {/* <Typography.Text className="text-lg">
                      SOC Skills
                    </Typography.Text>
                    <div className="flex">
                      <Typography.Text className="text-xs text-gray-500">
                        {isLoading ? (
                          <Skeleton.Button size="small" active />
                        ) : (
                          `${
                            assessmentDetails?.numberOfChallenges || 0
                          } Challenges`
                        )}
                      </Typography.Text>
                      <div
                        style={{ borderRight: "1px solid gray" }}
                        className="h-4 mx-2"
                      />
                      <Typography.Text className="text-xs text-gray-500">
                        {isLoading ? (
                          <Skeleton.Button size="small" active />
                        ) : (
                          `${
                            assessmentDetails?.numberOfQuestions || 0
                          }  Questions`
                        )}
                      </Typography.Text>
                      <div
                        style={{ borderRight: "1px solid gray" }}
                        className="h-4 mx-2"
                      />
                      <Typography.Text className="text-xs text-gray-500">
                        {isLoading ? (
                          <Skeleton.Button size="small" active />
                        ) : (
                          `${
                            assessmentDetails?.completionPercentage || 0
                          }% Completion`
                        )}
                      </Typography.Text>
                    </div> */}
                      {/* </div> */}
                    </div>
                  </Card>
                </div>
                <div className="w-1/3 px-5">
                  <Card className="shadow-lg text-left h-full ">
                    <div className="flex flex-col gap-5">
                      <div className="flex justify-between">
                        <p className="card-title">Score Card</p>
                        <img src={BrandLogo} alt="Logo" />
                      </div>
                      <div className="flex justify-center gap-2 flex-col items-center">
                        {user?.profilePic ? (
                          <Avatar size={50} src={user?.profilePic} />
                        ) : (
                          <Avatar size={50} icon={<UserOutlined />} />
                        )}
                        <p className="user-name">
                          {isLoading ? (
                            <Skeleton.Input size="small" active />
                          ) : (
                            user?.firstName + " " + user?.lastName
                          )}
                        </p>
                        <p className="user-email">
                          {isLoading ? (
                            <Skeleton.Input size="small" active />
                          ) : (
                            user.email
                          )}
                        </p>
                      </div>
                      <div
                        className="flex justify-between"
                        style={{ marginTop: "2px" }}
                      >
                        <div className="flex flex-col items-center gap-1 progress-container-custom">
                          <Progress
                            type="circle"
                            size={110}
                            strokeWidth={10}
                            strokeColor="#4BD097"
                            percent={assessmentDetails?.candidateScore}
                            format={(percent) => (
                              <div className="flex flex-col gap-1">
                                <p className="progress-text">{percent}</p>
                                <p className="progress-title">POINTS</p>
                              </div>
                            )}
                          />
                          <p className="progress-title">
                            Out of {assessmentDetails?.totalScore || 0}
                          </p>
                        </div>
                        <div className="flex flex-col items-center gap-1 progress-container-custom">
                          <Progress
                            type="circle"
                            size={110}
                            strokeWidth={10}
                            percent={assessmentDetails?.accuracy}
                            strokeColor="#4BD097"
                            format={(percent) => (
                              <p className="progress-text">{percent}%</p>
                            )}
                          />
                          <p className="progress-title">Accuracy</p>
                        </div>
                        <div className="flex flex-col items-center gap-1 progress-container-custom">
                          <Progress
                            type="circle"
                            size={110}
                            strokeWidth={10}
                            strokeColor="#4BD097"
                            percent={assessmentDetails?.completionPercentage}
                            format={(percent) => (
                              <p className="progress-text">{percent}%</p>
                            )}
                          />
                          <p className="progress-title">Completion</p>
                        </div>
                      </div>
                      <div className="mt-3">
                        <p
                          className="module-completion my-3"
                          style={{ fontWeight: 600 }}
                        >
                          Module Completion
                        </p>
                        <div className="flex flex-col gap-2">
                          <p className="progress-title2">SOC Skills</p>
                          <div className="flex gap-2 items-center">
                            <p
                              className="progress-text-percent"
                              style={{ fontWeight: 600 }}
                            >
                              {assessmentDetails?.completionPercentage}%
                            </p>
                            <Progress
                              type="line"
                              strokeWidth={10}
                              strokeColor={{
                                "0%": "#EFBE84",
                                "50%": "#E36D18",
                              }}
                              percent={assessmentDetails?.completionPercentage}
                              format={() => <></>}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="w-1/3 pr-5">
                  <Card className="shadow-lg text-left h-full">
                    <div className="mb- 2 flex justify-between">
                      <p className="card-title">Skills Tracker</p>
                      <img src={QuestionMark} alt="Logo" />
                    </div>
                    <Divider />
                    {assessmentDetails &&
                    assessmentDetails.challenges.length ? (
                      <RadarGraph
                        data={assessmentDetails?.challenges?.map((item) => {
                          return {
                            name: item?.name ? item?.name.trim() : "",
                            value: item?.percentage
                              ? parseInt(item?.percentage) / 100
                              : 0,
                          };
                        })}
                      />
                    ) : (
                      <div className="w-full h-40 flex justify-center items-end">
                        <div>
                          <Spin
                            size={40}
                            indicator={
                              <LoadingOutlined style={{ fontSize: 24 }} spin />
                            }
                          />
                          <Typography.Text className="ml-3">
                            Loading...
                          </Typography.Text>
                        </div>
                      </div>
                    )}
                  </Card>
                </div>
              </div>
            </div>
          </CandidateDashboardLayout>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({ user: state.auth.user });

export default connect(mapStateToProps, null)(AssessmentDetails);
