import { useState, useEffect } from 'react'
import { Button, Select, Input, DatePicker, Form, message, Card, Divider, Tooltip } from 'antd'
import Table from '../../components/Tables/Table'
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { StyledLoader as Loader } from '../../components/loader'
import moment from 'moment';
import { inviteCandidate, getAllAssessmentsOfCompany, fetchUserDetails, deleteInvitation } from '../../services/api';
import ModalWrapper from '../../components/Modals/ModalWrapper';
import { calculateDifferenceInDays } from '../../utils';
import { DeleteFilled, EyeFilled } from '@ant-design/icons';
import Swal from 'sweetalert2';
import DeleteModal from '../../components/Modals/DeleteModal2';


const handleCellClick = (navigate, record, id) => {
    return ({

        onClick: (event) => {
            navigate(`/company/admin/candidate/${id}/${record.assessmentId}/${record.companyId}/${record.userId}/${record.resultId}`)
        }
    })
}

function CompanyAdminCandidate({ user }) {
    const [openModal, setOpenModal] = useState(false)
    const [userDetails, setUserDetails] = useState({

        firstName: "N/A",
        lastName: "N/A",
        email: "N/A",
        invitations: []
    })
    const [isDelete, setIsDelete] = useState(false)
    const [deleteId, setDeleteId] = useState(false)
    const [assessments, setAssessments] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0, // You might not know this until you fetch the first set of data
    });
    const { id } = useParams()

    const navigate = useNavigate()

    useEffect(() => {
        handleTableChange(pagination, {}, {})
        fetchAssessments()
    }, [])

    const getInvitationStatus = (status) => {
        if (status === "IN_PROGRESS") {
            return "Started"
        }
        if (status === "COMPLETED") {
            return "Completed"
        }
        return "Unattempted"
    }

    const fetchDetails = async (query, currentPage) => {
        try {
            setIsLoading(true)
            const { data } = await fetchUserDetails(user?.companyIds[0], id, query)
            let details = {}

            if (data.invitations?.length) {
                let userDetails = []
                if (data.invitations[0].userDetails?.length) {
                    userDetails = data.invitations[0].userDetails
                    details = {
                        firstName: userDetails[0]?.firstName,
                        lastName: userDetails[0]?.lastName,
                        email: id,
                        userId: userDetails[0]?._id,
                        invitations: [],
                    }
                } else {
                    details = {
                        firstName: "N/A",
                        lastName: "N/A",
                        email: id,
                        invitations: [],

                    }
                }
                data?.invitations?.forEach((invitations) => {
                    details.invitations.push({
                        userId: details.userId,
                        name: userDetails?.length ? userDetails[0]?.firstName + " " + userDetails[0]?.lastName : "-",
                        email: id,
                        created: new Date().toISOString(),
                        title: invitations?.assessmentDetails?.length ? invitations?.assessmentDetails[0].title : "-",
                        invitedBy: invitations.invitedByUser ? invitations.invitedByUser?.firstName + " " + invitations.invitedByUser?.lastName : "",
                        daysAgo: calculateDifferenceInDays(invitations.createdAt),
                        status: getInvitationStatus(invitations.assessmentStatus),
                        ended: getInvitationStatus(invitations.assessmentStatus),
                        score: invitations.candidateScore === 0 ? "N/A" : invitations.candidateScore,
                        accuracy: invitations.accuracy === 0 ? "N/A" : invitations.accuracy,
                        key: invitations._id,
                        assessmentId: invitations.assessmentId,
                        companyId: invitations.companyId,
                        url: `${window.location.host}/candidate/invitation/${invitations.invitationToken}`,
                        invitationToken: invitations.invitationToken,
                        resultId: invitations.resultId
                    })
                })
                setUserDetails({ ...details })
            } else {
                setUserDetails({
                    firstName: "N/A",
                    lastName: "N/A",
                    email: id,
                    invitations: []
                })
            }


            setPagination(() => ({
                ...pagination, total: data?.count || 0, current: currentPage
            }))
        } catch (error) {
            message.error(error?.message || "Something went wrong")
        } finally {
            setIsLoading(false)
        }
    }

    const handleDelete = async () => {
        try {
            setIsLoading(true)
            await deleteInvitation(deleteId)
            fetchDetails()
            message.success("Successfully Deleted")
            setDeleteId(null)
            setIsDelete(false)
        } catch (error) {
            message.error(error.message || "Something went wrong")
        } finally {
            setIsLoading(false)
        }
    }


    const fetchAssessments = async () => {
        if (user.companyIds.length) {
            try {
                const { data } = await getAllAssessmentsOfCompany(
                    user.companyIds[0],
                );
                setAssessments(data?.company?.flatMap(({ assessments }) => assessments) || [])
            } catch (error) {
                message.error(error.message || "Something went wrong")
            }
        }
    }

    function copyTextToClipboard(text) {
        navigator.clipboard.writeText(text).then(
            function () { },
            function (err) {
                console.error("Could not copy text: ", err);
            }
        );
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            onCell: (record, rowIndex) => handleCellClick(navigate, record, id)
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: true,
            onCell: (record, rowIndex) => handleCellClick(navigate, record, id)
        },
        {
            title: 'Evaluation',
            dataIndex: 'title',
            key: 'title',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
            onCell: (record, rowIndex) => handleCellClick(navigate, record, id)
        },
        {
            title: 'Invited By',
            dataIndex: 'invitedBy',
            key: 'invitedBy',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
            onCell: (record, rowIndex) => handleCellClick(navigate, record, id)
        },
        {
            title: 'Invited',
            dataIndex: 'daysAgo',
            key: 'daysAgo',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
            onCell: (record, rowIndex) => handleCellClick(navigate, record, id)
        },
        {
            title: 'Started',
            dataIndex: 'status',
            key: 'status',
            sorter: true,
            onCell: (record, rowIndex) => handleCellClick(navigate, record, id)
        },
        {
            title: 'Ended',
            dataIndex: 'ended',
            key: 'ended',
            sorter: true,
            onCell: (record, rowIndex) => handleCellClick(navigate, record, id)
        },
        {
            title: 'Score',
            dataIndex: 'score',
            key: 'score',
            sorter: true,
            onCell: (record, rowIndex) => handleCellClick(navigate, record, id)
        },
        {
            title: 'Accuracy',
            dataIndex: 'accuracy',
            key: 'accuracy',
            sorter: true,
            onCell: (record, rowIndex) => {

                return {
                    onClick: (event) => {
                        navigate(`/company/admin/candidate/${id}/${record.assessmentId}/${record.companyId}/${record.userId}`)
                    }
                }
            }
        },
        {
            title: 'Copy URL',
            dataIndex: 'url',
            key: 'url',
            render: (_, item) => {
                return <Button disabled={item.invitationToken === null} onClick={() => copyTextToClipboard(item.url)}>Copy</Button>
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_, item) => {
                // const status = ["SENT", "FAILED", "CANCELED", "EXPIRED"]
                return <div style={{ display: "flex", gap: 2 }}>
                    <Tooltip placement="topLeft" title="Delete Invite">
                        <DeleteFilled style={{ cursor: "pointer" }} onClick={() => {
                            setIsDelete(true)
                            setDeleteId(item.key)
                        }} />
                    </Tooltip>
                    {/* {item.status === "SENT" ? <Tooltip placement="topLeft" title="Cancel Invite">
                        <CloseCircleFilled style={{ cursor: "pointer" }} onClick={() => handleStatusChange("CANCEL", item.key)} />
                    </Tooltip> : null}
                    {status.includes(item.status) ? <Tooltip placement="topLeft" title="Cancel Invite">
                        <RedoOutlined style={{ cursor: "pointer" }} onClick={() => handleStatusChange("RESEND", item.key)} />
                    </Tooltip> : null} */}
                    <Tooltip placement="topLeft" title="View Invite">
                        <EyeFilled style={{ cursor: "pointer" }} onClick={() => navigate(`/company/admin/invites/assessment/view?id=${item.assessmentId}`)} />
                    </Tooltip>
                </div>
            }
        },
    ];
    const handleTableChange = (pagination, filters, sorter) => {
        let query = `pageNo=${pagination.current}&pageSize=${pagination.pageSize}`
        const filtersKeys = Object.keys(filters)

        if (filtersKeys.length) {
            filtersKeys.forEach((filter) => {
                if (filters[filter]) {
                    query += `&${filter}=${filters[filter][0]}`
                }
            })
        }

        if (sorter.field && sorter.order) {
            query += `&sortBy=${sorter.field}&order=${sorter.order}`
        }
        setPagination(pagination)
        fetchDetails(query, pagination.current);
    };

    return <div style={{ marginTop: "10px" }}>
        {openModal ? <InviteFormModal user={user} firstName={userDetails?.firstName || ""} assessments={assessments} email={id} fetchInvitations={fetchDetails} onClose={setOpenModal} /> : null}
        {isLoading ? <Loader /> : null}
        {isDelete ? <DeleteModal heading="Are you sure you want
                to delete the invitation?" description="These changes will take effect immediately.
                You will not be able to restore this invitation." handleDelete={handleDelete} handleClose={() => {
                setDeleteId(null)
                setIsDelete(false)
            }} /> : null}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Card style={{ width: "max-content" }}>
                <table >
                    <TRow label="First Name" value={userDetails.firstName} />
                    <TRow label="Last Name" value={userDetails.lastName} />
                    <TRow label="Email" value={id} />
                    <TRow label="Invitations" value={userDetails.invitations?.length} />
                </table>
            </Card>
            <Button type="primary" size="large" onClick={() => setOpenModal(true)}>
                Send New Invite
            </Button>
        </div>
        <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />
        <Card>
            <h5 style={{ fontWeight: "300", fontSize: '20px', textAlign: "left", marginTop: "5px", marginBottom: "5px" }}>Invites</h5>
            <Table columns={columns}
                data={userDetails.invitations}
                onChange={handleTableChange}
                pagination={pagination}
                style={{ cursor: "pointer" }}
            />
        </Card>
    </div>
}

const mapStateToProps = (state) => ({
    user: state.auth.user
});
const mapDispatchToProps = {
    // getSelectedChallenge: ChallengeSlice.actions.getSelectedChallenge,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyAdminCandidate);



const TRow = ({ label, value }) => {
    return <tr>
        <td style={{ backgroundColor: "#F5F5F5", padding: "12px" }}>{label}</td>
        <td style={{ backgroundColor: "white", minWidth: "200px", textAlign: "left", padding: "12px" }}>{value}</td>
    </tr>
}



const InviteFormModal = ({ onClose, assessments, user, firstName, email, fetchInvitations }) => {
    const [form] = Form.useForm();

    const onFinish = async (values) => {
        try {

            await inviteCandidate(user.companyIds[0], values.assessment, {
                candidates: [{ firstName, email }],
                expiryTime: values.date,
                invitedBy: user._id
            })
            message.success("Invitation successfully sent")
            onClose("")
            fetchInvitations()
        } catch (error) {
            message.error(error?.response?.data?.error || error?.message || "Something went wrong");
        }
    };


    // Cancel handler
    const onCancel = () => {
        form.resetFields();
        onClose(false);
    };

    return (
        <ModalWrapper
            title={"Invite Candidate"}
            handleClose={onCancel}
            footer={null} // No footer needed as buttons are inside form
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                initialValues={{
                    email: email,
                    date: null,
                    assessment: undefined
                }}
            >

                <Form.Item
                    label="Expiry Date"
                    name="date"
                    rules={[
                        { required: true, message: 'Please select a date!' },
                        () => ({
                            validator(_, value) {
                                if (!value || value.isAfter(moment())) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The date must be in the future!'));
                            },
                        }),
                    ]}
                >
                    <DatePicker
                        style={{ height: '48px', width: "100%" }}
                        placeholder="Select date"
                        disabledDate={(current) => {
                            let customDate = moment().format("YYYY-MM-DD");
                            return current && current < moment(customDate, "YYYY-MM-DD");
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label="Assessment"
                    name="assessment"
                    rules={[{ required: true, message: 'Please select an assessment!' }]}
                >
                    <Select
                        placeholder="Select Assessment"
                    >
                        {
                            assessments.map(({ _id, title }) => <Select.Option value={_id}>{title}</Select.Option>)
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"
                >
                    <Input
                        placeholder="Enter candidate's email"
                        style={{ height: '48px' }}
                        disabled
                    />
                </Form.Item>
                <div style={{ display: "flex", justifyContent: "flex-end", gap: "8px" }}>
                    <Button onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button type="primary" htmlType="submit">
                        Continue
                    </Button>
                </div>
            </Form>
        </ModalWrapper>
    );
};