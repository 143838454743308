import React, { useState } from "react";
import { Button, Space } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import EndAssessment from "../../../components/Modals/EndAssessmentModal";
import { useNavigate } from "react-router-dom";
const SidebarFooterComponent = ({ stopAssessmentAPI }) => {
  const [endAssessment, setEndAssessment] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="sidebar-footer">
      {endAssessment && (
        <EndAssessment onEnd={stopAssessmentAPI} onClose={setEndAssessment} />
      )}
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <Button
          size="large"
          type="primary"
          className="text-black"
          onClick={() => setEndAssessment(true)}
          block
        >
          End Assessment
        </Button>
        <Button
          type="default"
          icon={
            <div className="icon-background icon-background-unselected">
              <LogoutOutlined
                style={{
                  color: "white",
                  fontSize: 18,
                }}
              />
            </div>
          }
          onClick={() => navigate("/user/assessment-listing")}
          style={{ background: "transparent" }}
          className="button-no-background "
          block
        >
          Back
        </Button>
      </Space>
    </div>
  );
};

export default SidebarFooterComponent;
