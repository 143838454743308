import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import AssessmentOverview from "../pages/Candidate/AssessmentOverview";
import SSO from "../pages/SSO";
import Login from "../pages/Auth/Login";
import ResetPassword from "../pages/Auth/ResetPassword";
import Signup from "../pages/Auth/Signup";
import NewPassword from "../pages/Auth/NewPassword";
import OTP from "../pages/Auth/OTP";
import Details from "../pages/Details";
import InvalidUrlPage from "../pages/ErrorPages";
import CompanyUserValidation from "../pages/CompanyUserValidation";

const PublicRoutes = () => {
  return (
    <>
      <Routes>
        <Route
          path="/assessment/invitation/confirmation/:id"
          element={<AssessmentOverview />}
        />
        <Route
          path="/candidate/invitation/:id"
          element={<AssessmentOverview />}
        />
        <Route
          path="/company/user/confirmation/:id"
          element={<CompanyUserValidation />}
        />
        {/* <Route path="user/assessment-details/:id" element={<Details />} /> */}
        <Route path="/url-not-found" element={<InvalidUrlPage />} />

        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/auth" element={<Navigate to="/login" />} />
        <Route path="/sso" element={<SSO />} />
        <Route path="/login" element={<Login />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/new-password" element={<NewPassword />} />
        <Route path="/otp" element={<OTP />} />
      </Routes>
    </>
  );
};

export default PublicRoutes;
