import React from 'react';
import { saveAs } from 'file-saver';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

const data = [
    { email: 'test-email@gmail.com' },
];
const multiData = [
    { firstName: "test", email: 'test-email@gmail.com' },
];

const convertToCSV = (data) => {
    const headers = Object.keys(data[0]).join(',');
    const rows = data.map(row => Object.values(row).join(',')).join('\n');
    return `${headers}\n${rows}`;
};

const downloadCSV = (isFirstName) => {

    const csv = convertToCSV(isFirstName ? multiData : data);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'bulk-import-template.csv');
};

const DownloadButton = ({ isFirstName = false }) => (
    <Button onClick={() => downloadCSV(isFirstName)} icon={<DownloadOutlined />}>
        Download Template
    </Button>
);

export default DownloadButton;
