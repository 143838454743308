import React from "react";
import { Menu, Dropdown, Typography } from "antd";
import "./index.css"; // Make sure to create this CSS file for styles
import PDF from "./../../../../assets/icons/pdf.png";
import { GoChevronDown } from "react-icons/go";
const { Link } = Typography;

const DownloadManager = ({ resources }) => {
  return (
    <Dropdown
      overlay={
        <Menu>
          {resources?.map((item, index) => {
            return (
              <Menu.Item key={index}>
                <Link href={item.url} target="_blank">
                  {item.name}
                </Link>
              </Menu.Item>
            );
          })}
        </Menu>
      }
      className="custom-dropdown"
    >
      <Link
        className="text-left flex items-center"
        icon={<img src={PDF} alt="PDF" />}
        size="large"
      >
        <img
          src="path_to_your_icon" // Replace with your icon path
          alt=""
          className="dropdown-icon"
        />
        Download Manager <GoChevronDown size={20} className="ml-5" />
      </Link>
    </Dropdown>
  );
};

export default DownloadManager;
