import { Button, Card, Col, Divider, Input, QRCode, Row, Typography, message } from "antd";
import {
    getCurrentUser,
    updateMFAPreference,
    verifyTOTPSetup,
    fetchMFAPreference,
    setUpTOTP,
} from "aws-amplify/auth";
import React, { useEffect, useState } from "react";
import { StyledLoader } from "../../components/loader";

const MFA = () => {
    const [url, setUrl] = useState("");
    const [code, setCode] = useState(null);
    const [mfaEnabled, setMfaEnabled] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const enable2FA = async () => {
        const totpSetupDetails = await setUpTOTP();
        const appName = "cyber-hire";
        const setupUri = totpSetupDetails.getSetupUri(appName);
        setUrl(setupUri.href);
    };

    const checkMFAStatus = async () => {
        const mfaSettings = await fetchMFAPreference()
        setMfaEnabled(!!mfaSettings.enabled?.length);
    }

    useEffect(() => {
        (async () => {
            try {
                checkMFAStatus()
                await enable2FA()
            } catch (err) {

            } finally {
                setIsLoading(false)
            }
        })();
    }, []);

    return (<>
        {isLoading ? <StyledLoader /> : <div className="w-full">
            <div style={{ maxWidth: 1500, margin: "auto" }} className="px-10">
                <Card className="shadow-lg mb-10">
                    <Row>
                        <Col span={8}>
                            <div className="flex flex-col text-left">
                                <Typography.Text className="text-2xl font-semibold">
                                    Two Factor Authentication
                                </Typography.Text>
                                <Typography.Text className="text-gray-500">
                                    Enable MFA to get extra layer of security
                                </Typography.Text>
                            </div>
                        </Col>
                        <Col span={16} className="flex flex-wrap">
                            <div className="w-full">
                                {!mfaEnabled ? (
                                    <div className="flex justify-center align-center mt-6 flex-col">

                                        <div className={"flex justify-center flex-col align-center mb-7"}>
                                            <div className="flex flex-col items-center gap-2 w-1/2">
                                                <div className="w-full flex justify-start">
                                                    <p className="text-left">1. Scan the following QR code with an Authenticator App on your phone</p>
                                                </div>
                                                <QRCode size={300} value={url} />
                                                <Divider />
                                                <div className="w-full flex justify-start">
                                                    <p>2. Enter the generated 6 digits code below</p>
                                                </div>
                                                <Input
                                                    onChange={(e) => setCode(e.target.value)}
                                                    className="flex justify-center align-middle my-4"
                                                    type="number"
                                                    size="medium"
                                                    placeholder="Enter your code"
                                                    max={999999}
                                                />
                                                <div className="flex justify-end w-full">
                                                    <Button
                                                        type="primary"
                                                        style={{ marginTop: "1rem" }}
                                                        onClick={async () => {
                                                            try {
                                                                await verifyTOTPSetup({ code });
                                                                await updateMFAPreference({ totp: "PREFERRED" });
                                                                message.success("MFA enabled");
                                                                setCode(null);
                                                                setUrl("");
                                                                setMfaEnabled(true);
                                                            } catch (error) {
                                                                message.error(error.message);
                                                            }
                                                        }}
                                                    >
                                                        Verify
                                                    </Button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>) : (
                                    <div>
                                        <h3 className="text-xl m-6 text-[#42937D] font-medium">
                                            MFA is enabled
                                        </h3>
                                        <Button
                                            onClick={async () => {
                                                try {
                                                    await updateMFAPreference({ totp: "DISABLED" });
                                                    message.success("MFA disabled");
                                                    checkMFAStatus()
                                                } catch (error) {
                                                    message.error(error.message);
                                                }
                                            }}
                                            size="large"
                                            className="mx-2"
                                            style={{
                                                alignSelf: "center",
                                                width: "10rem",
                                                color: "white",
                                                backgroundColor: "#42937D",
                                                transition: "background-color 0.3s ease",
                                                ":hover": {
                                                    backgroundColor: "#3a7b66",
                                                },
                                            }}
                                        >
                                            Disable MFA
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Card>
            </div>
        </div>}
    </>
    );
};

export default MFA;
