import {
  Button,
  Card,
  Col,
  Divider,
  Input,
  Row,
  Select,
  Space,
  Switch,
  Typography,
  message,
} from "antd";
import AdminsTable from "../../../components/Tables/AdminsTable";
import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  blockUser,
  deleteUserById,
  getAllSuperAdmins,
  unBlockUser,
} from "../../../services/api";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { StyledLoader } from "../../../components/loader";
import DeleteModal from "../../../components/Modals/DeleteModal";
import { debounce } from "lodash";

const { Text } = Typography;
const SuperAdmins = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [resetIsDisabled, setResetIsDisabled] = useState(true);
  const [selectedStatusFilter, setSelectedStatusFilter] = useState(null);
  const [searchKey, setSearchKey] = useState(null);
  const [allSuperAdmin, setAllSuperAdmin] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [deleteUserID, setDeleteUserID] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0, // You might not know this until you fetch the first set of data
  });
  const navigate = useNavigate();

  const onAccessChange = async (isBlocked, id) => {
    // setIsLoading(true)
    try {
      message.success(
        isBlocked ? "Super admin unblocked!" : "Super admin blocked!"
      );
      const index = allSuperAdmin.findIndex((ind) => ind._id === id);
      allSuperAdmin[index].isBlocked = !isBlocked;
      setAllSuperAdmin([...allSuperAdmin]);
      if (isBlocked) {
        await unBlockUser(id);
      } else {
        await blockUser(id);
      }
    } catch (error) {
      message.error("Error updating Blocked status");
    }
  };

  const handleDeleteModal = (id) => {
    setDeleteUserID(id);
    setIsModalOpen(true);
  };

  const columns = [
    {
      title: "SR No.",
      dataIndex: "key",
      key: "key",
      render: (text) => <Text>{text}</Text>,
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      render: (text) => <Text>{text}</Text>,
      sorter: true,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      render: (text) => <Text>{text}</Text>,
      sorter: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: true,
    },
    {
      title: "Blocked",
      dataIndex: "isBlocked",
      key: "isBlocked",
      render: (_, record) => {
        return (
          <div>
            <Switch
              checked={record?.isBlocked}
              onChange={() => onAccessChange(record?.isBlocked, record?._id)}
              style={{
                backgroundColor: record?.isBlocked ? "#ed5353" : "#4ac18e",
              }}
            />
          </div>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <Space size="middle">
            <EditOutlined
              className="hover:text-blue-400"
              onClick={() =>
                navigate(`/admin/super-admins/edit?id=${record?._id}`)
              }
            />
            <DeleteOutlined
              className="hover:text-blue-400"
              onClick={() => handleDeleteModal(record?._id)}
            />
          </Space>
        );
      },
    },
  ];
  const data = [
    ...allSuperAdmin.map((item, i) => {
      return {
        _id: item?._id,
        key: i + 1,
        firstName: item?.firstName,
        lastName: item?.lastName,
        email: item?.email,
        isBlocked: item?.isBlocked,
        // tags: ['nice', 'developer'],
      };
    }),
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    getAllSuperAdmin(
      pagination?.current,
      pagination?.pageSize,
      sorter.order ? sorter.field : null,
      sorter.order,
      selectedStatusFilter,
      searchKey
    );
  };

  const getAllSuperAdmin = async (
    page = 1,
    limit = 10,
    sortBy,
    order,
    isBlocked,
    searchKey
  ) => {
    setSortBy(sortBy);
    setSortOrder(order);

    const isBlockedQueryString = isBlocked
      ?.map((status) => {
        if (status === "Blocked") {
          return "isBlocked=true";
        } else if (status === "Un Blocked") {
          return "isBlocked=false";
        }
        return null;
      })
      .filter(Boolean)
      .join("&");

    const queryParams = {
      role: "SUPER_ADMIN",
      page,
      limit,
      sortBy,
      order,
      searchKey,
    };

    const queryString = Object.entries(queryParams)
      .reduce((acc, [key, value]) => {
        if (value !== undefined && value !== null) {
          acc.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
        }
        return acc;
      }, [])
      .concat(isBlockedQueryString ? [isBlockedQueryString] : [])
      .join("&");

    setIsLoading(true);
    try {
      const { data } = await getAllSuperAdmins(queryString);
      setAllSuperAdmin(data?.users);
      setPagination((prev) => ({ ...prev, total: data?.count, current: page }));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllSuperAdmin();
  }, []);

  const deleteUser = async () => {
    setIsModalOpen(false);
    try {
      const index = allSuperAdmin.findIndex((ind) => ind._id === deleteUserID);
      const arr = [...allSuperAdmin];
      arr.splice(index, 1);
      setAllSuperAdmin(arr);
      message.success("Super admin deleted!");

      await deleteUserById(deleteUserID);
      setDeleteUserID(null);
    } catch (error) {
      getAllSuperAdmin(
        pagination?.current,
        pagination?.pageSize,
        sortBy,
        sortOrder,
        selectedStatusFilter,
        searchKey
      );
      message.success("Error deleting super admin!");
    }
  };

  const handleStatusSelectChange = (value) => {
    setResetIsDisabled(false);
    setSelectedStatusFilter([value]);
    getAllSuperAdmin(
      1,
      pagination?.pageSize,
      sortBy,
      sortOrder,
      [value],
      searchKey
    );
  };

  const resetFilters = () => {
    setResetIsDisabled(true);
    setSelectedStatusFilter(null);
    setSearchKey(null);
    setSortOrder(null);
    setSortBy(null);
    getAllSuperAdmin();
  };

  const debouncedHandleSearch = useCallback(
    debounce((searchValue) => {
      getAllSuperAdmin(
        pagination?.current,
        pagination?.pageSize,
        sortBy,
        sortOrder,
        selectedStatusFilter,
        searchValue
      );
    }, 1000),
    []
  );

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchKey(searchValue);
    setResetIsDisabled(false);
    debouncedHandleSearch(searchValue?.trim());
  };

  return (
    <div>
      <Card style={{ width: "100%", background: "white" }}>
        <div className="flex justify-between items-center pl-3 pb-4 w-full">
          <Text className="text-2xl font-semibold font-[#1f2232]">
            Super Admins
          </Text>
          <Button
            size="large"
            onClick={() => navigate("/admin/super-admins/add")}
          >
            Add New +
          </Button>
        </div>
        <Divider style={{ margin: 0 }} />
        <Row gutter={12} justify={"space-between"} className="my-2">
          <Col span={8}>
            <Row gutter={12}>
              <Col span={24}>
                <Input
                  size="large"
                  value={searchKey}
                  onChange={(e) => handleSearch(e)}
                  placeholder="Search name..."
                />
              </Col>
            </Row>
          </Col>
          <Col span={16}>
            <Row justify={"end"} gutter={12}>
              <Col>
                <Select
                  placeholder="Filter Status"
                  size="large"
                  style={{
                    width: 130,
                  }}
                  onChange={handleStatusSelectChange}
                  value={selectedStatusFilter ? selectedStatusFilter[0] : null}
                  options={[
                    {
                      value: "Blocked",
                      label: "Blocked",
                    },
                    {
                      value: "Un Blocked",
                      label: "UnBlocked",
                    },
                  ]}
                />
              </Col>
              <Col>
                <Button
                  disabled={resetIsDisabled}
                  size="large"
                  onClick={resetFilters}
                >
                  Reset Filters
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider style={{ margin: 0 }} />
        {isLoading ? <StyledLoader /> : null}
        <AdminsTable
          onChange={handleTableChange}
          columns={columns}
          data={data}
          pagination={pagination}
        />
        <DeleteModal
          title="Delete record"
          text="Are you sure you want to delete this record?"
          deleteAction={deleteUser}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          isDelete
        />
      </Card>
    </div>
  );
};

export default SuperAdmins;
