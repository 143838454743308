import {
  Button,
  Card,
  Col,
  Divider,
  Input,
  Row,
  Select,
  Space,
  Switch,
  Typography,
  message,
} from "antd";
import AdminsTable from "../../../components/Tables/AdminsTable";
import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  blockUser,
  deleteUserById,
  getAllCompaniesData,
  getAllCompanyAdmins,
  unBlockUser,
} from "../../../services/api";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { StyledLoader } from "../../../components/loader";
import DeleteModal from "../../../components/Modals/DeleteModal";
import { debounce } from "lodash";

const { Text } = Typography;
const CompanyAdmins = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allCompaniesAdmin, setAllCompaniesAdmin] = useState([]);
  const [selectedCompaniesAdmin, setSelectedCompaniesAdmin] = useState([]);
  const [selectedCompanyOptions, setSelectedCompanyOptions] = useState([]);
  const [selectedStatusFilter, setSelectedStatusFilter] = useState(null);
  const [searchKey, setSearchKey] = useState(null);
  const [resetIsDisabled, setResetIsDisabled] = useState(true);
  const [deleteUserID, setDeleteUserID] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [allCompanies, setAllCompanies] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const navigate = useNavigate();

  const onAccessChange = async (isBlocked, id) => {
    try {
      message.success(
        isBlocked ? "Company admin unblocked!" : "Company admin blocked!"
      );
      const index = allCompaniesAdmin.findIndex((ind) => ind._id === id);
      allCompaniesAdmin[index].isBlocked = !isBlocked;
      setAllCompaniesAdmin([...allCompaniesAdmin]);
      if (isBlocked) {
        await unBlockUser(id);
      } else {
        await blockUser(id);
      }
    } catch (error) {
      console.log({ error });
      message.error("Error updating Blocked status");
    }
  };

  const handleDeleteModal = (id) => {
    setDeleteUserID(id);
    setIsModalOpen(true);
  };

  const uniqueCompanies = Array.from(
    new Set(allCompaniesAdmin.map((item) => item?.companies[0]?.name))
  ).filter(Boolean);

  const companyFilters = uniqueCompanies.map((companyName) => ({
    text: companyName,
    value: companyName,
  }));

  const columns = [
    {
      title: "SR No.",
      dataIndex: "key",
      key: "key",
      render: (text) => <Text>{text || "--"}</Text>,
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      render: (text) => <Text>{text || "--"}</Text>,
      // defaultSortOrder: "descend",
      sorter: true,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      render: (text) => <Text>{text || "--"}</Text>,
      sorter: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: true,
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      render: (text) => <Text>{text || "--"}</Text>,
    },
    {
      title: "Blocked",
      dataIndex: "isBlocked",
      key: "isBlocked",
      render: (_, record) => {
        return (
          <div>
            <Switch
              checked={record?.isBlocked}
              onChange={() => onAccessChange(record?.isBlocked, record?._id)}
              style={{
                backgroundColor: record?.isBlocked ? "#ed5353" : "#4ac18e",
              }}
            />
          </div>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <Space size="middle">
            <EditOutlined
              className="hover"
              onClick={() =>
                navigate(`/admin/company-admins/edit?id=${record?._id}`)
              }
            />
            <DeleteOutlined
              className="hover"
              onClick={() => handleDeleteModal(record?._id)}
            />
          </Space>
        );
      },
    },
  ];
  const data = [
    ...allCompaniesAdmin.map((item, i) => {
      return {
        _id: item?._id,
        key: i + 1,
        firstName: item?.firstName,
        lastName: item?.lastName,
        email: item?.email,
        company: item?.companies[0]?.name,
        isBlocked: item?.isBlocked,
      };
    }),
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    let status;
    if (filters.isBlocked === "Blocked") {
      status = true;
    } else if (filters.isBlocked === "Un Blocked") {
      status = false;
    }
    getAllCompaniesAdmin(
      pagination?.current,
      pagination?.pageSize,
      sorter.order ? sorter.field : null,
      sorter.order,
      selectedCompaniesAdmin,
      selectedStatusFilter,
      searchKey
    );
  };

  const options = [
    ...allCompanies?.map((item) => ({
      label: item?.name,
      value: item?.name,
    })),
  ];

  const handleCompanySelectChange = (value, record) => {
    setSelectedCompanyOptions(value);
    setResetIsDisabled(false);
    let admins = [];
    record.map((rec) => {
      const index = allCompanies.findIndex((ind) => ind.name === rec.value);
      admins.push(allCompanies[index]);
    });
    setSelectedCompaniesAdmin(admins);
    getAllCompaniesAdmin(
      1,
      pagination?.pageSize,
      sortBy,
      sortOrder,
      admins,
      selectedStatusFilter,
      searchKey
    );
  };

  const handleStatusSelectChange = (value) => {
    setResetIsDisabled(false);
    setSelectedStatusFilter([value]);
    getAllCompaniesAdmin(
      1,
      pagination?.pageSize,
      sortBy,
      sortOrder,
      selectedCompaniesAdmin,
      [value],
      searchKey
    );
  };

  const getAllCompaniesAdmin = async (
    page = 1,
    limit = 10,
    sortBy,
    order,
    companies,
    isBlocked,
    searchKey
  ) => {
    setSortOrder(order);
    setSortBy(sortBy);
    const companyIds = companies
      ?.map((company) => `companyId=${encodeURIComponent(company._id)}`)
      .join("&");

    const isBlockedQueryString = isBlocked
      ?.map((status) => {
        if (status === "Blocked") {
          return "isBlocked=true";
        } else if (status === "Un Blocked") {
          return "isBlocked=false";
        }
        return null;
      })
      .filter(Boolean)
      .join("&");

    const queryParams = {
      role: "COMPANY_ADMIN",
      page,
      limit,
      sortBy,
      order,
      searchKey,
    };

    const queryString = Object.entries(queryParams)
      .reduce((acc, [key, value]) => {
        if (value !== undefined && value !== null && value !== "") {
          acc.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
        }
        return acc;
      }, [])
      .concat(isBlockedQueryString ? [isBlockedQueryString] : [])
      .concat(companyIds ? [companyIds] : [])
      .join("&");

    setIsLoading(true);
    try {
      const { data } = await getAllCompanyAdmins(queryString);
      setAllCompaniesAdmin(data?.users);
      setPagination((prev) => ({ ...prev, total: data?.count, current: page }));
      setIsLoading(false);
    } catch (error) {
      console.log({ error });
      setIsLoading(false);
    }
  };

  const getAllCompanies = async (page = 1, limit = 10, sortBy, order) => {
    try {
      const { data } = await getAllCompaniesData(page, limit, sortBy, order);
      setAllCompanies(data?.companies);
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    getAllCompanies();
  }, []);

  useEffect(() => {
    getAllCompaniesAdmin();
  }, []);

  const deleteUser = async () => {
    setIsModalOpen(false);
    try {
      const index = allCompaniesAdmin.findIndex(
        (ind) => ind._id === deleteUserID
      );
      const arr = [...allCompaniesAdmin];
      arr.splice(index, 1);
      setAllCompaniesAdmin(arr);
      message.success("Company admin deleted!");
      await deleteUserById(deleteUserID);
      setDeleteUserID(null);
    } catch (error) {
      console.log({ error });
      getAllCompaniesAdmin(
        pagination?.current,
        pagination?.pageSize,
        sortBy,
        sortOrder,
        selectedCompaniesAdmin,
        selectedStatusFilter,
        searchKey
      );
      message.success("Error deleting company admin!");
    }
  };

  const resetFilters = () => {
    setResetIsDisabled(true);
    setSelectedCompaniesAdmin([]);
    setSelectedCompanyOptions([]);
    setSearchKey(null);
    setSelectedStatusFilter(null);
    setSortBy(null);
    setSortOrder(null);
    getAllCompaniesAdmin();
  };

  const debouncedHandleSearch = useCallback(
    debounce((searchValue) => {
      getAllCompaniesAdmin(
        1,
        pagination?.pageSize,
        sortBy,
        sortOrder,
        selectedCompaniesAdmin,
        selectedStatusFilter,
        searchValue
      );
    }, 1000),
    []
  );

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchKey(searchValue);
    setResetIsDisabled(false);
    debouncedHandleSearch(searchValue?.trim());
  };

  return (
    <div>
      <Card style={{ width: "100%" }}>
        <div className="flex justify-between items-center pl-3 pb-4 w-full">
          <Text className="text-2xl font-semibold font-[#1f2232]">
            Company Admins
          </Text>
          <Button
            size="large"
            style={{
              fontSize: "16px",
              lineHeight: "24px",
              textAlign: "left",
              color: "#070908",
              marginBottom: "10px",
            }}
            onClick={() => navigate("/admin/company-admins/add")}
          >
            Add New +
          </Button>
        </div>
        <Divider style={{ margin: 0 }} />
        <Row gutter={12} justify={"space-between"} className="my-2">
          <Col span={16}>
            <Row gutter={12}>
              <Col span={12}>
                <Input
                  size="large"
                  value={searchKey}
                  onChange={(e) => handleSearch(e)}
                  placeholder="Search name..."
                />
              </Col>
              <Col span={12}>
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                    minWidth: "200px",
                    fontSize: "16px",
                    lineHeight: "24px",
                    textAlign: "left",
                    color: "#070908",
                    marginBottom: "10px",
                  }}
                  showSearch
                  size="large"
                  placeholder="Search companies"
                  className="text-left"
                  onChange={handleCompanySelectChange}
                  value={selectedCompanyOptions}
                  options={options}
                />
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row justify={"end"} gutter={12}>
              <Col>
                <Select
                  placeholder="Filter Status"
                  size="large"
                  style={{
                    width: 130,
                    fontSize: "16px",
                    lineHeight: "24px",
                    textAlign: "left",
                    color: "#070908",
                    marginBottom: "10px",
                  }}
                  onChange={handleStatusSelectChange}
                  value={selectedStatusFilter ? selectedStatusFilter[0] : null}
                  options={[
                    {
                      value: "Blocked",
                      label: "Blocked",
                    },
                    {
                      value: "Un Blocked",
                      label: "UnBlocked",
                    },
                  ]}
                />
              </Col>
              <Col>
                <Button
                  disabled={resetIsDisabled}
                  size="large"
                  onClick={resetFilters}
                >
                  Reset Filters
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider style={{ margin: 0 }} />
        {isLoading ? <StyledLoader /> : null}
        <AdminsTable
          onChange={handleTableChange}
          columns={columns}
          data={data}
          pagination={pagination}
        />
        <DeleteModal
          title="Delete record"
          text="Are you sure you want to delete this record?"
          deleteAction={deleteUser}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          isDelete
        />
      </Card>
    </div>
  );
};

export default CompanyAdmins;
