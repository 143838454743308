import { useEffect } from 'react'
import { getCurrentUser } from 'aws-amplify/auth'
import { Spin, message } from 'antd';
import { setUserProfile } from "../store/actions";
import { connect } from 'react-redux';


function SSO({ setUserProfile }) {
  let count = 0
  useEffect(() => {
    (async () => {
      count++
      if (count > 1) {
        return
      }
      try {
        const user = await getCurrentUser();
        setUserProfile({ cognitoId: user.userId, providerId: user.username })
        localStorage.setItem("isSSO", true)
      } catch (err) {
        message.error(err.message || "Something went wrong")
      }
    })()
  }, [])

  // const location = useLocation()
  return <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
    <Spin size="large">
      <div className="content" />
    </Spin>
  </div>;
}

const mapDispatchToProps = { setUserProfile };

export default connect(null, mapDispatchToProps)(SSO);
