import moment from "moment";

export function convertMinutesToDaysOrHours(minutes) {
  const duration = moment.duration(minutes, "minutes");
  const days = duration.asDays();
  const hours = duration.asHours();
  const mins = duration.asMinutes();

  if (days >= 1) {
    return `${days} days`;
  } else if (hours >= 1) {
    return `${hours} hours`;
  } else {
    return `${mins} minutes`;
  }
}

export function calculateDifferenceInDays(isoDateTime) {
  const dateTime = moment(isoDateTime);
  const now = moment();
  const duration = moment.duration(now.diff(dateTime));

  const days = Math.floor(duration.asDays());
  const hours = Math.floor(duration.asHours());
  const minutes = Math.floor(duration.asMinutes());

  if (days >= 1) {
    return `${days} days ago`;
  } else if (hours >= 1) {
    return `${hours} hours ago`;
  } else if (minutes >= 1) {
    return `${minutes} minutes ago`;
  } else {
    return "Less than 1 minute ago";
  }
}

export function convertMinutes(minutes) {
  let returnString = "";

  if (minutes >= 1440) {
    // 1440 minutes in a day
    const days = Math.floor(minutes / 1440);
    let remainingMinutes = minutes % 1440;
    let hours = 0;

    if (remainingMinutes >= 60) {
      hours = Math.floor(remainingMinutes / 60);
      remainingMinutes = remainingMinutes % 60;
    }

    if (days > 0) {
      returnString += `${parseInt(days)}D `;
    }
    if (hours > 0) {
      returnString += `${parseInt(hours)}H `;
    }
    if (remainingMinutes > 0) {
      returnString += `${parseInt(remainingMinutes)}M `;
    }
  } else if (minutes >= 60) {
    // 60 minutes in an hour
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    if (hours > 0) {
      returnString += `${parseInt(hours)}H `;
    }
    if (remainingMinutes > 0) {
      returnString += `${parseInt(remainingMinutes)}M `;
    }
  } else {
    returnString = `${parseInt(minutes)}M`;
  }
  return returnString;
}
