import React, { useState, useEffect } from "react";
import TopBar from "./TopBar";
import "./index.css";
import { Col, Row, Skeleton } from "antd";

export default function CandidateDashboardHeader({
  bgURL,
  title,
  button,
  isLoading,
}) {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setIsScrolled(offset > 0);
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Dynamic classes for the top bar background and transition
  const topBarClasses = `bg-black flex justify-center fixed top-0 w-full transition-all duration-300 ${
    isScrolled ? " bg-opacity-100" : "bg-opacity-0"
  }`;

  return (
    <div
      style={{
        background: `url('${bgURL}')`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 560px",
        minWidth: "100vw",
        minHeight: "560px",
        backgroundAttachment: "fixed",
        overflowX: "hidden",
      }}
      className="relative w-full"
    >
      <div
        className="absolute w-full h-full"
        style={{
          zIndex: 1,
          backgroundColor: "#00000060",
        }}
      />
      <div className={topBarClasses} style={{ zIndex: 4 }}>
        <div className="w-full my-6" style={{ maxWidth: "1150px" }}>
          <TopBar />
        </div>
      </div>
      <div
        className="absolute w-full h-full flex justify-center items-center"
        style={{ zIndex: 2 }}
      >
        <div className="mb-6">
          <div className="w-full flex items-center text-white px-12">
            {isLoading ? (
              <div className="ml-6 w-full">
                <Row className="w-1/2">
                  <Col span={24}>
                    <Skeleton active />
                  </Col>
                </Row>
              </div>
            ) : (
              <div className="flex flex-col align-center gap-6 mt-10">
                <p className="heading">{title || ""}</p>
                <p className="description-challenge">
                  The goal of the challenge is to know you a little better and
                  assess your skill set while having some fun.
                </p>
                <div className="flex justify-around">
                  <DescriptionItem
                    description={
                      "You need is your web browser and your favorite set of cybersecurity tools"
                    }
                    number="1"
                  />
                  <DescriptionItem
                    description={
                      "Please don’t post the scenario with anyone else"
                    }
                    number="2"
                  />
                  <DescriptionItem
                    description={
                      "You may make multiple attempts for each questions"
                    }
                    number="3"
                  />
                </div>
              </div>
            )}
          </div>
          {button}
        </div>
      </div>
    </div>
  );
}

const DescriptionItem = ({ number, description }) => {
  return (
    <div className="item-of-description">
      <p className="number rounded-full">{number}</p>
      <p className="description-t">{description}</p>
    </div>
  );
};
