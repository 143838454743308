import { Card } from "antd";
import { updateUserProfile } from "../../store/actions";
import { connect } from "react-redux";
import Profile from "../Candidate/Profile";
import Password from "../Candidate/Password";
import MFA from "../Auth/MFA";
import Organization from "../Organization";

const ProfileComponent = ({ user }) => {
  return (
    <div className="w-full">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: 1500,
          margin: "auto",
        }}
        className="px-10 py-10"
      >
        <Profile />
        {localStorage.getItem("isSSO") === "false" ? (
          <>
            {" "}
            <Password />
            <MFA />
          </>
        ) : null}
        {user && user.roles.includes("COMPANY_ADMIN") ? <Organization /> : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = {
  updateUserProfile: updateUserProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileComponent);
