import { useState, useEffect } from 'react'
import { Button, Select, Input, DatePicker, Form, message, Upload, Tooltip, Card } from 'antd'
import Table from '../../components/Tables/Table'
import ModalWrapper from '../../components/Modals/ModalWrapper'
import moment from 'moment';
import { deleteInvitation, fetchAllInvitesOfCompany, getAllAssessmentsOfCompany, inviteCandidate } from '../../services/api';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { UploadOutlined, DeleteFilled, EyeFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { StyledLoader as Loader } from '../../components/loader'
const { Option } = Select;

function CompanyAdmin({ user }) {
    const [inviteModalVisible, setInviteModalVisible] = useState("")
    const [invitations, setInvitations] = useState([])
    const [assessments, setAssessments] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 5,
        total: 0, // You might not know this until you fetch the first set of data
    });
    const navigate = useNavigate()

    useEffect(() => {
        handleTableChange(pagination, {}, {})
        fetchAssessments()
    }, [])
    const fetchAssessments = async () => {
        if (user.companyIds.length) {
            try {
                const { data } = await getAllAssessmentsOfCompany(
                    user.companyIds[0],
                );
                setAssessments(data?.company?.flatMap(({ assessments }) => assessments) || [])
            } catch (error) {
                message.error(error.message || "Something went wrong")
            }
        }
    }

    const fetchInvitations = async (query, currentPage) => {
        try {
            setIsLoading(true)
            const response = await fetchAllInvitesOfCompany(user.companyIds[0], query)
            const { data } = response
            const { invitations } = data

            setInvitations(invitations.map((invitation) => ({
                name: invitation.userDetails.length ? invitation.userDetails[0].firstName + " " + invitation.userDetails[0].lastName : "-",
                email: invitation.email,
                status: invitation.status,
                expiry: moment(invitation.expiryTime).format('hh:MM A DD/MM/YY'),
                url: `${window.location.host}/candidate/invitation/${invitation.invitationToken}`,
                key: invitation._id,
                assessmentId: invitation.assessmentId
            })))
            setPagination(() => ({
                ...pagination, total: data?.count || 0, current: currentPage
            }))
        } catch (error) {
            message.error(error.message || "Something went wrong")
        } finally {
            setIsLoading(false)
        }
    }

    const handleDelete = async (id) => {

        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    setIsLoading(true)
                    await deleteInvitation(id)
                    message.success("Invitation successfully deleted")
                    fetchInvitations()
                    Swal.fire({
                        title: "Deleted!",
                        text: "Invitation has been deleted.",
                        icon: "success"
                    });
                } catch (error) {
                    Swal.fire({
                        title: "ERROR!",
                        text: error.message || "Something went wronf",
                        icon: "error"
                    });
                } finally {
                    setIsLoading(false)
                }
            }
        });

    }

    function copyTextToClipboard(text) {
        navigator.clipboard.writeText(text).then(
            function () { },
            function (err) {
                console.error("Could not copy text: ", err);
            }
        );
    }

    // const handleStatusChange = async (status, id) => {
    //     setIsLoading(true)
    //     try {
    //         await changeInviteStatus(id, status)
    //         message.success("Status successfully Updated")
    //     } catch (err) {
    //         message.error(err.message || "Something went wrong");
    //     } finally {
    //         setIsLoading(false)
    //     }
    // }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '30%',
            sorter: true,
        },
        {
            title: 'email',
            dataIndex: 'email',
            key: 'email',
            width: '20%',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Expiry',
            dataIndex: 'expiry',
            key: 'expiry',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: true,
        },
        {
            title: 'Copy URL',
            dataIndex: 'url',
            key: 'url',
            render: (_, item) => {
                return <Button onClick={() => copyTextToClipboard(item.url)}>Copy</Button>
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_, item) => {
                // const status = ["SENT", "FAILED", "CANCELED", "EXPIRED"]
                return <div style={{ display: "flex", gap: 2 }}>
                    <Tooltip placement="topLeft" title="Delete Invite">
                        <DeleteFilled style={{ cursor: "pointer" }} onClick={() => handleDelete(item.key)} />
                    </Tooltip>
                    {/* {item.status === "SENT" ? <Tooltip placement="topLeft" title="Cancel Invite">
                        <CloseCircleFilled style={{ cursor: "pointer" }} onClick={() => handleStatusChange("CANCEL", item.key)} />
                    </Tooltip> : null}
                    {status.includes(item.status) ? <Tooltip placement="topLeft" title="Cancel Invite">
                        <RedoOutlined style={{ cursor: "pointer" }} onClick={() => handleStatusChange("RESEND", item.key)} />
                    </Tooltip> : null} */}
                    <Tooltip placement="topLeft" title="View Invite">
                        <EyeFilled style={{ cursor: "pointer" }} onClick={() => navigate(`/company/admin/invites/assessment/view?id=${item.assessmentId}`)} />
                    </Tooltip>
                </div>
            }
        },
    ];
    const handleTableChange = (pagination, filters, sorter) => {
        let query = `pageNo=${pagination.current}&pageSize=${pagination.pageSize}`
        const filtersKeys = Object.keys(filters)

        if (filtersKeys.length) {
            filtersKeys.forEach((filter) => {
                if (filters[filter]) {
                    query += `&${filter}=${filters[filter][0]}`
                }
            })
        }

        if (sorter.field && sorter.order) {
            query += `&sortBy=${sorter.field}&order=${sorter.order}`
        }
        setPagination(pagination)
        fetchInvitations(query, pagination.current);
    };

    return <Card>
        {isLoading ? <Loader /> : null}
        <div style={{ display: "flex", justifyContent: "flex-end", gap: 5, marginBottom: "10px" }}>
            <Button type="primary" size="large" onClick={() => setInviteModalVisible("individual")}>
                Invite
            </Button>
            <Button type="primary" size="large" onClick={() => setInviteModalVisible("bulk")}>
                Bulk Invite
            </Button>
        </div>
        {inviteModalVisible.length ? <InviteFormModal user={user} isBulk={inviteModalVisible === "bulk"} assessments={assessments} fetchInvitations={fetchInvitations} onClose={setInviteModalVisible} /> : null}
        <Table columns={columns}
            data={invitations}
            onChange={handleTableChange}
            pagination={pagination}
            isSearch
            callback={fetchInvitations}
        />
    </Card>
}

const mapStateToProps = (state) => ({
    user: state.auth.user
});
const mapDispatchToProps = {
    // getSelectedChallenge: ChallengeSlice.actions.getSelectedChallenge,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyAdmin);

const InviteFormModal = ({ onClose, assessments, isBulk, user, fetchInvitations }) => {
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState([]);
    const [isAssessmentSelected, setIsAssessmentSelected] = useState(false);
    const [emails, setEmails] = useState([]);

    const beforeUpload = (file) => {

        const isCSV = file.type === 'text/csv';
        if (!isCSV) {
            message.error(`${file.name} is not a CSV file`);
        }


        const reader = new FileReader();

        reader.onload = e => {
            const data = e.target.result.split('\n');
            data.shift()
            setEmails(data)
        };
        reader.readAsText(file);

        return isCSV || Upload.LIST_IGNORE;

    };

    const onFinish = async (values) => {
        try {
            let bulkEmail
            if (isBulk) {
                bulkEmail = emails.map((email) => {
                    if (email.length) {
                        return email
                    }
                    return undefined
                }).filter((e) => e !== undefined)
                const uniqueEmailsSet = new Set(emails);
                bulkEmail = Array.from(uniqueEmailsSet);
            }
            await inviteCandidate(user.companyIds[0], values.assessment, {
                emails: isBulk ? bulkEmail : [values.email],
                expiryTime: values.date,
                invitedBy: user._id
            })
            message.success("Invitation successfully sent")
            onClose("")
            fetchInvitations()
        } catch (error) {
            message.error(error.message || "Something went wrong");
        }
    };

    const handleFileChange = (e) => {
        setFileList(e.fileList);
    };


    // Cancel handler
    const onCancel = () => {
        form.resetFields();
        onClose(false);
    };

    return (
        <ModalWrapper
            title={"Invite Candidate"}
            handleClose={onCancel}
            footer={null} // No footer needed as buttons are inside form
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                initialValues={{
                    email: '',
                    date: null,
                    assessment: undefined
                }}
            >

                <Form.Item
                    label="Expiry Date"
                    name="date"
                    rules={[
                        { required: true, message: 'Please select a date!' },
                        () => ({
                            validator(_, value) {
                                if (!value || value.isAfter(moment())) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The date must be in the future!'));
                            },
                        }),
                    ]}
                >
                    <DatePicker
                        style={{ height: '48px', width: "100%" }}
                        placeholder="Select date"
                        disabledDate={(current) => {
                            let customDate = moment().format("YYYY-MM-DD");
                            return current && current < moment(customDate, "YYYY-MM-DD");
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label="Assessment"
                    name="assessment"
                    rules={[{ required: true, message: 'Please select an assessment!' }]}
                >
                    <Select
                        placeholder="Select Assessment"
                        onChange={(value) => setIsAssessmentSelected(!!value)}
                    >
                        {
                            assessments.map(({ _id, title }) => <Option value={_id}>{title}</Option>)
                        }
                    </Select>
                </Form.Item>

                {isBulk ?
                    <Form.Item
                        label="CSV File"
                        name="fileList"
                        valuePropName="fileList"
                        getValueFromEvent={handleFileChange}
                    // rules={[{ required: true, message: 'Please upload a CSV file!' }]}
                    >
                        <Upload
                            beforeUpload={beforeUpload}
                            onChange={handleFileChange}
                            fileList={fileList}
                            disabled={!isAssessmentSelected}
                            accept=".csv"
                        >
                            <Button icon={<UploadOutlined />}>Click to upload CSV</Button>
                        </Upload>
                    </Form.Item>
                    : <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            { required: true, message: 'Please input candidate’s email!' },
                            { type: 'email', message: 'The input is not a valid email!' }
                        ]}
                    >
                        <Input
                            placeholder="Enter candidate's email"
                            style={{ height: '48px' }}
                        />
                    </Form.Item>}
                <div style={{ display: "flex", justifyContent: "flex-end", gap: "8px" }}>
                    <Button onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button type="primary" htmlType="submit">
                        Continue
                    </Button>
                </div>
            </Form>
        </ModalWrapper>
    );
};