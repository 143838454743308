import React from 'react';
import { Modal } from 'antd';

const ModalWrapper = ({ children, open = true, footer = null, handleClose, title }) => {
    return (
        <Modal theme="dark" title={title} open={open} onOk={handleClose} onCancel={handleClose} style={{ width: "max-content" }} footer={footer}>
            {children}
        </Modal>
    );
};

export default ModalWrapper;