import React, { useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { FiInbox } from "react-icons/fi";

import { message, Upload } from "antd";
const { Dragger } = Upload;
const UploadFile = ({
  uploadAPI,
  setResourcePath,
  accept,
  disabled = false,
}) => {
  const [fileList, setFileList] = useState([]);
  const props = {
    name: "attachment",
    multiple: false,
    accept: accept,
    action: uploadAPI,
    beforeUpload: (file) => {
      if (accept === ".zip" && !file.name.includes(accept)) {
        message.error("Only .zip files are allowed");
        setFileList([]);
        return Upload.LIST_IGNORE;
      }
      return true;
    },
    onChange(info) {
      const { status } = info.file;

      if (status === "done") {
        const urlArrays = info.fileList?.map((item) => item?.response?.URLs[0]);
        // setFileList(urlArrays);
        setResourcePath(urlArrays);
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };
  return (
    <Dragger disabled={disabled} {...props}>
      <p className="ant-upload-drag-icon">
        <FiInbox size={40} />
      </p>
      <p className="ant-upload-text">
        Click or drag file to this area to upload
      </p>
    </Dragger>
  );
};
export default UploadFile;
