import React from "react";
import UserProfileComponent from "../../UserProfileComponent";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

function TopBar({ user }) {
  const navigate = useNavigate();
  return (
    <div className="w-full flex justify-between items-center">
      <div className=" flex items-center">
        <img
          style={{
            height: "40px",
            cursor: "pointer",
          }}
          onClick={() => navigate("/user/assessment-listing")}
          src="../../assets/logo-dark.png"
          alt="Cyber hire"
        />
        <p
          className="btnActive ml-12 cursor-pointer"
          onClick={() => navigate("/user/assessment-listing")}
        >
          Talent
        </p>
        <p
          className="btn ml-10 cursor-pointer"
          onClick={() =>
            window.open("https://support.cyber-hire.com", "_blank")
          }
        >
          Support
        </p>
      </div>
      <div>
        <UserProfileComponent
          userName={`${user?.firstName || ""} ${user?.lastName || ""}`}
          userImage={user?.profilePic}
          notification={1}
          nameColor={"#FFFFFF"}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = {
  // stopAssessment: ChallengeSlice.actions.stopAssessment,
};

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
