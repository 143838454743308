import {
  Button,
  Card,
  Col,
  Divider,
  Input,
  Modal,
  Row,
  Space,
  Tooltip,
  Typography,
  message,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  associatCompaniesAndAssessments,
  deleteAssessmentById,
  getAllAssessments,
  getAllAssessmentsOfCompany,
  getAllCompaniesData,
} from "../../../services/api";
import { DeleteOutlined, EditOutlined, CopyOutlined } from "@ant-design/icons";
import { StyledLoader } from "../../../components/loader";
import DeleteModal from "../../../components/Modals/DeleteModal";
import AssessmentTable from "../../../components/Tables/AssessmentTable";
import CompaniesModal from "../../../components/Modals/CompaniesModal";
import { connect } from "react-redux";
import { debounce } from "lodash";
import { convertMinutes } from "../../../utils";

const { Text } = Typography;
const Assessments = ({ user }) => {
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCopyModalOpen, setIsCopyModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [linkCopied, setLinkCopied] = useState("");
  const [showAssociateCompanies, setShowAssociateCompanies] = useState(false);
  const [allAssessments, setAllAssessments] = useState([]);
  const [selectedAssessments, setSelectedAssessments] = useState([]);
  const [deleteAssessmentID, setDeleteAssessmentID] = useState(null);
  const [allCompanies, setAllCompanies] = useState(null);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [assessmentWithTokens, setAssessmentWithTokens] = useState([]);
  const [searchedAssessments, setSearchedAssessments] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0, // You might not know this until you fetch the first set of data
  });

  const navigate = useNavigate();

  useEffect(() => {
    setSearchedAssessments(allAssessments);
  }, [allAssessments]);

  useEffect(() => {
    // const user = JSON.parse(localStorage.getItem("user"));
    user?.roles?.map((role) => {
      if (role === "SUPER_ADMIN") {
        setIsSuperAdmin(true);
      } else {
        setIsSuperAdmin(false);
      }
      return null;
    });
  }, [allAssessments]);

  const getAllCompanies = async () => {
    try {
      const { data } = await getAllCompaniesData();
      setAllCompanies(data?.companies);
    } catch (error) {
      message.error(error.message || "Something went wrong");
    }
  };

  useEffect(() => {
    getAllCompanies();
  }, []);

  const handleDeleteModal = (id) => {
    setDeleteAssessmentID(id);
    setIsModalOpen(true);
  };

  function copyTextToClipboard(text) {
    navigator.clipboard.writeText(text).then(
      function () {},
      function (err) {
        console.error("Could not copy text: ", err);
      }
    );
  }

  const handleCopy = (id) => {
    setIsCopyModalOpen(true);
    const index = assessmentWithTokens.findIndex(
      (ind) => ind.assessmentId === id
    );
    setLinkCopied(
      `${window.location.host}/assessment/invitation/confirmation/${assessmentWithTokens[index]?.token}`
    );
  };

  const columns = [
    {
      title: "Sr No.",
      dataIndex: "key",
      key: "key",
      render: (text) => <Text>{text || "--"}</Text>,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text) => <Text>{text || "--"}</Text>,
      sorter: true,
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      render: (text) => <Text>{text || "--"}</Text>,
      sorter: true,
    },
    {
      title: "Number of Challenges",
      dataIndex: "challengesList",
      key: "challengesList",
      render: (text) => <Text>{text || "--"}</Text>,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <Space size="middle">
            {isSuperAdmin ? (
              <>
                <EditOutlined
                  className="hover"
                  onClick={() =>
                    navigate(`/admin/assessment/edit?id=${record?._id}`)
                  }
                />
                <DeleteOutlined
                  className="hover"
                  onClick={() => handleDeleteModal(record?._id)}
                />
                <CopyOutlined
                  className="hover"
                  onClick={() =>
                    navigate(`/admin/assessment/duplicate?id=${record?._id}`)
                  }
                />
              </>
            ) : (
              <>
                <Button type="link">View</Button>
                <Button type="link" onClick={() => handleCopy(record?._id)}>
                  Copy
                </Button>
              </>
            )}
          </Space>
        );
      },
    },
  ];
  const data = [
    ...searchedAssessments.map((item, i) => {
      return {
        _id: item?._id,
        key: i + 1,
        title: item?.title,
        duration: convertMinutes(item.duration),
        status: item?.status,
        challengesList: isSuperAdmin
          ? item?.challengesList?.length
          : item?.challengeIdsWithSequences?.length,
      };
    }),
  ];

  const handleTableChange = (cPagination, filters, sorter) => {
    if (pagination.current !== cPagination.current) {
      setSelectedAssessments([]);
      setSelectedRowKeys([]);
    }
    getAllAssessmentsData(
      cPagination?.current,
      cPagination?.pageSize,
      sorter.order ? sorter.field : null,
      sorter.order,
      searchKey
    );
  };

  function isSuperAdminRole(roles) {
    return roles?.includes("SUPER_ADMIN");
  }

  async function fetchAndSetAllAssessments(
    companyIds,
    page = 1,
    limit = 10,
    sortBy,
    order
  ) {
    if (!companyIds || companyIds.length === 0) {
      setAllAssessments([]);
      setSearchedAssessments([]);
      return;
    }

    try {
      const allAssessmentsResults = await Promise.all(
        companyIds.map(async (company) => {
          const { data } = await getAllAssessmentsOfCompany(
            company,
            page,
            limit,
            sortBy,
            order
          );
          setPagination((prev) => ({
            ...prev,
            total: data?.count,
            current: page,
          }));
          const assessments = data?.company.flatMap(
            (comp) => comp.assessments || []
          );
          const assessmentIdsWithTokens = data?.company.flatMap(
            (comp) => comp.assessmentIdsWithTokens || []
          );
          return { assessments, assessmentIdsWithTokens };
        })
      );

      const assessments = allAssessmentsResults
        .flatMap((result) => result.assessments)
        .filter(Boolean);
      const assessmentIdsWithTokens = allAssessmentsResults
        .flatMap((result) => result.assessmentIdsWithTokens)
        .filter(Boolean);

      setAssessmentWithTokens(assessmentIdsWithTokens);
      setAllAssessments(assessments);
      setSearchedAssessments(assessments);
    } catch (error) {
      message.error("Failed to fetch assessments");
      setAllAssessments([]);
      setSearchedAssessments([]);
      setAssessmentWithTokens([]);
    }
  }

  const getAllAssessmentsData = async (
    page = 1,
    limit = 10,
    sortBy,
    order,
    search = searchKey
  ) => {
    setIsLoading(true);
    try {
      if (isSuperAdminRole(user?.roles)) {
        const { data } = await getAllAssessments(
          page,
          limit,
          sortBy,
          order,
          search
        );
        setAllAssessments(data?.assessments);
        setPagination((prev) => ({
          ...prev,
          total: data?.count,
          current: page,
        }));
        setSearchedAssessments(data?.assessments);
      } else {
        await fetchAndSetAllAssessments(
          user?.companyIds,
          page,
          limit,
          sortBy,
          order,
          search
        );
      }
    } catch (error) {
      console.error("Error fetching assessments:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllAssessmentsData();
  }, []);

  const deleteAssessment = async () => {
    setIsModalOpen(false);
    try {
      const index = allAssessments.findIndex(
        (ind) => ind._id === deleteAssessmentID
      );
      const arr = [...allAssessments];
      arr.splice(index, 1);
      setAllAssessments(arr);
      message.success("Assessment deleted!");
      await deleteAssessmentById(deleteAssessmentID);
      setDeleteAssessmentID(null);
    } catch (error) {
      getAllAssessmentsData();
      message.error("Error deleting assessment!");
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedAssessments(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      name: record.name,
    }),
    // selections: false,
    // preserveSelectedRowKeys: false,
    // checkStrictly: true,
    selectedRowKeys,
  };

  const associatCompanies = async () => {
    if (selectedCompanies.length) {
      try {
        setShowAssociateCompanies(false);
        setSelectedAssessments([]);
        setSelectedCompanies([]);
        message.success("Assessments assigned successfully");
        const companyIds = selectedCompanies.map((item) => item._id);
        const assessmentsIds = selectedAssessments.map((item) => item._id);
        await associatCompaniesAndAssessments({
          companyIds,
          assessmentsIds,
        });
        setSelectedRowKeys([]);
      } catch (error) {
        message.error(error.message || "Something went wrong");
      }
    } else {
      message.error("Kindly select atleast 1 company!");
    }
  };

  const debouncedHandleSearch = useCallback(
    debounce((searchKey) => {
      getAllAssessmentsData(1, pagination?.pageSize, null, null, searchKey);
    }, 1000),
    []
  );

  const handleSearch = ({ target: { value } }) => {
    setSearchKey(value);
    debouncedHandleSearch(value);
  };

  const handleCancel = () => {
    setIsCopyModalOpen(false);
  };
  return (
    <div>
      <Card style={{ width: "100%" }}>
        <div className="flex justify-between items-center px-3 pb-4 w-full">
          <p
            style={{
              fontSize: "16px",
              fontWeight: "600",
              lineHeight: "24px",
              textAlign: "left",
              color: "#070908",
              marginBottom: "10px",
            }}
          >
            Assessment
          </p>
          <div className="flex">
            {isSuperAdmin ? (
              <>
                {selectedAssessments.length ? (
                  <Button
                    className="mr-2"
                    disabled={selectedAssessments.length ? false : true}
                    size="large"
                    onClick={() => setShowAssociateCompanies(true)}
                  >
                    Associate Companies
                  </Button>
                ) : (
                  <Tooltip title="Select assessments from the table to make this button active">
                    <Button
                      className="mr-2"
                      disabled={selectedAssessments.length ? false : true}
                      size="large"
                      onClick={() => setShowAssociateCompanies(true)}
                    >
                      Associate Companies
                    </Button>
                  </Tooltip>
                )}
                <Button
                  size="large"
                  onClick={() => navigate("/admin/assessment/add")}
                >
                  Add New +
                </Button>
              </>
            ) : null}
          </div>
        </div>
        <Divider style={{ margin: 0 }} />
        <Col span={8} className="py-2">
          <Input
            size="large"
            value={searchKey}
            onChange={(e) => handleSearch(e)}
            placeholder="Search..."
          />
        </Col>
        <Divider style={{ margin: 0 }} />
        {isLoading ? <StyledLoader /> : null}
        <AssessmentTable
          onChange={handleTableChange}
          columns={columns}
          data={data}
          pagination={pagination}
          rowSelection={
            isSuperAdmin
              ? {
                  type: "checkbox",
                  ...rowSelection,
                }
              : null
          }
        />
        <DeleteModal
          title="Delete record"
          text="Are you sure you want to delete this record?"
          deleteAction={deleteAssessment}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          isDelete
        />
        <CompaniesModal
          selectedCompanies={selectedCompanies}
          setSelectedCompanies={setSelectedCompanies}
          allCompanies={allCompanies}
          title="Select Companies"
          action={associatCompanies}
          isModalOpen={showAssociateCompanies}
          setIsModalOpen={setShowAssociateCompanies}
        />
        <Modal
          title="Copy link"
          open={isCopyModalOpen}
          footer={false}
          onCancel={handleCancel}
        >
          <div className="py-5">
            <Input type="text" value={linkCopied} />
          </div>
          <Row justify={"end"}>
            <Button
              onClick={() => {
                copyTextToClipboard(linkCopied);
                setIsCopyModalOpen(false);
                message.success("Link copied!");
              }}
            >
              Copy
            </Button>
          </Row>
        </Modal>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Assessments);
