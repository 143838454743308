import { connect } from "react-redux";
import SiderComponent from "../components/Sidebar";
import UserProfileComponent from "../components/UserProfileComponent";
import { Divider, Layout } from "antd";
import { useNavigate } from "react-router-dom";
import ModalWrapper from "../components/Modals/ModalWrapper";
import MFA from "../pages/Auth/MFA";
import { useEffect, useState } from 'react'
import { getCompanyDataAPI } from "../store/actions/index";
import ProvideAuth from "../context/Auth";

function CompanyAdminLayout({ user, children, type, getCompanyDataAPI, company }) {
    const [isMFAModalOpen, setIsOpenMFAModal] = useState(false)
    const navigate = useNavigate()
    useEffect(() => {
        if (!user.roles?.includes("COMPANY_ADMIN")) {
            navigate(-1)
            return
        }
        getCompanyDataAPI(user.companyIds[0])
    }, [])

    return <ProvideAuth ><div style={{ position: "relative", display: "flex" }}>
        <div className="w-full flex justify-between items-center absolute left-0 right-0 !bg-[#F5F5F5]" style={{ background: "#F5F5F5 !important", paddingLeft: "20% !important", padding: "25px" }}>
            <div className=" flex items-center" style={{ paddingLeft: "275px" }}>
                <p className="ml-12 btn" style={{
                    fontSize: "24px",
                    fontWeight: "600",
                    lineHeight: "36px",
                    textAlign: "left",
                    color: "#1F2232"
                }}>{type}</p>
            </div>
            <p style={{
                fontSize: "20px",
                fontWeight: "500",
                lineHeight: "36px",
                textAlign: "left",
                color: "#1F2232"
            }}>Invites: {company?.usedInvites || 0}/{company?.totalInvites || 0} </p>
            <div style={{ marginRight: "3%" }}>
                <UserProfileComponent
                    userName={user.firstName + " " + user.lastName}
                    userImage={user.profilePic}
                    // notification={1}
                    nameColor={"green"}
                    customProfileMenu={[]}
                />
            </div>
        </div >
        {
            isMFAModalOpen ? (
                <ModalWrapper
                    open={isMFAModalOpen}
                    handleClose={() => setIsOpenMFAModal(false)
                    }
                >
                    <MFA />
                </ModalWrapper >
            ) : null}
        <SiderComponent />
        <Layout style={{ width: "75vw", height: "100vh", padding: "22px", overflow: "hidden" }}>
            <Divider style={{ marginTop: "67px", position: "sticky" }} />
            <div style={{ overflowY: "scroll", borderRadius: "8px", overflowX: "hidden" }}>
                {children}
            </div>

        </Layout>
    </div>
    </ProvideAuth>
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    company: state.auth.company,
});
const mapDispatchToProps = {
    getCompanyDataAPI
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyAdminLayout);