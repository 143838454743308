import { Button, Form, Input, message } from "antd";
import "./Auth.css";
import AuthBackground from "../../components/Backgrounds/AuthBackground";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { confirmResetPassword } from "@aws-amplify/auth";

function NewPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  useEffect(() => {
    // Clear input values on component mount
    form.setFieldsValue({
      email: "",
      password: "",
    });
  }, []);

  const handleConfirmResetPassword = async (values) => {
    try {
      setIsLoading(true);
      const email = params.get("email");
      if (!email.length) {
        message.error("Please enter your email");
        navigate("reset-password");
        return;
      }
      const { password, confirmationCode } = values;
      await confirmResetPassword({
        confirmationCode,
        newPassword: password,
        username: email,
      });
      message.success("Password reset successfully please login now.");
      navigate("/login");
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthBackground>
      <div className="w-full flex flex-col items-center mt-7">
        <p className="title">Create New Password</p>
        <p className="description-2 mt-4">
          Enter your new password to continue
        </p>
        <div className="w-full mt-8">
          <Form
            form={form}
            layout="vertical"
            className="w-full"
            onFinish={handleConfirmResetPassword}
          >
            <Form.Item
              label="Confirmation Code"
              name="confirmationCode"
              className="w-full custom-form-item"
              rules={[
                {
                  required: true,
                  message: "Please enter your confirmation code!",
                },
              ]}
            >
              <Input
                className="custom-input input"
                size="large"
                style={{ borderColor: "#E6E6EB10" }}
                placeholder="Please enter your confirmation code"
              />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              className="w-full custom-form-item"
              rules={[
                {
                  required: true,
                  message: "Please enter your password!",
                },
              ]}
            >
              <Input.Password
                className="custom-input input password"
                size="large"
                style={{ borderColor: "#E6E6EB10" }}
                placeholder="Please enter your password"
              />
            </Form.Item>

            <Form.Item
              label="Confirm Password"
              name="confirmPassword"
              className="w-full custom-form-item"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                className="custom-input input password"
                size="large"
                style={{ borderColor: "#E6E6EB10" }}
                placeholder="Confirm your password"
              />
            </Form.Item>

            <Button
              size="large"
              htmlType="submit"
              style={{
                height: "44px",
                fontSize: "16px",
                fontWeight: 500,
                border: "none",
              }}
              loading={isLoading}
              // disabled={isLoading}
              type="primary"
              className="w-full"
            >
              Save
            </Button>

            <div className="w-full flex justify-between mt-8">
              <Button
                type="link"
                className="flex items-center"
                onClick={() => navigate("/login")}
              >
                <img
                  src="assets/icons/Back.png"
                  style={{ width: "18px", height: "12px" }}
                  alt="back to sign in"
                />
                <p
                  className="ml-2"
                  style={{
                    color: "#FFFFFF",
                  }}
                >
                  Back to Sign In
                </p>
              </Button>
              <Button
                type="link"
                className="flex items-center"
                onClick={() => navigate("/reset-password")}
              >
                <p
                  className="ml-2"
                  style={{
                    color: "#FFFFFF",
                  }}
                >
                  Resend OTP
                </p>
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </AuthBackground>
  );
}

export default NewPassword;
