import React, { useState, useEffect } from "react";
import "./index.css";
import CandidateLayout from "../../layout/CandidateLayout";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  createAssessmentsMapping,
  endMyAssessment,
  getAssessmentResultsAssessmentMappingId,
  getSettings,
  startMyAssessment,
  validateToken,
} from "../../services/api";
import { Button, Col, Divider, Row, Skeleton, message } from "antd";
import { connect } from "react-redux";
import { StyledLoader } from "../../components/loader";

const Details = ({ user }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [assessment, setAssessment] = useState({});
  const [assessmentId, setAssessmentId] = useState(null);
  const [companyId, setCompanyId] = useState("");
  const [allSettings, setAllSettings] = useState([]);
  const [searchParams] = useSearchParams();
  const from = searchParams.get("from");
  const mappingId = searchParams.get("mappingId");

  const { id, compId } = useParams();

  const getAssessmentDetails = async (assessmentId) => {
    setIsLoading(true);
    try {
      const userId = user._id;
      const { data } = await getAssessmentResultsAssessmentMappingId(
        userId,
        assessmentId,
        compId || companyId,
        mappingId
      );
      setAssessment(data);
      setAssessmentId(data?.assessmentId);
    } catch (error) {
      message.error(
        error?.response?.data?.error || error?.message || "Something went wrong"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const validateMyToken = async () => {
    setIsLoading(true);
    try {
      const { data } = await validateToken(id);
      getAssessmentDetails(data?.assessmentId);
      const userId = user._id;
      assessmentsMapping(userId, data?.assessmentId, data?.companyId);
      setCompanyId(data?.companyId);
    } catch (error) {
      message.error(
        error?.response?.data?.error || error?.message || "Something went wrong"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const assessmentsMapping = async (userId, assessmentId, companyId) => {
    try {
      const isOpenUrl = localStorage.getItem("isOpenURL");
      let data = {
        userId,
        assessmentId,
        companyId,
      };
      if (isOpenUrl) {
        data.type = "openUrl";
      }
      localStorage.removeItem("isOpenURL");
      await createAssessmentsMapping(data);
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    if (id.length > 70) {
      validateMyToken();
    } else {
      getAssessmentDetails(id);
    }
  }, [id]);

  const startAssessment = async () => {
    try {
      setIsLoading(true);
      await startMyAssessment({
        assessmentId,
        companyId: compId || companyId,
        userId: user._id,
      });
      if (from === "listing") {
        navigate(
          `/assessment-details/${assessmentId}/${
            compId || companyId
          }?from=listing&mappingId=${mappingId}`
        );
        return;
      }
      navigate(`/assessment-details/${assessmentId}/${compId || companyId}`);
    } catch (error) {
      message.error(
        error?.response?.data?.error || error?.message || "Something went wrong"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const resumeAssessment = async () => {
    try {
      if (from === "listing") {
        navigate(
          `/assessment-details/${assessmentId}/${
            compId || companyId
          }?from=listing&mappingId=${mappingId}`
        );
        return;
      }
      navigate(`/assessment-details/${assessmentId}/${compId || companyId}`);
    } catch (error) {
      message.error(error.message || "Something went wrong");
    }
  };

  const handleMarkDone = async () => {
    try {
      setIsLoading(true);
      setAssessment({
        ...assessment,
        status: "COMPLETED",
      });
      await endMyAssessment(
        user._id,
        assessmentId,
        compId || companyId,
        assessment._id
      );
      message.success("Assessment has been marked as done!");
    } catch (error) {
      message.error(
        error?.response?.data?.error || error?.message || "Something went wrong"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getAllSettings = async () => {
    try {
      const { data } = await getSettings();
      setAllSettings(data?.settings);
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    getAllSettings();
  }, []);

  return (
    <div>
      {isLoading ? <StyledLoader /> : null}
      <CandidateLayout
        title={
          assessment?.assessmentTitle ? (
            assessment?.assessmentTitle
          ) : (
            <Skeleton.Button size="small" active />
          )
        }
        button={
          user ? (
            assessment?.status === "UNATTEMPTED" ? (
              <Button
                size="large"
                style={{
                  height: "44px",
                  fontSize: "16px",
                  fontWeight: 500,
                  border: "none",
                  color: "#1E1D1D",
                  width: "412px",
                }}
                onClick={startAssessment}
                //   loading={isLoading}
                //   disabled={disabled}
                type="primary"
                className="mt-6"
              >
                Start Challenge
              </Button>
            ) : assessment?.status === "IN_PROGRESS" ? (
              <Row justify={"center"} gutter={12}>
                <Col>
                  <Button
                    size="large"
                    htmlType="submit"
                    style={{
                      height: "44px",
                      fontSize: "16px",
                      fontWeight: 500,
                      border: "none",
                      color: "#1E1D1D",
                      width: "243px",
                    }}
                    onClick={resumeAssessment}
                    type="primary"
                    className="mt-6"
                  >
                    Resume Challenge
                  </Button>
                </Col>
                <Col>
                  <Button
                    size="large"
                    htmlType="submit"
                    style={{
                      height: "44px",
                      fontSize: "16px",
                      fontWeight: 500,
                      border: "none",
                      color: "#1E1D1D",
                      width: "243px",
                    }}
                    onClick={handleMarkDone}
                    type="primary"
                    className="mt-6"
                  >
                    Mark as done
                  </Button>
                </Col>
              </Row>
            ) : assessment?.status === "COMPLETED" ? (
              <Button
                size="large"
                htmlType="submit"
                style={{
                  height: "44px",
                  fontSize: "16px",
                  fontWeight: 500,
                  border: "none",
                  color: "#1E1D1D",
                  width: "412px",
                }}
                type="primary"
                className="mt-6"
              >
                Assessment Completed
              </Button>
            ) : (
              <Button
                size="large"
                htmlType="submit"
                style={{
                  height: "44px",
                  fontSize: "16px",
                  fontWeight: 500,
                  border: "none",
                  color: "#1E1D1D",
                  width: "412px",
                }}
                type="primary"
                className="mt-6"
                onClick={startAssessment}
              >
                Start Challenge
              </Button>
            )
          ) : (
            <Row justify={"center"} gutter={12}>
              <Col>
                <Button
                  size="large"
                  htmlType="submit"
                  style={{
                    height: "44px",
                    fontSize: "16px",
                    fontWeight: 500,
                    border: "none",
                    color: "#1E1D1D",
                    width: "243px",
                  }}
                  type="primary"
                  className="mt-6"
                >
                  Create Account
                </Button>
              </Col>
              <Col>
                <Button
                  size="large"
                  htmlType="submit"
                  style={{
                    height: "44px",
                    fontSize: "16px",
                    fontWeight: 500,
                    border: "none",
                    color: "#1E1D1D",
                    width: "243px",
                  }}
                  type="primary"
                  className="mt-6"
                >
                  Login
                </Button>
              </Col>
            </Row>
          )
        }
      >
        <div className="flex justify-center">
          <div
            style={{
              maxWidth: "1150px",
              zIndex: 3,
              paddingTop: "50px",
              paddingBottom: "100px",
            }}
          >
            {allSettings.map((item, i) => {
              if (item.typeName === "ASSESSMENT_DEFAULT_INSTRUCTIONS") {
                return (
                  <div
                    className="text-left"
                    dangerouslySetInnerHTML={{ __html: item.value }}
                  />
                );
              }
            })}
            <Divider />
            {assessment?.assessmentInstructions !== "" ? (
              <div
                className="text-left ql-editor"
                dangerouslySetInnerHTML={{
                  __html: assessment?.assessmentInstructions,
                }}
              />
            ) : (
              allSettings.map((item, i) => {
                if (item.typeName === "ASSESSMENT_OVERVIEW_INSTRUCTIONS") {
                  return (
                    <div
                      className="text-left ql-editor"
                      dangerouslySetInnerHTML={{ __html: item.value }}
                    />
                  );
                }
              })
            )}
          </div>
        </div>
      </CandidateLayout>
    </div>
  );
};

const mapStateToProps = (state) => ({ user: state.auth.user });

export default connect(mapStateToProps, null)(Details);
