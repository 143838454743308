import {
  Button,
  ConfigProvider,
  Space,
  Input,
  ColorPicker,
  Divider,
  Typography,
  Dropdown,
  theme,
  Layout,
  Menu,
  Switch,
} from "antd";
// import logo from "./../assets/logo.png";
import React, { useContext, useEffect, useState } from "react";
import tinycolor from "tinycolor2";
// import Logo from "../assets/logo";
import { useNavigate } from "react-router-dom";
import { FaCaretDown } from "react-icons/fa";
import { FaBell } from "react-icons/fa";
import { ThemeContext } from "../providers/ThemeProvider.jsx";
import { getCurrentUser } from "aws-amplify/auth";
import ModalWrapper from "../components/Modals/ModalWrapper.jsx";
import MFA from "../pages/Auth/MFA.jsx";
import useProvideAuth from "../hooks/useAuth.js";
import UserProfileComponent from "../components/UserProfileComponent/index.js";
import { connect } from "react-redux";
import { PhoneOutlined } from "@ant-design/icons";
import { UserSlice } from "../store/slices/index.js";

const { Text } = Typography;
const { useToken } = theme;

const UserLayout = ({ children, user, resetUserState }) => {
  const [primary, setPrimary] = useState("#FFFFFF"); // Corrected initial color
  const [relatedColors, setRelatedColors] = useState({
    mainColor: primary,
    lighter: generateRelatedColorsWithTextContrast(primary).lighter,
    darker: generateRelatedColorsWithTextContrast(primary).darker,
    textContrastColor:
      generateRelatedColorsWithTextContrast(primary).textContrastColor,
    complementary: generateRelatedColorsWithTextContrast(primary).complementary,
  });
  const auth = useProvideAuth();
  const [isMFAModalOpen, setIsOpenMFAModal] = useState(false);

  const navigate = useNavigate();
  function generateRelatedColorsWithTextContrast(baseColor) {
    const hexToRgb = (hex) => {
      const bigint = parseInt(hex.slice(1), 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;
      return [r, g, b];
    };

    const rgbToHex = (rgb) => {
      return `#${((1 << 24) + (rgb[0] << 16) + (rgb[1] << 8) + rgb[2])
        .toString(16)
        .slice(1)}`;
    };

    const adjustColorBrightness = (color, factor) => {
      const rgb = hexToRgb(color);
      const adjustedRgb = rgb.map((channel) =>
        Math.min(Math.max(Math.round(channel * factor), 0), 255)
      );
      return rgbToHex(adjustedRgb);
    };

    const calculateContrastRatio = (color1, color2) => {
      const luminance1 = calculateLuminance(hexToRgb(color1));
      const luminance2 = calculateLuminance(hexToRgb(color2));
      const contrast =
        (Math.max(luminance1, luminance2) + 0.05) /
        (Math.min(luminance1, luminance2) + 0.05);
      return contrast;
    };

    const calculateLuminance = (rgb) => {
      const [r, g, b] = rgb.map((channel) => {
        const channelValue = channel / 255;
        return channelValue <= 0.03928
          ? channelValue / 12.92
          : Math.pow((channelValue + 0.055) / 1.055, 2.4);
      });
      return 0.2126 * r + 0.7152 * g + 0.0722 * b;
    };

    const baseRgb = hexToRgb(baseColor);

    const mainColor = baseColor;
    const lighter = adjustColorBrightness(mainColor, 1.2);
    const darker = adjustColorBrightness(mainColor, 0.7);

    const baseLuminance = calculateLuminance(baseRgb);
    let textContrastColor;
    let linkHoverColor;
    let logoPrim;
    let logoSec;

    if (baseLuminance > 0.2) {
      textContrastColor = "black";
      logoPrim = "black";
      logoSec = "#0866C5";
      linkHoverColor = "#7E7E7E";
    } else if (baseLuminance < 0.2) {
      textContrastColor = "white";
      linkHoverColor = "#ABABAB";
      logoPrim = "white";
      logoSec = "#aeb0f5";
    } else {
      textContrastColor =
        calculateContrastRatio(mainColor, lighter) >
        calculateContrastRatio(mainColor, darker)
          ? lighter
          : darker;
    }

    const complementary = rgbToHex([
      225 - baseRgb[0],
      225 - baseRgb[1],
      225 - baseRgb[2],
    ]);

    return {
      mainColor,
      lighter,
      darker,
      textContrastColor,
      complementary,
      linkHoverColor,
      logoPrim,
      logoSec,
    };
  }
  useEffect(() => {
    setRelatedColors(generateRelatedColorsWithTextContrast(primary));
  }, [primary]);

  const items = [
    {
      key: "1",
      label: (
        <div
          onClick={() => {
            navigate("/profile");
          }}
        >
          <Text className="text-blue-900">User Settings</Text>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => {
            window.open("https://support.cyber-hire.com");
          }}
          className="flex items-center gap-2 py-2"
        >
          <PhoneOutlined
            style={{
              color: "#070908",
            }}
          />
          <p className="text-blue-900">Support</p>
        </div>
      ),
    },
    // {
    //   key: "2",
    //   label: (
    //     <div
    //       onClick={() => {
    //         setIsOpenMFAModal(true);
    //       }}
    //     >
    //       <Text className="text-blue-900">MFA</Text>
    //     </div>
    //   ),
    // },
    {
      key: "3",
      label: (
        <div onClick={() => auth.signOut(resetUserState)}>
          <Text className="text-blue-900">Logout</Text>
        </div>
      ),
    },
  ];
  const { token } = useToken();

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: primary,
          colorBgBase: primary === "#FFFFFF" ? "#ebebf2" : relatedColors.darker,
          colorBgContainer: primary,
          colorLink: primary === "#FFFFFF" ? "black" : "white",
          colorLinkHover: relatedColors.linkHoverColor,
          colorText: primary === "#FFFFFF" ? "black" : "white",
          colorSuccessText: "red",
          colorTextLabel: primary === "#FFFFFF" ? "black" : "white",
          colorTextBase: primary === "#FFFFFF" ? "black" : "white",
          colorTextSecondary: primary === "#FFFFFF" ? "black" : "white",
          colorTextHeading: primary === "#FFFFFF" ? "black" : "white",
          colorTextTertiary: primary === "#FFFFFF" ? "black" : "white",
          colorTextDescription: primary === "#FFFFFF" ? "black" : "white",
          colorIcon: primary === "#FFFFFF" ? "black" : "white",
          colorIconHover: relatedColors.textContrastColor,
          colorPrimaryHover: primary === "#FFFFFF" ? "#58ad89" : "white",
          colorPrimary: primary === "#FFFFFF" ? "#58ad89" : "white",
        },
      }}
    >
      <Layout.Header
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <img src="../assets/logo-dark.png" className="w-32" />
        <div className="w-full flex justify-between items-center">
          <Menu
            className="ml-10"
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={["1"]}
            items={[
              {
                key: "1",
                label: "Talent",
                onClick: () => {
                  navigate("/user/assessment-listing");
                },
              },
              {
                key: "2",
                label: "Support",
              },
            ]}
            style={{
              flex: 1,
              minWidth: 0,
            }}
          />
          {/* <FaBell className="mr-8" color="white" /> */}
          {/* <Dropdown
            menu={{
              items,
            }}
            trigger={["click"]}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space className="text-white flex items-center">
                <Typography.Text className="text-white">
                  {`${user?.firstName || ""} ${user?.lastName || ""}`}
                </Typography.Text>
                <FaCaretDown className="ml-2" />
              </Space>
            </a>
          </Dropdown> */}

          <UserProfileComponent
            userName={user?.firstName + " " + user?.lastName}
            userImage={user?.profilePic}
            // notification={1}
            nameColor={"green"}
            // customProfileMenu={items}
          />
        </div>
      </Layout.Header>
      <div className="">{children}</div>
    </ConfigProvider>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = {
  resetUserState: UserSlice.actions.resetUserState,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserLayout);
