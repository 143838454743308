import axios from "axios";

import {
  LOGIN,
  SIGNUP,
  COMPANY,
  GET_ALL,
  USER,
  CREATE_COMPANY_ADMIN,
  CREATE_SUPER_ADMIN,
  CREATE,
  GET_BY_ID,
  UPDATE,
  GET_COMPANY_DETAILS_BY_ID,
  BLOCK,
  UNBLOCK,
  DELETE,
  ADD_INVITES,
  TAG,
  CHALLENGE,
  OPTIONS,
  ASSESSMENT,
  ASSIGN_ASSESSMENTS,
  GET_ASSESSMENT,
  VALIDATE_ASSESSMENTS_TOKEN,
  ASSESSMENTS_MAPPING,
  RESULT,
  CHANGE_PASSWORD,
  FEEDBACK,
  CLOSE,
  SETTINGS,
  EMAILS,
  CREATE_USER_IF_NOT_EXISTS,
} from "../../constants";

const {
  REACT_APP_AXIOS_RETRY,
  REACT_APP_API_PREFIX,
  // REACT_APP_CONTENT_TYPE,
  // REACT_APP_APPLICATION_X_WWW_FORM_URLENCODED,
} = process.env;

// Constants
const AXIOS_RETRY = REACT_APP_AXIOS_RETRY;
const API_PREFIX = REACT_APP_API_PREFIX;
// const CONTENT_TYPE = REACT_APP_CONTENT_TYPE;
// const APPLICATION_X_WWW_FORM_URLENCODED =
//   REACT_APP_APPLICATION_X_WWW_FORM_URLENCODED;
export const axiosInstance = axios.create({
  baseURL: API_PREFIX,
  [AXIOS_RETRY]: {
    retries: 3,
  },
  errorHandling: {
    global: true,
  },
});

// Add request interceptor to set Authorization header
axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = JSON.parse(localStorage.getItem("accessToken"));
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const createUserIfNotExist = (data) => {
  return axiosInstance.post(`${API_PREFIX}${CREATE_USER_IF_NOT_EXISTS}`, data);
};
export const sendWelcomeEmail = (data) => {
  return axiosInstance.post(`${API_PREFIX}user/sendWelcomeEmail`, data);
};

export const login = ({ email, password }) => {
  return axiosInstance.post(`${API_PREFIX}${LOGIN}`, { email, password });
};

export const getUserProfile = (id) => {
  return axiosInstance.get(`${API_PREFIX}user/getByCognitoId/${id}`);
};
export const getUserProfileById = (id) => {
  return axiosInstance.get(`${API_PREFIX}user/getByCognitoId/${id}`);
};
export const setUserData = (data) => {
  return axiosInstance.post(`${API_PREFIX}user/addUser`, data);
};

export const signup = (data) => {
  return axiosInstance.post(`${API_PREFIX}${SIGNUP}`, data);
};

export const getAllCompaniesData = (queryString) => {
  return axiosInstance.get(`${API_PREFIX}${COMPANY}/${GET_ALL}?${queryString}`);
};

export const getChallenges = (limit = 100, page = 1) => {
  return axiosInstance.get(
    `${API_PREFIX}${CHALLENGE}?limit=${limit}&page=${page}`
  );
};

export const getChallengesByType = (
  type,
  tags,
  page = 1,
  limit = 10,
  sortBy,
  order,
  searchKey
) => {
  let query = "?";

  if (type) {
    query += `typeOfChallenge=${type}`;
  }

  if (page) {
    query += `&page=${page}`;
  }

  if (tags) {
    query += `&tags=${tags}`;
  }

  if (limit) {
    query += `&limit=${limit}`;
  }

  if (sortBy) {
    query += `&sortBy=${sortBy}`;
  }
  if (order) {
    query += `&order=${order}`;
  }
  if (searchKey && typeof searchKey === "string") {
    query += `&searchKey=${searchKey}`;
  }

  return axiosInstance.get(
    `${API_PREFIX}${CHALLENGE}${query !== "?" ? query : ""}`
  );
};

export const searchChallenges = (key) => {
  return axiosInstance.get(`${API_PREFIX}${CHALLENGE}?q=${key}`);
};

export const getUserById = (id) => {
  return axiosInstance.get(`${API_PREFIX}${USER}/${GET_BY_ID}/${id}`);
};

export const getAssessmentById = (id) => {
  return axiosInstance.get(`${API_PREFIX}${ASSESSMENT}/${id}`);
};

export const getAssessmentResults = (userId, assessmentId, companyId) => {
  return axiosInstance.get(
    `${API_PREFIX}${RESULT}/${userId}/${assessmentId}/${companyId}`
  );
};
export const getAssessmentResultsAssessmentMappingId = (
  userId,
  assessmentId,
  companyId,
  assessmentMappingId
) => {
  return axiosInstance.get(
    `${API_PREFIX}${RESULT}/${userId}/${assessmentId}/${companyId}?assessmentMappingId=${assessmentMappingId}`
  );
};
export const getAssessmentResultsId = (
  userId,
  assessmentId,
  companyId,
  resultId
) => {
  return axiosInstance.get(
    `${API_PREFIX}${RESULT}/${userId}/${assessmentId}/${companyId}?resultId=${resultId}`
  );
};

export const changeAssessmentStatus = (assessmentId, status) => {
  return axiosInstance.post(
    `${API_PREFIX}candidates-invitations/change-assessment-result/${assessmentId}`,
    {
      status,
    }
  );
};

export const deleteUserById = (id) => {
  return axiosInstance.delete(`${API_PREFIX}${USER}/${id}`);
};

export const deleteAssessmentById = (id) => {
  return axiosInstance.delete(`${API_PREFIX}${ASSESSMENT}/${id}`);
};

export const deleteTagById = (id) => {
  return axiosInstance.delete(`${API_PREFIX}${TAG}/${id}`);
};

export const getCompanyById = (id) => {
  return axiosInstance.get(
    `${API_PREFIX}${COMPANY}/${GET_COMPANY_DETAILS_BY_ID}/${id}`
  );
};

export const getChallengeById = (id) => {
  return axiosInstance.get(`${API_PREFIX}${CHALLENGE}/${id}`);
};

export const getOptions = (type) => {
  return axiosInstance.get(`${API_PREFIX}${OPTIONS}?typeOfOptions=${type}`);
};

export const getAllCompanyAdmins = (queryString) => {
  return axiosInstance.get(`${API_PREFIX}${USER}/${GET_ALL}?${queryString}`);
};

export const getAllSuperAdmins = (queryString) => {
  return axiosInstance.get(`${API_PREFIX}${USER}/${GET_ALL}?${queryString}`);
};

const constructQueryParams = (data) => {
  let query = "?";

  if (data.page) {
    query += `page=${data.page}`;
  }

  if (data.limit) {
    query += `&limit=${data.limit}`;
  }
  if (data.sortBy) {
    query += `&sortBy=${data.sortBy}`;
  }
  if (data.order) {
    query += `&order=${data.order}`;
  }
  if (data.searchKey) {
    query += `&searchKey=${data.searchKey}`;
  }

  return query === "?" ? "" : query;
};

export const getAllAssessmentsWithoutLimit = () => {
  return axiosInstance.get(`${API_PREFIX}${ASSESSMENT}`);
};
export const getAllAssessments = (
  page = 1,
  limit = 10,
  sortBy,
  order,
  searchKey
) => {
  const query = constructQueryParams({ page, limit, sortBy, order, searchKey });

  return axiosInstance.get(`${API_PREFIX}${ASSESSMENT}${query}`);
};

export const getAllAssessmentsByUserId = (id) => {
  return axiosInstance.get(`${API_PREFIX}${ASSESSMENTS_MAPPING}?userId=${id}`);
};

export const getAllAssessmentsOfCompany = (
  id,
  page = 1,
  limit = 10,
  sortBy,
  order,
  searchKey
) => {
  const query = constructQueryParams({ page, limit, sortBy, order, searchKey });

  return axiosInstance.get(
    `${API_PREFIX}${COMPANY}/${GET_ASSESSMENT}/${id}${query}`
  );
};

export const fetchUserDetails = (companyId, email, query) => {
  return axiosInstance.get(
    `${API_PREFIX}candidates-invitations/${companyId}?email=${email}&${query}`
  );
};

export const getAssessmentDetails = (companyId, email, assessmentId) => {
  return axiosInstance.get(
    `${API_PREFIX}candidates-invitations/${companyId}?email=${email}&assessmentId=${assessmentId}`
  );
};

export const getAssessmentDetailsUsingResultId = (
  companyId,
  email,
  assessmentId,
  resultId
) => {
  return axiosInstance.get(
    `${API_PREFIX}candidates-invitations/${companyId}?email=${email}&assessmentId=${assessmentId}&resultId=${resultId}`
  );
};

export const getEvaluations = (companyId, query) => {
  return axiosInstance.get(
    `${API_PREFIX}assessment/getAllAssessmentsOfCompany/${companyId}?${query}`
  );
};

export const getEvaluationsDetail = (companyId, assessmentId, query) => {
  if (query) {
    return axiosInstance.get(
      `${API_PREFIX}candidates-invitations/getAssessmentDetailsWithInviteDetails/${companyId}/${assessmentId}?${query}`
    );
  }
  return axiosInstance.get(
    `${API_PREFIX}candidates-invitations/getAssessmentDetailsWithInviteDetails/${companyId}/${assessmentId}`
  );
};

export const updateAssessmentInstructions = (companyId, assessmentId, data) => {
  return axiosInstance.patch(
    `${API_PREFIX}company/updateCompaniesAssessment/${companyId}/${assessmentId}`,
    data
  );
};

export const getGraphDataAPI = (companyId, assessmentId) => {
  return axiosInstance.get(
    `${API_PREFIX}result/getEvaluationDetailsGraph/${companyId}/${assessmentId}`
  );
};
export const getGraphDataAPICandidate = (
  companyId,
  assessmentId,
  userId,
  resultId
) => {
  return axiosInstance.get(
    `${API_PREFIX}result/getInviteDetailsGraph/${companyId}/${assessmentId}/${userId}?resultId=${resultId}`
  );
};

export const saveAssessmentNotes = (inviteId, message, addedBy) => {
  return axiosInstance.post(
    `${API_PREFIX}candidates-invitations/add-note/${inviteId}`,
    {
      message,
      addedBy,
    }
  );
};

export const inviteCandidate = (companyId, assessmentId, data) => {
  return axiosInstance.post(
    `${API_PREFIX}candidates-invitations/${companyId}/${assessmentId}`,
    data
  );
};
export const inviteCandidateAdmin = (companyId, data) => {
  return axiosInstance.post(
    `${API_PREFIX}company-users-invitations/${companyId}`,
    data
  );
};
export const changeUserRole = (userId, role) => {
  return axiosInstance.patch(`${API_PREFIX}user/updateUserRole/${userId}`, {
    role,
  });
};
export const changeInviteRole = (inviteId, role) => {
  return axiosInstance.patch(
    `${API_PREFIX}company-users-invitations/${inviteId}/${role}`
  );
};

export const deleteUserInvite = (inviteId) => {
  return axiosInstance.delete(
    `${API_PREFIX}company-users-invitations/${inviteId}`
  );
};

export const fetchAllInvitesOfCompany = (companyId, query) => {
  if (query) {
    return axiosInstance.get(
      `${API_PREFIX}candidates-invitations/${companyId}?${query}`
    );
  }
  return axiosInstance.get(`${API_PREFIX}candidates-invitations/${companyId}`);
};
export const fetchAllCompanyUsers = (companyId, query) => {
  if (query) {
    return axiosInstance.get(
      `${API_PREFIX}company-users-invitations/${companyId}?status=SENT&${query}`
    );
  }
  return axiosInstance.get(
    `${API_PREFIX}company-users-invitations/${companyId}?status=SENT`
  );
};

export const fetchAllExistingCompanyUsers = (companyId, query) => {
  if (query) {
    return axiosInstance.get(
      `${API_PREFIX}user/getAllCompanyUsers/${companyId}?${query}`
    );
  }
  return axiosInstance.get(`${API_PREFIX}user/getAllCompanyUsers/${companyId}`);
};

export const fetchAllCandidates = (companyId, query) => {
  if (query) {
    return axiosInstance.get(
      `${API_PREFIX}candidates-invitations/${companyId}?groupByAssessment=true&${query}`
    );
  }
  return axiosInstance.get(
    `${API_PREFIX}candidates-invitations/${companyId}?groupByAssessment=true`
  );
};

export const deleteInvitation = (invitationId) => {
  return axiosInstance.delete(
    `${API_PREFIX}candidates-invitations/${invitationId}`
  );
};
export const changeInviteStatus = (invitationId, status) => {
  return axiosInstance.post(
    `${API_PREFIX}candidates-invitations/change-status/${invitationId}`,
    { status }
  );
};

export const validateCompanyUser = (token) => {
  return axiosInstance.get(
    `${API_PREFIX}company-users-invitations/validateInvitationToken/${token}`
  );
};

export const deleteCompanyUser = (invitationId) => {
  return axiosInstance.delete(
    `${API_PREFIX}company-users-invitations/${invitationId}`
  );
};

export const validateToken = (token) => {
  return axiosInstance.get(
    `${API_PREFIX}${COMPANY}/${VALIDATE_ASSESSMENTS_TOKEN}?token=${token}`
  );
};
export const validateCandidateToken = (token) => {
  return axiosInstance.get(
    `${API_PREFIX}candidates-invitations/validateInvitationToken/${token}`
  );
};

export const updateSettings = (data) => {
  return axiosInstance.post(`${API_PREFIX}${SETTINGS}`, data);
};

export const updateTemplates = (data) => {
  return axiosInstance.post(`${API_PREFIX}${EMAILS}`, data);
};

export const createCompanyAdmin = (data) => {
  return axiosInstance.post(
    `${API_PREFIX}${USER}/${CREATE_COMPANY_ADMIN}`,
    data
  );
};

export const startMyAssessment = (data) => {
  return axiosInstance.post(`${API_PREFIX}${RESULT}`, data);
};

export const endMyAssessment = (userId, assessmentId, companyId, resultId) => {
  return axiosInstance.post(
    `${API_PREFIX}${RESULT}/${CLOSE}/${userId}/${assessmentId}/${companyId}?resultId=${resultId}`
  );
};

export const createAssessmentsMapping = (data) => {
  return axiosInstance.post(`${API_PREFIX}${ASSESSMENTS_MAPPING}`, data);
};

export const createAssessment = (data) => {
  return axiosInstance.post(`${API_PREFIX}${ASSESSMENT}`, data);
};

export const associatCompaniesAndAssessments = (data) => {
  return axiosInstance.post(
    `${API_PREFIX}${COMPANY}/${ASSIGN_ASSESSMENTS}`,
    data
  );
};

export const updateUser = (data, id) => {
  return axiosInstance.patch(`${API_PREFIX}${USER}/${UPDATE}/${id}`, data);
};

export const updateFeedback = (data, id) => {
  return axiosInstance.patch(`${API_PREFIX}${FEEDBACK}/${id}`, data);
};

export const updatePassword = (data) => {
  return axiosInstance.patch(`${API_PREFIX}${USER}/${CHANGE_PASSWORD}`, data);
};

export const updateAssessment = (data, id) => {
  return axiosInstance.patch(`${API_PREFIX}${ASSESSMENT}/${id}`, data);
};

export const updateChallenge = (data, id) => {
  return axiosInstance.patch(`${API_PREFIX}${CHALLENGE}/${id}`, data);
};

export const updateTag = (data, id) => {
  return axiosInstance.patch(`${API_PREFIX}${TAG}/${id}`, data);
};

export const blockUser = (id) => {
  return axiosInstance.post(`${API_PREFIX}${USER}/${BLOCK}/${id}`, {});
};

export const unBlockUser = (id) => {
  return axiosInstance.post(`${API_PREFIX}${USER}/${UNBLOCK}/${id}`, {});
};

export const blockCompany = (id) => {
  return axiosInstance.post(`${API_PREFIX}${COMPANY}/${BLOCK}/${id}`, {});
};

export const unBlockCompany = (id) => {
  return axiosInstance.post(`${API_PREFIX}${COMPANY}/${UNBLOCK}/${id}`, {});
};

export const updateCompany = (data, id) => {
  return axiosInstance.patch(`${API_PREFIX}${COMPANY}/${UPDATE}/${id}`, data);
};

export const addCompanyInvites = (data, id) => {
  return axiosInstance.post(
    `${API_PREFIX}${COMPANY}/${ADD_INVITES}/${id}`,
    data
  );
};

export const createTag = (data) => {
  return axiosInstance.post(`${API_PREFIX}${TAG}`, data);
};

export const createChallenge = (data) => {
  return axiosInstance.post(`${API_PREFIX}${CHALLENGE}`, data);
};

export const getTags = (page = 1, limit = 10, sortBy, order, searchVal) => {
  let query = "?";
  if (page) {
    query += `page=${page}`;
  }
  if (limit) {
    query += `&limit=${limit}`;
  }
  if (sortBy) {
    query += `&sortBy=${sortBy}`;
  }
  if (order) {
    query += `&order=${order}`;
  }
  if (searchVal) {
    query += `&searchKey=${searchVal}`;
  }

  return axiosInstance.get(`${API_PREFIX}${TAG}${query !== "?" ? query : ""}`);
};

export const getAllTagsWithoutFilter = () => {
  return axiosInstance.get(`${API_PREFIX}${TAG}`);
};

export const getSettings = () => {
  return axiosInstance.get(`${API_PREFIX}${SETTINGS}`);
};

export const getTemplates = () => {
  return axiosInstance.get(`${API_PREFIX}${EMAILS}`);
};

export const getCurrentVersion = () => {
  return axiosInstance.get(`${API_PREFIX}version`);
};

export const getFeedbacks = (
  page = 1,
  limit = 10,
  sortBy,
  order,
  searchKey
) => {
  const query = constructQueryParams({ page, limit, sortBy, order, searchKey });
  return axiosInstance.get(`${API_PREFIX}${FEEDBACK}${query}`);
};

export const createSuperAdmin = (data) => {
  return axiosInstance.post(`${API_PREFIX}${USER}/${CREATE_SUPER_ADMIN}`, data);
};

export const createCompany = (data) => {
  return axiosInstance.post(`${API_PREFIX}${COMPANY}/${CREATE}`, data);
};

export const deleteCompany = (id) => {
  return axiosInstance.delete(`${API_PREFIX}${COMPANY}/${DELETE}/${id}`, {});
};

export const deleteChallenge = (id) => {
  return axiosInstance.delete(`${API_PREFIX}${CHALLENGE}/${id}`, {});
};

export const changeSSHDirectory = (data) => {
  return axiosInstance.post(`${API_PREFIX}ssh-terminal/changeDirectory`, data);
};
