import React, { useEffect, useState } from "react";
import { Button, ColorPicker, Form, Input, Modal, Row, message } from "antd";
import { createTag, updateTag } from "../../services/api";

const TagModal = ({
  allTags,
  isEditable,
  setIsEditable,
  isModalOpen,
  setIsModalOpen,
  action,
  editabledata,
  setEditabledata,
  title = "Add Tag",
}) => {
  const [colorValue, setColorValue] = useState("");

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setEditabledata(null);
    setIsEditable(false);
  };

  const onFinish = async (values) => {
    const color =
      typeof values.colorCode === "string"
        ? values.colorCode
        : values.colorCode.toHexString();
    if (color?.toUpperCase() === "#FFFFFF") {
      message.error("Please select different color than white!");
      return;
    }
    try {
      if (isEditable) {
        await updateTag({ ...values, colorCode: color }, editabledata?._id);
        message.success("Tag updated!");
        setIsModalOpen(false);
        setEditabledata(null);
        setIsEditable(false);
        action();
      } else {
        await createTag({
          tags: [{ ...values, colorCode: color }],
        });
        message.success("Tag created!");
        setIsModalOpen(false);
        setEditabledata(null);
        setIsEditable(false);
        action();
      }
    } catch (error) {
      message.error(
        error?.response?.data?.error || error?.message || "Something went wrong"
      );
    }
  };

  const [form] = Form.useForm();
  useEffect(() => {
    if (isEditable) {
      const fieldsData = {
        name: editabledata?.name,
        colorCode: editabledata?.colorCode,
      };
      form.setFieldsValue(fieldsData);
    } else {
      const fieldsData = {
        name: "",
        colorCode: "#807E7E",
      };
      form.setFieldsValue(fieldsData);
    }
  }, [editabledata]);

  return (
    <>
      <Modal
        title={title}
        open={isModalOpen}
        footer={false}
        onCancel={handleCancel}
      >
        <div>
          <Row gutter={12}>
            <Form
              form={form}
              name="basic"
              layout="vertical"
              className="w-full p-3"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                label="Name"
                name="name"
                className=" px-2"
                rules={[
                  {
                    required: true,
                    message: "Please input tag's name!",
                  },
                  {
                    message: "This tag already exists!",
                    validator: (_, value) => {
                      const index = allTags.findIndex(
                        (item) =>
                          item.name.toLowerCase() === value.toLowerCase()
                      );
                      if (index < 0 || isEditable) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject("Some message here");
                      }
                    },
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
              <Form.Item
                label="Color code"
                name="colorCode"
                className=" px-2"
                rules={[
                  {
                    required: true,
                    message: "Please input tag's color code!",
                  },
                ]}
              >
                {/* <Input size="large"/> */}
                <ColorPicker
                  defaultValue="#807E7E"
                  format={"hex"}
                  onChangeComplete={(color) => {
                    setColorValue(color.toHexString());
                  }}
                  showText
                  disabledColors={(color) => color.toUpperCase() === "#FFFFFF"}
                  getPopupContainer={(trigger) => trigger.parentNode}
                />
                {/* <input type='color' onChange={(val) => console.log(val)} /> */}
              </Form.Item>
              <Row justify={"end"}>
                <Button size="large" htmlType="submit">
                  Save
                </Button>
              </Row>
            </Form>
          </Row>
        </div>
      </Modal>
    </>
  );
};
export default TagModal;
