import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Typography,
} from "antd";
import CompanyDropdown from "../Dropdowns/CompanyDropdown";
import { getAllCompaniesData } from "../../services/api";

const { Text } = Typography;

const InvitesModal = ({
  isModalOpen,
  setIsModalOpen,
  onFinish,
  setSelectedCompany,
  selectedCompany,
}) => {
  const [showCompanyDropDown, setShowCompanyDropDown] = useState(false);
  const [searchedCompanies, setSearchedCompanies] = useState([]);
  const [allCompanies, setAllCompanies] = useState([]);

  const getAllCompanies = async () => {
    try {
      const { data, status } = await getAllCompaniesData();
      setAllCompanies(data?.companies);
      setSearchedCompanies(data?.companies);
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    getAllCompanies();
  }, []);

  const [form] = Form.useForm();
  const handleFinish = (values) => {
    onFinish(values);
    form.setFieldsValue({
      invites: "",
      expiryDate: "",
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCompanySearch = (e) => {
    const val = e.target.value;
    const filteredArr = allCompanies.filter((item) => item?.name.includes(val));
    setSearchedCompanies(filteredArr);
  };

  return (
    <>
      <Modal
        title={"Add Invites"}
        open={isModalOpen}
        footer={false}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          name="basic"
          layout="vertical"
          className="w-full mt-5"
          labelCol={{
            span: 12,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={handleFinish}
          autoComplete="off"
        >
          <div className="flex flex-wrap">
            <Form.Item
              label="Invites"
              name="invites"
              className="w-1/2 px-2"
              rules={[
                {
                  required: true,
                  message: "Please input number of Invites!",
                },
                {
                  message: "Please enter a positive number!",
                  validator: (_, value) => {
                    if (value < 0) {
                      return Promise.reject("Some message here");
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
                {
                  message: "Value is too long!",
                  validator: (_, value) => {
                    if (value > 99999999) {
                      return Promise.reject("Some message here");
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
                {
                  message: "Decimal is not allowed!",
                  validator: (_, value) => {
                    if (value?.includes(".")) {
                      return Promise.reject("Decimal is not allowed!");
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                type="number"
                size="large"
                placeholder="Number of Invites"
              />
            </Form.Item>

            <Form.Item
              label="Expiry date"
              name="expiryDate"
              className="w-1/2 px-2"
              rules={[
                {
                  required: true,
                  message: "Please input Expiry date!",
                },
              ]}
            >
              <DatePicker size="large" className="w-full" />
            </Form.Item>

            <Form.Item
              label="Company"
              name="company"
              className="w-full px-2"
              rules={[
                {
                  required: true,
                  message: "Please select a company",
                  validator: (_, value) => {
                    if (selectedCompany.name) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject("Some message here");
                    }
                  },
                },
              ]}
            >
              <CompanyDropdown
                showCompanyDropDown={showCompanyDropDown}
                setShowCompanyDropDown={setShowCompanyDropDown}
                allCompanies={searchedCompanies}
                setSelectedCompany={setSelectedCompany}
                selectedCompany={selectedCompany}
                search={handleCompanySearch}
              />
            </Form.Item>

            <Form.Item className="flex justify-end w-full">
              <Button size="large" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </>
  );
};
export default InvitesModal;
