import { useEffect } from 'react'
import { Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { validateCompanyUser } from '../services/api';


function ValidateCompanyUser() {
    const { id } = useParams();
    const navigate = useNavigate()

    useEffect(() => {
        (async () => {
            try {
                const response = await validateCompanyUser(id);
                localStorage.setItem('newUserRole', response?.data?.role)
                localStorage.setItem('companyId', response?.data?.companyId)
                localStorage.setItem('emailOfValidation', response?.data?.email)
                navigate("/login")
            } catch (err) {
                navigate("/url-not-found")
            }
        })()
    }, [])

    // const location = useLocation()
    return <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <Spin size="large">
            <div className="content" />
        </Spin>
    </div>;
}

export default ValidateCompanyUser
