import { useState, useEffect } from 'react'
import { Button, Card, message } from 'antd'
import Table from '../../components/Tables/Table'
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { StyledLoader as Loader } from '../../components/loader'
import { getEvaluations } from '../../services/api';

const onCellClick = (navigate, record) => ({
    onClick: (event) => {
        navigate(`/company/admin/evaluation/${record.key}`)
    }
}
)

function CompanyAdminCandidate({ user }) {
    const [isLoading, setIsLoading] = useState(false)
    const [evaluationData, setEvaluationData] = useState([])
    const [sorter, setSorter] = useState({})
    const [searchKey, setSearchKey] = useState("")
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0, // You might not know this until you fetch the first set of data
    });

    const navigate = useNavigate()

    useEffect(() => {
        handleTableChange(pagination, {}, {})
    }, [])
    const columns = [
        {
            title: 'Evaluation Name',
            dataIndex: 'title',
            key: 'title',
            width: '30%',
            onCell: (record, rowIndex) => onCellClick(navigate, record),
            sorter: true,
        },
        {
            title: 'Invited',
            dataIndex: 'invitedCount',
            key: 'invitedCount',
            width: '30%',
            onCell: (record, rowIndex) => onCellClick(navigate, record),
            sorter: true,
        },
        {
            title: 'In Progress',
            dataIndex: 'inProgressCount',
            key: 'inProgressCount',
            width: '20%',
            onCell: (record, rowIndex) => onCellClick(navigate, record),
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Evaluated',
            dataIndex: 'evaluatedCount',
            key: 'evaluatedCount',
            onCell: (record, rowIndex) => onCellClick(navigate, record),
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Qualified',
            dataIndex: 'qualifiedCount',
            key: 'qualifiedCount',
            onCell: (record, rowIndex) => onCellClick(navigate, record),
            sorter: true,
        },
        {
            title: 'Copy URL',
            dataIndex: 'token',
            key: 'token',
            render: (_, item) => {
                return <Button onClick={() => copyTextToClipboard(item.token)}>Copy</Button>
            }
        },
    ];

    function copyTextToClipboard(token) {
        navigator.clipboard.writeText(`${window.location.host}/assessment/invitation/confirmation/${token}`).then(
            () => message.success("Copied to clipboard")
        ).catch(() => message.error("Couldn't copy to clipboard"))
    }


    const fetchEvaluation = async (query, currentPage) => {
        try {
            setIsLoading(true);
            const { data } = await getEvaluations(user.companyIds[0], query)
            setEvaluationData(data?.assessments?.map(({ title, invitedCount, inProgressCount, evaluatedCount, qualifiedCount, _id }) => {
                const obj = {
                    title, invitedCount, inProgressCount, evaluatedCount, qualifiedCount, key: _id
                }
                const foundIndex = data.assessmentIdsWithTokens.findIndex((token) => token.assessmentId === _id)
                if (foundIndex !== -1) {
                    obj.token = data.assessmentIdsWithTokens[foundIndex].token
                }
                return obj
            }))
            setPagination(() => ({
                ...pagination, total: data?.count || 0, current: currentPage
            }))
        } catch (e) {
            message.error(e?.response?.data?.error || e?.message || "Something went wrong")
        } finally {
            setIsLoading(false);
        }
    }
    const handleTableChange = (pagination, filters, sorter) => {
        let query = `pageNo=${pagination.current}&pageSize=${pagination.pageSize}`

        for (let key in filters) {
            query += `&${key}=${filters[key]}`
        }


        if (sorter?.field && sorter?.order) {
            query += `&sortBy=${sorter.field}&order=${sorter.order}`
        }
        setPagination(pagination)
        setSorter(sorter)
        fetchEvaluation(query, pagination.current);
    };

    return <div>
        {isLoading ? <Loader /> : null}
        <Card style={{ boxShadow: "0px 1px 2px 0px #000000 !important" }}>
            <Table
                isSearch={true}
                columns={columns}
                data={evaluationData}
                onChange={(p, f, s) => handleTableChange(p, !searchKey?.length ? {} : { searchKey }, s)}
                pagination={pagination}
                style={{ cursor: "pointer" }}
                appendSearchKey={false}
                sorter={sorter}
                callback={(val, s, p) => {
                    handleTableChange({
                        current: 1,
                        pageSize: 10,
                        total: 0, // You might not know this until you fetch the first set of data
                    }, !val?.length ? {} : { searchKey: val }, s)
                    setSearchKey(val)
                }}
            />
        </Card>
    </div>
}

const mapStateToProps = (state) => ({
    user: state.auth.user
});
const mapDispatchToProps = {
    //Dispatch function's from redux
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyAdminCandidate);

