import React, { useState, useEffect } from "react";
import "./Candidate.css";
import CandidateLayout from "../../layout/CandidateLayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getAllAssessmentsOfCompany,
  getAssessmentById,
  startMyAssessment,
  validateCandidateToken,
  validateToken,
} from "../../services/api";
import { Button, Col, Row } from "antd";
import { connect } from "react-redux";
import { StyledLoader } from "../../components/loader";
import BrandedLandingPage from "../../pages/BrandedLandingPage";

const Details = ({ user }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [assessment, setAssessment] = useState({});
  const [assessmentId, setAssessmentId] = useState(null);
  const [companyId, setCompanyId] = useState("");
  const [companyData, setCompanyData] = useState({});

  const { id } = useParams();
  const location = useLocation();

  const getAssessmentDetails = async (assessmentId) => {
    setIsLoading(true);
    try {
      const { data } = await getAssessmentById(assessmentId);
      setAssessment(data);
      setAssessmentId(data?._id);
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const getCompanyData = async (companyId) => {
    if (companyId) {
      try {
        const { data } = await getAllAssessmentsOfCompany(companyId);
        if (data.company.length) {
          setCompanyData(data.company[0]);
          return;
        }
        setCompanyData({});
      } catch (error) {
        throw error;
      }
    }
  };

  const validateMyToken = async () => {
    setIsLoading(true);
    try {
      let response;
      if (location.pathname.includes("candidate")) {
        response = await validateCandidateToken(id);
        localStorage.setItem("tokenInviteId", response?.data?._id);
        localStorage.removeItem("isOpenURL");
      } else {
        response = await validateToken(id);
        localStorage.setItem("isOpenURL", true);
      }
      const { data } = response;
      await getCompanyData(data.companyId);
      localStorage.setItem("assessmentId", data.assessmentId);
      localStorage.setItem("companyIdAssessment", data.companyId);
      if (location.pathname.includes("candidate")) {
        localStorage.setItem("emailOfValidationCandidate", data.email);
      }

      setCompanyId(data.companyId);
      getAssessmentDetails(response?.data?.assessmentId);
    } catch (error) {
      navigate("/url-not-found");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id.length > 70) {
      validateMyToken();
    } else {
      getAssessmentDetails(id);
    }
  }, [id]);

  const startAssessment = async () => {
    try {
      if (user && Object?.keys(user)?.length) {
      } else {
        await startMyAssessment({
          assessmentId: assessmentId,
          companyId: location?.state?.companyId || companyId,
          userId: user._id,
        });
      }

      navigate(
        `/assessment-details/${assessmentId}/${
          location?.state?.companyId || companyId
        }`
      );
    } catch (error) {
      console.log({ error });
    }
  };

  const resumeAssessment = async () => {
    try {
      navigate(
        `/assessment-details/${assessmentId}/${
          location?.state?.companyId || companyId
        }`
      );
      // navigate("/user/challenge", {
      //   state: {
      //     assessmentId: assessmentId,
      //     userId: user._id,
      //     companyId: location?.state?.companyId || companyId,
      //   },
      // });
    } catch (error) {
      console.log({ error });
    }
  };
  return (
    <div>
      {
        isLoading ? (
          <StyledLoader />
        ) : (
          <BrandedLandingPage
            startAssessment={startAssessment}
            resumeAssessment={resumeAssessment}
            user={user}
            id={id}
            data={companyData}
            assessment={assessment}
            companyId={location?.state?.companyId || companyId}
          />
        )
        // : (
        //   <CandidateLayout
        //     title={assessment.title ? assessment.title : "Title Not Found"}
        //     button={
        //       Object.keys(user)?.length ? (
        //         assessment?.status === "UNATTEMPTED" ? (
        //           <Button
        //             size="large"
        //             style={{
        //               height: "44px",
        //               fontSize: "16px",
        //               fontWeight: 500,
        //               border: "none",
        //               color: "#1E1D1D",
        //               width: "412px",
        //             }}
        //             onClick={startAssessment}
        //             //   loading={isLoading}
        //             //   disabled={disabled}
        //             type="primary"
        //             className="mt-6"
        //           >
        //             Start Challenge
        //           </Button>
        //         ) : assessment?.status === "IN_PROGRESS" ? (
        //           <Row justify={"center"} gutter={12}>
        //             <Col>
        //               <Button
        //                 size="large"
        //                 htmlType="submit"
        //                 style={{
        //                   height: "44px",
        //                   fontSize: "16px",
        //                   fontWeight: 500,
        //                   border: "none",
        //                   color: "#1E1D1D",
        //                   width: "243px",
        //                 }}
        //                 onClick={resumeAssessment}
        //                 type="primary"
        //                 className="mt-6"
        //               >
        //                 Resume Challenge
        //               </Button>
        //             </Col>
        //             <Col>
        //               <Button
        //                 size="large"
        //                 htmlType="submit"
        //                 style={{
        //                   height: "44px",
        //                   fontSize: "16px",
        //                   fontWeight: 500,
        //                   border: "none",
        //                   color: "#1E1D1D",
        //                   width: "243px",
        //                 }}
        //                 //   loading={isLoading}
        //                 //   disabled={disabled}
        //                 type="primary"
        //                 className="mt-6"
        //               >
        //                 Mark as done
        //               </Button>
        //             </Col>
        //           </Row>
        //         ) : assessment?.status === "COMPLETED" ? (
        //           <Button
        //             size="large"
        //             htmlType="submit"
        //             style={{
        //               height: "44px",
        //               fontSize: "16px",
        //               fontWeight: 500,
        //               border: "none",
        //               color: "#1E1D1D",
        //               width: "412px",
        //             }}
        //             //   loading={isLoading}
        //             //   disabled={disabled}
        //             type="primary"
        //             className="mt-6"
        //           >
        //             Mark as done
        //           </Button>
        //         ) : (
        //           <Button
        //             size="large"
        //             htmlType="submit"
        //             style={{
        //               height: "44px",
        //               fontSize: "16px",
        //               fontWeight: 500,
        //               border: "none",
        //               color: "#1E1D1D",
        //               width: "412px",
        //             }}
        //             //   loading={isLoading}
        //             //   disabled={disabled}
        //             type="primary"
        //             className="mt-6"
        //             onClick={startAssessment}
        //           >
        //             Start Challenge
        //           </Button>
        //         )
        //       ) : (
        //         <Row justify={"center"} gutter={12}>
        //           <Col>
        //             <Button
        //               size="large"
        //               htmlType="submit"
        //               style={{
        //                 height: "44px",
        //                 fontSize: "16px",
        //                 fontWeight: 500,
        //                 border: "none",
        //                 color: "#1E1D1D",
        //                 width: "243px",
        //               }}
        //               onClick={() => navigate(`/signup?token=${id}`)}
        //               type="primary"
        //               className="mt-6"
        //             >
        //               Create Account
        //             </Button>
        //           </Col>
        //           <Col>
        //             <Button
        //               size="large"
        //               htmlType="submit"
        //               style={{
        //                 height: "44px",
        //                 fontSize: "16px",
        //                 fontWeight: 500,
        //                 border: "none",
        //                 color: "#1E1D1D",
        //                 width: "243px",
        //               }}
        //               onClick={() => navigate(`/login?token=${id}`)}
        //               type="primary"
        //               className="mt-6"
        //             >
        //               Login
        //             </Button>
        //           </Col>
        //         </Row>
        //       )
        //     }
        //   >
        //     <div className="flex justify-center">
        //       <div
        //         style={{
        //           maxWidth: "1150px",
        //           zIndex: 3,
        //           paddingTop: "50px",
        //           paddingBottom: "100px",
        //         }}
        //       >
        //         <div
        //           className="text-left"
        //           dangerouslySetInnerHTML={{
        //             __html: assessment?.instructions,
        //           }}
        //         />
        //       </div>
        //     </div>
        //   </CandidateLayout>
        // )
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, null)(Details);
