import { useEffect, useState } from "react";
// import { Auth } from "../helper/Auth";
import { login, signup, createUserIfNotExist } from "./../services/api";
import useLocalStorage from "./useLocalStorage";
import moment from "moment";
import { message } from "antd";
import { signIn, signOut } from "aws-amplify/auth";
import { useNavigate } from "react-router-dom";

export default function useProvideAuth() {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      allowOrNot();
    }
  }, []);

  const setUserData = (user) => {
    setUser({ ...user });
  };

  const allowOrNot = () => {
    if (user) {
      if (!user.rememberMe) {
        var now = moment(new Date()); //todays date
        var end = moment(user.lastLoggedIn); // another date
        var duration = moment.duration(now.diff(end));
        var days = duration.asDays();
        if (days > 1) {
          setUser(null);
          localStorage.removeItem("user");
        }
      }
    }
  };

  const signInDB = async (data, redirect, loading) => {
    try {
      const res = await login(data);
      const loginData = {
        ...res.data.user,
        lastLoggedIn: moment(),
        rememberMe: data.remember,
      };
      setUser(loginData);
      localStorage.setItem("user", JSON.stringify(loginData));
      localStorage.setItem("accessToken", JSON.stringify(res.data.accessToken));
      // sessionStorage.setItem('user', JSON.stringify(res.data.data))
      redirect();
      loading();
    } catch (error) {
      loading();
      message.error(error.response.data.error);
      console.log({ error });
    }
  };

  const signUp = async (data, redirect, loading) => {
    try {
      const res = await signup(data);
      const loginData = {
        ...res.data.newUser,
        lastLoggedIn: moment(),
        rememberMe: data.remember,
      };
      setUser(loginData);
      localStorage.setItem("user", JSON.stringify(loginData));
      localStorage.setItem("accessToken", JSON.stringify(res.data.accessToken));
      // sessionStorage.setItem('user', JSON.stringify(res.data.data))
      redirect();
      loading();
    } catch (error) {
      loading();
      message.error(error.response.data.error);
      console.log({ error });
    }
  };

  const signInWithCognito = async (values) => {
    return await signIn({
      username: values.email,
      password: values.password,
    });
  };

  const logOut = async (resetUserState, cb) => {
    await signOut();
    window.localStorage.clear();
    setUser(null);
    if (resetUserState) {
      resetUserState();
    }

    if (cb) {
      cb();
      return;
    }
    navigate("/login");
  };

  const preSignIn = async (user) => {
    return createUserIfNotExist(user);
  };

  return {
    user,
    setUser,
    signIn: signInDB,
    signUp,
    signOut: logOut,
    signInWithCognito,
    preSignIn,
    setUserData,
  };
}
