import React, { useEffect, useRef, useState } from "react";
import { Divider, Layout, Menu } from "antd";
import "../index.css"; // Import the CSS file
import SidebarFooterComponent from "./SidebarFooterComponent";
import ChallengesIcon from "../../../assets/icons/challenges.svg";
import ChallengesActiveIcon from "../../../assets/icons/challenges-active.svg";
import FeedbackIcon from "../../../assets/icons/feedback.svg";
import FeedbackActiveIcon from "../../../assets/icons/feedback-active.svg";
import HistoryIcon from "../../../assets/icons/history.svg";
import HistoryActiveIcon from "../../../assets/icons/history-active.svg";
import "../index.css";
import { connect } from "react-redux";
import { ChallengeSlice } from "../../../store/slices";
import ModalWrapper from "../../../components/Modals/ModalWrapper";
import Feedback from "./ChallengeComponent/Feedback";
import History from "./ChallengeComponent/History";
import { useNavigate } from "react-router-dom";

const { Sider } = Layout;

const SiderComponent = ({
  assessment,
  getSelectedChallenge,
  userId,
  companyId,
  assessmentId,
  selectedChallenge,
  stopAssessmentAPI,
}) => {
  const [selectedKey, setSelectedKey] = useState("challenges"); // The key for "Penetration Testing"
  const [submenuVisible, setSubmenuVisible] = useState(true); // State to track submenu visibility
  const [menuItems, setMenuItems] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setMenuItems(() =>
      assessment?.challenges?.map((challenge, index) => {
        if (index === 0 && !selectedChallenge) {
          getSelectedChallenge(challenge._id);
          setSelectedKey(challenge._id);
        }
        return {
          key: challenge._id,
          name: challenge.name,
        };
      })
    );
  }, [assessment]);

  const toggleSubmenu = () => {
    setSubmenuVisible(!submenuVisible);
  };

  return (
    <div
      style={{
        position: "sticky",
        top: 0,
        zIndex: 1,
        display: "flex",
        alignItems: "center",
      }}
      className="h-screen"
    >
      <Sider
        className="relative h-full"
        style={{
          backgroundColor: "#000",
        }}
        width={275}
      >
        <div style={{ padding: "30px" }} className="h-full">
          <div
            className="flex justify-start"
            style={{
              height: "58px",
            }}
          >
            <img
              style={{
                height: "45px",
                width: "135px",
              }}
              onClick={() => navigate("/user/assessment-listing")}
              src="../assets/logo-dark.png"
              alt="Cyber hire"
            />
          </div>
          <Divider style={{ margin: 0, borderColor: "#DFE0DF" }} />
          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[selectedKey]}
            onClick={(e) => {
              setSelectedKey(e.key);
            }}
            style={{
              backgroundColor: "transparent",
              color: "white",
              marginTop: "30px",
            }}
            className="menu"
          >
            <Menu.Item
              onClick={toggleSubmenu}
              className="text-left"
              key="challenges"
              icon={
                <div
                  className={`icon-background ${
                    menuItems?.includes(selectedKey)
                      ? "icon-background-selected"
                      : "icon-background-unselected"
                  }`}
                >
                  {menuItems?.includes(selectedKey) ? (
                    <img src={ChallengesActiveIcon} alt="challenges" />
                  ) : (
                    <img src={ChallengesIcon} alt="challenges" />
                  )}
                </div>
              }
              style={{
                color: menuItems?.includes(selectedKey) ? "white" : "#888A8D",
              }}
            >
              Challenges
            </Menu.Item>

            {menuItems?.map((item) => {
              return (
                <Menu.Item
                  onClick={(e) => {
                    getSelectedChallenge(e?.key);
                    setSelectedKey(e.key);
                  }}
                  key={item.key}
                  style={{
                    overflow: "hidden",
                    display: "flex",
                    textAlign: "left",
                    transition:
                      "opacity 0.5s ease, transform 0.5s ease, max-height 0.5s ease",
                    opacity: submenuVisible ? 1 : 0,
                    transform: submenuVisible
                      ? "translateY(0)"
                      : "translateY(-20px)",
                    maxHeight: submenuVisible ? "500px" : "0",
                    color:
                      selectedChallenge?._id === item.key
                        ? "white"
                        : "rgba(255, 255, 255, 0.65)",
                  }}
                >
                  <span
                    style={{ marginLeft: "15px" }}
                    className={`submenu-dot ${
                      selectedChallenge?._id === item.key
                        ? "submenu-dot-selected"
                        : "submenu-dot-unselected"
                    }`}
                  />
                  <span style={{ marginLeft: 27 }}>{item.name}</span>
                </Menu.Item>
              );
            })}
            <Menu.Item
              className="text-left"
              key="feedback"
              icon={
                <div
                  className={`icon-background ${
                    selectedKey === "feedback"
                      ? "icon-background-selected"
                      : "icon-background-unselected"
                  }`}
                >
                  {selectedKey === "feedback" ? (
                    <img src={FeedbackActiveIcon} alt="Feedback" />
                  ) : (
                    <img src={FeedbackIcon} alt="Feedback" />
                  )}
                </div>
              }
              style={{
                color: selectedKey === "feedback" ? "white" : "#888A8D",
              }}
            >
              Feedback
            </Menu.Item>
            <Menu.Item
              className="text-left"
              key="history"
              icon={
                <div
                  className={`icon-background ${
                    selectedKey === "history"
                      ? "icon-background-selected"
                      : "icon-background-unselected"
                  }`}
                >
                  {selectedKey === "history" ? (
                    <img src={HistoryActiveIcon} alt="History" />
                  ) : (
                    <img src={HistoryIcon} alt="History" />
                  )}
                </div>
              }
              style={{
                color: selectedKey === "history" ? "white" : "#888A8D",
              }}
            >
              History
            </Menu.Item>
          </Menu>
        </div>
        <div className="absolute bottom-0 w-full" style={{ padding: "30px" }}>
          <SidebarFooterComponent stopAssessmentAPI={stopAssessmentAPI} />
        </div>
      </Sider>
      {selectedKey === "feedback" && (
        <ModalWrapper
          title={"Feedback"}
          handleClose={() => setSelectedKey("challenges")}
        >
          <Feedback
            selectedKey={setSelectedKey}
            userId={userId}
            companyId={companyId}
            assessmentId={assessmentId}
          />
        </ModalWrapper>
      )}
      {selectedKey === "history" && (
        <ModalWrapper
          title={"History"}
          handleClose={() => setSelectedKey("challenges")}
        >
          <History />
        </ModalWrapper>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  assessment: state.challenge.assessment,
  selectedChallenge: state.challenge.selectedChallenge,
  currentQuestion: state.challenge.currentQuestion,
  isAssessmentFetching: state.challenge.isAssessmentFetching,
  attempts: state.challenge.attempts,
  history: state.challenge.history,
  size: state.challenge.size,
  type: state.challenge.type,
  selectedChallengeIndex: state.challenge.selectedChallengeIndex,
  currentScore: state.challenge.currentScore,
  resourceData: state.challenge.resourceData,
});
const mapDispatchToProps = {
  getSelectedChallenge: ChallengeSlice.actions.getSelectedChallenge,
};

export default connect(mapStateToProps, mapDispatchToProps)(SiderComponent);
