import DashboardCard from "../../components/DashboardCard";

const Dashboard = () => {
  return (
    <div className="flex flex-wrap w-full">
      <div className="w-full flex flex-wrap">
        <div className="w-1/2 p-3">
          <DashboardCard />
        </div>
        <div className="w-1/2 p-3">
          <DashboardCard />
        </div>
        <div className="w-1/2 p-3">
          <DashboardCard />
        </div>
        <div className="w-1/2 p-3">
          <DashboardCard />
        </div>
        <div className="w-full p-3">{/* <LineGraph /> */}</div>
      </div>
      <div className="w-1/3 p-3">{/* <BarGraph /> */}</div>
    </div>
  );
};

export default Dashboard;
