import React from 'react';
import { ClockCircleOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Timeline, Typography, Divider, Card, Space, Layout } from 'antd';
import { connect } from 'react-redux';

const { Text } = Typography;
const { Content } = Layout;
const History = ({ history }) => {
    return (
        <Layout style={{ background: "white" }}>
            {/* <Text className="title p-0 font-bold bg-white text-lg" >History</Text> */}
            <Content className="feedback-user px-3" style={{ overflow: "scroll", height: "60vh" }}>
                {/* #000D17 */}
                <Divider />
                <Timeline
                    items={history?.map((hist) => ({
                        color: hist?.isQuestion ? 'grey' : hist?.icon === "RIGHT" ? "green" : "red",
                        dot: !hist?.isQuestion ? <ClockCircleOutlined /> : hist?.icon === "RIGHT" ? <CheckOutlined style={{ color: "#49C18E" }} /> : <CloseOutlined style={{ color: "red" }} />,
                        children: !hist?.isQuestion ? <ChallengeStatus title={hist.title} time={hist.time} /> : <QuestionsHistory title={hist.title} question={hist.question} answer={hist.answer} time={hist.time} />,
                    })
                    )}
                />
            </Content>
        </Layout>
    )
};
const mapStateToProps = (state) => ({
    history: state.challenge.history
});

export default connect(mapStateToProps, null)(History);

const QuestionsHistory = ({ question, answer, title, time }) => {

    return (
        <Card
            style={{
                width: "400px",
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            }}
        >
            <div style={{
                display: "flex",
                flexDirection: "column",
            }}>
                <Text>{title}</Text>
                <Text type="secondary">{question}</Text>
                <Text type="secondary">{answer}</Text>
                <Text type="secondary" style={{ fontSize: "10px" }}>{time}</Text>
            </div>
        </Card>
    )
}

const ChallengeStatus = ({ title, time }) => {
    return (
        <Space size={20}>
            <Card
                style={{
                    width: "400px",
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
            >
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                }}>
                    <Text>{title}</Text>
                    <Text type="secondary" style={{ fontSize: "10px" }}>{time}</Text>
                </div>
            </Card>
        </Space>
    )
};