import { Card, Col, Divider, Input, Row, Space, Typography } from "antd";
import AdminsTable from "../../../components/Tables/AdminsTable";
import { useEffect, useState } from "react";
import { getTemplates, updateTemplates } from "../../../services/api";
import { EditOutlined } from "@ant-design/icons";
import { StyledLoader } from "../../../components/loader";
import TextEditorModal from "../../../components/Modals/TextEditorModal";

const { Text } = Typography;
const EmailTemplate = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false);
  const [allSettings, setAllSettings] = useState([]);
  const [searchedSettings, setSearchedSettings] = useState([]);
  const [selectedSetting, setSelectedSetting] = useState(null);

  const handleEdit = (record) => {
    setSelectedSetting(record);
    setIsAddEditModalOpen(true);
    setIsEditable(true);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <Text>{text || "--"}</Text>,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <Space size="middle">
            <EditOutlined
              onClick={() => handleEdit(record)}
              className="hover:text-blue-400"
            />
          </Space>
        );
      },
    },
  ];

  function convertToTitleCase(string) {
    // Split the string into an array of words
    const words = string.split("_");

    // Capitalize the first letter of each word
    for (let i = 0; i < words.length; i++) {
      words[i] =
        words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
    }

    // Join the words into a single string and return
    return words.join(" ");
  }

  const data = [
    ...searchedSettings?.map((item, i) => {
      return {
        _id: item?._id,
        key: i + 1,
        name: convertToTitleCase(item?.typeName),
        value: item?.value,
      };
    }),
  ];

  useEffect(() => {
    setSearchedSettings(allSettings);
  }, [allSettings]);

  const getAllSettings = async () => {
    setIsLoading(true);
    try {
      const { data } = await getTemplates();
      setAllSettings(data?.emails);
      setIsLoading(false);
    } catch (error) {
      console.log({ error });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllSettings();
  }, []);

  const handleSearch = (e) => {
    const val = e.target.value;
    const filteredArr = allSettings.filter((item) =>
      convertToTitleCase(item?.typeName)
        ?.toLowerCase()
        .includes(val.toLowerCase())
    );
    setSearchedSettings(filteredArr);
  };

  function convertToSnakeCase(string) {
    return string.toUpperCase().replace(/\s+/g, "_");
  }

  const updateTemplate = async (richtextValue) => {
    try {
      setIsLoading(true); // Set loading state to true when submitting
      await updateTemplates({
        typeName: convertToSnakeCase(selectedSetting?.name),
        value: richtextValue,
      });
      setIsAddEditModalOpen(false);
      getAllSettings();
    } catch (error) {
      console.log({ error });
    } finally {
      setIsLoading(false); // Reset loading state regardless of success or failure
    }
  };

  return (
    <div>
      <Card style={{ width: "100%" }}>
        <div className="flex justify-between items-center px-3 pb-4 w-full">
          <Text className="text-2xl font-semibold font-[#1f2232]">
            Settings
          </Text>
        </div>
        <Divider style={{ margin: 0 }} />
        <Row gutter={12} justify={"space-between"} className="my-2">
          <Col span={8}>
            <Input
              size="large"
              onChange={(e) => handleSearch(e)}
              placeholder="Search..."
            />
          </Col>
        </Row>
        <Divider style={{ margin: 0 }} />
        {isLoading ? (
          <StyledLoader />
        ) : (
          <AdminsTable columns={columns} data={data} />
        )}
        {selectedSetting && (
          <TextEditorModal
            allSettings={allSettings}
            isEditable={isEditable}
            isModalOpen={isAddEditModalOpen}
            setIsModalOpen={setIsAddEditModalOpen}
            action={updateTemplate}
            editabledata={selectedSetting}
            isLoading={isLoading}
            title="Edit Template"
          />
        )}
      </Card>
    </div>
  );
};

export default EmailTemplate;
