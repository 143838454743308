import { LoadingOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, Spin, Typography, message } from "antd";
import { useEffect, useState } from "react";
import { getUserById, updateUser } from "../../../services/api";
import { useNavigate, useSearchParams } from "react-router-dom";
import DeleteModal from "../../../components/Modals/DeleteModal";

const { Text } = Typography;
const EditSuperAdmins = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userData, setUserData] = useState({});
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("id");

  const navigate = useNavigate();

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      await updateUser(
        {
          ...values,
          email: values.email?.trim(),
        },
        userId
      );
      message.success("Super admin updated!");
      navigate("/admin/super-admins");
    } catch (error) {
      message.error(error.response.data.error);
    } finally {
      setIsLoading(false);
    }
  };

  const getUserData = async () => {
    try {
      const { data } = await getUserById(userId);
      setUserData(data);
    } catch (error) {
      message.error(error.response.data.error);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  const [form] = Form.useForm();
  useEffect(() => {
    //companyInfo -> dynamic obj fetched from API
    form.setFieldsValue({
      firstName: userData?.firstName,
      lastName: userData?.lastName,
      email: userData?.email,
    });
  }, [userData, form]);

  return (
    <div>
      <Card style={{ width: "100%" }}>
        <>
          <div className="flex justify-between items-center px-3 py-4 w-full">
            <Text className="text-2xl font-semibold font-[#1f2232]">
              Edit Super Admin
            </Text>
          </div>
          <div className="p-3">
            <Form
              form={form}
              name="basic"
              layout="vertical"
              className="w-full"
              labelCol={{
                span: 8,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <div className="flex flex-wrap">
                <Form.Item
                  label="First Name"
                  name="firstName"
                  className="w-1/2 px-2"
                  rules={[
                    {
                      required: true,
                      message: "Please input admin's Firstname!",
                    },
                    {
                      pattern: /^[a-zA-Z\s]+$/,
                      message: "Name can only include letters.",
                    },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>

                <Form.Item
                  label="Last Name"
                  name="lastName"
                  className="w-1/2 px-2"
                  rules={[
                    {
                      required: true,
                      message: "Please input admin's Lastname!",
                    },
                    {
                      pattern: /^[a-zA-Z\s]+$/,
                      message: "Name can only include letters.",
                    },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>

                <Form.Item
                  label="Email"
                  name="email"
                  className="w-1/2 px-2"
                  rules={[
                    {
                      required: true,
                      message: "Please input admin's Email!",
                    },
                    {
                      pattern:
                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\s*$/,
                      message: "Please enter a valid Email!",
                    },
                  ]}
                >
                  <Input size="large" disabled />
                </Form.Item>

                {/* <Form.Item
                                    label="Password"
                                    name="password"
                                    className='w-1/2 px-2'
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input admin's password!",
                                        },
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item> */}

                {/* <Form.Item
                                    label="Company"
                                    name="companyIds"
                                    className='w-1/2 px-2'
                                    rules={[
                                        // {
                                        //     required: true,
                                        //     message: "Please input admin's password!",
                                        // },
                                    ]}
                                >
                                    <Dropdown menu={menuProps}>
                                        <Button size="large"className="w-full">
                                            <Space className="flex items-center justify-between">
                                                {selectedCompany?.name || "Select"}
                                                <DownOutlined />
                                            </Space>
                                        </Button>
                                    </Dropdown>
                                </Form.Item> */}

                <Form.Item className="flex justify-end w-full">
                  <Button
                    className="mr-2"
                    onClick={() => setIsModalOpen(true)}
                    size="large"
                  >
                    Cancel
                  </Button>
                  <Button loading={isLoading} size="large" htmlType="submit">
                    Save
                  </Button>
                </Form.Item>
              </div>
            </Form>
            <DeleteModal
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              deleteAction={() => navigate("/admin/super-admins")}
              title="Discard changes"
              actionText="Yes"
              text="Are you sure you want to discard?"
            />
          </div>
        </>
      </Card>
    </div>
  );
};

export default EditSuperAdmins;
