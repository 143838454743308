import React from "react";
import { Space, Table, Tag } from "antd";
import "./Tables.css";

const AssessmentTable = ({
  columns,
  data,
  rowSelection,
  onChange,
  pagination,
}) => (
  <Table
    className="custom-table table-component"
    rowSelection={rowSelection}
    columns={columns}
    dataSource={data}
    onChange={onChange}
    pagination={{ ...pagination, showSizeChanger: false }}
  />
);
export default AssessmentTable;
