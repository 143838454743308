import { useEffect, useState } from "react";
import { ResizableBox } from "react-resizable";
import PDFViewer from "./PdfViewer";
import TXTFileViewer from "./TXTFileViewer";
import { changeSSHDirectory } from "../../../services/api";
const ConsoleComponent = ({ resourceData, selectedChallenge }) => {
  const [size, setSize] = useState({
    width: window.innerWidth - 367,
    height: 300,
  });

  const handleResize = (e, { size }) => {
    setSize(size);
  };

  useEffect(() => {
    if (
      resourceData.renderResource.name?.toLowerCase() === "iframe" &&
      resourceData.renderResource.type === "SSH"
    ) {
      changeSSHDirectory({
        nameSpace: resourceData?.renderResource?.namespaceName,
        challenge: selectedChallenge.name,
      });
    }
  }, [resourceData]);

  return (
    <div>
      <ResizableBox
        className={"bg-[#F5F5F5]"}
        width={size.width}
        height={size.height}
        onResize={handleResize}
        // draggableOpts={{ grid: [10, 10] }}
        resizeHandles={["se"]}
        axis={"y"}
        minConstraints={[window.innerWidth - 360, 200]}
        maxConstraints={[window.innerWidth - 360, 600]}
        style={{
          maxWidth: size.width - 10,
          padding: 2,
          overflow: "hidden",
          borderRadius: "8px",
          border: "1px solid #CCCCCC",
        }}
      >
        <div style={{ width: "100%", height: "100%", overflow: "auto" }}>
          {resourceData.renderResource.name?.toLowerCase() === "pdf" &&
            (resourceData.renderResource.path?.includes(".txt") ? (
              <TXTFileViewer
                size={size}
                filePath={resourceData.renderResource.path}
              />
            ) : (
              <PDFViewer size={size} href={resourceData.renderResource.path} />
            ))}

          {resourceData.renderResource.name?.toLowerCase() === "iframe" &&
            resourceData.renderResource.type === "SSH" && (
              <iframe
                height={size.height}
                width={size.width}
                src={resourceData.renderResource.path}
                title={"iframe"}
              />
            )}
          {resourceData.renderResource.name?.toLowerCase() === "iframe" &&
            resourceData.renderResource.type === "URL" && (
              <iframe
                height={size.height}
                width={size.width}
                src={resourceData.renderResource.path}
                title={"iframe"}
              />
            )}
        </div>
      </ResizableBox>
    </div>
  );
};

export default ConsoleComponent;
