import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance, getUserProfileById } from "../../services/api";

export const getUserProfile = createAsyncThunk(
  "user/fetchProfile",
  async (id) => {
    const response = await axiosInstance.get(`user/getByCognitoId/${id}`);
    if (localStorage.getItem("emailOfValidationCandidate")) {
      if (
        response?.data?.user?.email !==
        localStorage.getItem("emailOfValidationCandidate")
      ) {
        window.location.replace("/login");
        localStorage.clear();
        localStorage.removeItem("emailOfValidationCandidate");
        return;
      }
      localStorage.removeItem("emailOfValidationCandidate");
    }
    return response.data;
  }
);
export const updateUserProfile = createAsyncThunk(
  "user/updateUser",
  async ({ data, id }) => {
    const response = await axiosInstance.patch(`/user/update/${id}`, data);
    return response.data;
  }
);
export const validateToken = createAsyncThunk(
  "user/vaildateToken",
  async (token) => {
    const response = await axiosInstance.get(
      `company/validateAssessmentToken?token=${token}`
    );
    return response.data;
  }
);

export const setUserProfile = createAsyncThunk("user/setUser", async (data) => {
  if (localStorage.getItem("newUserRole")) {
    data.roles = [localStorage.getItem("newUserRole")];
    data.companyIds = localStorage.getItem("companyId");
    // data.email = localStorage.getItem("emailOfValidation");
    localStorage.removeItem("newUserRole");
    localStorage.removeItem("companyId");
    localStorage.removeItem("emailOfValidation");
  }

  if (localStorage.getItem("emailOfValidationCandidate")) {
    const { data: responseData } = await getUserProfileById(data.cognitoId);
    if (
      responseData?.user?.email !==
      localStorage.getItem("emailOfValidationCandidate")
    ) {
      window.location.replace("/login");
      localStorage.clear();
      localStorage.removeItem("emailOfValidationCandidate");
      return;
    }
    localStorage.removeItem("emailOfValidationCandidate");
  }

  const response = await axiosInstance.post(`user/addUser`, data);
  return response.data;
});

export const getCompanyDataAPI = createAsyncThunk(
  "user/getCompany",
  async (id) => {
    const response = await axiosInstance.get(
      `company/getCompanyDetailsById/${id}`
    );
    return response.data;
  }
);

export const getChallengeDetails = createAsyncThunk(
  "get/assessment",
  async (data) => {
    const { companyId, userId, assessmentId, mappingId } = data;
    const response = await axiosInstance.get(
      `result/${userId}/${assessmentId}/${companyId}?assessmentMappingId=${mappingId}`
    );
    return response.data;
  }
);
