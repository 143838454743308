import {
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  Input,
  Row,
  Space,
  Tag,
  Typography,
  message,
  theme,
} from "antd";
import AdminsTable from "../../../components/Tables/AdminsTable";
import { useCallback, useEffect, useState } from "react";
import {
  addCompanyInvites,
  deleteChallenge,
  getChallengesByType,
  getOptions,
  getTags,
} from "../../../services/api";
import { useNavigate } from "react-router-dom";
import { DeleteOutlined, DownOutlined, EditOutlined } from "@ant-design/icons";
import { StyledLoader } from "../../../components/loader";
import DeleteModal from "../../../components/Modals/DeleteModal";
import InvitesModal from "../../../components/Modals/InvitesModal";
import { debounce } from "lodash";
import "./index.css";

const { Text } = Typography;
const Challenges = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [resetIsDisabled, setResetIsDisabled] = useState(true);
  const [allCompanies, setAllCompanies] = useState([]);
  const [searchedCompanies, setSearchedCompanies] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [resourcesOptions, setResourcesOptions] = useState([]);
  const [deleteChallengeId, setDeleteChallengeID] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState({});
  const [selectedTypeFilter, setSelectedTypeFilter] = useState("");
  const [selectedTags, setSelectedTags] = useState("");
  const [selectedTagName, setSelectedTagName] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0, // You might not know this until you fetch the first set of data
  });
  const navigate = useNavigate();

  const {
    token: { colorIcon, colorBgContainer, colorPrimary },
  } = theme.useToken();

  const handleDeleteModal = (id) => {
    setDeleteChallengeID(id);
    setIsModalOpen(true);
  };

  const handleSearch = ({ target: { value } }) => {
    setSearchKey(value);
    debouncedHandleSearch(value);
  };

  useEffect(() => {
    setSearchedCompanies(allCompanies);
  }, [allCompanies]);

  const columns = [
    {
      title: "SR No.",
      dataIndex: "key",
      key: "key",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text}</a>,
      sorter: true,
    },
    {
      title: "Challenge Type",
      dataIndex: "typeOfChallenge",
      key: "typeOfChallenge",
      render: (text) => <a>{text}</a>,
      sorter: true,
    },
    {
      title: "Number of questions",
      dataIndex: "numberOfQuestions",
      key: "numberOfQuestions",
      sorter: true,
    },
    {
      title: "Tags",
      key: "tags",
      dataIndex: "tags",
      render: (_, { tags }) => (
        <>
          {tags.map((tag) => {
            return (
              <Tag className="my-1" color={tag.colorCode} key={tag}>
                {tag.name.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
      sorter: true,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <Space size="middle">
            <EditOutlined
              className="hover:text-blue-400"
              onClick={() =>
                navigate(`/admin/challenge/edit?id=${record?._id}`)
              }
            />
            <DeleteOutlined
              className="hover:text-blue-400"
              onClick={() => handleDeleteModal(record?._id)}
            />
          </Space>
        );
      },
    },
  ];
  const data = [
    ...searchedCompanies.map((item, i) => {
      return {
        _id: item?._id,
        key: i + 1,
        name: item?.name,
        typeOfChallenge: item?.typeOfChallenge.map((item) => item).join(", "),
        numberOfQuestions: item?.questions?.length,
        tags: item?.tags,
      };
    }),
  ];

  const debouncedHandleSearch = useCallback(
    debounce((searchKey) => {
      getAllChallenges(
        selectedTypeFilter,
        selectedTags,
        1,
        10,
        null,
        null,
        searchKey
      );
    }, 1000),
    []
  );

  const handleTableChange = (
    pagination,
    filters,
    sorter,
    search = searchKey
  ) => {
    getAllChallenges(
      selectedTypeFilter,
      selectedTags,
      pagination?.current,
      pagination?.pageSize,
      sorter.order ? sorter.field : null,
      sorter.order,
      search
    );
  };

  const getAllChallenges = async (
    type,
    tags,
    page = 1,
    limit = 10,
    sortBy,
    order,
    searchKey
  ) => {
    setIsLoading(true);
    try {
      const { data, status } = await getChallengesByType(
        type,
        tags,
        page,
        limit,
        sortBy,
        order ? order : "descend",
        searchKey
      );
      setAllCompanies(data?.challenges);
      setPagination((prev) => ({ ...prev, total: data?.count, current: page }));
    } catch (error) {
      message.error(
        error?.response?.data?.error || error.message || "Something wen wrong"
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllChallenges(selectedTypeFilter, selectedTags);
  }, []);

  const handleDeleteChallenge = async () => {
    setIsModalOpen(false);
    // setIsLoading(true)
    try {
      const index = allCompanies.findIndex(
        (ind) => ind._id === deleteChallengeId
      );
      const arr = [...allCompanies];
      arr.splice(index, 1);
      setAllCompanies(arr);
      message.success("Challenge deleted!");

      const { data, status } = await deleteChallenge(deleteChallengeId);
      setDeleteChallengeID(null);
      // setIsLoading(false)
    } catch (error) {
      // setIsLoading(false)
      getAllChallenges();
      message.success("Error deleting challenge!");
    }
  };

  const addInvites = async (values) => {
    setIsInviteModalOpen(false);
    setIsLoading(true);
    try {
      const { data, status } = await addCompanyInvites(
        { invites: parseInt(values?.invites), expiryDate: values?.expiryDate },
        selectedCompany?._id
      );
      setSelectedCompany({});
      getAllChallenges();
      setIsLoading(false);
    } catch (error) {
      console.log({ error });
      setIsLoading(false);
    }
  };

  const getAllTags = async () => {
    try {
      const { data } = await getTags(null, null);
      setAllTags(data.tags);
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    getAllTags();
  }, []);

  const handleMenuClick = (e) => {
    setSelectedTags(allTags[e.key]?._id);
    setSelectedTagName(allTags[e.key]?.name);
    getAllChallenges(selectedTypeFilter, allTags[e.key]?._id);
    setResetIsDisabled(false);
  };

  const menuProps = {
    items: [
      ...allTags?.map((item, i) => {
        return {
          label: item.name,
          key: i,
        };
      }),
    ],
    onClick: handleMenuClick,
  };

  const geAllResources = async () => {
    try {
      const { data } = await getOptions("CHALLENGE_RESOURCE_TYPE");
      console.log(data);
      setResourcesOptions(data?.companies);
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    geAllResources();
  }, []);

  const handleMenuClickTypes = (e) => {
    setSelectedTypeFilter(resourcesOptions[e.key]?.name.toUpperCase());
    if (
      resourcesOptions[e.key] &&
      resourcesOptions[e.key].hasOwnProperty("name")
    ) {
      getAllChallenges(
        resourcesOptions[e.key].name.toUpperCase(),
        selectedTags
      );
    } else {
      message.error("Data not found for this type!");
    }
    getAllChallenges(resourcesOptions[e.key]?.name.toUpperCase(), selectedTags);
    setResetIsDisabled(false);
  };

  const resetFilters = () => {
    setResetIsDisabled(true);
    setSelectedTypeFilter("");
    setSelectedTags("");
    setSelectedTagName("");
    getAllChallenges();
  };

  const menuPropsTypes = {
    items: [
      ...resourcesOptions?.map((item, i) => {
        return {
          label: item.name,
          key: i,
        };
      }),
    ],
    onClick: handleMenuClickTypes,
  };

  return (
    <div>
      <Card style={{ width: "100%" }}>
        <div className="flex justify-between items-center px-3 pb-4 w-full">
          <Text className="text-2xl font-semibold font-[#1f2232]">
            Challenges
          </Text>
          <Row>
            {/* <Button size="large"onClick={() => setIsInviteModalOpen(true)} type="link">Add Invites +</Button> */}
            <Button
              style={{
                fontSize: "16px",
                lineHeight: "24px",
                textAlign: "left",
                color: "#070908",
                marginBottom: "10px",
              }}
              size="large"
              onClick={() => navigate("/admin/challenges/add")}
            >
              Add New +
            </Button>
          </Row>
        </div>
        <Divider style={{ margin: 0 }} />
        <Row gutter={12} justify={"space-between"} className="my-2">
          <Col span={8}>
            <Input
              size="large"
              value={searchKey}
              onChange={(e) => handleSearch(e)}
              placeholder="Search name..."
            />
          </Col>
          <Col span={16}>
            <Row justify={"end"} gutter={12}>
              <Col>
                <Dropdown menu={menuPropsTypes} trigger={["click"]}>
                  <Button
                    style={{
                      fontSize: "16px",
                      lineHeight: "24px",
                      textAlign: "left",
                      color: "#070908",
                      marginBottom: "10px",
                    }}
                    size="large"
                    className="w-full"
                  >
                    <Space className="flex items-center justify-between">
                      {selectedTypeFilter || "Select challenge type"}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Col>
              <Col>
                <Dropdown
                  overlayClassName="tags-selection-dropdown"
                  menu={menuProps}
                  trigger={["click"]}
                >
                  <Button
                    style={{
                      fontSize: "16px",
                      lineHeight: "24px",
                      textAlign: "left",
                      color: "#070908",
                      marginBottom: "10px",
                    }}
                    size="large"
                    className="w-full"
                  >
                    <Space className="flex items-center justify-between">
                      {selectedTagName || "Select tag"}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Col>
              <Col>
                <Button
                  disabled={resetIsDisabled}
                  size="large"
                  onClick={resetFilters}
                >
                  Reset
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider style={{ margin: 0 }} />
        {isLoading ? <StyledLoader /> : null}
        <AdminsTable
          onChange={handleTableChange}
          columns={columns}
          data={data}
          pagination={pagination}
        />
        <DeleteModal
          title="Delete record"
          text="Are you sure you want to delete this record?"
          deleteAction={handleDeleteChallenge}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          isDelete
        />
        <InvitesModal
          onFinish={addInvites}
          selectedCompany={selectedCompany}
          setSelectedCompany={setSelectedCompany}
          isModalOpen={isInviteModalOpen}
          setIsModalOpen={setIsInviteModalOpen}
        />
      </Card>
    </div>
  );
};

export default Challenges;
