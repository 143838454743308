import { LoadingOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, Spin, Typography, message } from "antd";
import { useState } from "react";
import { createCompany } from "../../../services/api";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../../components/Modals/DeleteModal";

const { Text } = Typography;
const AddCompany = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      await createCompany({ ...values, remainingInvites: values.totalInvites });
      message.success("Company created!");
      navigate("/admin/companies");
      setIsLoading(false);
    } catch (error) {
      message.error(
        error?.response?.data?.error || error?.message || "Something went wrong"
      );
      setIsLoading(false);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const validateInput = async (_, value) => {
    if (value < 0) {
      return Promise.reject(new Error("Input value cannot be less than 0!"));
    }
    const isInteger = Number.isInteger(Number(value));
    if (!isInteger) {
      return Promise.reject("Please enter an integer number!");
    }
    return Promise.resolve();
  };

  return (
    <div>
      <Card style={{ width: "100%" }}>
        <>
          <div className="flex justify-between items-center px-3 py-4 w-full">
            <Text className="text-2xl font-semibold font-[#1f2232]">
              Create Company
            </Text>
          </div>
          <div className="p-3">
            <Form
              name="basic"
              layout="vertical"
              className="w-full"
              labelCol={{
                span: 8,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <div className="flex flex-wrap">
                <Form.Item
                  label="Name"
                  name="name"
                  className="w-1/2 px-2"
                  rules={[
                    {
                      required: true,
                      message: "Please input company's name!",
                    },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>

                <Form.Item
                  label="Description"
                  name="description"
                  className="w-1/2 px-2"
                  rules={[
                    {
                      required: true,
                      message: "Please input company's description!",
                    },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>

                <Form.Item
                  label="Number of invites"
                  name="totalInvites"
                  className="w-1/2 px-2"
                  rules={[
                    {
                      required: true,
                      message: "Please input number of invites!",
                    },
                    {
                      validator: validateInput,
                    },
                  ]}
                >
                  <Input size="large" type="number" />
                </Form.Item>

                <Form.Item className="flex justify-end w-full">
                  <Button
                    className="mr-2"
                    onClick={() => setIsModalOpen(true)}
                    size="large"
                  >
                    Cancel
                  </Button>
                  <Button size="large" htmlType="submit">
                    {isLoading ? (
                      <div className="flex items-center">
                        <Spin
                          className="mr-2"
                          indicator={
                            <LoadingOutlined
                              style={{
                                fontSize: 18,
                              }}
                              spin
                            />
                          }
                        />
                        Create
                      </div>
                    ) : (
                      "Create"
                    )}
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </>
      </Card>
      <DeleteModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        deleteAction={() => navigate("/admin/companies")}
        title="Discard changes"
        actionText="Yes"
        text="Are you sure you want to discard?"
      />
    </div>
  );
};

export default AddCompany;
