import { LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Spin,
  Typography,
  message,
} from "antd";
import "./Candidate.css";
import { useState } from "react";
import SubHeader from "../../components/Headers/SubHeader";
import { PiLockKey } from "react-icons/pi";
import { updatePassword } from "../../services/api";

const Password = () => {
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      await updatePassword({
        ...values,
        email: JSON.parse(localStorage.getItem("user")).email,
      });
      setIsLoading(false);
      message.success("Password updated!");
    } catch (error) {
      message.error(error.response.data.error);
      setIsLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="w-full">
      <div style={{ maxWidth: 1500, margin: "auto" }} className="px-10">
        <Card className="shadow-lg mb-10">
          <Form
            name="basic"
            layout="vertical"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Row>
              <Col span={8}>
                <div className="flex flex-col text-left">
                  <Typography.Text className="text-2xl font-semibold">
                    Password
                  </Typography.Text>
                  <Typography.Text className="text-gray-500">
                    Changing your password will sign out all sessions
                  </Typography.Text>
                </div>
              </Col>
              <Col span={16} className="flex flex-wrap">
                <Form.Item
                  name="oldPassword"
                  rules={[
                    {
                      required: true,
                      message: "Please input your current password!",
                    },
                  ]}
                  className="w-full px-2"
                >
                  <Input.Password
                    className="input"
                    prefix={
                      <PiLockKey className="mr-2" color="rgb(99, 98, 98)" />
                    }
                    placeholder="Current Password"
                    size="large"
                  />
                </Form.Item>

                <Form.Item
                  name="newPassword"
                  rules={[
                    {
                      required: true,
                      message: "Please input your new password!",
                    },
                    {
                      min: 8,
                      message: "Password must be at least 8 characters long!",
                    },
                  ]}
                  className="w-full px-2"
                >
                  <Input.Password
                    className="input"
                    prefix={
                      <PiLockKey className="mr-2" color="rgb(99, 98, 98)" />
                    }
                    placeholder="New Password"
                    size="large"
                  />
                </Form.Item>

                <Form.Item
                  name="confirmNewPassword"
                  dependencies={["newPassword"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your new password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("newPassword") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The two passwords that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                  className="w-full px-2"
                >
                  <Input.Password
                    className="input"
                    prefix={
                      <PiLockKey className="mr-2" color="rgb(99, 98, 98)" />
                    }
                    placeholder="Confirm Password"
                    size="large"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            <Form.Item>
              <Row justify={"end"}>
                <Button type="primary" size="large" htmlType="submit">
                  {isLoading ? (
                    <div className="flex items-center">
                      <Spin
                        className="mr-2"
                        indicator={
                          <LoadingOutlined
                            style={{
                              fontSize: 18,
                              color: "white",
                            }}
                            spin
                          />
                        }
                      />
                      Update Password
                    </div>
                  ) : (
                    "Update Password"
                  )}
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default Password;
