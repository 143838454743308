import { Col, Row, Tag, message } from "antd";
import { useEffect, useRef, useState } from "react";
import { createTag, getAllTagsWithoutFilter } from "../../services/api";

const ChallengeTags = ({ selectedTags, setSelectedTags }) => {
  const initialTags = [
    { name: "Excelent", colorCode: "#f50" },
    { name: "Good", colorCode: "#2db7f5" },
    { name: "Poor", colorCode: "#87d068" },
    { name: "Loser", colorCode: "#2db7f5" },
  ];
  const [tags, setTags] = useState(initialTags);
  const [searchedTags, setSearchedTags] = useState(initialTags);
  const [showDropDown, setShowDropDown] = useState(false);
  const [showAddTag, setShowAddTag] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const inputRef = useRef(null);
  const dropdownRef = useRef(null); // Ref for the dropdown

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropDown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleItemClick = (item) => {
    setShowDropDown(false);
    const arr = [...selectedTags];
    arr.push(item);
    setSelectedTags(arr);
    const allTags = [...tags];
    const index = allTags.findIndex((ind) => ind.name === item.name);
    allTags.splice(index, 1);
    setTags(allTags);
    setSearchedTags(allTags);
    setSearchValue("");
    focusInput();
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchValue(value);
    setShowDropDown(true);
    const filteredArr = tags.filter((item) =>
      item?.name.toLowerCase().includes(value.toLowerCase())
    );
    setSearchedTags(filteredArr);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleAddTag(searchValue);
      event.preventDefault();
    }
  };
  const focusInput = () => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);
  };

  const handleDeleteTag = (tag) => {
    let filteredArray = selectedTags.filter(
      (item) => item.name.toLowerCase() !== tag.name.toLowerCase()
    ); // Use unique identifier
    setSelectedTags(() => filteredArray);
    const tagsArr = [...tags, tag]; // It seems correct to add back to the available tags
    setTags(tagsArr);
    setSearchedTags(tagsArr);
    focusInput();
  };

  const handleAddTag = async (val = searchValue) => {
    try {
      setShowDropDown(false);
      setSearchValue("");
      focusInput();

      const { data } = await createTag({
        tags: [{ name: val, colorCode: "#807e7e" }],
      });
      const arr = [
        ...selectedTags,
        {
          name: val,
          colorCode: "#807e7e",
          _id: data.tag.length ? data.tag[0]._id : undefined,
        },
      ];
      setSelectedTags(arr);
    } catch (error) {
      message.error(error.response.data.error);
    }
  };

  const getAllTags = async () => {
    try {
      const { data } = await getAllTagsWithoutFilter();
      setTags(data?.tags);
      setSearchedTags(data?.tags);
    } catch (error) {
      message.error(error.response.data.error);
    }
  };

  useEffect(() => {
    getAllTags();
  }, []);

  useEffect(() => {
    const index = tags.findIndex(
      (item) =>
        item?.name.toLocaleLowerCase() === searchValue.toLocaleLowerCase()
    );
    if (index < 0) {
      setShowAddTag(true);
    } else {
      setShowAddTag(false);
    }
  }, [searchValue, tags]);

  return (
    <div
      style={{
        border: "1px solid #bdbdd4",
        borderRadius: 6,
      }}
      className="w-full flex flex-wrap py-1 px-2"
    >
      <>
        {selectedTags.map((tag, i) => {
          return (
            <Tag
              className="my-2"
              onClose={() => handleDeleteTag(tag)}
              bordered={false}
              closable
              color={tag.colorCode}
              key={tag.name}
            >
              {tag.name?.toUpperCase()}
            </Tag>
          );
        })}
      </>
      <input
        onFocus={() => setShowDropDown(true)}
        placeholder="Search"
        ref={inputRef}
        value={searchValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        className="my-2 flex-1 outline-none border-none"
      />
      <div className="w-full relative">
        {showDropDown ? (
          <div
            ref={dropdownRef}
            className="absolute z-10 top-2 max-h-[300px]  overflow-x-hidden overflow-auto shadow-xl rounded-lg bg-white w-64 p-3"
          >
            <Col>
              {searchedTags?.map((item, i) => {
                return (
                  <Row
                    onClick={() => handleItemClick(item)}
                    className="px-2 py-1 cursor-pointer hover:bg-gray-200 rounded-lg"
                    key={i}
                  >
                    {item?.name}
                  </Row>
                );
              })}
              {showAddTag ? (
                <Row
                  onClick={() => handleAddTag()}
                  className="px-2 py-1 cursor-pointer hover:bg-gray-200 rounded-lg"
                >
                  Add tag as "{searchValue}"
                </Row>
              ) : null}
            </Col>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ChallengeTags;
