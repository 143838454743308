import { Button, Form, message } from "antd";
import "./Auth.css";
import AuthBackground from "../../components/Backgrounds/AuthBackground";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  confirmSignIn,
  confirmSignUp,
  getCurrentUser,
  resendSignUpCode,
  resetPassword,
} from "@aws-amplify/auth";
import { getUserProfile } from "../../store/actions";
import { connect } from "react-redux";
import useProvideAuth from "../../hooks/useAuth";
import { InputOTP } from "antd-input-otp";
import { StyledLoader } from "../../components/loader";
import { sendWelcomeEmail } from "../../services/api";

function OTP({ getUserProfile }) {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const auth = useProvideAuth();
  const location = useLocation();
  const type = params.get("type");
  useEffect(() => {
    // Clear input values on component mount
    form.setFieldsValue({
      email: "",
      password: "",
    });
  }, []);

  const handleLogin = async ({ otp }) => {
    setIsLoading(true);
    try {
      switch (type) {
        case "LOGIN":
          await confirmSignIn({ challengeResponse: otp.join("") });
          break;
        case "SIGNUP": {
          const { email, password, returnPath } = location.state;
          if (!email?.length || !password?.length) {
            navigate("/signup");
          }
          await confirmSignUp({
            username: email,
            confirmationCode: otp.join(""),
          });
          const { nextStep } = await auth.signInWithCognito({
            email: email,
            password: password,
          });
          if (nextStep.signInStep !== "DONE") {
            message.error("Not able to complete the Signup");
            navigate(returnPath || "/signup");
          }
          break;
        }
        case "FORGOT_PASSWORD": {
          const { email } = location.state;
          if (!email?.length) {
            navigate("/signup");
          }
          await confirmSignUp({
            username: email,
            confirmationCode: otp.join(""),
          });
          await resetPassword({ username: email });
          message.success("A confirmation code has been sent to your email");
          navigate(`/new-password?email=${email}`);
          break;
        }
        default:
          navigate("/login");
          return;
      }
      if (type === "FORGOT_PASSWORD") {
        return;
      }
      const currentUser = await getCurrentUser();
      const user = await getUserProfile(currentUser.userId);

      if (type === "SIGNUP") {
        const { firstName, email } = user?.payload?.user || {};
        sendWelcomeEmail({
          firstName,
          email,
        });
      }

      localStorage.setItem("isSSO", false);
    } catch (err) {
      message.error(
        err?.response?.data?.error || err?.message || "Something went wrong"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendEmail = async () => {
    try {
      setIsLoading(true);
      const { email, returnPath } = location.state;
      if (!email || !email?.length) {
        message.error("No Email Found");
        navigate(returnPath || "/signup");
        return;
      }
      await resendSignUpCode({ username: email });
      message.info("Code resent successfully.");
    } catch (error) {
      message.error(error.message || "Unable to resend code");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthBackground>
      {isLoading ? <StyledLoader /> : null}

      <div className="w-full flex flex-col items-center mt-7">
        <p className="title">One Time Password</p>
        <p className="description-2 mt-4">Enter your OTP in order to proceed</p>
        <div className="w-full mt-8">
          <Form
            form={form}
            layout="vertical"
            className="w-full"
            onFinish={handleLogin}
          >
            <Form.Item
              label="OTP"
              name="otp"
              className="w-full custom-form-item" // Add a custom class to the Form.Item
              rules={[
                {
                  required: true,
                  message: "Please enter your OTP!",
                },
              ]}
            >
              {/* <Input
                autoComplete="off"
                className="w-full input"
                size="large"
                style={{ borderColor: "#E6E6EB10" }}
                placeholder="Enter your OTP"
              /> */}
              {/* <OTPInput length={6} onComplete={(e) => alert(e)} /> */}
              <InputOTP
                inputClassName="input"
                autoSubmit={form}
                inputType="numeric"
              />
            </Form.Item>
            {/* <Button
              size="large"
              htmlType="submit"
              style={{
                height: "44px",
                fontSize: "16px",
                fontWeight: 500,
                border: "none",
              }}
              loading={isLoading}
              disabled={isLoading}
              type="primary"
              className="w-full"
            >
              Continue
            </Button> */}
            <div className="w-full flex justify-between mt-8">
              <Button
                type="link"
                className="flex items-center"
                onClick={() => navigate("/login")}
              >
                <img
                  src="assets/icons/Back.png"
                  style={{ width: "18px", height: "12px" }}
                  alt="back to sign in"
                />
                <p
                  className="ml-2"
                  style={{
                    color: "#FFFFFF",
                  }}
                >
                  Back to Sign In
                </p>
              </Button>

              {type === "SIGNUP" && (
                <Button
                  type="link"
                  className="flex items-center"
                  onClick={handleResendEmail}
                >
                  <p
                    className="ml-2"
                    style={{
                      color: "#FFFFFF",
                      cursor: "pointer",
                    }}
                  >
                    Resend OTP
                  </p>
                </Button>
              )}
            </div>
          </Form>
        </div>
      </div>
    </AuthBackground>
  );
}

const mapDispatchToProps = { getUserProfile };

export default connect(null, mapDispatchToProps)(OTP);
