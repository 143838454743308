import { Button, Checkbox, Divider, Form, Input, message } from "antd";
import "./Auth.css";
import AuthBackground from "../../components/Backgrounds/AuthBackground";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { signup } from "../../services/api";
import { signInWithRedirect } from "@aws-amplify/auth";

function Signup() {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  let token = searchParams.get("token");

  useEffect(() => {
    form.setFieldsValue({
      email: "",
      password: "",
    });
  }, []);

  const handleSignup = async (values) => {
    setIsLoading(true);
    if (localStorage.getItem("newUserRole")) {
      const email = localStorage.getItem("emailOfValidation");
      values.roles = [
        email?.trim() === values?.email?.trim()
          ? localStorage.getItem("newUserRole")
          : "USER",
      ];
      values.companyIds = [localStorage.getItem("companyId")];
      localStorage.removeItem("newUserRole");
      localStorage.removeItem("companyId");
    }
    try {
      await signup(values);
      message.info(`We have sent you a OTP via an email on ${values.email}`);
      localStorage.setItem("assessmentToken", JSON.stringify(token));
      navigate(`/otp?type=SIGNUP`, {
        state: { email: values.email, password: values.password },
      });
    } catch (error) {
      message.error(
        error?.response?.data?.error || error?.message || "Something went wrong"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleIDPClick = async (provider) => {
    localStorage.setItem("assessmentToken", JSON.stringify(token));
    signInWithRedirect({
      provider: { custom: provider },
    });
  };

  return (
    <AuthBackground class={"py-20"}>
      <div className="w-full flex flex-col items-center mt-7">
        <p className="title">Create Account</p>
        <p className="description-2 mt-4">Create an account to get started </p>
        <div className="w-full">
          <Form
            form={form}
            layout="vertical"
            className="w-full"
            onFinish={handleSignup}
          >
            <SSOButton
              className="mt-6"
              onClick={() => handleIDPClick("Google")}
            >
              <img
                src="./assets/icons/google.webp"
                style={{ width: "22px" }}
                alt="google"
                className="mr-2"
              />
              Sign Up with Google
            </SSOButton>
            <SSOButton
              className="mt-8"
              onClick={() => handleIDPClick("Linkedin")}
            >
              <img
                src="./assets/icons/linkedin.png"
                style={{ width: "28px" }}
                alt="google"
                className="mr-2"
              />
              Sign Up with LinkedIn
            </SSOButton>
            {/* <SSOButton
              className="mt-8"
              onClick={() => handleIDPClick("Microsoft")}
            >
              <img
                src="./assets/icons/microsoft.png"
                style={{ width: "20px" }}
                alt="google"
                className="mr-2"
              />
              Sign Up with Microsoft
            </SSOButton> */}
            <div className="relative mt-8">
              <Divider
                className="absolute top-0"
                style={{ margin: 0, borderColor: "#E6E6EB", opacity: "10%" }}
              />
              <div
                style={{ marginTop: -11 }}
                className="absolute w-full text-center"
              >
                <div className="">
                  <span className="text-[#E6E6EB] bg-[#010c07] px-2">OR</span>
                </div>
              </div>
            </div>
            <Form.Item
              label="First Name"
              name="firstName"
              className="w-full custom-form-item mt-14" // Add a custom class to the Form.Item
              rules={[
                {
                  required: true,
                  message: "Please enter first name!",
                },
              ]}
            >
              <Input
                autoComplete="off"
                className="w-full input"
                size="large"
                style={{ borderColor: "#E6E6EB10" }}
                placeholder="Enter your first name"
              />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="lastName"
              className="w-full custom-form-item" // Add a custom class to the Form.Item
              rules={[
                {
                  required: true,
                  message: "Please enter last name!",
                },
              ]}
            >
              <Input
                autoComplete="off"
                className="w-full input"
                size="large"
                style={{ borderColor: "#E6E6EB10" }}
                placeholder="Enter your last name"
              />
            </Form.Item>
            <Form.Item
              label="Email Address"
              name="email"
              className="w-full custom-form-item" // Add a custom class to the Form.Item
              rules={[
                {
                  type: "email",
                  message: "Email not valid!",
                },
                {
                  required: true,
                  message: "Please enter email",
                },
              ]}
            >
              <Input
                autoComplete="off"
                className="w-full input"
                size="large"
                style={{ borderColor: "#E6E6EB10" }}
                placeholder="Enter your email"
              />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              className="w-full custom-form-item"
              labelCol={{ style: { color: "#FFFFFF" } }}
              rules={[
                { required: true, message: "Please enter your password!" },
                {
                  pattern:
                    /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+}{"':;?/>.<,])(?=.{8,})/,
                  message:
                    "Password must contain at least one capital letter and one symbol and 8 characters.",
                },
              ]}
            >
              <Input.Password
                autoComplete="off"
                className="custom-input input password"
                size="large"
                style={{ borderColor: "#E6E6EB10" }}
                placeholder="Please enter your password"
              />
            </Form.Item>
            <Form.Item
              name="toc"
              className="w-full custom-form-item flex justify-start mb-0"
              labelCol={{ style: { color: "#FFFFFF" } }}
              rules={[
                {
                  required: true,
                  message: "You need to accept the terms and conditions",
                },
              ]}
              valuePropName="checked"
            >
              <Checkbox>
                <div className="flex items-center">
                  <p className="description-2">I agree with the</p>
                  <Button
                    type="link"
                    className="flex items-center p-0 m-0"
                    onClick={() =>
                      window.open(
                        "https://www.cyber-hire.com/terms-of-use",
                        "_blank"
                      )
                    }
                  >
                    <p
                      className="ml-1 underline"
                      style={{
                        color: "#FFFFFF",
                      }}
                    >
                      Terms and Conditions
                    </p>
                  </Button>
                </div>
              </Checkbox>
            </Form.Item>
            <Form.Item>
              <Button
                size="large"
                htmlType="submit"
                style={{
                  height: "44px",
                  fontSize: "16px",
                  fontWeight: 500,
                  border: "none",
                }}
                loading={isLoading}
                // disabled={isLoading}
                type="primary"
                className="w-full"
              >
                Sign Up
              </Button>
            </Form.Item>
            <div className="w-full flex justify-center items-center mt-8">
              <p className="description-2">Already have an account?</p>
              <Button
                type="link"
                className="flex items-center p-0"
                onClick={() => navigate("/login")}
              >
                <p
                  className="ml-1 underline"
                  style={{
                    color: "#FFFFFF",
                  }}
                >
                  Sign In
                </p>
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </AuthBackground>
  );
}

export default Signup;

const SSOButton = ({ children, onClick, className }) => (
  <Button
    size="large"
    onClick={onClick}
    style={{
      height: "44px",
      fontSize: "16px",
      fontWeight: 500,
      border: "none",
    }}
    className={`text-[#FFFFFF] w-full bg-[#343D39] flex items-center justify-center ${className}`}
  >
    {children}
  </Button>
);
