import React from 'react';
import { Bubble } from 'react-chartjs-2';

// const data = {
//     datasets: [
//         {
//             label: 'Bubble Chart',
//             data: [
//                 { x: 10, y: 20, r: 5 },
//                 { x: 15, y: 10, r: 10 },
//                 { x: 8, y: 25, r: 8 },
//                 { x: 20, y: 15, r: 12 },
//                 { x: 12, y: 18, r: 6 },
//             ],
//             backgroundColor: 'rgba(255, 99, 132, 0.6)',
//             borderColor: 'rgba(255, 99, 132, 1)',
//         },
//     ],
// };


// export const options = {
//     scales: {
//         y: {
//             beginAtZero: true,
//         },
//     },
//     responsive: true,
//     plugins: {
//         legend: {
//             position: 'left',
//             display: false
//         },
//         title: {
//             display: false,
//             text: 'Chart.js Line Chart',
//         },
//     },
// };


const BubbleChart = ({ data }) => {

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false
                }
            },
            y: {
                grid: {
                    display: false
                }
            }
        },
        plugins: {
            legend: {
                position: 'left',
                display: false
            },
            title: {
                display: false,
                text: 'Chart.js Bar Chart',
            },

            tooltip: {
                callbacks: {
                    title: (xDatapoint) => {
                        return xDatapoint.raw
                    },
                    label: (yDatapoint) => {
                        return `${yDatapoint.raw.x} Accuracy`

                    }
                }
            },
        },
    };
    const graphData = {
        datasets: [
            {
                label: '',
                data,
                backgroundColor: '#FFC48C',
            }
        ],
    };
    return (
        <>
            <p className="notes" style={{ marginBottom: "20px" }}>Composite Performance</p>
            <Bubble options={options} data={graphData} />
        </>
    )
};

export default BubbleChart;
