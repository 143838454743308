import { useState, useEffect } from 'react'
import { Button, Select, Input, Form, message, Upload, Table, Card, Tooltip } from 'antd'
import TableComponent from '../../components/Tables/Table'
import ModalWrapper from '../../components/Modals/ModalWrapper'
import moment from 'moment';
import { changeInviteRole, deleteUserInvite, changeUserRole, fetchAllCompanyUsers, fetchAllExistingCompanyUsers, inviteCandidateAdmin, deleteUserById, blockUser, unBlockUser } from '../../services/api';
import { connect } from 'react-redux';
import { UploadOutlined, CheckOutlined, CloseOutlined, EditOutlined, DeleteFilled, StopOutlined, UnlockOutlined } from '@ant-design/icons';
import React from 'react';
import { StyledLoader } from '../../components/loader';
import DeleteModal from '../../components/Modals/DeleteModal2';
import CSVDownload from '../../components/DownloadTemplate'


const { Option } = Select;

function CompanyAdmin({ user }) {
    const [inviteModalVisible, setInviteModalVisible] = useState("")
    const [invitations, setInvitations] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [editData, setEditData] = useState({})
    const [isEdit, setIsEdit] = useState(false)
    const [userType, setUserType] = useState("existing")
    const [sorter, setSorter] = useState({})
    const [searchKey, setSearchKey] = useState("")
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0, // You might not know this until you fetch the first set of data
    });
    const [isDelete, setIsDelete] = useState(false)
    const [deleteId, setDeleteId] = useState(null)

    useEffect(() => {
        handleTableChange(pagination, {}, {})
    }, [])

    useEffect(() => {
        setPagination({
            current: 1,
            pageSize: 10,
            total: 0, // You might not know this until you fetch the first set of data
        })
    }, [userType])

    const fetchCompanyUsers = async (query, currentPage) => {
        try {
            setIsLoading(true)
            if (userType === "existing") {
                const { data } = await fetchAllExistingCompanyUsers(user.companyIds[0], query)
                const { users } = data
                const dummyData = users.map((user) => ({
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                    status: user.status,
                    role: user.roles[0],
                    key: user._id,
                    userDetails: [{ _id: user._id }],
                    lastLogin: user.lastLogin ? moment(user.lastLogin).format('MMMM D, YYYY') : "N/A",
                }))

                setInvitations([...dummyData])
                setPagination(() => ({
                    ...pagination, total: data?.count || 0, current: currentPage
                }))
                return
            }
            const { data } = await fetchAllCompanyUsers(user.companyIds[0], query)
            const { invitations } = data
            const dummyData = invitations.map((invitation) => ({
                firstName: invitation.userDetails.length ? invitation.userDetails[0].firstName : "-",
                lastName: invitation.userDetails.length ? invitation.userDetails[0].lastName : "-",
                email: invitation.email,
                status: invitation.status,
                role: invitation.role,
                expiry: moment(invitation.expiryTime).format('hh:MM A DD-MM-YY'),
                url: `${window.location.host}/company/user/confirmation/${invitation.invitationToken}`,
                key: invitation._id,
                userDetails: invitation.userDetails,
            }))
            setInvitations([...dummyData])
            setPagination(() => ({
                ...pagination, total: data?.count || 0
            }))
        } catch (error) {
            message.error(error.message || "Something went wrong")
        } finally {
            setIsLoading(false)
        }
    }

    function copyTextToClipboard(text) {
        navigator.clipboard.writeText(text).then(
            function () { },
            function (err) {
                console.error("Could not copy text: ", err);
            }
        );
    }

    const handleBlockUser = async (id) => {
        try {
            setIsLoading(true);
            await blockUser(id)
            message.success("User successfully blocked")
            fetchCompanyUsers()
        } catch (err) {
            message.error(err.message || "Something went wrong")
        } finally {
            setIsLoading(false);
        }
    }
    const handleUnblockUser = async (id) => {
        try {
            setIsLoading(true);
            await unBlockUser(id)
            message.success("User successfully activated")
            fetchCompanyUsers()
        } catch (err) {
            message.error(err.message || "Something went wrong")
        } finally {
            setIsLoading(false);
        }
    }

    const columns = [
        {
            title: 'email',
            dataIndex: 'email',
            key: 'email',
            width: '20%',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'role',
            dataIndex: 'role',
            key: 'role',
            width: '20%',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Expiry',
            dataIndex: 'expiry',
            key: 'expiry',
            // ...getColumnSearchProps('expiry'),
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: true,
        },
        {
            title: 'Copy URL',
            dataIndex: 'url',
            key: 'url',
            render: (_, item) => {
                return <Button onClick={() => copyTextToClipboard(item.url)}>Copy</Button>
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            // render: (_, item) => {
            //     return <ActionMenu fetchCompanyUsers={fetchCompanyUsers} data={item} />
            // }
            render: (_, item) => {

                return <div style={{ display: "flex", gap: 5 }}>

                    <Tooltip placement="topLeft" title="Edit User">
                        <EditOutlined style={{ cursor: "pointer" }} onClick={() => {
                            setEditData(item)
                            setInviteModalVisible("individual")
                            setIsEdit(true)
                        }} />
                    </Tooltip>
                    <Tooltip placement="topLeft" title="Delete User">
                        <DeleteFilled style={{ cursor: "pointer" }} onClick={() => {
                            setDeleteId(item.key)
                            setIsDelete(true)
                        }} />
                    </Tooltip>
                </div>
            }
        },
    ];
    const existingUserColumn = [
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
            width: '30%',
            sorter: true,
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
            width: '30%',
            sorter: true,
        },
        {
            title: 'email',
            dataIndex: 'email',
            key: 'email',
            width: '20%',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'role',
            dataIndex: 'role',
            key: 'role',
            width: '20%',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: true,
        },
        {
            title: 'Last Active',
            dataIndex: 'lastLogin',
            key: 'lastLogin',
            sorter: true,
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            // render: (_, item) => {
            //     return <ActionMenu fetchCompanyUsers={fetchCompanyUsers} data={item} />
            // }
            render: (_, item) => {
                return user.email === item.email ? <></> : <div style={{ display: "flex", gap: 2 }}>

                    <Tooltip placement="topLeft" title="Edit User">
                        <EditOutlined style={{ cursor: "pointer" }} onClick={() => {
                            setEditData(item)
                            setInviteModalVisible("individual")
                            setIsEdit(true)
                        }} />
                    </Tooltip>
                    <Tooltip placement="topLeft" title="Delete user">
                        <DeleteFilled style={{ cursor: "pointer" }} onClick={() => {
                            setDeleteId(item.key)
                            setIsDelete(true)
                        }} />
                    </Tooltip>
                    {item.status === "BLOCKED" ?
                        <Tooltip placement="topLeft" title="Activate user">
                            <UnlockOutlined style={{ cursor: "pointer" }} onClick={() => handleUnblockUser(item.key)} />
                        </Tooltip>
                        : <Tooltip placement="topLeft" title="Block user">
                            <StopOutlined style={{ cursor: "pointer" }} onClick={() => handleBlockUser(item.key)} />
                        </Tooltip>}

                </div>
            }
        },
    ];
    const handleTableChange = (pagination, filters, sorter) => {
        let query = `pageNo=${pagination?.current}&pageSize=${pagination?.pageSize}`

        for (let key in filters) {
            query += `&${key}=${filters[key]}`
        }
        if (sorter?.field && sorter?.order) {
            query += `&sortBy=${sorter.field === "name" ? "firstName" : sorter.field}&order=${sorter.order}`
        }
        setPagination(pagination)
        setSorter(sorter)
        fetchCompanyUsers(query, pagination.current);
    };

    useEffect(() => {
        fetchCompanyUsers()
    }, [userType])

    const handleDelete = async () => {
        try {
            setIsLoading(true)
            if (userType === 'pending') {
                await deleteUserInvite(deleteId)
            } else {
                await deleteUserById(deleteId)
            }

            setIsDelete(false)
            setDeleteId(null)
            fetchCompanyUsers()
            message.success("Deletion successful")
        } catch (error) {
            message.error(error?.message || "Something went wrong")
        } finally {
            setIsLoading(false)
        }
    }

    return <div >
        {isLoading ? <StyledLoader /> : null}
        <div style={{ display: "flex", justifyContent: "space-between" }}>

            <div style={{ display: "flex", justifyContent: "flex-end", gap: 5, marginBottom: "10px" }}>
                <Button type={userType === "existing" ? "primary" : ""} size="large" onClick={() => setUserType("existing")}>
                    Existing
                </Button>
                <Button type={userType === "pending" ? "primary" : ""} size="large" onClick={() => setUserType("pending")}>
                    Pending
                </Button>
            </div>

            <div style={{ display: "flex", justifyContent: "flex-end", gap: 5, marginBottom: "10px" }}>
                <Button type="primary" size="large" onClick={() => setInviteModalVisible("individual")}>
                    Invite
                </Button>
                <Button type="primary" size="large" onClick={() => setInviteModalVisible("bulk")}>
                    Bulk Invite
                </Button>
            </div>
        </div>
        {inviteModalVisible.length ? <InviteFormModal userType={userType} setIsLoading={setIsLoading} setIsEdit={setIsEdit} setEditData={setEditData} isEdit={isEdit} editData={editData} user={user} isBulk={inviteModalVisible === "bulk"} fetchCompanyUsers={fetchCompanyUsers} onClose={setInviteModalVisible} /> : null}
        {isDelete ? <DeleteModal heading="Are you sure you want
                to delete the user?" description="These changes will take effect immediately.
                You will not be able to restore this user." handleDelete={handleDelete} handleClose={() => {
                setDeleteId(null)
                setIsDelete(false)
            }} /> : null}
        <Card >
            <TableComponent
                columns={userType === "existing" ? existingUserColumn : columns}
                data={invitations}
                onChange={(p, f, s) => handleTableChange(p, !searchKey?.length ? {} : { searchKey }, s)}
                pagination={pagination}
                isSearch
                appendSearchKey={false}
                sorter={sorter}
                callback={(val, s, p) => {
                    handleTableChange({
                        current: 1,
                        pageSize: 10,
                        total: 0, // You might not know this until you fetch the first set of data
                    }, !val?.length ? {} : { searchKey: val }, s)
                    setSearchKey(val)
                }}
            />
        </Card>
    </div>
}

const mapStateToProps = (state) => ({
    user: state.auth.user
});
const mapDispatchToProps = {
    // getSelectedChallenge: ChallengeSlice.actions.getSelectedChallenge,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyAdmin);

const InviteFormModal = ({ isEdit, editData, userType, setIsEdit, setIsLoading, setEditData, onClose, isBulk, user, fetchCompanyUsers }) => {
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState([]);
    const [isRoleSelected, setIsRoleSelected] = useState(false);
    const [emails, setEmails] = useState([]);
    const [isAdmin, setIsAdmin] = useState(isEdit && editData.role !== "COMPANY_ADMIN" ? false : true)

    const beforeUpload = (file) => {

        const isCSV = file.type === 'text/csv';
        if (!isCSV) {
            message.error(`${file.name} is not a CSV file`);
        }

        const reader = new FileReader();

        reader.onload = e => {
            const data = e.target.result.split('\n');
            data.shift()
            setEmails(data)
        };
        reader.readAsText(file);

        return isCSV || Upload.LIST_IGNORE;

    };

    const onFinish = async (values) => {
        let bulkEmails = []
        try {
            setIsLoading(true)
            if (isEdit) {
                if (userType === "existing") {
                    await changeUserRole(editData.userDetails[0]._id, values.role)
                } else {
                    await changeInviteRole(editData.key, values.role)
                }
                message.success("User role successfully changed")
            } else {

                if (isBulk) {
                    if (!emails.length) {
                        message.error("Emails must be provided")
                        return
                    }
                    emails.map((email) => {
                        if (email.length) {
                            bulkEmails.push(email.trim())
                        }
                    })

                    const uniqueEmailsSet = new Set(bulkEmails);
                    bulkEmails = Array.from(uniqueEmailsSet);
                }

                await inviteCandidateAdmin(user.companyIds[0], {
                    emails: isBulk ? bulkEmails : [values.email],
                    role: values.role
                })
                message.success("Invitation successfully sent")
            }

            onClose("")
            setIsEdit(false)
            onClose(false);
            fetchCompanyUsers()
        } catch (error) {
            message.error(error?.response?.data?.error || error.message || "Something went wrong");
        } finally {
            setIsLoading(false)
        }
    };

    const handleFileChange = (e) => {
        setFileList(e.fileList);
    };
    // Cancel handler
    const onCancel = () => {
        form.resetFields();
        setEditData({})
        setIsEdit(false)
        onClose(false);
    };

    const handleDelete = () => {
        setFileList([])
    }
    return (
        <ModalWrapper
            title={isEdit ? `Edit ${userType === 'existing' ? "User" : "Invitations"} ` : `Add ${userType === 'existing' ? "User" : "Invitations"}`}
            handleClose={onCancel}
            footer={null} // No footer needed as buttons are inside form
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                initialValues={isEdit ? {
                    email: editData.email,
                    role: editData.role
                } : {
                    email: '',
                    role: "COMPANY_ADMIN"
                }}
            >
                <Form.Item
                    label="Role"
                    name="role"
                    rules={[{ required: true, message: 'Please select an Role!' }]}
                >
                    <Select
                        placeholder="Select Role"
                        onChange={(value) => {
                            setIsAdmin(value === "COMPANY_ADMIN")
                            setIsRoleSelected(!!value)
                        }}
                    >
                        <Option value={"COMPANY_ADMIN"}>Company Admin</Option>
                        <Option value={"CANDIDATE_ADMIN"}>Candidate Admin</Option>

                    </Select>
                </Form.Item>
                <DetailTable value={isAdmin} />
                {isBulk ?
                    <Form.Item
                        label="CSV File"
                        name="fileList"
                        valuePropName="fileList"
                        getValueFromEvent={handleFileChange}
                        style={{ marginTop: "2%" }}
                    >
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "start" }}>
                            <Upload
                                customRequest={({ onSuccess }) => {
                                    onSuccess("ok")
                                }}
                                beforeUpload={beforeUpload}
                                onChange={handleFileChange}
                                fileList={fileList}
                                accept=".csv"
                                onRemove={handleDelete}
                                multiple={false}
                                maxCount={1}
                            >
                                <Button icon={<UploadOutlined />}>Click to upload CSV</Button>
                            </Upload>
                            <CSVDownload />
                        </div>
                    </Form.Item>
                    : <Form.Item
                        style={{ marginTop: "2%" }}
                        label="Email"
                        name="email"
                        rules={[
                            { required: true, message: 'Please input candidate’s email!' },
                            { type: 'email', message: 'The input is not a valid email!' }
                        ]}
                    >
                        <Input
                            disabled={isEdit}
                            placeholder="Enter candidate's email"
                            style={{ height: '48px' }}
                        />
                    </Form.Item>}
                <div style={{ display: "flex", justifyContent: "flex-end", gap: "8px" }}>
                    <Button onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button type="primary" htmlType="submit">
                        Continue
                    </Button>
                </div>
            </Form>

        </ModalWrapper>
    );
};

const columns = [
    {
        title: 'Compatibility Preview',
        dataIndex: 'preview',
        key: 'preview',
    },
    {
        title: 'View',
        dataIndex: 'view',
        key: 'view',
    },
    {
        title: 'Modify',
        dataIndex: 'modify',
        key: 'modify',
    },
];

const DetailTable = ({ value }) => {
    const data = [
        {
            key: '1',
            preview: 'Candidate Management',
            view: "Full",
            modify: <CheckOutlined style={{ color: "green" }} />,
        },
        {
            key: '2',
            preview: 'Evaluation Management',
            view: <CheckOutlined style={{ color: "green" }} />,
            modify: <CheckOutlined style={{ color: "green" }} />,
        },
        {
            key: '3',
            preview: 'User Management',
            view: value ? <CheckOutlined style={{ color: "green" }} /> : <CloseOutlined style={{ color: "red" }} />,
            modify: value ? <CheckOutlined style={{ color: "green" }} /> : <CloseOutlined style={{ color: "red" }} />
        },
    ];

    return <Table className="table-component" columns={columns} dataSource={data} pagination={false} />
};