// ProtectedRoutes.js
import React, { useEffect, useState } from "react";
import {
  Route,
  Navigate,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import AdminLayout from "../layout/AdminLayout";
import AuthUserLayout from "../layout/AuthUserLayout";
import Dashboard from "../pages/Admin/Dashboard";
import CompanyAdmins from "../pages/Admin/CompanyAdmins/CompanyAdmins";
import AddCompanyAdmins from "../pages/Admin/CompanyAdmins/AddCompanyAdmins";
import EditCompanyAdmins from "../pages/Admin/CompanyAdmins/EditCompanyAdmins";
import SuperAdmins from "../pages/Admin/SuperAdmins/SuperAdmins";
import AddSuperAdmins from "../pages/Admin/SuperAdmins/AddSuperAdmins";
import EditSuperAdmins from "../pages/Admin/SuperAdmins/EditSuperAdmins";
import Companies from "../pages/Admin/Companies/Companies";
import AddCompany from "../pages/Admin/Companies/AddCompany";
import EditCompany from "../pages/Admin/Companies/EditCompany";
import Challenges from "../pages/Admin/Challenges/Challenges";
import AddChallenge from "../pages/Admin/Challenges/AddChallenge";
import Tags from "../pages/Admin/Tags/Tags";
import EditChallenge from "../pages/Admin/Challenges/EditChallenge";
import Assessments from "../pages/Admin/Assessments/Assessments";
import AddAssessment from "../pages/Admin/Assessments/AddAssessment";
import EditAssessment from "../pages/Admin/Assessments/EditAssessment";
import DuplicateAssessment from "../pages/Admin/Assessments/DuplicateAssessment";
import UserLayout from "../layout/UserLayout";
import CDashboard from "../pages/Candidate/Dashboard";
import AssessmentDetails from "../pages/Candidate/AssessmentDetails";
import Feedback from "../pages/Admin/Feedback";
import Settings from "../pages/Admin/Settings/Settings";
import EmailTemplate from "../pages/Admin/EmailTemplate/EmailTemplate";
import UserAssessment from "../pages/UserAssessment";
import AssessmentsListing from "../pages/AssessmentsListing";
import CompanyAdmin from "../pages/CompanyInvites";
import CompanyUser from "../pages/CompanyUsers";
import Organization from "../pages/Organization";
import CompanyAdminLayout from "../layout/CompnayAdminLayout";
import CompanyAdminCandidate from "../pages/CompanyAdminCandidate";
import CompanyAdminCandidateDetails from "../pages/CompanyAdminCandidateDetails";
import CompanyAdminCandidateInviteDetails from "../pages/CompanyAdminCandidateInviteDetails";
import CompanyAdminDashboard from "../pages/CompanyAdminDashboard";
import CompanyAdminEvaluation from "../pages/CompanyAdminEvaluation";
import CompanyAdminEvaluationDetails from "../pages/CompanyAdminEvaluationDetails";
import SettingsProfile from "../pages/SettingsProfile";
import { connect } from "react-redux";
import ProvideAuth from "../context/Auth";
import Details from "../pages/Details";
import { StyledLoader } from "../components/loader";
const antIcon = <LoadingOutlined style={{ fontSize: 100 }} spin />;

const PrivateRoute = ({ component: Component, ...rest }) => {
  // State to track the authentication status and loading state
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  if (loading) {
    // Show loading indicator or some placeholder while checking authentication
    return (
      <div
        style={{ height: "100vh" }}
        className="flex justify-center items-center w-full"
      >
        <Spin indicator={antIcon} size="large" />
      </div>
    );
  }

  return localStorage.getItem("accessToken") !== "undefined" &&
    localStorage.getItem("accessToken") &&
    JSON.parse(localStorage.getItem("accessToken")) ? (
    <Component {...rest} />
  ) : (
    <Navigate to="/login" replace state={{ from: location }} />
  );
  // return <Component {...rest} />;
};

const ProtectedRoutes = ({ user }) => {
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isCompanyAdmin, setIsCompanyAdmin] = useState(false);
  const [isUser, setIsUser] = useState(false);
  useEffect(() => {
    user?.roles?.map((role) => {
      if (role === "SUPER_ADMIN") {
        setIsSuperAdmin(true);
      } else if (role === "COMPANY_ADMIN") {
        setIsCompanyAdmin(true);
      } else {
        setIsUser(true);
      }
      return null;
    });
  }, [isSuperAdmin, isCompanyAdmin, isUser]);
  return (
    // isSuperAdmin ?
    <Routes>
      {/* Company Admin new routes */}

      <Route
        path="/company/admin/assessments"
        element={
          <CompanyAdminLayout type="Assessments">
            <PrivateRoute component={Assessments} />
          </CompanyAdminLayout>
        }
      />

      <Route
        path="/company/admin/invites/assessment/view"
        element={
          <CompanyAdminLayout type="Assessments">
            <PrivateRoute component={EditAssessment} />
          </CompanyAdminLayout>
        }
      />

      <Route
        path="/company/admin/dashboard"
        element={
          <CompanyAdminLayout type="Dashboard">
            <PrivateRoute component={CompanyAdminDashboard} />
          </CompanyAdminLayout>
        }
      />

      <Route
        path="/company/admin/profile"
        element={
          <CompanyAdminLayout type="Settings">
            <PrivateRoute component={SettingsProfile} />
          </CompanyAdminLayout>
        }
      />

      {/* <Route
        path="/company/admin/mfa"
        element={
          <CompanyAdminLayout type="MFA Settings">
            <PrivateRoute component={MFA} />
          </CompanyAdminLayout>
        }
      />
      <Route
        path="/company/admin/password"
        element={
          <CompanyAdminLayout type="Settings">
            <PrivateRoute component={Password} />
          </CompanyAdminLayout>
        }
      /> */}
      <Route
        path="/company/admin/organization"
        element={
          <CompanyAdminLayout type="Settings">
            <PrivateRoute component={Organization} />
          </CompanyAdminLayout>
        }
      />

      <Route
        path="/company/admin/invites"
        element={
          <CompanyAdminLayout type="Invites">
            <CompanyAdmin />
          </CompanyAdminLayout>
        }
      />
      <Route
        path="/company/admin/candidate"
        element={
          <CompanyAdminLayout type="Candidates">
            <CompanyAdminCandidate />
          </CompanyAdminLayout>
        }
      />
      <Route
        path="/company/admin/evaluation"
        element={
          <CompanyAdminLayout type="Evaluation">
            <CompanyAdminEvaluation />
          </CompanyAdminLayout>
        }
      />
      <Route
        path="/company/admin/evaluation/:id"
        element={
          <CompanyAdminLayout type="Evaluation">
            <CompanyAdminEvaluationDetails />
          </CompanyAdminLayout>
        }
      />
      <Route
        path="/company/admin/candidate/:id"
        element={
          <CompanyAdminLayout type="Candidates Details">
            <CompanyAdminCandidateDetails />
          </CompanyAdminLayout>
        }
      />
      <Route
        path="/company/admin/candidate/:email/:assessmentId/:companyId/:userId/:resultId"
        element={
          <CompanyAdminLayout type="Candidates Details">
            <CompanyAdminCandidateInviteDetails />
          </CompanyAdminLayout>
        }
      />
      <Route
        path="/company/admin/invites"
        element={
          <CompanyAdminLayout type="Invites">
            <CompanyAdmin />
          </CompanyAdminLayout>
        }
      />
      <Route
        path="/company/admin"
        element={
          <CompanyAdminLayout type="Dashboard">
            <CompanyAdmin />
          </CompanyAdminLayout>
        }
      />
      <Route
        path="/company/admin/users"
        element={
          <CompanyAdminLayout type="Company Users">
            <CompanyUser />
          </CompanyAdminLayout>
        }
      />

      {/* Admin Routes */}
      <Route
        path="/admin"
        element={
          <AdminLayout>
            <PrivateRoute component={Dashboard} />
          </AdminLayout>
        }
      />
      <Route
        path="/admin/dashboard"
        element={
          <AdminLayout>
            <PrivateRoute component={Dashboard} />
          </AdminLayout>
        }
      />
      <Route
        path="/admin/company-admins"
        element={
          <AdminLayout>
            <PrivateRoute component={CompanyAdmins} />
          </AdminLayout>
        }
      />
      <Route
        path="/admin/company-admins/add"
        element={
          <AdminLayout>
            <PrivateRoute component={AddCompanyAdmins} />
          </AdminLayout>
        }
      />
      <Route
        path="/admin/company-admins/edit"
        element={
          <AdminLayout>
            <PrivateRoute component={EditCompanyAdmins} />
          </AdminLayout>
        }
      />
      <Route
        path="/admin/super-admins"
        element={
          <AdminLayout>
            <PrivateRoute component={SuperAdmins} />
          </AdminLayout>
        }
      />
      <Route
        path="/admin/super-admins/add"
        element={
          <AdminLayout>
            <PrivateRoute component={AddSuperAdmins} />
          </AdminLayout>
        }
      />
      <Route
        path="/admin/super-admins/edit"
        element={
          <AdminLayout>
            <PrivateRoute component={EditSuperAdmins} />
          </AdminLayout>
        }
      />
      <Route
        path="/admin/companies"
        element={
          <AdminLayout>
            <PrivateRoute component={Companies} />
          </AdminLayout>
        }
      />
      <Route
        path="/admin/company/add"
        element={
          <AdminLayout>
            <PrivateRoute component={AddCompany} />
          </AdminLayout>
        }
      />
      <Route
        path="/admin/company/edit"
        element={
          <AdminLayout>
            <PrivateRoute component={EditCompany} />
          </AdminLayout>
        }
      />
      <Route
        path="/admin/challenges"
        element={
          <AdminLayout>
            <PrivateRoute component={Challenges} />
          </AdminLayout>
        }
      />
      <Route
        path="/admin/challenges/add"
        element={
          <AdminLayout>
            <PrivateRoute component={AddChallenge} />
          </AdminLayout>
        }
      />
      <Route
        path="/admin/challenge/edit"
        element={
          <AdminLayout>
            <PrivateRoute component={EditChallenge} />
          </AdminLayout>
        }
      />
      <Route
        path="/admin/tags"
        element={
          <AdminLayout>
            <PrivateRoute component={Tags} />
          </AdminLayout>
        }
      />
      <Route
        path="/admin/assessments"
        element={
          <AdminLayout>
            <PrivateRoute component={Assessments} />
          </AdminLayout>
        }
      />
      <Route
        path="/admin/assessment/add"
        element={
          <AdminLayout>
            <PrivateRoute component={AddAssessment} />
          </AdminLayout>
        }
      />
      <Route
        path="/admin/assessment/edit"
        element={
          <AdminLayout>
            <PrivateRoute component={EditAssessment} />
          </AdminLayout>
        }
      />
      <Route
        path="/admin/assessment/duplicate"
        element={
          <AdminLayout>
            <PrivateRoute component={DuplicateAssessment} />
          </AdminLayout>
        }
      />
      {/* <Route path="/*" element={<Comp_404 />} /> */}
      {/* </Routes>
      : isCompanyAdmin ?
        <Routes> */}
      {/* Company Admin Routes */}
      <Route
        path="/admin/assessments"
        element={
          <AdminLayout>
            <PrivateRoute component={Assessments} />
          </AdminLayout>
        }
      />
      <Route
        path="/admin/assessment/add"
        element={
          <AdminLayout>
            <PrivateRoute component={AddAssessment} />
          </AdminLayout>
        }
      />
      <Route
        path="/admin/assessment/edit"
        element={
          <AdminLayout>
            <PrivateRoute component={EditAssessment} />
          </AdminLayout>
        }
      />
      <Route
        path="/admin/feedback"
        element={
          <AdminLayout>
            <PrivateRoute component={Feedback} />
          </AdminLayout>
        }
      />
      <Route
        path="/admin/settings"
        element={
          <AdminLayout>
            <PrivateRoute component={Settings} />
          </AdminLayout>
        }
      />
      <Route
        path="/admin/email-template"
        element={
          <AdminLayout>
            <PrivateRoute component={EmailTemplate} />
          </AdminLayout>
        }
      />
      {/* <Route path="/*" element={<Comp_404 />} /> */}
      {/* </Routes>
        : <Routes> */}
      {/* Candidate Routes */}
      <Route
        path="/dashboard"
        element={
          <AuthUserLayout>
            <UserLayout>
              <PrivateRoute component={CDashboard} />
            </UserLayout>
          </AuthUserLayout>
        }
      />
      <Route
        path="/"
        element={
          <UserLayout>
            <PrivateRoute component={CDashboard} />
          </UserLayout>
        }
      />
      <Route
        path="/settings"
        element={
          <AuthUserLayout>
            <UserLayout>
              <PrivateRoute component={SettingsProfile} />
            </UserLayout>
          </AuthUserLayout>
        }
      />
      <Route
        path="/user/assessment-details/:id/:compId"
        element={
          <AuthUserLayout>
            <Details />
          </AuthUserLayout>
        }
      />
      {/* <Route
        path="user/assessment-details"
        element={
          <AuthUserLayout>
            <Details />
          </AuthUserLayout>
        }
      /> */}
      {/* <Route
        path="/password"
        element={
          <UserLayout>
            <PrivateRoute component={Password} />
          </UserLayout>
        }
      />
      <Route
        path="/mfa"
        element={
          <UserLayout>
            <PrivateRoute component={MFA} />
          </UserLayout>
        }
      /> */}
      <Route
        path="/assessment-details/:id/:compId"
        element={
          // <AuthUserLayout>
          // <ProvideAuth>
          // <AuthUserLayout>
          <AssessmentDetails />
          // </AuthUserLayout>
          // </ProvideAuth>
        }
      />
      <Route
        path="/user/challenge/:mappingId/:assessmentId/:userId/:companyId"
        element={
          <UserAuth user={user}>
            <UserAssessment />
          </UserAuth>
        }
      />
      <Route
        path="/user/assessment"
        element={
          <AuthUserLayout>
            <PrivateRoute component={UserAssessment} />
          </AuthUserLayout>
        }
      />
      <Route
        path="/user/assessment-listing"
        element={
          <AuthUserLayout>
            <PrivateRoute component={AssessmentsListing} />
          </AuthUserLayout>
        }
      />
      {/* <Route path="/*" element={<Comp_404 />} /> */}
    </Routes>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, null)(ProtectedRoutes);

const UserAuth = ({ user, children }) => {
  const navigate = useNavigate();
  const [isAllowedPath, setIsAllowedPath] = useState(false);

  useEffect(() => {
    if (!user?.roles?.includes("USER")) {
      navigate(-1);
      return;
    }
    setIsAllowedPath(true);
  }, []);

  return <>{!isAllowedPath ? <StyledLoader /> : <>{children}</>}</>;
};
