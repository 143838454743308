import React from "react";
import ChallengeIcon from "../../../assets/icons/challenge.svg";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

export default function ChallengeCard({ item }) {
  // /assessment-details?id=664bbf1fd8762893ca0797d4
  const navigate = useNavigate();
  return (
    <div
      style={{
        padding: "16px",
        backgroundColor: "#F5F5F5",
        borderRadius: "8px",
        border: "1px solid #CCCCCC",
        minHeight: "270px",
      }}
      className="text-left flex justify-between"
    >
      <div className="flex items-center justify-center px-10">
        <img
          width={146}
          height={118}
          src={item.image ? item.image : ChallengeIcon}
          alt="Challenge"
        />
      </div>
      <div className="mr-10 mt-8">
        <p
          style={{
            fontSize: "24px",
            fontWeight: 600,
            lineHeight: "40px",
            color: "#070908",
          }}
          className="capitalize"
        >
          {item?.title || "Title not found"}
        </p>
        <p
          style={{
            marginTop: "20px",
            color: "#888D89",
            fontSize: "16px",
            fontWeight: 300,
            lineHeight: "24px",
            overflow: "hidden",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 6,
            whiteSpace: "pre-line", // To maintain line breaks
          }}
        >
          {item.summary ? item.summary : `No Summary attached`}
        </p>
      </div>

      <div>
        <Button
          size="large"
          htmlType="submit"
          style={{
            visibility:
              item?.assessmentStatus !== "COMPLETED" ? "none" : "block",
            height: "44px",
            fontSize: "16px",
            fontWeight: 500,
            border: "none",
            color: "#1E1D1D",
            width: "243px",
          }}
          type="primary"
          className="mt-6"
          onClick={() => {
            if (item.isCompleted) {
              navigate(
                `/assessment-details/${item._id}/${item.companyId}?from=listing&mappingId=${item.mappingId}`
              );
              return;
            }
            navigate(
              `/user/assessment-details/${item?._id}/${item.companyId}?from=listing&mappingId=${item.mappingId}`
            );
          }}
        >
          View details
        </Button>
      </div>
    </div>
  );
}
