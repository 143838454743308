import React, { useState, useEffect } from "react";
import LogoDark from "../../../assets/logo-dark.png";
import TopBar from "./TopBar";
import "./index.css";

export default function CandidateHeader({ bgURL, title, button }) {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setIsScrolled(offset > 0);
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Dynamic classes for the top bar background and transition
  const topBarClasses = `bg-black flex justify-center fixed top-0 w-full transition-all duration-300 ${
    isScrolled ? " bg-opacity-100" : "bg-opacity-0"
  }`;

  return (
    <div
      style={{
        background: `url('${bgURL}')`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 68vh",
        minWidth: "100vw",
        minHeight: "68vh",
        backgroundAttachment: "fixed",
      }}
      className="relative w-full"
    >
      <div
        className="absolute w-full h-full"
        style={{
          zIndex: 1,
          backgroundColor: "#00000060",
        }}
      />
      <div className={topBarClasses} style={{ zIndex: 4 }}>
        <div className="w-full my-6" style={{ maxWidth: "1150px" }}>
          <TopBar />
        </div>
      </div>
      <div
        className="absolute w-full h-full flex justify-center items-center"
        style={{ zIndex: 2 }}
      >
        <div style={{ maxWidth: "946px" }}>
          {title ? (
            <p
              className="capitalize font-normal text-white text-[56px]"
              style={{ fontSize: "56px !important" }}
            >
              {title}
            </p>
          ) : (
            <p
              className="capitalize font-normal text-white text-[56px]"
              style={{ fontSize: "56px !important" }}
            >
              Challenge <span className="text-[#34c788]">Invites</span>
            </p>
          )}
          <p className="description">
            Welcome to the CyberHire skills platform, below is a listing of all
            challenge invites that you have accepted. Please click on an invite
            to proceed.
          </p>
          {button}
        </div>
      </div>
    </div>
  );
}
