import RadarChart from "react-svg-radar-chart";
import "react-svg-radar-chart/build/css/index.css";
import { useEffect, useState } from "react";
import { Typography } from "antd";

const RadarGraph = ({ data, style, color = "green" }) => {
  const [graphData, setGraphData] = useState(null);
  const [graphCaptions, setGraphCaptions] = useState(null);
  useEffect(() => {
    const transformedData = data?.reduce((acc, item) => {
      acc[item.name] = item.value;
      return acc;
    }, {});

    const transformedCaptions = {};
    data.forEach((item) => {
      const trimmedName = item.name.trim();
      transformedCaptions[trimmedName] = trimmedName;
    });
    setGraphData(transformedData);
    setGraphCaptions(transformedCaptions);
  }, [data]);

  const graphdata = [
    {
      data: graphData,
      meta: { color: color },
    },
  ];

  return (
    <div className="flex justify-center items-center">
      {graphData ? (
        <RadarChart
          style={style}
          captions={graphCaptions}
          data={graphdata}
          size={300}
        />
      ) : (
        <Typography.Text className="text-2xl">No data found</Typography.Text>
      )}
    </div>
  );
};

export default RadarGraph;
