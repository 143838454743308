import axios from "axios";
import { axiosInstance } from "..";

// const API_CONFIGURATION = {
//   AXIOS_RETRY: {
//     retries: 3,
//   },
//   errorHandling: {
//     global: true,
//   },
// };

const baseUrl = process.env.REACT_APP_API_PREFIX;

const AssessmentChallenge = {
  getAssessmentById: async (userId, assessmentId, companyId) => {
    return axiosInstance.get(
      `${baseUrl}result/${userId}/${assessmentId}/${companyId}`
      // `${baseUrl}assessment/${id}`
      // API_CONFIGURATION
    );
  },
  getQuestionResult: (body, resultId) => {
    return axiosInstance.post(
      `${baseUrl}result/answer/${resultId}`,
      body
      // API_CONFIGURATION
    );
  },
  sendFeedback: (body) => {
    return axiosInstance.post(
      `${baseUrl}feedback`,
      body
      // API_CONFIGURATION
    );
  },
  startAssessment: (body) => {
    return axiosInstance.post(
      `${baseUrl}result/`,
      body
      // API_CONFIGURATION
    );
  },
  closeAssessment: (companyId, userId, assessmentId, resultId) => {
    return axiosInstance.post(
      `${baseUrl}result/close/${userId}/${assessmentId}/${companyId}?resultId=${resultId}`
      // API_CONFIGURATION
    );
  },
  redeemHint: (resultId, questionId, challengeId) => {
    return axiosInstance.post(
      `${baseUrl}result/redeemHint/${resultId}`,
      {
        questionId,
        challengeId,
      }
      // API_CONFIGURATION
    );
  },
};

export default AssessmentChallenge;
