import React, { useState, useEffect } from "react";
import { Input, Typography, Button, message, Tooltip } from "antd";
import Send from "../../../../assets/icons/send.png";
import {
  ClockCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import ModalWrapper from "../../../../components/Modals/ModalWrapper";
import HintActiveIcon from "../../../../assets/icons/hint-active.svg";
import Hint from "./Hint";

const { Text } = Typography;

const QuestionInput = ({
  question,
  currentAnswer,
  index,
  handleSubmit,
  isRightAnswer,
  currentScore,
  hintRevealCost,
  hintsRevealedByCandidate,
  maximumHints,
  isAnswering,
  hintExists,
}) => {
  const [reattemptModal, setReAttemptModal] = useState(false);
  const [answer, setAnswer] = useState("");
  const [redeemHint, setRedeemHint] = useState(false);
  useEffect(() => {
    setAnswer(currentAnswer);
  }, [currentAnswer]);
  const handleQuestionSubmit = () => {
    if (isAnswering) {
      return;
    }
    if (isRightAnswer) {
      message.info("This question is already answered.");
      return;
    }
    const answerCopy = answer?.trim();
    if (answerCopy === "" || !answerCopy?.length) {
      return;
    }
    if (isRightAnswer === undefined) {
      handleSubmit(answerCopy, index);
      return;
    }
    setReAttemptModal(true);
  };

  return (
    <>
      {reattemptModal && (
        <ModalWrapper handleClose={() => setReAttemptModal(false)}>
          <div className="flex flex-col items-center justify-center">
            <p
              style={{
                fontSize: "24px",
                fontWeight: "600",
                lineHeight: "36px",
                textAlign: "center",
              }}
            >
              Reattempt
            </p>
            <Text
              style={{
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
                textAlign: "center",
                color: "#888A8D",
              }}
            >
              Your attempt to this question was wrong. You can either reattempt
              or cancel. Reattempt will deduct one attempt{" "}
            </Text>
            <Button
              type="primary"
              onClick={() => {
                // Handler for reattempt action
                handleSubmit(answer, index);
                setReAttemptModal(false); // Close modal after action
              }}
              style={{
                height: "46px",
                padding: "8px 16px 8px 16px",
                gap: "8px",
                borderRadius: "8px",
                background: "#070908",
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "24px",
                textAlign: "center",
                width: "80%",
                color: "white",
                marginBottom: "2%",
                marginTop: "5%",
              }}
            >
              Reattempt
            </Button>

            {/* Cancel Button */}
            <Button
              style={{
                height: "46px",
                padding: "8px 16px 8px 16px",
                gap: "8px",
                borderRadius: "8px",
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "24px",
                textAlign: "center",
                width: "80%",
              }}
              onClick={() => setReAttemptModal(false)}
            >
              Cancel
            </Button>
          </div>
        </ModalWrapper>
      )}
      <div
        style={{ marginTop: "20px", marginBottom: "16px", padding: "0 16px" }}
        className="question-input text-left"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: 15 }}>
            <p style={{ fontSize: "14px", fontWeight: 300 }}>
              <span style={{ color: "#34C788" }}>Question {index + 1}.</span>{" "}
              {question}
            </p>
            {!isRightAnswer &&
              hintExists &&
              currentScore >= hintRevealCost &&
              hintsRevealedByCandidate < maximumHints && (
                <p
                  onClick={() => setRedeemHint(true)}
                  style={{
                    display: "flex",
                    cursor: "pointer",
                    alignItems: "center",
                    color: "#34C788",
                  }}
                >
                  Redeem Hint
                  <img src={HintActiveIcon} alt="Hint" />
                </p>
              )}
          </div>

          {isRightAnswer === undefined ? (
            <Text
              type="secondary"
              style={{ display: "flex", alignItems: "center", gap: 3 }}
            >
              Unanswered
              <ClockCircleOutlined style={{ fontSize: "20px" }} />
            </Text>
          ) : isRightAnswer ? (
            <Text
              type="success"
              style={{ display: "flex", alignItems: "center", gap: 3 }}
            >
              Correct Answer
              <CheckCircleOutlined
                style={{ color: "green", fontSize: "20px" }}
              />
            </Text>
          ) : (
            <Text
              type="danger"
              style={{ display: "flex", alignItems: "center", gap: 3 }}
            >
              Wrong Answer
              <CloseCircleOutlined style={{ color: "red", fontSize: "20px" }} />
            </Text>
          )}
        </div>

        <Input
          className="customInput"
          disabled={isRightAnswer}
          style={{
            marginTop: "16px",
          }}
          size="large"
          placeholder="Answer..."
          suffix={
            <img
              style={{
                cursor: "pointer",
                display: isRightAnswer ? "none" : "block",
              }}
              src={Send}
              alt="Icon"
              onClick={handleQuestionSubmit}
            />
          }
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              e.preventDefault();
              e.stopPropagation();
              handleQuestionSubmit();
            }
          }}
          value={answer}
          onChange={({ target: { value } }) => setAnswer(value)}
        />
      </div>

      {redeemHint && (
        <ModalWrapper handleClose={() => setRedeemHint(false)}>
          <Hint currentQuestion={index} hintRevealCost={hintRevealCost} />
        </ModalWrapper>
      )}
    </>
  );
};

export default QuestionInput;
