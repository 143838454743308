import React from 'react';
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);

export default function App({ payload }) {
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'left',
                display: false
            },
            title: {
                display: false,
                text: 'Chart.js Line Chart',
            },
        },
    };
    const dataOptions = {
        labels: payload.labels || [],
        datasets: [
            {
                data: payload.data || [],
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
            },
        ],
    };
    return <>{payload?.data?.length ? <>
        <p className="notes" style={{ marginBottom: "20px" }}>Skills Tracker</p>
        <Radar data={dataOptions} options={options} />
    </> :
        <p className="notes" style={{ marginBottom: "20px" }}>Not enough data </p>
    }</>
}
