import { Layout, message } from "antd";
import ConsoleComponent from "./components/ConsoleComponent";
import SiderComponent from "./components/SiderComponent";
import HeaderComponent from "./components/HeaderComponent";
import { useState, useEffect } from "react";
import DownloadManager from "./components/DownloadManager";
import ChallengeComponent from "./components/ChallengeComponent";
import { getChallengeDetails } from "../../store/actions";
import { connect } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ChallengeSlice } from "../../store/slices";
import UserService from "../../services/api/user";
import { StyledLoader } from "../../components/loader";

const { Content } = Layout;
const UserAssessment = ({
  getChallengeDetails,
  assessment,
  attempts,
  resourceData,
  getSelectedChallenge,
  stopAssessment,
  selectedChallenge,
  isLoading,
  resetState,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  // console.log(location);
  // const {
  //   state: { assessmentId, userId, companyId },
  // } = location;
  const { mappingId, assessmentId, userId, companyId } = useParams();

  useEffect(() => {
    (async () => {
      await getChallengeDetails({ companyId, userId, assessmentId, mappingId });
      getSelectedChallenge(undefined);
    })();

    return () => resetState();
  }, []);

  useEffect(() => {
    if (attempts <= 0 && Object.keys(assessment).length) {
      stopAssessment();
      stopAssessmentAPI();
    }
  }, [attempts]);

  const stopAssessmentAPI = async () => {
    try {
      await UserService.assessmentChallenge.closeAssessment(
        companyId,
        userId,
        assessmentId,
        assessment._id
      );

      message.success("Your assessment has ended");
      navigate("/user/assessment-listing");
    } catch (e) {
      message.error("Something went wrong");
    }
  };

  return (
    <Layout>
      <Layout>
        <SiderComponent
          userId={userId}
          companyId={companyId}
          assessmentId={assessmentId}
          stopAssessmentAPI={stopAssessmentAPI}
        />
        {isLoading ? (
          <StyledLoader />
        ) : (
          <div
            className="flex-grow overflow-x-hidden"
            style={{ overflowX: "hidden" }}
          >
            <div style={{ padding: "0 24px" }}>
              <HeaderComponent stopAssessmentAPI={stopAssessmentAPI} />
            </div>
            <Content
              style={{
                padding: "0 24px",
                minHeight: 350,
                marginTop: "20px",
                maxHeight: "100vh",
                overflowY: "scroll",
              }}
            >
              <div className="flex justify-between items-center">
                <p style={{ fontSize: "24px" }}>
                  {assessment?.assessmentTitle || ""}
                </p>
                {resourceData.downloadableResources.length ? (
                  <DownloadManager
                    resources={resourceData.downloadableResources}
                  />
                ) : null}
              </div>
              <div
                style={{
                  padding: "20px",
                  borderRadius: "8px",
                  marginTop: "16px",
                }}
                className="bg-white shadow-lg"
              >
                <div>
                  <ConsoleComponent
                    resourceData={resourceData}
                    selectedChallenge={selectedChallenge}
                  />
                </div>
                <div style={{ marginTop: "20px" }}>
                  <ChallengeComponent />
                </div>
              </div>
            </Content>
          </div>
        )}
      </Layout>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  assessment: state.challenge.assessment,
  selectedChallenge: state.challenge.selectedChallenge,
  currentQuestion: state.challenge.currentQuestion,
  isAssessmentFetching: state.challenge.isAssessmentFetching,
  attempts: state.challenge.attempts,
  history: state.challenge.history,
  isLoading: state.challenge.isLoading,
  selectedChallengeIndex: state.challenge.selectedChallengeIndex,
  currentScore: state.challenge.currentScore,
  resourceData: state.challenge.resourceData,
});
const mapDispatchToProps = {
  getChallengeDetails,
  getSelectedChallenge: ChallengeSlice.actions.getSelectedChallenge,
  stopAssessment: ChallengeSlice.actions.stopAssessment,
  resetState: ChallengeSlice.actions.resetState,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserAssessment);
