export const USER = "user";
export const LOGIN = "user/login";
export const SIGNUP = "user/signup";
export const COMPANY = "company";
export const TAG = "tag";
export const GET_ALL = "getAll";
export const CREATE_COMPANY_ADMIN = "createCompanyAdmin";
export const CREATE_SUPER_ADMIN = "createSuperAdmin";
export const CREATE = "create";
export const DELETE = "delete";
export const GET_BY_ID = "getById";
export const GET_COMPANY_DETAILS_BY_ID = "getCompanyDetailsById";
export const UPDATE = "update";
export const CHANGE_PASSWORD = "changePassword";
export const ADD_INVITES = "addInvites";
export const BLOCK = "block";
export const UNBLOCK = "unblock";
export const CHALLENGE = "challenge";
export const OPTIONS = "options";
export const ASSESSMENT = "assessment";
export const RESULT = "result";
export const CLOSE = "close";
export const ASSESSMENTS_MAPPING = "assessments-mapping";
export const GET_ASSESSMENT = "getAssessments";
export const ASSIGN_ASSESSMENTS = "assignAssessments";
export const VALIDATE_ASSESSMENTS_TOKEN = "validateAssessmentToken";
export const FEEDBACK = "feedback";
export const SETTINGS = "settings";
export const EMAILS = "emails";
export const CREATE_USER_IF_NOT_EXISTS = "user/createUserOnCognito";
