import React, { useState, useEffect } from "react";
import { Divider, message } from "antd";
import Question from "./Question";
import Challenge from "../../../../assets/icons/challenge.svg";
import { connect } from "react-redux";
import moment from "moment";
import UserService from "../../../../services/api/user";
import { ChallengeSlice } from "../../../../store/slices";

const QuestionCard = ({
  selectedChallenge,
  assessment,
  history,
  setHistory,
  setAttempts,
  handleRightAnswer,
  currentScore,
  hintRevealCost,
  maximumHints,
  hintsRevealedByCandidate,
}) => {
  const [isLoading, seIsLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  useEffect(() => {
    if (selectedChallenge) {
      const questions = selectedChallenge?.questions?.map((question, index) => {
        let currentTime;
        const status = question.status !== "UNATTEMPTED";
        if (status) {
          currentTime = moment(question.answerTime);
        }
        return {
          id: question._id,
          question: question.question,
          scores: question.scores,
          answer: status ? question.givenAnswer : "",
          time: status ? currentTime : null,
          result: status ? true : false,
          isRightAnswer: question.isTrueAnswer,
          questionScore: question.score,
          hintExists: question?.hintExists,
        };
      });
      // if (questions?.length) {
      //     setCurrentQuestion(currentIndex)
      // }

      setMessages(questions || []);
    }
  }, [selectedChallenge]);

  const handleSubmit = async (value, currentQuestion) => {
    seIsLoading(true);
    const dummyMessage = [...messages];
    dummyMessage[currentQuestion].answer = value;
    try {
      const response = await UserService.assessmentChallenge.getQuestionResult(
        {
          challengeId: selectedChallenge._id,
          questionId: messages[currentQuestion].id,
          answer: value?.trim() || "",
        },
        assessment._id
      );
      const {
        data: {
          result: { isTrueAnswer, completionPercentageToReturn },
        },
      } = response;

      const dummyHistory = [...history];
      const currentTime = moment();

      dummyHistory.push({
        title: isTrueAnswer
          ? "You Correctly answered a Question."
          : "You were unable to answer correctly",
        question: messages[currentQuestion].question,
        answer: value,
        time: currentTime.format("hh:mm:ss A"),
        icon: isTrueAnswer ? "RIGHT" : "WRONG",
        isQuestion: true,
      });
      setHistory([...dummyHistory]);
      dummyMessage[currentQuestion].result = true;
      dummyMessage[currentQuestion].isRightAnswer = isTrueAnswer;

      if (!isTrueAnswer) {
        message.error("Incorrect Attempt");
        setAttempts();
        handleRightAnswer({
          score: 0,
          questionId: dummyMessage[currentQuestion].id,
          answer: value,
          isTrueAnswer,
          completionPercentageToReturn,
        });
      }
      if (isTrueAnswer) {
        handleRightAnswer({
          score: dummyMessage[currentQuestion].questionScore,
          questionId: dummyMessage[currentQuestion].id,
          answer: value,
          isTrueAnswer,
          completionPercentageToReturn,
        });
      }
      setMessages([...dummyMessage]);
    } catch (e) {
      message.error(e?.message || "Something went wrong");
    } finally {
      seIsLoading(false);
    }
  };

  return (
    <div className="card scroll-bar">
      <div
        style={{
          padding: "16px",
          position: "sticky",
          top: 0,
          zIndex: 2,
          backgroundColor: "#F5F5F5",
        }}
        className="text-left flex"
      >
        <div>
          <img width={47} height={38} src={Challenge} alt="Challenge" />
        </div>
        <div className="ml-3">
          <p
            style={{
              fontSize: "16px",
              fontWeight: 600,
              letterSpacing: "5%",
              color: "#070908",
            }}
          >
            {selectedChallenge?.name}
          </p>
          <p style={{ color: "#888D89", fontSize: "14px", fontWeight: 300 }}>
            {selectedChallenge.description}
          </p>
        </div>
      </div>

      {messages.map(
        ({ question, answer, isRightAnswer, hintExists }, index) => (
          <div>
            <Divider style={{ margin: 0 }} />
            <Question
              hintExists={hintExists}
              isAnswering={isLoading}
              key={index}
              question={question}
              currentAnswer={answer}
              index={index}
              handleSubmit={handleSubmit}
              isRightAnswer={isRightAnswer}
              currentScore={currentScore}
              hintRevealCost={hintRevealCost}
              hintsRevealedByCandidate={hintsRevealedByCandidate}
              maximumHints={maximumHints}
            />
          </div>
        )
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  assessment: state.challenge.assessment,
  selectedChallenge: state.challenge.selectedChallenge,
  currentQuestion: state.challenge.currentQuestion,
  isAssessmentFetching: state.challenge.isAssessmentFetching,
  attempts: state.challenge.attempts,
  history: state.challenge.history,
  size: state.challenge.size,
  type: state.challenge.type,
  selectedChallengeIndex: state.challenge.selectedChallengeIndex,
  currentScore: state.challenge.currentScore,
  resourceData: state.challenge.resourceData,
  hintRevealCost: state.challenge.hintRevealCost,
  maximumHints: state.challenge.maximumHints,
  hintsRevealedByCandidate: state.challenge.hintsRevealedByCandidate,
});
const mapDispatchToProps = {
  // getChallengeDetails,
  setHistory: ChallengeSlice.actions.setHistory,
  setAttempts: ChallengeSlice.actions.setAttempts,
  handleRightAnswer: ChallengeSlice.actions.handleRightAnswer,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionCard);
