import React, { useState, useEffect } from "react";
import "./index.css";
import CandidateLayout from "../../layout/CandidateLayout";
import ChallengeCard from "./components/ChallengeCard";
import { getAllAssessmentsByUserId } from "../../services/api";
import { connect } from "react-redux";
import { StyledLoader } from "../../components/loader";
import { message } from "antd";

const AssessmentsListing = ({ user }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [allAssessments, setAllAssessments] = useState([]);
  localStorage.removeItem("isOpenURL");
  localStorage.removeItem("tokenInviteId");

  const getAllAssessmentsData = async () => {
    setIsLoading(true);
    try {
      const { data } = await getAllAssessmentsByUserId(user._id);
      setAllAssessments(
        data.assessments.map((assessment) => ({
          userId: user._id,
          companyId: assessment.companyId,
          assessmentStatus: assessment.status,
          isCompleted: assessment.isCompleted,
          ...assessment.assessment[0],
          mappingId: assessment._id,
        }))
      );
      setIsLoading(false);
    } catch (error) {
      message.error(
        error?.response?.data?.error || error?.message || "Something went wrong"
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllAssessmentsData();
  }, []);

  return (
    <div className="overflow-x-hidden">
      {isLoading ? <StyledLoader /> : null}
      <CandidateLayout>
        <div className="flex justify-center">
          <div
            style={{
              maxWidth: "1150px",
              marginTop: "-150px",
              zIndex: 5,
              paddingBottom: "100px",
              overflow: "hidden",
            }}
            className="overflow-x-hidden"
          >
            {!allAssessments?.length ? (
              <p className="title capitalize">No Assessments Found</p>
            ) : (
              allAssessments?.map((item, i) => {
                return (
                  <div
                    key={item?.mappingId}
                    style={{
                      marginTop: 30,
                    }}
                    className="overflow-x-hidden"
                  >
                    <ChallengeCard item={item} key={item?.mappingId} />
                  </div>
                );
              })
            )}
          </div>
        </div>
      </CandidateLayout>
    </div>
  );
};

const mapStateToProps = (state) => ({ user: state.auth.user });

export default connect(mapStateToProps, null)(AssessmentsListing);
