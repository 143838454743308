import React from "react";
import { Form } from "antd";
import QuestionCard from "./QuestionCard";
import "./index.css";
const ChallengeComponent = () => (
  <Form>
    <QuestionCard />
  </Form>
);

export default ChallengeComponent;
