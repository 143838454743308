import React from "react";
import { Table } from "antd";

const AdminsTable = ({ columns, data, rowSelection, onChange, pagination }) => (
  <Table
    rowSelection={rowSelection}
    columns={columns}
    dataSource={data}
    onChange={onChange}
    pagination={{ ...pagination, showSizeChanger: false }}
    className="table-component"
  />
);
export default AdminsTable;
