import { DownOutlined, LoadingOutlined, UserOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Dropdown,
  Form,
  Input,
  Space,
  Spin,
  Typography,
  message,
  theme,
} from "antd";
import { useEffect, useState } from "react";
import { createCompanyAdmin, getAllCompaniesData } from "../../../services/api";
import { useNavigate } from "react-router-dom";
import { StyledLoader } from "../../../components/loader";
import CompanyDropdown from "../../../components/Dropdowns/CompanyDropdown";
import DeleteModal from "../../../components/Modals/DeleteModal";

const { Text } = Typography;
const AddCompanyAdmins = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allCompanies, setAllCompanies] = useState([]);
  const [searchedCompanies, setSearchedCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [showCompanyDropDown, setShowCompanyDropDown] = useState(false);
  const navigate = useNavigate();
  const {
    token: { colorIcon, colorBgContainer, colorPrimary },
  } = theme.useToken();

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      const { data, status } = await createCompanyAdmin({
        ...values,
        email: values.email.trim(),
        companyIds: [selectedCompany?._id],
      });
      message.success("Company admin created!");
      navigate("/admin/company-admins");
      setIsLoading(false);
    } catch (error) {
      console.log({ error });
      message.error(error.response.data.error);
      setIsLoading(false);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleMenuClick = (e) => {
    // message.info('Click on menu item.');
    setSelectedCompany(allCompanies[parseInt(e.key)]);
  };

  const getAllCompanies = async () => {
    try {
      const { data, status } = await getAllCompaniesData();
      setAllCompanies(data?.companies);
      setSearchedCompanies(data?.companies);
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    getAllCompanies();
  }, []);

  const handleCompanySearch = (val) => {
    const filteredArr = allCompanies.filter((item) =>
      item?.name.toLowerCase().includes(val.toLowerCase())
    );
    setSearchedCompanies(filteredArr);
  };

  return (
    <div>
      <Card style={{ width: "100%" }}>
        {/* {
                isLoading ? <StyledLoader /> : */}
        <>
          <div className="flex justify-between items-center px-3 py-4 w-full">
            <Text className="text-2xl font-semibold font-[#1f2232]">
              Create Company Admin
            </Text>
          </div>
          <div className="p-3">
            <Form
              name="basic"
              layout="vertical"
              className="w-full"
              labelCol={{
                span: 8,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <div className="flex flex-wrap">
                <Form.Item
                  label="First Name"
                  name="firstName"
                  className="w-1/2 px-2"
                  rules={[
                    {
                      required: true,
                      message: "Please input admin's Firstname!",
                    },
                    {
                      pattern: /^[a-zA-Z\s]+$/,
                      message: "Name can only include letters.",
                    },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
                <Form.Item
                  label="Last Name"
                  name="lastName"
                  className="w-1/2 px-2"
                  rules={[
                    {
                      required: true,
                      message: "Please input admin's Lastname!",
                    },
                    {
                      pattern: /^[a-zA-Z\s]+$/,
                      message: "Name can only include letters.",
                    },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>

                <Form.Item
                  label="Email"
                  name="email"
                  className="w-1/2 px-2"
                  rules={[
                    {
                      required: true,
                      message: "Please input admin's Email!",
                    },
                    {
                      pattern:
                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\s*$/,
                      message: "Please enter a valid Email!",
                    },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>

                {/* <Form.Item
                  label="Password"
                  name="password"
                  className="w-1/2 px-2"
                  rules={[
                    {
                      required: true,
                      message: "Please input admin's password!",
                    },
                    {
                      pattern:
                        /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+}{"':;?/>.<,])(?=.{8,})/,
                      message:
                        "Password must contain at least one capital letter and one symbol and 8 characters.",
                    },
                  ]}
                >
                  <Input.Password size="large" />
                </Form.Item> */}

                <Form.Item
                  label="Company"
                  name="companyIds"
                  className="w-1/2 px-2"
                  rules={[
                    {
                      required: true,
                      message: "Please select a company",
                      validator: (_, value) => {
                        if (selectedCompany) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject("Some message here");
                        }
                      },
                    },
                  ]}
                >
                  <CompanyDropdown
                    showCompanyDropDown={showCompanyDropDown}
                    setShowCompanyDropDown={setShowCompanyDropDown}
                    allCompanies={searchedCompanies}
                    setSelectedCompany={setSelectedCompany}
                    selectedCompany={selectedCompany}
                    search={handleCompanySearch}
                  />
                </Form.Item>

                <Form.Item className="flex justify-end w-full">
                  <Button
                    className="mr-2"
                    onClick={() => setIsModalOpen(true)}
                    size="large"
                  >
                    Cancel
                  </Button>
                  <Button size="large" htmlType="submit">
                    {isLoading ? (
                      <div className="flex items-center">
                        <Spin
                          className="mr-2"
                          indicator={
                            <LoadingOutlined
                              style={{
                                fontSize: 18,
                              }}
                              spin
                            />
                          }
                        />
                        Create
                      </div>
                    ) : (
                      "Create"
                    )}
                  </Button>
                </Form.Item>
              </div>
            </Form>
            <DeleteModal
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              deleteAction={() => navigate("/admin/company-admins")}
              title="Discard changes"
              actionText="Yes"
              text="Are you sure you want to discard?"
            />
          </div>
        </>
        {/* } */}
      </Card>
    </div>
  );
};

export default AddCompanyAdmins;
