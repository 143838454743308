import { Layout, Flex, Card, Button, Progress, Typography, Avatar, message, Timeline, Divider, Select, Empty } from "antd";
import './index.css'
import TextArea from "antd/es/input/TextArea";
import SplineChart from "../../components/charts/SplineChart";
import BarChart from "../../components/charts/BarChart";
import LineChart from "../../components/charts/LineChart";
import RadarChart from "../../components/charts/RadarChart";
import TickIcon from '../../assets/icons/tick.svg'
import TickCircle from '../../assets/icons/tick-circle.svg'
import CloseCircle from '../../assets/icons/close-circle.svg'
import {
    Chart as ChartJS,
    LinearScale,
    PointElement,
    Tooltip,
    Legend,
} from 'chart.js';
import { CheckOutlined, CloseOutlined, UserOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import { saveAssessmentNotes, changeAssessmentStatus, getGraphDataAPICandidate, getAssessmentDetailsUsingResultId, getAssessmentResultsId } from '../../services/api';
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { StyledLoader } from "../../components/loader";
import moment from "moment";
import _ from 'lodash'

const styles = {
    textarea: {
        height: "150px",
        padding: "16px",
        gap: "10px",
        borderRadius: "8px",
        border: "1px 1px 1px 1px"
    },
    button: {
        width: "160px",
        height: "40px",
        gap: "8px",
        borderRadius: "8px",
        background: "#4AC18E",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "24px",
        color: "white"

    },
    alert: {
        minHeight: "74px",
        gap: "24px",
        borderRadius: "8px",
        opacity: "0px",
        background: "#DDF6EB",
        padding: "16px",
        border: "1px solid #4AC18E",
        position: "relative"
    },
    alertMessage: {
        fontSize: "14px",
        fontStyle: "italic",
        fontWeight: 500,
        lineHeight: "21px",
        textAlign: "left",
        color: "#4AC18E"
    },
    scoreStyle: {
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "24px",
        textAlign: "left",
        color: "#070908"
    },
    score: {
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "21px",
        textAlign: "left",
        color: "#888A8D"
    }
}

const enumBarChartKeys = {
    "between0And10": "0%-10%",
    "between10And20": "10%-20%",
    "between20And30": "20%-30%",
    "between30And40": "30%-40%",
    "between40And50": "40%-50%",
    "between50And60": "50%-60%",
    "between60And70": "60%-70%",
    "between70And80": "70%-80%",
    "between80And90": "80%-90%",
    "between90And100": "90%-100%"
}

ChartJS.register(LinearScale, PointElement, Tooltip, Legend);
function generateIntervals(startTime, endTime) {
    const intervals = [];
    let currentTime = new Date(startTime);

    while (currentTime <= endTime) {
        intervals.push(new Date(currentTime));
        currentTime.setMinutes(currentTime.getMinutes() + 15);
    }

    return intervals;
}

// Function to get max score for each interval
function getMaxScoresForIntervals(data) {
    if (!data?.length || data?.length === 1) {
        return []
    }
    const startTime = new Date(data[0].time);
    const endTime = new Date(data[data.length - 1].time);
    const intervals = generateIntervals(startTime, endTime);
    const results = [];

    for (let i = 0; i < intervals.length; i++) {
        const intervalStart = intervals[i];
        const intervalEnd = i + 1 < intervals.length ? intervals[i + 1] : endTime;
        let maxScore = -Infinity;

        data.forEach((entry) => {
            const entryTime = new Date(entry.time);
            if (entryTime >= intervalStart && entryTime < intervalEnd) {
                if (entry.score > maxScore) {
                    maxScore = entry.score;
                }
            }
        });

        const hours = Math.floor(i * 15 / 60);
        const minutes = (i * 15) % 60;
        const intervalLabel = `${hours}h:${minutes}m`;

        results.push({
            interval: intervalLabel,
            maxScore: maxScore === -Infinity ? 0 : maxScore,
        });
    }

    return results;
}

function CompanyAdminCandidateInviteDetails({ user }) {
    const [isLoading, setIsLoading] = useState(false)
    const [selectedChallengeResult, setSelectedChallengeResult] = useState([])
    const [graphsData, setGraphData] = useState({
        barCharData: {
            data: [],
            labels: [],
            numberOfCandidates: 0,
        },
        radarChart: {
            data: [],
            labels: [],
        },
        lineChartData: {
            data: [],
            labels: []
        },
        compositeChartData: {
            data: [],
            labels: []
        }
    })
    const [result, setResult] = useState([])
    const [selectedChallenge, setSelectedChallenge] = useState("")
    const [data, setData] = useState({})
    const { email, assessmentId, companyId, userId, resultId } = useParams()
    const inputRef = useRef()

    useEffect(() => {
        fetchAssessmentDetails()
        fetchResult()
        if (userId !== "undefined" || !userId) {
            getGraphData()
        }
    }, [])

    const fetchAssessmentDetails = async () => {
        try {
            setIsLoading(true)
            const { data } = await getAssessmentDetailsUsingResultId(user.companyIds[0], email, assessmentId, resultId)
            if (data.invitations?.length) {
                const invitations = data.invitations[0]
                let currentUser = {
                    name: "N/A",
                    email,
                    heading1: "Evaluation",
                    heading2: "Status",
                    description1: invitations?.assessmentDetails?.length ? invitations?.assessmentDetails[0].title : "-",
                    description2: invitations.status,
                    assessmentId: invitations?.assessmentDetails?.length ? invitations?.assessmentDetails[0]._id : null,
                }
                if (invitations.userDetails?.length) {
                    currentUser.name = invitations.userDetails[0].firstName + " " + invitations.userDetails[0].lastName
                }

                let sender = {
                    name: invitations?.invitedByUser ? invitations?.invitedByUser?.firstName + " " + invitations.invitedByUser?.lastName : "N/A",
                    email: invitations.invitedByUser?.email || "N/A",
                    heading1: "Created",
                    heading2: "Email Status",
                    description1: moment(invitations.createdAt).utcOffset('+0000').format("YYYY-MM-DD HH:mm"),
                    description2: "Click 2024-04-19 13:55"
                }

                let totalScore = 0

                if (invitations.assessmentDetails.length) {
                    invitations.assessmentDetails[0]?.challengesList?.forEach((challenge) => {
                        totalScore += challenge.totalScore
                    })
                }
                let percentage = 0

                if (
                    totalScore > 0
                ) {
                    percentage = invitations.candidateScore * 100 / totalScore
                }
                setData({ ...invitations, inviteUpdates: invitations.inviteUpdates ? _.reverse(invitations.inviteUpdates) : [{}], sender, currentUser, totalScore, percentage })
            }
        } catch (error) {
            message.error(error.message)

        } finally {
            setIsLoading(false)
        }
    }

    const getGraphData = async () => {
        try {
            const { data: graphs } = await getGraphDataAPICandidate(user.companyIds[0], assessmentId, userId, resultId)
            const distributionGraph = graphs?.scoreDistribution
            let barChartKeys = Object.keys(distributionGraph || {})?.map((key) => enumBarChartKeys[key])?.filter((e) => e !== undefined)
            const performancTimeLineGraph = getMaxScoresForIntervals(graphs?.performanceTimeLine?.scoreAndTime || [])

            const lineChartData = {
                data: [],
                labels: []
            }

            performancTimeLineGraph?.forEach(({ interval, maxScore }) => {
                lineChartData.data.push(maxScore)
                lineChartData.labels.push(interval)
            })

            const compositeGraph = graphs?.compositeGraph?.map(({ candidateScore, accuracy }) => ({
                x: accuracy,
                y: candidateScore,
                r: 10
            }))


            const data = {
                barCharData: {
                    labels: barChartKeys,
                    data: Object.keys(distributionGraph || {})?.map((key) => distributionGraph[key]),
                    numberOfCandidates: distributionGraph?.numberOfCandidates || 0
                },
                radarChart: {
                    labels: graphs?.skillsTracker?.map((radar) => (radar._id)),
                    data: graphs?.skillsTracker?.map((radar) => (10))
                },
                compositeChartData: compositeGraph,
                lineChartData
            }
            setGraphData({ ...graphs, ...graphsData, ...data })
        } catch (error) {
            message.error(error.message || "Unable to fetch graphs data")
        }
    }

    const fetchResult = async () => {
        try {
            const { data } = await getAssessmentResultsId(userId, assessmentId, companyId, resultId)
            const questions = []
            if (data) {
                data.challenges.forEach((challenge, index) => {

                    let questionData = {
                        value: challenge._id,
                        label: challenge.name,
                        result: []
                    }
                    challenge.questions.forEach((question) => {
                        if (question.status === "ATTEMPTED") {
                            const answerTime = moment(question.answerTime).format("hh:mm:ss A")
                            questionData.result.push({
                                isCorrect: question.isTrueAnswer,
                                answer: question.givenAnswer,
                                question: question.question,
                                answerTime
                            })
                        }

                    })
                    if (index === 0) {
                        setSelectedChallenge(challenge._id)
                        setSelectedChallengeResult(questionData.result)
                    }
                    questions.push(questionData)
                })
            }
            setResult(questions)
        } catch (error) {
            message.error(error?.message || "Something went wrong")
        } finally {

        }
    }

    useEffect(() => {
        const foundIndex = result.findIndex((ind) => ind.value === selectedChallenge)
        if (foundIndex !== -1) {
            setSelectedChallengeResult(result[foundIndex]?.result || [])
        }
    }, [selectedChallenge])

    const saveNote = async () => {
        try {
            if (data._id) {
                setIsLoading(true)
                await saveAssessmentNotes(data._id, inputRef.current.resizableTextArea.textArea.value, user._id)
                message.success("Note Successfully Saved")
                fetchAssessmentDetails()

            } else {
                message.error("No Invitation found")
            }

        } catch (e) {
            message.error(e?.message || "Something went wrong")
        } finally {
            setIsLoading(false)
        }
    }

    const handleStatus = async (status) => {
        try {
            setIsLoading(true)
            if (data._id) {
                await changeAssessmentStatus(data._id, status)
                message.success("Assessment status changed")
                fetchAssessmentDetails()
            } else {
                message.error("Not Assessment Found")
            }

        } catch (error) {
            message.error(error?.message || "Something went wrong")
        } finally {
            setIsLoading(false)
        }
    }

    return <Layout className="main-layout">
        {isLoading ? <StyledLoader /> : null}
        <Layout className="assessment-details">
            <Card className="information-container">
                <InfoContainer data={data.currentUser} label="Candidate" />
                <InfoContainer data={data.sender} label="Sender" />
            </Card>
            <Card className="notes-container">
                <p className="notes">Notes</p>
                {
                    data.notes?.map(({ message, createdAt, addedBy }) => <div style={styles.alert}>
                        <p style={{ ...styles.alertMessage, position: "absolute", right: 10, bottom: 0 }}>{addedBy && addedBy.length ? addedBy[0].firstName + " " + addedBy[0].lastName : "-"} ({moment(createdAt).format('MMMM D, YYYY')})</p>
                        <p style={styles.alertMessage}>{message}</p>
                    </div>)
                }
                <TextArea ref={inputRef} style={styles.textarea} placeholder="Enter your internal notes. These will not be seen by the candidate." classNames="text-area" rows={4} />
                <Flex style={{ justifyContent: "end" }}>
                    <Button style={styles.button} onClick={saveNote} className="button">Submit</Button>
                </Flex>
            </Card>
            <Card className="notes-container">
                {!graphsData?.compositeChartData?.length ? <div style={{ height: "200px" }}>
                    <p className="notes" style={{ marginBottom: "20px" }}>
                        Composite Performance
                    </p>
                    Not enough data. This graph will populate once the candidate starts the evaluation.
                </div> : <div style={{ height: "380px", width: "100%", marginBottom: "2%" }}><SplineChart data={graphsData?.compositeChartData || []} /></div>
                }
            </Card>
            <Card className="notes-container">
                {!graphsData?.barCharData?.labels?.length ? <div style={{ height: "200px" }}>
                    <p className="notes" style={{ marginBottom: "20px" }}>
                        Score Distribution
                    </p>
                    Not enough data. This graph will populate once the candidate starts the evaluation.
                </div> : <div style={{ height: "380px", width: "100%", marginBottom: "2%" }}><BarChart payload={graphsData.barCharData || {
                    label: [], data: [], numberOfCandidates: 0
                }} /></div>
                }

            </Card>
            <Card className="notes-container">
                {!graphsData?.lineChartData?.labels?.length ? <div style={{ height: "200px" }}>
                    <p className="notes" style={{ marginBottom: "20px" }}>
                        Performance Timeline
                    </p>
                    Not enough data. This graph will populate once the candidate starts the evaluation.
                </div> :
                    <div style={{ height: "380px", width: "100%", marginBottom: "2%" }}><LineChart payload={graphsData?.lineChartData || { data: [], labels: [] }} /></div>}
            </Card>
            <Card className="notes-container">
                {!graphsData?.radarChart?.labels?.length ? <div style={{ height: "200px" }}>
                    <p className="notes" style={{ marginBottom: "20px" }}>
                        Skills Tracker
                    </p>
                    Not enough data. This graph will populate once the candidate starts the evaluation.
                </div> : <div style={{ height: "380px", width: "100%", marginBottom: "2%" }}> <RadarChart
                    payload={graphsData.radarChart || { data: [], labels: [] }}
                /></div>}
            </Card>
            <Card className="question-history-container">
                <div style={{ display: "flex", justifyContent: "right", width: "100%" }}>
                    {result?.length ? <Select
                        className="selection-dropdown selection-dropdown-history"
                        value={selectedChallenge}
                        style={{ width: "200px !important" }}
                        onChange={(value) => setSelectedChallenge(value)}
                        options={result || []}
                    /> : null}
                </div>
                <Divider />
                {selectedChallengeResult?.length ? <Timeline
                    items={
                        selectedChallengeResult.map(({ question, isCorrect, answer, answerTime }) => {
                            return ({
                                color: "RIGHT" ? "green" : "red",
                                dot: isCorrect ? <CheckOutlined style={{ color: "#49C18E" }} /> : <CloseOutlined style={{ color: "red" }} />,
                                children: <div>
                                    <div className="flex gap-2">
                                        <p style={{
                                            fontSize: "16px",
                                            fontWeight: "600",
                                            lineHeight: "24px",
                                            textAlign: "left",
                                            color: "#070908"
                                        }}>Question:</p>
                                        <p style={{
                                            fontSize: "16px",
                                            fontWeight: "500",
                                            lineHeight: "24px",
                                            textAlign: "left",
                                            color: "#070908"
                                        }}>{question}</p>
                                    </div>
                                    <div className="flex gap-2 item-center">
                                        <p style={{
                                            fontSize: "16px",
                                            fontWeight: "600",
                                            lineHeight: "24px",
                                            textAlign: "left",
                                            color: "#070908"
                                        }}>Answer:</p>
                                        <p style={{
                                            fontSize: "14px",
                                            fontWeight: "500",
                                            textAlign: "left",
                                            color: isCorrect ? "#4AC18E" : "red"
                                        }}>{answer}</p>
                                    </div>
                                    <p style={{
                                        fontSize: "12px",
                                        fontWeight: "400",
                                        lineHeight: "24px",
                                        textAlign: "left",
                                        color: "#070908"
                                    }}>{answerTime}</p>
                                </div>
                            })
                        })
                    }
                /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            </Card>
        </Layout>
        <Layout className="history-section">
            <Card className="info-container">
                <Progress
                    type="circle"
                    size={200}
                    percent={data?.percentage || 0}
                    format={(percent) => (
                        <div className="flex flex-col">
                            <Typography.Text className="text-xl">
                                {Math.round(percent)}%
                            </Typography.Text>
                            <Typography.Text type="secondary" className="text-xs">
                                Candidate Score
                            </Typography.Text>
                        </div>
                    )}
                    steps={{ steps: 2, gap: 3 }}
                    trailColor="#E0E0E0"
                    strokeWidth={15}
                    strokeColor={{
                        "0%": "#22A56D",
                        "50%": "#4BD097",
                    }}
                    strokeLinecap="butt"
                />

                <Flex gap="middle" style={{ alignItems: "end" }}>
                    <div>
                        <p style={styles.score}>Score</p>
                        <p style={styles.scoreStyle}>{data.candidateScore}/{data.totalScore}</p>
                    </div>

                    <Progress type="line" percent={data.percentage}
                        strokeColor={{
                            "0%": "#EFBE84",
                            "50%": "#E36D18",
                        }}
                        strokeWidth={10}
                        format={(percent) => (
                            <></>
                        )}
                    />
                </Flex>
                <Flex gap="middle" style={{ alignItems: "end", marginTop: "2%" }}>
                    <div>
                        <p style={styles.score}>Accuracy</p>
                        <p style={styles.scoreStyle}>{data.accuracy ?? 0}</p>
                    </div>

                    <Progress type="line" percent={data?.accuracy ?? 0}
                        strokeColor={{
                            "0%": "#BAA0EF",
                            "50%": "#8938DB",
                        }}
                        strokeWidth={10}
                        format={(percent) => (
                            <></>
                        )}
                    />
                </Flex>

            </Card>
            <div style={{
                display: "flex",
                gap: 2
            }}>

                {!isLoading ? <ActionButtons result={data?.assessmentResult} isCompleted={data?.assessmentStatus === "COMPLETED"} handleStatus={handleStatus} /> : null}
            </div>
            <Card className="history-container">
                <p style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    lineHeight: "21px",
                    textAlign: "left",
                    color: "#888A8D"
                }}>Updates</p>
                <div style={{ display: "flex", flexDirection: "column", gap: "10px", marginTop: "20px" }}>
                    {
                        data?.inviteUpdates?.map(({ dateOfUpdation, inviteUpdatesMessagesAndDates }) => {
                            return (
                                <>
                                    <div style={{
                                        width: "max-content",
                                        borderRadius: "4px",
                                        height: "29px",
                                        padding: "4px 8px 4px 8px",
                                        gap: "10px",
                                        background: "#888A8D"
                                    }}>
                                        <p style={{
                                            fontSize: "14px",
                                            fontWeight: "500",
                                            lineHeight: "21px",
                                            textAlign: "left",
                                            color: "#FFFFFF"
                                        }}>{dateOfUpdation}</p>
                                    </div>
                                    {
                                        _.reverse(inviteUpdatesMessagesAndDates)?.map(({ message, createdAt }) => {
                                            return <HistoryItem message={message} date={createdAt} />
                                        })
                                    }
                                </>
                            )
                        })
                    }
                </div>
            </Card>

        </Layout>
    </Layout >
}

const mapStateToProps = (state) => ({
    user: state.auth.user
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(CompanyAdminCandidateInviteDetails);

const InfoItem = ({ title, description }) => {
    return <div style={{ display: "flex", flexDirection: "column" }}>
        <p style={{
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "21px",
            textAlign: "left",
            color: "#888A8D"
        }}>{title}</p>
        <p style={{
            fontSize: "16px",
            fontWeight: "500",
            lineHeight: "24px",
            textAlign: "left",
            color: "#070908"
        }}>{description}</p>
    </div>
}
const InfoItemWithURL = ({ title, description, assessmentId }) => {
    const navigate = useNavigate()
    return <div style={{ display: "flex", flexDirection: "column" }}>
        <p style={{
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "21px",
            textAlign: "left",
            color: "#888A8D"
        }}>{title}</p>
        <Button type="link" primary onClick={() => navigate(`/company/admin/evaluation/${assessmentId}`)} style={{
            fontSize: "16px",
            fontWeight: "500",
            lineHeight: "24px",
            textAlign: "left",
            color: "#4AC18E",
            textDecoration: "underline",
            padding: 0
        }}>{description}</Button>
    </div>
}


const InfoContainer = ({ label, data }) => {
    return <div style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px"
    }}>
        <div style={{ display: "flex", justifyContent: "left", flexDirection: "column", gap: "10px" }}>
            <Typography.Text type="secondary" style={{
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "21px",
                textAlign: "left",
                color: "#888A8D"
            }}>
                {label}
            </Typography.Text>
            <div style={{ display: "flex", gap: "10px", }}>
                <Avatar size={48} icon={<UserOutlined />} />
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <p style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        lineHeight: "24px",
                        textAlign: "left",
                        color: "#070908"
                    }}>{data?.name ?? "N/A"}</p>
                    <p style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "21px",
                        textAlign: "center",
                        color: "#888A8D"
                    }}>{data?.email ?? "N/A"}</p>
                </div>
            </div>
        </div>
        {label === "Candidate" ? <InfoItemWithURL title={data?.heading1} description={data?.description1 || "N/A"} assessmentId={data?.assessmentId} /> : <InfoItem title={data?.heading1} description={data?.description1 || "N/A"} />}
        <InfoItem title={data?.heading2} description={data?.description2 || "N/A"} />
    </div>
}

const HistoryItem = ({ message, date }) => {
    return <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>

            <img src={TickIcon} alt="tick-icon" />
            <p style={{
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "24px",
                textAlign: "left",
                color: "#070908",
                width: "189px"
            }}> {message}</p>

        </div>
        <p style={{
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "21px",
            textAlign: "center",
            color: "#888A8D",
            width: "189px"
        }}>{moment(date).utcOffset('+0000').format("YYYY-MM-DD HH:mm")}</p>
    </div>
}


const ActionButtons = ({ result, handleStatus, isCompleted }) => {
    const [newAction, setNewAction] = useState(false)

    if (result === "QAULIFIED") {
        return <div style={{ display: "flex", gap: 2, width: "100%" }}>
            <SuccessActionButton style={{ width: newAction ? "48%" : "96%" }} onClick={() => setNewAction((value) => !value)} label="Qualified" />
            {newAction &&
                <DangerActionButton onClick={() => {
                    handleStatus("FAILED")
                }} />}
        </div>
    }
    if (result === "FAILED") {
        return <div style={{ display: "flex", gap: 2, width: "100%" }}>
            <DangerActionButton style={{ width: newAction ? "48%" : "96%" }} onClick={() => setNewAction((value) => !value)} label="Disqualified" newAction={newAction} />
            {newAction &&
                <SuccessActionButton onClick={() => {
                    handleStatus("QAULIFIED")
                }} />}
        </div>
    }
    return <>
        <SuccessActionButton disabled={!isCompleted} onClick={() => handleStatus("QAULIFIED")} />
        <DangerActionButton disabled={!isCompleted} onClick={() => handleStatus("FAILED")} />

    </>

}


const DangerActionButton = ({ onClick, label, disabled, style = {} }) => {
    return <Button type="primary" disabled={disabled} onClick={onClick} danger style={{
        height: "40px", display: "flex", width: "48%", alignItems: "center", gap: 2, justifyContent: "center", ...style
    }}>
        <img src={CloseCircle} alt="qualified" />
        <p style={{
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "24px",
            textAlign: "left",
            color: "white"
        }}>{label || "Disqualified"}</p>
    </Button>
}
const SuccessActionButton = ({ onClick, label, style, disabled }) => {
    return <Button disabled={disabled} onClick={onClick} type="primary" style={{ height: "40px", borderRadius: "8px", width: "48%", display: "flex", alignItems: "center", gap: 2, justifyContent: "center", ...style }}>
        <img src={TickCircle} alt="qualified" />
        <p style={{
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "24px",
            textAlign: "left",
            color: "white"
        }}>{label || "Qualified"}</p>
    </Button>
}