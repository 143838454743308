import { Button, Card, Form, Input, Spin, Typography, message } from "antd";
import {
  associatCompaniesAndAssessments,
  getAllAssessmentsWithoutLimit,
  getCompanyById,
  updateCompany,
} from "../../../services/api";
import Select from "react-select";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import DeleteModal from "../../../components/Modals/DeleteModal";
import "./index.css";

const { Text } = Typography;
const EditCompany = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [companyData, setCompanyData] = useState({});
  const [allAssessments, setAllAssessments] = useState([]);
  const [selectedAssessments, setSelectedAssessments] = useState([]);
  const [searchParams] = useSearchParams();
  const companyId = searchParams.get("id");
  const navigate = useNavigate();

  const onFinish = async (values) => {
    if (!selectedAssessments.length) {
      message.error("Assessment is required");
      return;
    }

    setIsLoading(true);
    try {
      await updateCompany(
        {
          ...values,
          assessmentIds: selectedAssessments.map((item) => item.value),
        },
        companyId
      );
      message.success("Company updated!");
      associatCompanies();
      navigate("/admin/companies");
      setIsLoading(false);
    } catch (error) {
      message.error(error?.response?.data?.error || "Something went wrong");
      setIsLoading(false);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const associatCompanies = async () => {
    try {
      const assessmentsIds = selectedAssessments.map((item) => item.value);
      await associatCompaniesAndAssessments({
        companyIds: [companyId],
        assessmentsIds,
      });
    } catch (error) {
      console.log({ error });
    }
  };

  const fetchData = async () => {
    try {
      const companyPromise = getCompanyById(companyId);
      const assessmentsPromise = getAllAssessmentsWithoutLimit();

      const [companyResponse, assessmentsResponse] = await Promise.all([
        companyPromise,
        assessmentsPromise,
      ]);

      if (
        companyResponse.status === 200 &&
        assessmentsResponse.status === 200
      ) {
        const { data: companyData } = companyResponse;
        const { data: assessmentsData } = assessmentsResponse;

        setCompanyData(companyData);
        setAllAssessments(assessmentsData?.assessments);
        if (
          companyData &&
          companyData.assessmentIdsWithTokens &&
          assessmentsData &&
          assessmentsData.assessments
        ) {
          const filteredAssessments = assessmentsData?.assessments
            .filter((assessment) =>
              companyData.assessmentIdsWithTokens.some(
                (token) => token.assessmentId === assessment._id
              )
            )
            .map((selectedAssessment) => ({
              label: selectedAssessment.title,
              value: selectedAssessment._id,
            }));
          setSelectedAssessments(filteredAssessments);
        }
      }
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({
      name: companyData?.name,
      description: companyData?.description,
    });
  }, [companyData, form]);

  const menuProps = [
    ...allAssessments?.map((item, i) => {
      return {
        label: item.title,
        value: item._id,
      };
    }),
  ];

  return (
    <div>
      <Card style={{ width: "100%" }}>
        <div className="flex justify-between items-center px-3 py-4 w-full">
          <Text className="text-2xl font-semibold font-[#1f2232]">
            Edit Company
          </Text>
        </div>
        <div className="p-3">
          <Form
            form={form}
            name="basic"
            layout="vertical"
            className="w-full"
            labelCol={{
              span: 8,
            }}
            initialValues={{
              remember: true,
              assessment: selectedAssessments,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div className="flex flex-wrap">
              <Form.Item
                label="Name"
                name="name"
                className="w-1/2 p-2"
                rules={[
                  {
                    required: true,
                    message: "Please input company name!",
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>

              <Form.Item
                label="Description"
                name="description"
                className="w-1/2 p-2"
                rules={[
                  {
                    required: true,
                    message: "Please input company description!",
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
              <Form.Item
                label="Select Assessments"
                className="w-1/2 p-2"
                rules={[
                  {
                    required: true,
                    message: "Please select an assessments!",
                    validator: (_, value) => {
                      if (selectedAssessments?.length) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject("Some message here");
                      }
                    },
                  },
                ]}
              >
                <Select
                  value={selectedAssessments}
                  onChange={setSelectedAssessments}
                  options={menuProps}
                  isMulti
                  className="react-select-assessment"
                  style={{ height: "48px", borderRadius: "28px" }}
                />
              </Form.Item>

              <Form.Item className="flex justify-end w-full">
                <Button
                  className="mr-2"
                  onClick={() => setIsModalOpen(true)}
                  size="large"
                >
                  Cancel
                </Button>
                <Button size="large" htmlType="submit">
                  {isLoading ? (
                    <div className="flex items-center">
                      <Spin
                        className="mr-2"
                        indicator={
                          <LoadingOutlined
                            style={{
                              fontSize: 18,
                            }}
                            spin
                          />
                        }
                      />
                      Save
                    </div>
                  ) : (
                    "Save"
                  )}
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </Card>
      <DeleteModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        deleteAction={() => navigate("/admin/companies")}
        title="Discard changes"
        actionText="Yes"
        text="Are you sure you want to discard?"
      />
    </div>
  );
};

export default EditCompany;
