import React, { useState, useEffect } from "react";
import { AutoComplete, message } from "antd";

const MultiSelectDropdown = ({
  disabled,
  allChallenges,
  selectedChallenge,
  setSelectedChallenge,
}) => {
  const [value, setValue] = useState("");
  const [anotherOptions, setAnotherOptions] = useState([]);

  useEffect(() => {
    setAnotherOptions([
      ...allChallenges.map((item) => ({ value: item.name, _id: item._id })),
    ]);
  }, [allChallenges]);
  const getPanelValue = (searchText) => {
    if (!searchText) {
      return [
        ...allChallenges.map((item) => ({ value: item.name, _id: item._id })),
      ];
    }
    const filteredChallenges = allChallenges.filter((item, i) => {
      const index = selectedChallenge.findIndex((ind) => ind._id === item._id);
      if (index < 0) {
        return item.name.toLowerCase().includes(searchText.toLowerCase());
      }
      return null;
    });
    return filteredChallenges.map((item) => ({
      value: item.name,
      _id: item._id,
    }));
  };
  const onSelect = (data, e) => {
    if (selectedChallenge.length >= 5) {
      message.error("You can select maximum 5 challenges");
    } else {
      const index = allChallenges.findIndex((item) => item._id === e._id);

      const isAlreadyAdded = selectedChallenge.findIndex(
        (ind) => ind._id === e._id
      );
      if (isAlreadyAdded !== -1) {
        message.error("Challenge already selected");
      } else {
        const arr = [...selectedChallenge, allChallenges[index]];
        setSelectedChallenge(arr);
      }
      setAnotherOptions([
        ...allChallenges.map((item) => ({ value: item.name, _id: item._id })),
      ]);
    }

    setValue("");
  };
  const onChange = (data) => {
    setValue(data);
  };
  return (
    <>
      <AutoComplete
        disabled={disabled}
        value={value}
        size="large"
        options={anotherOptions}
        style={{
          textAlign: "left",
        }}
        onSelect={(val, e) => onSelect(val, e)}
        onSearch={(text) => setAnotherOptions(getPanelValue(text))}
        onChange={onChange}
        placeholder="Search challenge..."
      />
    </>
  );
};

export default MultiSelectDropdown;
