import {
  Card,
  Col,
  Divider,
  Dropdown,
  Input,
  Row,
  Space,
  Tooltip,
  Typography,
  message,
} from "antd";
import AdminsTable from "../../components/Tables/AdminsTable";
import { useCallback, useEffect, useState } from "react";
import { getFeedbacks, updateFeedback } from "../../services/api";
import { StyledLoader } from "../../components/loader";
import { FaCaretDown } from "react-icons/fa";
import { debounce } from "lodash";

const { Text } = Typography;
const Feedback = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [allFeedbacks, setAllFeedbacks] = useState([]);
  const [searchedFeedbacks, setSearchedFeedbacks] = useState([]);
  const [searchKey, setSearchKey] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0, // You might not know this until you fetch the first set of data
  });

  const handleStatusChange = async (feedbackStatus, id) => {
    try {
      const index = allFeedbacks.findIndex((ind) => ind._id === id);
      const arr = [...allFeedbacks];
      arr[index].status = feedbackStatus;
      setAllFeedbacks(arr);
      message.success("Status updated!");
      await updateFeedback({ status: feedbackStatus }, id);
    } catch (error) {
      console.log({ error });
      message.error(error.response.data.error);
    }
  };

  const columns = [
    {
      title: "SR No.",
      dataIndex: "key",
      key: "key",
      render: (text) => <Typography.Text>{text || "--"}</Typography.Text>,
      // sorter: true,
    },
    {
      title: "Assessment Name",
      dataIndex: "assessmentName",
      key: "assessmentName",
      render: (text) => <Typography.Text>{text || "--"}</Typography.Text>,
      // sorter: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => <Typography.Text>{text || "--"}</Typography.Text>,
      // sorter: true,
    },
    {
      title: "Feedback",
      dataIndex: "feedback",
      key: "feedback",
      render: (text) => <Typography.Text>{text || "--"}</Typography.Text>,
      // sorter: true,
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
      render: (text) => (
        <Tooltip title={text}>
          <div className="max-w-32 truncate cursor-pointer">
            <Typography.Text>{text || "--"}</Typography.Text>
          </div>
        </Tooltip>
      ),
      // sorter: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => (
        <Dropdown
          trigger={["click"]}
          menu={{
            items: [
              {
                key: "1",
                label: (
                  <Typography.Text
                    onClick={() => handleStatusChange("PENDING", record._id)}
                  >
                    PENDING
                  </Typography.Text>
                ),
              },
              {
                key: "3",
                label: (
                  <Typography.Text
                    onClick={() => handleStatusChange("REVIEWED", record._id)}
                  >
                    REVIEWED
                  </Typography.Text>
                ),
              },
            ],
          }}
        >
          <Typography.Text onClick={(e) => e.preventDefault()}>
            <Space>
              {record.status}
              <FaCaretDown className="ml-2" />
            </Space>
          </Typography.Text>
        </Dropdown>
      ),
    },
    // {
    //     title: 'Action',
    //     dataIndex: 'action',
    //     key: 'action',
    //     render: (text) => <a>REVIEWED</a>,
    // },
  ];
  const data = [
    ...searchedFeedbacks.map((item, i) => {
      return {
        _id: item?._id,
        key: i + 1,
        assessmentName: item?.assessment?.title || "-- --",
        email: item?.user?.email || "-- --",
        feedback: item?.difficultyLevel || "-- --",
        comment: item?.comments || "-- --",
        status: item?.status || "-- --",
      };
    }),
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    getAllFeedbacks(
      pagination?.current,
      pagination?.pageSize,
      sorter.order ? sorter.field : null,
      sorter.order
    );
  };

  useEffect(() => {
    setSearchedFeedbacks(allFeedbacks);
  }, [allFeedbacks]);

  const getAllFeedbacks = async (
    page = 1,
    limit = 10,
    sortBy,
    order,
    searchKey
  ) => {
    setIsLoading(true);
    try {
      const { data } = await getFeedbacks(
        page,
        limit,
        sortBy,
        order,
        searchKey
      );
      setAllFeedbacks(data?.feedbacks);
      setPagination((prev) => ({ ...prev, total: data?.count, current: page }));
      setIsLoading(false);
    } catch (error) {
      console.log({ error });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllFeedbacks();
  }, []);

  const debouncedHandleSearch = useCallback(
    debounce((searchValue) => {
      getAllFeedbacks(1, pagination?.pageSize, null, null, searchValue);
    }, 1000),
    []
  );

  const handleSearch = (e) => {
    const val = e.target.value;
    setSearchKey(val);
    // const filteredArr = allFeedbacks.filter((item) =>
    //   item?.name.toLowerCase().includes(val.toLowerCase())
    // );
    // setSearchedFeedbacks(filteredArr);
    debouncedHandleSearch(val);
  };

  return (
    <div>
      <Card style={{ width: "100%" }}>
        <div className="flex justify-between items-center px-3 pb-4 w-full">
          <Text className="text-2xl font-semibold font-[#1f2232]">
            Feedback
          </Text>
        </div>
        <Divider style={{ margin: 0 }} />
        <Row gutter={12} justify={"space-between"} className="my-2">
          <Col span={8}>
            <Input
              size="large"
              onChange={(e) => handleSearch(e)}
              placeholder="Search..."
              value={searchKey}
            />
          </Col>
        </Row>
        <Divider style={{ margin: 0 }} />
        {isLoading ? <StyledLoader /> : null}
        <AdminsTable
          onChange={handleTableChange}
          columns={columns}
          data={data}
          pagination={pagination}
        />
      </Card>
    </div>
  );
};

export default Feedback;
