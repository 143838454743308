import { SearchOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Card, Divider, Input, Layout, Progress, Select, Space, message } from "antd";
import "./index.css"
import { useEffect, useMemo, useRef, useState } from "react";
import Table from '../../components/Tables/Table'
import Trophy from '../../assets/icons/trophy.svg'
import { StyledLoader } from "../../components/loader";
import { axiosInstance, getAllAssessmentsOfCompany } from "../../services/api";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

function CompanyAdminDashboard({ user }) {
    const [dashboardData, setDashboardData] = useState({
        allCandidates: [],
        averageStats: [],
        barChartData: {},
        topCandidate: {}
    })
    const [assessment, setAssessments] = useState([])
    const searchInput = useRef(null);
    const [isLoading, setIsLoading] = useState(false)
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0, // You might not know this until you fetch the first set of data
    });
    const [selectedAssessment, setSelectedAssessment] = useState("")
    const navigate = useNavigate()

    useEffect(() => {
        handleTableChange(pagination, {}, {})
        fetchAssessments()
    }, [])

    const fetchAssessments = async () => {
        if (user.companyIds.length) {
            try {
                setIsLoading(true)
                const { data } = await getAllAssessmentsOfCompany(
                    user.companyIds[0],
                );
                const d = data?.company?.flatMap(({ assessments }) => assessments) || []
                setAssessments(d)
                if (d.length) {
                    setSelectedAssessment(d[0]._id)
                }
            } catch (error) {
                message.error(error.message || "Something went wrong")
            } finally {
                setIsLoading(false)
            }
        }
    }

    useEffect(() => {
        getDashboardData()
    }, [selectedAssessment])

    const getDashboardData = async () => {
        if (selectedAssessment?.length) {
            try {
                setIsLoading(true)
                const { data } = await axiosInstance.get(`${process.env.REACT_APP_API_PREFIX}result/dashboard/${user.companyIds[0]}/${selectedAssessment}`)
                let topCandidate = {}
                if (data.topCandidate.length) {
                    const candidate = data.topCandidate[0]
                    topCandidate = {
                        candidateScore: candidate.candidateScore,
                        accuracy: candidate.accuracy,
                        completionPercentage: candidate.completionPercentage,
                        firstName: candidate.userDetails?.length ? candidate.userDetails[0].firstName : "",
                        lastName: candidate.userDetails?.length ? candidate.userDetails[0].lastName : "",
                        profilePic: candidate.userDetails?.length && candidate.userDetails[0].profilePic ? candidate.userDetails[0].profilePic : null
                    }
                }
                setDashboardData({ ...data, topCandidate })
            } catch (error) {
                message.error(error.message || "Something went wrong")
            } finally {
                setIsLoading(false)
            }
        }
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
    };
    const handleReset = (clearFilters, confirm) => {
        clearFilters();
        confirm()
    };

    const handleTableChange = (pagination, filters, sorter) => {
        let query = `pageNo=${pagination.current}&pageSize=${pagination.pageSize}`
        const filtersKeys = Object.keys(filters)

        if (filtersKeys.length) {
            filtersKeys.forEach((filter) => {
                if (filters[filter]) {
                    query += `&${filter}=${filters[filter][0]}`
                }
            })
        }

        setPagination(pagination)
        // fetchInvitations(query, pagination.current);
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters, confirm)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        )
    });

    const columns = [
        {
            title: 'NO.',
            dataIndex: 'sr',
            width: "5%",
            key: 'sr',
        },
        {
            title: 'Name Surname',
            dataIndex: 'name',
            key: 'name',
            width: '20%',
            sorter: true,
            render: (item, _) => <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                {_.profilePic ? <Avatar size={24} src={_.profilePic} /> : <Avatar size={24} icon={<UserOutlined />} />}
                <p style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    lineHeight: "18px",
                    textAlign: "left",
                    color: "#070908"
                }}>{item}</p>
            </div>
        },
        {
            title: 'Points Score',
            dataIndex: 'score',
            key: 'score',
            width: '10%',
            sorter: true,
        },
        {
            title: 'Accuracy Score',
            dataIndex: 'accuracy',
            key: 'accuracy',
            width: '15%',
            sorter: true,
        },
        {
            title: 'Total Score',
            dataIndex: 'totalScore',
            key: 'totalScore',
            width: '10%',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
            render: (item) => {
                return <p className="font-semibold">{item}</p>
            }
        },
        {
            title: 'Completion Status',
            dataIndex: 'accuracy',
            key: 'accuracy',
            width: '15%',
            render: (item) => {
                if (item >= 90) {
                    return <div style={{
                        height: "25px",
                        padding: " 4px 8px 4px 8px",
                        gap: "10px",
                        borderRadius: "4px",
                        border: "1px solid #9B51E0",
                        fontSize: "11px",
                        fontWeight: "500",
                        lineHeight: "16.5px",
                        letterSpacing: "0.05em",
                        textAlign: "center",
                        color: "#9B51E0",
                        textTransform: "uppercase",
                        width: "max-content"
                    }}>
                        EXCEPTIONAL
                    </div>
                }
                if (item >= 75 && item < 90) {
                    return <div style={{
                        height: "25px",
                        padding: " 4px 8px 4px 8px",
                        gap: "10px",
                        borderRadius: "4px",
                        border: "1px solid #219653",
                        fontSize: "11px",
                        fontWeight: "500",
                        lineHeight: "16.5px",
                        letterSpacing: "0.05em",
                        textAlign: "center",
                        color: "#219653",
                        textTransform: "uppercase",
                        width: "max-content"
                    }}>
                        EXCELLENT
                    </div>
                }
                if (item >= 60 && item < 75) {
                    return <div style={{
                        height: "25px",
                        padding: " 4px 8px 4px 8px",
                        gap: "10px",
                        borderRadius: "4px",
                        border: "1px solid #2F80ED",
                        fontSize: "11px",
                        fontWeight: "500",
                        lineHeight: "16.5px",
                        letterSpacing: "0.05em",
                        textAlign: "center",
                        color: "#2F80ED",
                        textTransform: "uppercase",
                        width: "max-content"
                    }}>
                        Good
                    </div>
                }

                if (item >= 40 && item < 60) {
                    return <div style={{
                        height: "25px",
                        padding: " 4px 8px 4px 8px",
                        gap: "10px",
                        borderRadius: "4px",
                        border: "1px solid #F2994A",
                        fontSize: "11px",
                        fontWeight: "500",
                        lineHeight: "16.5px",
                        letterSpacing: "0.05em",
                        textAlign: "center",
                        color: "#F2994A",
                        textTransform: "uppercase",
                        width: "max-content"
                    }}>
                        Average
                    </div>
                }

                return <div style={{
                    height: "25px",
                    padding: " 4px 8px 4px 8px",
                    gap: "10px",
                    borderRadius: "4px",
                    border: "1px solid #F2994A",
                    fontSize: "11px",
                    fontWeight: "500",
                    lineHeight: "16.5px",
                    letterSpacing: "0.05em",
                    textAlign: "center",
                    color: "#F2994A",
                    textTransform: "uppercase",
                    width: "max-content"
                }}>
                    Below Average
                </div>

            },
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Result',
            dataIndex: 'assessmentResult',
            key: 'assessmentResult',
            width: '10%',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
            render: (item) => {
                if (item === "QAULIFIED") {
                    return <div style={{
                        height: "25px",
                        padding: " 4px 8px 4px 8px",
                        gap: "10px",
                        borderRadius: "4px",
                        border: "1px solid #4AC18E",
                        fontSize: "11px",
                        fontWeight: "500",
                        lineHeight: "16.5px",
                        letterSpacing: "0.05em",
                        textAlign: "center",
                        color: "#4AC18E",
                        textTransform: "uppercase",
                        width: "max-content"
                    }}>
                        {item}
                    </div>
                }
                if (item === "UNMARKED") {
                    return <div style={{
                        height: "25px",
                        padding: " 4px 8px 4px 8px",
                        gap: "10px",
                        borderRadius: "4px",
                        border: "1px solid #F2994A",
                        fontSize: "11px",
                        fontWeight: "500",
                        lineHeight: "16.5px",
                        letterSpacing: "0.05em",
                        textAlign: "center",
                        color: "#F2994A",
                        textTransform: "uppercase",
                        width: "max-content"
                    }}>
                        {item}
                    </div>
                }

                return <div style={{
                    height: "25px",
                    padding: " 4px 8px 4px 8px",
                    gap: "10px",
                    borderRadius: "4px",
                    border: "1px solid red",
                    fontSize: "11px",
                    fontWeight: "500",
                    lineHeight: "16.5px",
                    letterSpacing: "0.05em",
                    textAlign: "center",
                    color: "red",
                    textTransform: "uppercase",
                    width: "max-content"
                }}>
                    UNMARKED
                </div>

            },
        },
        {
            title: '',
            dataIndex: 'link',
            key: 'link',
            width: "30%",
            render: (_, item) => {
                return <p style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    lineHeight: "21px",
                    textAlign: "left",
                    color: "#4AC18E",
                    cursor: "pointer"
                }}
                    onClick={() => navigate(`/company/admin/candidate/${item.email}/${item.assessmentId}/${item.companyId}/${item.userId}/${item._id}`)}
                >View Profile</p>
            }
        },
    ];

    const getName = useMemo(() => {
        let name = ""
        if (dashboardData?.topCandidate?.firstName) {
            name += dashboardData?.topCandidate.firstName
        }
        if (dashboardData?.topCandidate?.lastName) {
            name += ` ${dashboardData?.topCandidate.lastName}`
        }
        return name.length ? name : "-"
    }, [dashboardData])

    return <Layout>
        {isLoading ? <StyledLoader /> : <>
            <div>
                <div style={{ display: "flex", justifyContent: "start" }}>


                    <Select
                        style={{
                            minWidth: "197px",
                            textAlign: "left",
                            height: "40px"
                        }}
                        value={selectedAssessment}
                        onChange={(value) => setSelectedAssessment(value)}
                        placeholder="Select Assessment"
                        options={assessment?.map((assmnt) => ({ value: assmnt._id, label: assmnt.title }))}
                    />
                </div>
            </div>
            <Divider style={{ marginTop: "2%" }} />
            <p className="dashboard-heading" style={{ margin: "1% 0", fontSize: "18px" }}>Top candidate statistics</p>
            <div className="card-container">
                <ProfileCard name={getName} score={dashboardData.topCandidate.candidateScore} profilePic={dashboardData.topCandidate.profilePic} />
                <Statistic label={"Average Score"} value={dashboardData.topCandidate?.candidateScore || 0} />
                <Statistic label={"Average Accuracy"} value={`${dashboardData.topCandidate?.accuracy || 0}%`} />
                <Statistic label={"ASSMNT Completion Rate"} value={`${dashboardData.topCandidate?.completionPercentage || 0}%`} percentage={dashboardData.topCandidate?.completionPercentage} isProgress />
            </div>

            <p className="dashboard-heading" style={{ margin: "1% 0", fontSize: "18px" }}>Average statistics</p>
            <div className="card-container">
                <Statistic label="Number of Candidates" value={dashboardData?.averageStats?.candidates?.length || 0} />
                <Statistic label="Average Score" value={dashboardData?.averageStats?.averageScore || 0} />
                <Statistic label={"Average Accuracy"} value={`${dashboardData?.averageStats?.averageAccuracy || 0}%`} />
                <Statistic label={"ASSMNT Completion Rate"} value={`${dashboardData?.averageStats?.averageCompletionPercentage || 0}%`} percentage={dashboardData?.averageStats?.averageCompletionPercentage} isProgress />
            </div>
            <Divider style={{ marginTop: "2%", marginBottom: "2%" }} />

            <Card className="bar-char-card">
                <p className="dashboard-heading" style={{ margin: "2% 0" }}>Candidate Performance Distribution</p>
                <div className="bar-chart-container">
                    <div className="custom-progress-wrapper" style={{ rotate: "270deg" }}>
                        <Progress
                            trailColor="#ffffff"
                            strokeLinecap="square"
                            strokeWidth={130}
                            percent={dashboardData?.barChartData?.greaterThan90 ? Math.round(((dashboardData?.barChartData?.greaterThan90 / dashboardData?.barChartData?.numberOfCandidates) * 100)) : 0}
                            format={(percent) => (
                                <p style={{
                                    fontFamily: "Poppins",
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    lineHeight: "24px",
                                    textAlign: "left",
                                    color: "#070908"
                                }}>{percent}%</p>
                            )}

                            strokeColor={{
                                "50%": "#C189F6",
                                "0%": "#7329BB",
                            }}
                        />
                        <div className="diagonal-lines-overlay"></div>
                    </div>
                    <div className="custom-progress-wrapper" style={{ rotate: "270deg" }}>
                        <Progress percent={dashboardData?.barChartData?.between75And90 ? Math.round(((dashboardData?.barChartData?.between75And90 / dashboardData?.barChartData?.numberOfCandidates) * 100)) : 0} type="line" strokeColor={{
                            "50%": "#34C788",
                            "0%": "#217C55",
                        }} strokeWidth={130} format={(percent) => (
                            <p style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "500",
                                lineHeight: "24px",
                                textAlign: "left",
                                color: "#070908"
                            }}>{percent}%</p>
                        )} strokeLinecap="square" trailColor="#ffffff"

                        />
                        <div className="diagonal-lines-overlay"></div>
                    </div>
                    <div className="custom-progress-wrapper" style={{ rotate: "270deg" }}>
                        <Progress percent={dashboardData?.barChartData?.between60And75 ? Math.round(((dashboardData?.barChartData?.between60And75 / dashboardData?.barChartData?.numberOfCandidates) * 100)) : 0} format={(percent) => (
                            <p style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "500",
                                lineHeight: "24px",
                                textAlign: "left",
                                color: "#070908"
                            }}>{percent}%</p>
                        )} type="line" strokeColor={{
                            "50%": "#5196F4",
                            "0%": "#0A4089",
                        }} strokeWidth={130} strokeLinecap="square" trailColor="#ffffff"

                        />
                        <div className="diagonal-lines-overlay"></div>
                    </div>
                    <div className="custom-progress-wrapper" style={{ rotate: "270deg" }}>
                        <Progress percent={dashboardData?.barChartData?.between40And60 ? Math.round(((dashboardData?.barChartData?.between40And60 / dashboardData?.barChartData?.numberOfCandidates) * 100)) : 0} format={(percent) => (
                            <p style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "500",
                                lineHeight: "24px",
                                textAlign: "left",
                                color: "#070908"
                            }}>{percent}%</p>
                        )} type="line" strokeColor={{
                            "50%": "#FFAD64",
                            "0%": "#A4550F",
                        }} strokeWidth={130} strokeLinecap="square" trailColor="#ffffff"
                        />
                        <div className="diagonal-lines-overlay"></div>
                    </div>
                    <div className="custom-progress-wrapper" style={{ rotate: "270deg" }}>
                        <Progress percent={dashboardData?.barChartData?.below40 ? Math.round(((dashboardData?.barChartData?.below40 / dashboardData?.barChartData?.numberOfCandidates) * 100)) : 0} format={(percent) => (
                            <p style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "500",
                                lineHeight: "24px",
                                textAlign: "left",
                                color: "#070908"
                            }}>{percent}%</p>
                        )} type="line" strokeColor={{
                            "50%": "#FE5B5B",
                            "0%": "#760707",
                        }} strokeWidth={130} strokeLinecap="square" trailColor="#ffffff"

                        />
                        <div className="diagonal-lines-overlay"></div>
                    </div>
                </div>
                <div className="headings-container">
                    <div className="heading-div">
                        <p>Exceptional </p>
                        <p>(&gt; 90%)</p>
                    </div>
                    <div className="heading-div">
                        <p>Excellent </p>
                        <p>(75% - 90%)</p>
                    </div>
                    <div className="heading-div">
                        <p>Good </p>
                        <p>(60% - 75%)</p>
                    </div>
                    <div className="heading-div">
                        <p>Average </p>
                        <p>(40%-60%)</p>
                    </div>
                    <div className="heading-div">
                        <p>Below Average</p>
                        <p>(&lt; 40%)</p>
                    </div>
                </div>
            </Card>

            <Card className="table-container">
                <p style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    lineHeight: "24px",
                    textAlign: "left",
                    color: "#070908"

                }}>Leaderboard</p>
                <Table columns={columns}
                    data={dashboardData.allCandidates?.map((candidate, index) => ({
                        sr: index + 1,
                        name: candidate?.userDetails?.length ? candidate?.userDetails[0].firstName + " " + candidate?.userDetails[0].lastName : "-",
                        score: candidate?.candidateScore,
                        accuracy: candidate.accuracy,
                        totalScore: candidate.totalScore,
                        profilePic: candidate.userDetails?.length && candidate.userDetails[0].profilePic ? candidate.userDetails[0].profilePic : null,
                        assessmentResult: candidate.assessmentResult,
                        email: candidate?.userDetails?.length ? candidate?.userDetails[0].email : "-",
                        userId: candidate?.userDetails?.length ? candidate?.userDetails[0]._id : "",
                        companyId: candidate?.companyId,
                        assessmentId: candidate?.assessmentId,
                        key: index,
                        _id: candidate?._id
                    }))}
                    onChange={handleTableChange}
                    pagination={pagination}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: (event) => {
                                // navigate(`/company/admin/candidate/${record.key}/${record.key}`)
                            }
                        }
                    }}
                    style={{ cursor: "pointer" }}
                />
            </Card>
        </>}
    </Layout >
}


const mapStateToProps = (state) => ({
    user: state.auth.user
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyAdminDashboard);


const Statistic = ({ isProgress, label, value = "", percentage }) => {
    return <Card className="statistic-card">
        <p className="title" style={{ fontWeight: "600" }}>{label ?? ""}</p>
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <p className="description" style={{ fontWeight: "600" }}>{value ?? ""}</p>
            {isProgress ? <Progress
                type="line"
                style={{ width: "130px", height: "6px" }}
                percent={percentage ?? 0}
                strokeColor={{
                    "0%": "#4AC18E",
                    "50%": "#4AC18E",
                }}
                strokeWidth={6}
                format={(percent) => (
                    <></>
                )}
            /> : null}
        </div>

    </Card>
}

const ProfileCard = ({ name, score, profilePic }) => {
    return <Card className="statistic-card">
        <p className="title" style={{ fontWeight: "600" }}>Average Score</p>
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <div style={{ position: "relative" }}>
                {profilePic ? <Avatar size={50} src={profilePic} /> : <Avatar size={50} icon={<UserOutlined />} />}
                <img src={Trophy} alt="Leader" style={{
                    position: "absolute",
                    left: "33px",
                    bottom: "-4px"
                }} />
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: 2 }}>


                <p style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    lineHeight: "24px",
                    textAlign: "left",
                    color: "#070908",
                }}>
                    {name ?? ""}
                </p>
                <p style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    lineHeight: "21px",
                    textAlign: "left",
                    color: "#4AC18E"
                }}>{score ?? 0} score</p>

            </div>
        </div>

    </Card>
}