import {
  Button,
  Card,
  Col,
  Divider,
  Input,
  Row,
  Space,
  Tag,
  Typography,
  message,
} from "antd";
import AdminsTable from "../../../components/Tables/AdminsTable";
import { useCallback, useEffect, useState } from "react";
import { deleteTagById, getTags } from "../../../services/api";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { StyledLoader } from "../../../components/loader";
import DeleteModal from "../../../components/Modals/DeleteModal";
import TagModal from "../../../components/Modals/TagModal";
import { debounce } from "lodash";

const { Text } = Typography;
const Tags = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false);
  const [allTags, setAllTags] = useState([]);
  const [searchedTags, setSearchedTags] = useState([]);
  const [deleteTagID, setDeleteTagID] = useState(null);
  const [selectedTag, setSelectedTag] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0, // You might not know this until you fetch the first set of data
  });

  const handleDeleteModal = (id) => {
    setDeleteTagID(id);
    setIsModalOpen(true);
  };

  const handleEdit = (record) => {
    setSelectedTag(record);
    setIsAddEditModalOpen(true);
    setIsEditable(true);
  };

  const columns = [
    {
      title: "SR No.",
      dataIndex: "key",
      key: "key",
      render: (text) => <Text>{text || "--"}</Text>,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <Text>{text || "--"}</Text>,
      sorter: true,
    },
    {
      title: "Color code",
      dataIndex: "colorCode",
      key: "colorCode",
      render: (text) => <Tag color={text}>{text.toUpperCase()}</Tag>,
      sorter: true,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <Space size="middle">
            <EditOutlined
              onClick={() => handleEdit(record)}
              className="hover:text-blue-400"
            />
            <DeleteOutlined
              className="hover:text-blue-400"
              onClick={() => handleDeleteModal(record?._id)}
            />
          </Space>
        );
      },
    },
  ];
  const data = [
    ...searchedTags.map((item, i) => {
      return {
        _id: item?._id,
        key: i + 1,
        name: item?.name,
        colorCode: item?.colorCode,
      };
    }),
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    getAllTags(
      pagination?.current,
      pagination?.pageSize,
      sorter.order ? sorter.field : null,
      sorter.order
    );
  };

  useEffect(() => {
    setSearchedTags(allTags);
  }, [allTags]);

  const getAllTags = async (
    page = 1,
    limit = 10,
    sortBy,
    order,
    search = searchKey
  ) => {
    setIsLoading(true);
    try {
      const { data } = await getTags(page, limit, sortBy, order, search);
      setAllTags(data?.tags);
      setPagination((prev) => ({ ...prev, total: data?.count, current: page }));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllTags();
  }, []);

  const debouncedHandleSearch = useCallback(
    debounce((searchValue) => {
      getAllTags(
        pagination?.current,
        pagination?.pageSize,
        undefined,
        undefined,
        searchValue
      );
    }, 1000),
    []
  );
  const deleteTag = async () => {
    setIsModalOpen(false);
    // setIsLoading(true)
    try {
      const index = allTags.findIndex((ind) => ind._id === deleteTagID);
      const arr = [...allTags];
      arr.splice(index, 1);
      setAllTags(arr);
      message.success("Tag deleted!");

      await deleteTagById(deleteTagID);
      setDeleteTagID(null);
      // setIsLoading(false)
    } catch (error) {
      console.log({ error });
      // setIsLoading(false)
      getAllTags();
      message.error("Error deleting a tag!");
    }
  };

  const handleSearch = (e) => {
    const val = e.target.value;
    setSearchKey(val);
    debouncedHandleSearch(val);
  };

  return (
    <div>
      <Card style={{ width: "100%" }}>
        <div className="flex justify-between items-center px-3 pb-4 w-full">
          <Text className="text-2xl font-semibold font-[#1f2232]">Tags</Text>
          <Button
            style={{
              fontSize: "16px",
              lineHeight: "24px",
              textAlign: "left",
              color: "#070908",
              marginBottom: "10px",
            }}
            size="large"
            onClick={() => setIsAddEditModalOpen(true)}
          >
            Add New +
          </Button>
        </div>
        <Divider style={{ margin: 0 }} />
        <Row gutter={12} justify={"space-between"} className="my-2">
          <Col span={8}>
            <Input
              size="large"
              value={searchKey}
              onChange={(e) => handleSearch(e)}
              placeholder="Search..."
            />
          </Col>
        </Row>
        <Divider style={{ margin: 0 }} />
        {isLoading ? <StyledLoader /> : null}
        <AdminsTable
          onChange={handleTableChange}
          columns={columns}
          data={data}
          pagination={pagination}
        />
        <DeleteModal
          title="Delete record"
          text="Are you sure you want to delete this record?"
          deleteAction={deleteTag}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          isDelete
        />
        {isAddEditModalOpen && (
          <TagModal
            allTags={allTags}
            isEditable={isEditable}
            setIsEditable={setIsEditable}
            title={isEditable ? "Edit Tag" : "Add Tag"}
            isModalOpen={isAddEditModalOpen}
            setIsModalOpen={setIsAddEditModalOpen}
            action={getAllTags}
            editabledata={selectedTag}
            setEditabledata={setSelectedTag}
          />
        )}
      </Card>
    </div>
  );
};

export default Tags;
