import { Progress } from "antd";
import "./index.css";
import InfoDisplay from "./InfoDisplay";
import UserProfileComponent from "../../../../components/UserProfileComponent";
import { connect } from "react-redux";
import CountDown from "./CountDown";

const HeaderComponent = ({
  attempts,
  currentScore,
  totalScore,
  userName,
  userImage,
  completionPercentage,
  stopAssessmentAPI,
}) => (
  <div
    style={{
      position: "sticky",
      overflow: "auto",
      top: 0,
      zIndex: 1,
      width: "100%",
      display: "flex",
      alignItems: "center",
    }}
    className="header"
  >
    <InfoDisplay label="Attempts" value={attempts} />
    <InfoDisplay label="Score" value={`${currentScore}/${totalScore}`} />
    <div className="completion">
      <div className="label">Completion</div>
      <Progress
        style={{ width: "140px" }}
        strokeColor={"#34C788"}
        percent={completionPercentage}
        status="active"
      />
    </div>
    <CountDown stopAssessmentAPI={stopAssessmentAPI} />
    <UserProfileComponent
      noLogout
      userImage={userImage}
      userName={userName}
      notification={1}
    />
  </div>
);

const mapStateToProps = (state) => ({
  attempts: state.challenge.attempts,
  currentScore: state.challenge.currentScore,
  totalScore: state.challenge.assessment.totalScore,
  duration: state.challenge.assessment.expiresAt,
  userName: state?.auth?.user?.firstName + " " + state?.auth?.user?.lastName,
  userImage: state.auth.user?.profilePic,
  completionPercentage: state.challenge.completionPercentage || 0,
});
// const mapDispatchToProps = { getChallengeDetails };

export default connect(mapStateToProps, null)(HeaderComponent);
