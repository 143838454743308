import {
  DeleteOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
  Tag,
  Typography,
  Upload,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { createAssessment, getChallenges } from "../../../services/api";
import { useNavigate } from "react-router-dom";
import RichtextEditor from "../../../components/RichtextEditor";
import SortableTable from "../../../components/Tables/SortableTable";
import MultiSelectDropdown from "../../../components/Dropdowns/MultiSelectDropdown";
import DeleteModal from "../../../components/Modals/DeleteModal";

const { Text } = Typography;
const AddAssessment = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [remainingChallenges, setRemainingChallenges] = useState([]);
  const [searchedChallenges, setSearchedChallenges] = useState([]);
  const [selectedChallenges, setSelectedChallenges] = useState([]);
  const [richtextValue, setRichtextValue] = useState("");
  const [durationType, setDurationType] = useState("Minutes");
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    const data = selectedChallenges?.map((item, i) => ({
      _id: item?._id,
      key: i + 1,
      name: item?.name,
      typeOfChallenge: item?.typeOfChallenge.map((item) => item).join(", "),
      numberOfQuestions: item?.questions?.length,
      tags: item?.tags,
    }));
    setTableData(data);
  }, [selectedChallenges]);

  const onFinish = async (values) => {
    let file = null;
    if (fileList.length) {
      if (fileList[0].status === "done") {
        file = fileList[0].response?.URLs[0];
      }
    }

    if (tableData.length) {
      setIsLoading(true);
      try {
        const challengeIds = tableData.map((item) => item._id);
        const duration =
          durationType === "Minutes"
            ? parseFloat(values?.duration)
            : durationType === "Hours"
            ? parseFloat(values?.duration) * 60
            : parseFloat(values?.duration) * 24 * 60;
        const bodyData = {
          ...values,
          duration,
          challengeIds,
          instructions: richtextValue,
          hintRevealCost: Number(values.hintRevealCost),
          maximumHints: Number(values.maximumHints),
          summary: values.summary,
        };
        if (file) {
          bodyData.image = file;
        }
        await createAssessment(bodyData);
        message.success("Assessment created!");
        navigate("/admin/assessments");
        setIsLoading(false);
      } catch (error) {
        message.error(error?.response?.data?.error);
        setIsLoading(false);
      }
    } else {
      message.error("Kindly attach atleast 1 challenge!");
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const getAllChallenges = async () => {
    try {
      const { data } = await getChallenges();
      setSearchedChallenges(data?.challenges);
      setRemainingChallenges(data?.challenges);
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    getAllChallenges();
  }, []);

  useEffect(() => {
    setSearchedChallenges(remainingChallenges);
  }, [remainingChallenges]);

  const handleChallengeDelete = (record) => {
    setRemainingChallenges([...remainingChallenges, record]);
    const index = selectedChallenges?.findIndex(
      (ind) => ind._id === record?._id
    );
    const arr = [...selectedChallenges];
    arr.splice(index, 1);
    setSelectedChallenges(arr);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <Text>{text}</Text>,
    },
    {
      title: "Challenge Type",
      dataIndex: "typeOfChallenge",
      key: "typeOfChallenge",
      render: (text) => <Text>{text}</Text>,
    },
    {
      title: "Number of questions",
      dataIndex: "numberOfQuestions",
      key: "numberOfQuestions",
    },
    {
      title: "Tags",
      key: "tags",
      dataIndex: "tags",
      render: (_, { tags }) => (
        <>
          {tags.map((tag) => {
            return (
              <Tag color={tag.colorCode} key={tag}>
                {tag.name.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <Space size="middle">
            <DeleteOutlined
              onClick={() => handleChallengeDelete(record)}
              className="hover:text-blue-400"
            />
          </Space>
        );
      },
    },
  ];

  const selectAfter = (
    <Select
      size="large"
      onChange={(val) => setDurationType(val)}
      className="border-none"
      defaultValue={durationType}
    >
      <Select.Option value="Minutes">Minutes</Select.Option>
      <Select.Option value="Hours">Hours</Select.Option>
      <Select.Option value="Days">Days</Select.Option>
    </Select>
  );

  const validateInput = async (_, value) => {
    if (value < 0) {
      return Promise.reject(new Error("Input value cannot be less than 0!"));
    }
    if (value.includes(".")) {
      return Promise.reject(new Error("Decimal is not accepted!"));
    }
    return Promise.resolve();
  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  return (
    <div>
      <Card style={{ width: "100%" }}>
        <>
          <div className="flex justify-between items-center px-3 py-4 w-full">
            <Text className="text-2xl font-semibold font-[#1f2232]">
              Create Assessments
            </Text>
          </div>
          <div className="p-3">
            <Form
              name="basic"
              layout="vertical"
              className="w-full"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <div className="flex flex-wrap">
                <Form.Item
                  label="Title"
                  name="title"
                  className="w-1/2 px-2"
                  rules={[
                    {
                      required: true,
                      message: "Please enter title!",
                    },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
                <Form.Item
                  label="Duration"
                  name="duration"
                  className="w-1/2 px-2"
                  rules={[
                    {
                      required: true,
                      message: "Please enter challenge duration time!",
                    },
                    {
                      message: "Please enter a positive value for duration!",
                      validator: (_, value) => {
                        if (value < 1) {
                          return Promise.reject("Some message here");
                        } else {
                          return Promise.resolve();
                        }
                      },
                    },
                  ]}
                >
                  <Input type="number" size="large" addonAfter={selectAfter} />
                </Form.Item>

                <Form.Item
                  label="Maximum Hints"
                  name="maximumHints"
                  className="w-1/2 px-2"
                  rules={[
                    {
                      required: true,
                      message: "Maximum Hints is required!",
                    },
                    {
                      validator: validateInput,
                    },
                  ]}
                >
                  <Input size="large" type="number" />
                </Form.Item>
                <Form.Item
                  label="Hint Reveal Cost"
                  name="hintRevealCost"
                  className="w-1/2 px-2"
                  rules={[
                    {
                      required: true,
                      message: "Hint Reveal Cost is required!",
                    },
                    {
                      validator: validateInput,
                    },
                  ]}
                >
                  <Input size="large" type="number" />
                </Form.Item>
                <Form.Item
                  label="Instructions"
                  name="instructions"
                  className="w-full px-2"
                >
                  <RichtextEditor
                    richtextValue={richtextValue}
                    setRichtextValue={setRichtextValue}
                  />
                </Form.Item>
                <div className="w-full flex my-2">
                  <Form.Item label="Logo" className="w-full px-2">
                    <Upload
                      accept="image/png, image/jpeg"
                      className="w-full px-2"
                      action={`${process.env.REACT_APP_API_PREFIX}upload`}
                      listType="picture-card"
                      fileList={fileList}
                      onChange={handleChange}
                      maxCount={1}
                      name="attachment"
                    >
                      {fileList.length >= 1 ? null : uploadButton}
                    </Upload>
                  </Form.Item>
                  <Form.Item
                    label="Summary"
                    name="summary"
                    className="w-full px-2"
                  >
                    <Input.TextArea
                      rules={[
                        {
                          required: true,
                          message: "Hint Reveal Cost is required!",
                        },
                      ]}
                      rows={4}
                      minLength={4}
                      placeholder="Summary"
                    />
                  </Form.Item>
                </div>
                <Form.Item
                  label="Attach Challenges (Max 5)"
                  name="challengeIds"
                  className="w-full px-2"
                  rules={[
                    {
                      required: true,
                      message: "Please attach atleast 1 challenge",
                      validator: (_, value) => {
                        if (selectedChallenges) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject("Some message here");
                        }
                      },
                    },
                  ]}
                >
                  <MultiSelectDropdown
                    allChallenges={searchedChallenges}
                    selectedChallenge={selectedChallenges}
                    setSelectedChallenge={setSelectedChallenges}
                  />
                </Form.Item>

                <div className="relative z-10 w-full">
                  <SortableTable
                    columns={columns}
                    dataSource={tableData}
                    setDataSource={setTableData}
                  />
                </div>

                <Form.Item className="flex justify-end w-full mt-3">
                  <Button
                    className="mr-2"
                    onClick={() => setIsModalOpen(true)}
                    size="large"
                  >
                    Cancel
                  </Button>
                  <Button size="large" htmlType="submit">
                    {isLoading ? (
                      <div className="flex items-center">
                        <Spin
                          className="mr-2"
                          indicator={
                            <LoadingOutlined
                              style={{
                                fontSize: 18,
                              }}
                              spin
                            />
                          }
                        />
                        Create
                      </div>
                    ) : (
                      "Create"
                    )}
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </>
      </Card>
      <DeleteModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        deleteAction={() => navigate("/admin/assessments")}
        title="Discard changes"
        actionText="Yes"
        text="Are you sure you want to discard?"
      />
    </div>
  );
};

export default AddAssessment;
