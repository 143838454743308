import { createSlice } from "@reduxjs/toolkit";
import {
  getCompanyDataAPI,
  getUserProfile,
  setUserProfile,
  updateUserProfile,
  validateToken,
} from "../actions/index";

const initialState = {
  user: {},
  authenticated: false,
  loggedIn: false,
  isLoading: false,
  company: {},
};

export const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    resetUserState: (state) => (state = initialState),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserProfile.pending, (state, action) => {
        state.isLoading = true;
        state.authenticated = false;
        state.loggedIn = false;
      })
      .addCase(getUserProfile.fulfilled, (state, { payload }) => {
        state.user = payload.user;
        state.loggedIn = true;
        state.authenticated = true;
        state.isLoading = false;
      })
      .addCase(getUserProfile.rejected, (state, action) => {
        state.isLoading = false;
      });
    builder
      .addCase(getCompanyDataAPI.pending, (state, action) => {
        state.isLoading = true;
        state.authenticated = false;
        state.loggedIn = false;
      })
      .addCase(getCompanyDataAPI.fulfilled, (state, { payload }) => {
        state.company = payload;
        state.loggedIn = true;
        state.authenticated = true;
        state.isLoading = false;
      })
      .addCase(getCompanyDataAPI.rejected, (state, action) => {
        state.isLoading = false;
      });
    builder
      .addCase(validateToken.pending, (state, action) => {
        state.isLoading = true;
        state.authenticated = false;
        state.loggedIn = false;
      })
      .addCase(validateToken.fulfilled, (state, { payload }) => {
        state.user = payload.user;
        state.loggedIn = true;
        state.authenticated = true;
        state.isLoading = false;
      })
      .addCase(validateToken.rejected, (state, action) => {
        state.isLoading = false;
      });
    builder
      .addCase(updateUserProfile.pending, (state, action) => {
        state.isLoading = true;
        state.authenticated = false;
        state.loggedIn = false;
      })
      .addCase(updateUserProfile.fulfilled, (state, { payload }) => {
        state.user = { ...state.user, ...payload.user };
        state.loggedIn = true;
        state.authenticated = true;
        state.isLoading = false;
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.isLoading = false;
      });
    builder
      .addCase(setUserProfile.pending, (state, action) => {
        state.isLoading = true;
        state.authenticated = false;
        state.loggedIn = false;
      })
      .addCase(setUserProfile.fulfilled, (state, { payload }) => {
        state.user = payload.user;
        state.loggedIn = true;
        state.authenticated = true;
        state.isLoading = false;
      })
      .addCase(setUserProfile.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export default userSlice;
