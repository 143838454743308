import React from "react";
import Glass from "../assets/background/Glass.webp";
import CandidateHeader from "../components/Headers/CandidateHeader";

const CandidateLayout = ({ title, button, children }) => {
  return (
    <div>
      <CandidateHeader title={title} button={button} bgURL={Glass} />
      <div>{children}</div>
    </div>
  );
};

export default CandidateLayout;
