import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Input, Modal, Row, Typography, theme } from 'antd';
import { FaCheck } from "react-icons/fa6";

const { Text } = Typography;

const CompaniesModal = ({
    isModalOpen,
    setIsModalOpen,
    action,
    title = "Select Companies",
    allCompanies,
    selectedCompanies,
    setSelectedCompanies
}) => {

    const [searchedCompanies, setSearchedCompanies] = useState([])

    useEffect(() => {
        setSearchedCompanies(allCompanies)
    }, [allCompanies]);

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const {
        token: { colorIcon, colorBgContainer, colorPrimary },
    } = theme.useToken();

    const handleCompanyClick = (company) => {
        const index = selectedCompanies?.findIndex(ind => ind._id === company._id)
        if (index < 0) {
            setSelectedCompanies([...selectedCompanies, company])
        } else {
            const arr = [...selectedCompanies];
            arr.splice(index, 1)
            setSelectedCompanies(arr)
        }
    }

    const handleSearch = (val) => {
        const filteredArr = allCompanies.filter((item) => item?.name.toLowerCase().includes(val.toLowerCase()));
        setSearchedCompanies(filteredArr)
    }

    return (
        <>
            <Modal title={title} open={isModalOpen} footer={false} onCancel={handleCancel}>
                <div>
                    <Row className='px-1' gutter={12}>
                        <Input onChange={(e) => handleSearch(e.target.value)} size='large' placeholder='Search...' />
                    </Row>
                    <Row className='p-2 mt-2 bg-white rounded-lg'>
                        <Col span={24}>
                            {
                                searchedCompanies?.map((item, i) => {
                                    const index = selectedCompanies.findIndex(ind => ind._id === item._id)
                                    return <div onClick={() => handleCompanyClick(item)} className={`${index < 0 ? "bg-white" : "bg-green-200"} shadow-md flex justify-between items-center cursor-pointer px-4 py-1 mt-1 rounded-lg bg-white hover:bg-green-100`} key={i} gutter={12}>
                                        <Text>{item?.name}</Text>
                                        {
                                            index < 0 ? null : <FaCheck />
                                        }
                                    </div>
                                })
                            }
                        </Col>
                    </Row>
                    <Row className='p-2 mt-2' justify={"end"}>
                        <Button onClick={action} size='large'>Submit</Button>
                    </Row>
                </div>
            </Modal>
        </>
    );
};
export default CompaniesModal;