import { Menu } from "antd";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

const SubHeader = ({
  headerType = "profile",
  user,
  isOrganization = false,
}) => {
  const navigate = useNavigate();
  const selectedKey = window.location.pathname.includes("/profile")
    ? "1"
    : window.location.pathname.includes("/password")
    ? "2"
    : window.location.pathname.includes("/billing")
    ? "3"
    : window.location.pathname.includes("/organization")
    ? "4"
    : "5";

  const profileSectionHeader = [
    {
      key: "1",
      label: "Profile",
      onClick: () =>
        navigate(
          user?.roles?.includes("COMPANY_ADMIN")
            ? "/company/admin/profile"
            : "/profile"
        ),
    },
    // {
  ];

  if (localStorage.getItem("isSSO") === "false") {
    profileSectionHeader.push({
      key: "2",
      label: "Password",
      onClick: () =>
        navigate(
          user?.roles?.includes("COMPANY_ADMIN")
            ? "/company/admin/password"
            : "/password"
        ),
    });
    profileSectionHeader.push({
      key: "3",
      label: "MFA",
      onClick: () =>
        navigate(
          user?.roles?.includes("COMPANY_ADMIN") ? "/company/admin/mfa" : "/mfa"
        ),
    });
  }

  if (user && user.roles.includes("COMPANY_ADMIN")) {
    profileSectionHeader.push({
      key: "4",
      label: "Organizations",
      onClick: () => navigate("/company/admin/organization"),
    });
  }

  const menuItems =
    headerType === "profile"
      ? profileSectionHeader
      : [
          {
            key: "1",
            label: "Overwiew",
            // onClick: () => alert("Clicked")
            disabled: true,
          },
          {
            key: "2",
            label: "Leaderboard",
            disabled: true,
          },
          {
            key: "3",
            label: "Score Report",
            disabled: true,
          },
          {
            key: "4",
            label: "Calender",
            disabled: true,
            // onClick: () => alert("Clicked")
          },
        ];
  return (
    <div className="bg-[#616263]">
      <Menu
        theme="dark"
        className="bg-[#616263] ml-10"
        mode="horizontal"
        // selectedKey={[selectedKey]}
        selectedKeys={selectedKey}
        items={menuItems}
        style={{
          flex: 1,
          minWidth: 0,
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SubHeader);
