import React, { createContext, useEffect, useState } from "react";
import useProvideAuth from "../../hooks/useAuth";
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin, message } from "antd";
import { connect } from "react-redux";
import { fetchAuthSession } from "aws-amplify/auth";
import moment from "moment";
import { getCurrentVersion } from "../../services/api";
export const AuthContext = createContext();

function ProvideAuth({ auth, children }) {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const authProvider = useProvideAuth();

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const version = localStorage.getItem("version");
  //       console.log(version, typeof version);
  //       const response = await getCurrentVersion();
  //       if (version !== null && response.data !== version) {
  //         localStorage.clear();
  //         navigate("/login");
  //       }
  //       localStorage.setItem("version", response.data.toString());
  //     } catch (err) {}
  //   })();
  // }, []);
  useEffect(() => {
    (async () => {
      try {
        const user = auth.user;
        if (user && Object.keys(user).length) {
          const session = await fetchAuthSession();
          const accessToken = session?.tokens?.accessToken?.toString();

          localStorage.setItem("accessToken", JSON.stringify(accessToken));

          const loginData = {
            ...user,
            lastLoggedIn: moment(),
            rememberMe: false,
          };
          authProvider.setUser(loginData);
          localStorage.setItem("user", JSON.stringify(loginData));
          const assessmentToken = JSON.parse(
            localStorage.getItem("assessmentToken")
          );
          const cp = localStorage.getItem("lastPath");
          const currentPath = location.pathname;
          if (
            cp === currentPath &&
            !currentPath.includes("sso") &&
            !currentPath.includes("login") &&
            !currentPath.includes("otp") &&
            currentPath !== "/"
          ) {
            navigate(currentPath);
            return;
          }
          localStorage.setItem("lastPath", currentPath);
          if (
            location.pathname.includes("/candidate/invitation/") ||
            (location.pathname.includes(
              "/assessment/invitation/confirmation/"
            ) &&
              !loginData.roles.includes("COMPANY_ADMIN") &&
              !loginData.roles.includes("CANDIDATE_ADMIN") &&
              !loginData.roles.includes("SUPER_ADMIN"))
          ) {
            navigate(currentPath);
            return;
          }

          if (
            assessmentToken === null ||
            assessmentToken === "null" ||
            assessmentToken === undefined ||
            assessmentToken === "" ||
            loginData.roles.includes("COMPANY_ADMIN") ||
            loginData.roles.includes("CANDIDATE_ADMIN") ||
            loginData.roles.includes("SUPER_ADMIN")
          ) {
            let defaultPath;
            if (
              loginData.roles.includes("COMPANY_ADMIN") ||
              loginData.roles.includes("CANDIDATE_ADMIN")
            ) {
              defaultPath = "/company/admin/dashboard";
            } else if (loginData.roles.includes("SUPER_ADMIN")) {
              defaultPath = "/admin/dashboard";
            } else if (loginData.roles.includes("USER")) {
              defaultPath = "/user/assessment-listing";
            }
            if (currentPath !== defaultPath) {
              navigate(defaultPath);
            }
            setLoading(false);
            return;
          }

          navigate(
            `/assessment-details/${localStorage.getItem(
              "assessmentId"
            )}/${localStorage.getItem("companyIdAssessment")}`
          );
          setLoading(false);
        } else {
          if (
            !location.pathname.includes(
              "/assessment/invitation/confirmation"
            ) &&
            !location.pathname.includes("/company/user/confirmation") &&
            !location.pathname.includes("/sso") &&
            !location.pathname.includes("/candidate/invitation")
          ) {
            if (location.pathname.includes("signup")) {
              return;
            } else if (location.pathname.includes("otp")) {
              return;
            }
            // localStorage.removeItem("lastPath");
            navigate("/login");
          }
        }
      } catch (e) {
        message.error("Something went wrong");
      } finally {
        setLoading(false);
      }
    })();
  }, [auth.user]);

  // useEffect(() => {
  //   const lastPath = localStorage.getItem("lastPath");
  //   if (lastPath && lastPath === location.pathname) {
  //     navigate(lastPath);
  //     return;
  //   }
  //   localStorage.setItem("lastPath", location.pathname);
  //   navigate(location.pathname);
  // }, [location.pathname, navigate]);

  if (loading) {
    // Show loading indicator or some placeholder while checking authentication
    return (
      <div
        style={{ height: "100vh" }}
        className="flex justify-center items-center w-full"
      >
        <Spin
          indicator={<LoadingOutlined style={{ fontSize: 100 }} spin />}
          size="large"
        />
      </div>
    );
  }

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}
const mapStateToProps = (state) => ({ auth: state.auth });
export default connect(mapStateToProps, null)(ProvideAuth);
