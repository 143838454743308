// ReadOnlyEditor.js
import React, { useEffect, useState } from 'react';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-text';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/ext-language_tools';

// Function to fetch the content of the txt file
const fetchTxtFileContent = async (filePath) => {
    const response = await fetch(filePath);
    const text = await response.text();
    return text;
};

const ReadOnlyEditor = ({ filePath, size }) => {
    const [content, setContent] = useState('');

    useEffect(() => {
        const loadContent = async () => {
            const text = await fetchTxtFileContent(filePath);
            setContent(text);
        };
        loadContent();
    }, [filePath]);

    return (
        <AceEditor
            style={{
                width: `${size.width}px`,
                height: `${size.height}px`
            }}
            mode="text"
            theme="monokai"
            name="readonly-editor"
            value={content}
            readOnly={true}
            editorProps={{ $blockScrolling: true }}
            setOptions={{
                useWorker: false,
                showLineNumbers: true,
                tabSize: 2,
            }}
            width={`${size.width}px`}
            height={`${size.height}pxs`}
        />
    );
};

export default ReadOnlyEditor;
