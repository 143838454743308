import React from "react";
import Stars from "./../../assets/background/Stars.webp";
import TopPart from "./../../assets/background/TopPart.webp";
import Background from "./../../assets/background/Backgroundblack.png";
import { useLocation } from "react-router-dom";
import "./AuthBackground.css";
import { connect } from "react-redux";

function AuthBackground({ children, bgColor = false, image, user }) {
  const location = useLocation();
  if (user && Object.keys(user).length) {
    if (user.roles?.includes("SUPER_ADMIN")) {
      window.location.href = "/admin/dashboard";
      return;
    } else if (
      user.roles?.includes("COMPANY_ADMIN") ||
      user.roles?.includes("COMPANY_USER")
    ) {
      window.location.href = "/company/admin/dashboard";
    } else if (user.roles?.includes("USER")) {
      // console.log("This onee is sending it");
      // window.location.href = "/user/assessment-listing";
    }
  }
  return (
    <div
      style={{
        background: `url('${Background}')`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        // minWidth: "100vw",
        minHeight: "100vh",
        backgroundAttachment: "fixed", // Make background fixed
        display: "flex",
        flexDirection: "column",
        overflow: "scroll",
      }}
      className={`relative text-white w-full object-cover flex justify-center items-center authpages`}
    >
      <div
        className="fixed bg-red-300 -mt-56"
        style={{
          background:
            location.pathname === "/signup"
              ? `url('${Stars}')`
              : `url('${TopPart}')`,
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: location.pathname === "/signup" ? "600px" : "500px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundAttachment: "fixed", // Make background fixed
        }}
      ></div>
      <div
        style={{ width: "400px" }}
        className="flex flex-col items-center justify-center"
      >
        {image ? (
          image
        ) : (
          <img
            className="w-56"
            src="../assets/logo-dark.png"
            alt="Cyber hire"
          />
        )}
        {children}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({ user: state.auth.user });
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AuthBackground);
