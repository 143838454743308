import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Input, Modal, Row, Typography, theme } from 'antd';
import { FaCheck } from "react-icons/fa6";

const { Text } = Typography;

const AssessmentsModal = ({
    selectedAssessments,
    setSelectedAssessments,
    allAssessments,
    setAllAssessments,
    title = "Select Assessments",
    action,
    isModalOpen,
    setIsModalOpen
}) => {

    const [searchedAssessments, setSearchedAssessments] = useState([])

    useEffect(() => {
        setSearchedAssessments(allAssessments)
    }, [allAssessments]);

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const {
        token: { colorIcon, colorBgContainer, colorPrimary },
    } = theme.useToken();

    const handleAssessmentClick = (assessment) => {
        const index = selectedAssessments?.findIndex(ind => ind._id === assessment._id)
        if (index < 0) {
            setSelectedAssessments([...selectedAssessments, assessment])
        } else {
            const arr = [...selectedAssessments];
            arr.splice(index, 1)
            setSelectedAssessments(arr)
        }
    }

    const handleSearch = (val) => {
        const filteredArr = allAssessments.filter((item) => item?.title.toLowerCase().includes(val.toLowerCase()));
        setSearchedAssessments(filteredArr)
    }

    return (
        <>
            <Modal title={title} open={isModalOpen} footer={false} onCancel={handleCancel}>
                <div>
                    <Row className='px-1' gutter={12}>
                        <Input onChange={(e) => handleSearch(e.target.value)} size='large' placeholder='Search...' />
                    </Row>
                    <Row className='p-2 mt-2 bg-white rounded-lg'>
                        <Col span={24}>
                            {
                                searchedAssessments?.map((item, i) => {
                                    const index = selectedAssessments.findIndex(ind => ind._id === item._id)
                                    return <div onClick={() => handleAssessmentClick(item)} className={`${index < 0 ? "bg-white" : "bg-green-200"} shadow-md flex justify-between items-center cursor-pointer px-4 py-1 mt-1 rounded-lg bg-white hover:bg-green-100`} key={i} gutter={12}>
                                        <Text>{item?.title}</Text>
                                        {
                                            index < 0 ? null : <FaCheck />
                                        }
                                    </div>
                                })
                            }
                        </Col>
                    </Row>
                    <Row className='p-2 mt-2' justify={"end"}>
                        <Button onClick={action} size='large'>Submit</Button>
                    </Row>
                </div>
            </Modal>
        </>
    );
};
export default AssessmentsModal;