import {
  DeleteOutlined,
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Form,
  Input,
  Select,
  Space,
  Spin,
  Tag,
  Typography,
  Upload,
  message,
} from "antd";
import { useEffect, useState } from "react";
import {
  createAssessment,
  getAssessmentById,
  getChallenges,
} from "../../../services/api";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import RichtextEditor from "../../../components/RichtextEditor";
import SortableTable from "../../../components/Tables/SortableTable";
import MultiSelectDropdown from "../../../components/Dropdowns/MultiSelectDropdown";
import DeleteModal from "../../../components/Modals/DeleteModal";

const { Text } = Typography;
const EditAssessment = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [remainingChallenges, setRemainingChallenges] = useState([]);
  const [selectedChallenges, setSelectedChallenges] = useState([]);
  const [assessmentData, setAssessmentData] = useState(null);
  const [richtextValue, setRichtextValue] = useState("");
  const [durationType, setDurationType] = useState("Minutes");
  const [tableData, setTableData] = useState([]);
  const [searchParams] = useSearchParams();
  const assessmentId = searchParams.get("id");
  const location = useLocation();
  const view = location.pathname.includes("view");
  const [fileList, setFileList] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const data = selectedChallenges?.map((item, i) => ({
      _id: item?._id,
      key: i + 1,
      name: item?.name,
      typeOfChallenge: item?.typeOfChallenge.map((item) => item).join(", "),
      numberOfQuestions: item?.questions?.length,
      tags: item?.tags,
    }));
    setTableData(data);
  }, [selectedChallenges]);

  const onFinish = async (values) => {
    let file = null;
    if (fileList.length) {
      if (fileList[0].status === "done") {
        file =
          fileList[0].uid === "-1"
            ? fileList[0].url
            : fileList[0].response?.URLs[0];
      }
    }

    if (tableData.length) {
      setIsLoading(true);
      try {
        const challengeIds = tableData.map((item) => item._id);
        const duration =
          durationType === "Minutes"
            ? parseFloat(values?.duration)
            : durationType === "Hours"
            ? parseFloat(values?.duration) * 60
            : parseFloat(values?.duration) * 24 * 60;
        const bodyData = {
          ...values,
          challengeIds,
          duration,
          instructions: richtextValue,
          maximumHints: Number(values.maximumHints),
          hintRevealCost: Number(values.hintRevealCost),
        };

        if (file) {
          bodyData.image = file;
        }
        await createAssessment(bodyData);
        message.success("Assessment created!");
        navigate("/admin/assessments");
        setIsLoading(false);
      } catch (error) {
        message.error(error.response.data.error);
        setIsLoading(false);
      }
    } else {
      message.error("Kindly attach atleast 1 challenge!");
    }
  };
  const onFinishFailed = (errorInfo) => {};

  const getAllChallenges = async () => {
    try {
      const { data } = await getChallenges();
      setRemainingChallenges(data?.challenges);
    } catch (error) {}
  };

  useEffect(() => {
    getAllChallenges();
  }, []);

  const getAssessment = async () => {
    setIsLoading(true);
    try {
      const { data } = await getAssessmentById(assessmentId);
      setAssessmentData({ ...data, title: "" });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAssessment();
  }, []);

  const [form] = Form.useForm();
  useEffect(() => {
    setRichtextValue(assessmentData?.instructions);
    setSelectedChallenges(assessmentData?.challengesList);
    const duration =
      assessmentData?.duration >= 60 && assessmentData?.duration < 1440
        ? assessmentData?.duration / 60
        : assessmentData?.duration >= 1440
        ? assessmentData?.duration / 60 / 24
        : assessmentData?.duration;
    const typeDuration =
      assessmentData?.duration >= 60 && assessmentData?.duration < 1440
        ? "Hours"
        : assessmentData?.duration >= 1440
        ? "Days"
        : "Minutes";
    setDurationType(typeDuration);
    const fieldsData = {
      title: assessmentData?.title,
      duration,
      hintRevealCost: assessmentData?.hintRevealCost,
      maximumHints: assessmentData?.maximumHints,
      summary: assessmentData?.summary || "",
    };

    if (assessmentData?.image) {
      setFileList([
        {
          uid: "-1",
          name: "logo.png",
          status: "done",
          response: '{"status": "success"}',
          url: assessmentData.image,
        },
      ]);
    }
    form.setFieldsValue(fieldsData);
    // const challengesListIds = new Set(
    //   assessmentData?.challengesList?.map((challenge) => challenge._id)
    // );

    // const updatedRemainingChallenges = remainingChallenges.filter(
    //   (challenge) => !challengesListIds.has(challenge._id)
    // );
    // setRemainingChallenges(updatedRemainingChallenges);
  }, [assessmentData, form]);

  const handleChallengeDelete = (record) => {
    // setRemainingChallenges([...remainingChallenges, record]);
    const index = selectedChallenges?.findIndex(
      (ind) => ind._id === record?._id
    );
    const arr = [...selectedChallenges];
    arr.splice(index, 1);
    setSelectedChallenges(arr);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <Text>{text}</Text>,
    },
    {
      title: "Challenge Type",
      dataIndex: "typeOfChallenge",
      key: "typeOfChallenge",
      render: (text) => <Text>{text}</Text>,
    },
    {
      title: "Number of questions",
      dataIndex: "numberOfQuestions",
      key: "numberOfQuestions",
      render: (text) => <Typography.Text>{text || 0}</Typography.Text>,
    },
    {
      title: "Tags",
      key: "tags",
      dataIndex: "tags",
      render: (_, { tags }) => (
        <>
          {tags.map((tag) => {
            return (
              <Tag className="my-1" color={tag.colorCode} key={tag}>
                {tag.name.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <Space size="middle">
            {/* <EditOutlined className="hover:text-blue-400" onClick={() => navigate(`/admin/challenge/edit?id=${record?._id}`)} /> */}
            <DeleteOutlined
              onClick={() => handleChallengeDelete(record)}
              className="hover:text-blue-400"
            />
          </Space>
        );
      },
    },
  ];

  const selectAfter = (
    <Select
      size="large"
      onChange={(val) => setDurationType(val)}
      className="border-none"
      value={durationType}
      disabled={view}
    >
      <Select.Option value="Minutes">Minutes</Select.Option>
      <Select.Option value="Hours">Hours</Select.Option>
      <Select.Option value="Days">Days</Select.Option>
    </Select>
  );
  const validateInput = async (_, value) => {
    if (value < 0) {
      return Promise.reject(new Error("Input value cannot be less than 0!"));
    }
    return Promise.resolve();
  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );
  return (
    <div>
      <Card style={{ width: "100%" }}>
        <>
          {view ? null : (
            <div className="flex justify-between items-center px-3 py-4 w-full">
              <Text className="text-2xl font-semibold font-[#1f2232]">
                Duplicate Assessments
              </Text>
            </div>
          )}
          <div className="p-3">
            <Form
              form={form}
              name="basic"
              layout="vertical"
              className="w-full"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <div className="flex flex-wrap">
                <Form.Item
                  label="Title"
                  name="title"
                  className="w-1/2 px-2"
                  rules={[
                    {
                      required: true,
                      message: "Please enter title!",
                    },
                  ]}
                >
                  <Input disabled={view} size="large" />
                </Form.Item>
                <Form.Item
                  label="Duration"
                  name="duration"
                  className="w-1/2 px-2"
                  rules={[
                    {
                      required: true,
                      message: "Please enter challenge duration time!",
                    },
                    {
                      message: "Please enter a positive value for duration!",
                      validator: (_, value) => {
                        if (value < 1) {
                          return Promise.reject("Some message here");
                        } else {
                          return Promise.resolve();
                        }
                      },
                    },
                  ]}
                >
                  <Input
                    disabled={view}
                    type="number"
                    size="large"
                    addonAfter={selectAfter}
                  />
                </Form.Item>
                <Form.Item
                  label="Maximum Hints"
                  name="maximumHints"
                  className="w-1/2 px-2"
                  rules={[
                    {
                      required: true,
                      message: "Maximum Hints is required!",
                    },
                    {
                      validator: validateInput,
                    },
                  ]}
                >
                  <Input disabled={view} type="number" size="large" />
                </Form.Item>
                <Form.Item
                  label="Hint Reveal Cost"
                  name="hintRevealCost"
                  className="w-1/2 px-2"
                  rules={[
                    {
                      required: true,
                      message: "Hint Reveal Cost is required!",
                    },
                    {
                      validator: validateInput,
                    },
                  ]}
                >
                  <Input disabled={view} type="number" size="large" />
                </Form.Item>
                <Form.Item
                  label="Instructions"
                  name="instructions"
                  className="w-full px-2"
                >
                  <RichtextEditor
                    readOnly={view}
                    richtextValue={richtextValue}
                    setRichtextValue={setRichtextValue}
                  />
                </Form.Item>
                <div className="w-full flex my-2">
                  <Form.Item label="Logo" className="w-full px-2">
                    <div className="w-full flex justify-start items-center gap-4">
                      {fileList?.length && fileList[0].uid === "-1" ? (
                        <img
                          style={{
                            width: "100px",
                            height: "100px",
                          }}
                          src={fileList[0].url}
                        />
                      ) : null}
                      <Upload
                        accept="image/png, image/jpeg"
                        defaultFileList={fileList}
                        className="w-full px-2"
                        action={`${process.env.REACT_APP_API_PREFIX}upload`}
                        listType="picture-card"
                        fileList={
                          fileList?.length && fileList[0].uid === "-1"
                            ? []
                            : fileList
                        }
                        onChange={handleChange}
                        maxCount={1}
                        multiple={false}
                        name="attachment"
                      >
                        {fileList?.length && fileList[0].uid === "-1" ? (
                          <Button icon={<UploadOutlined />}>Edit</Button>
                        ) : (
                          uploadButton
                        )}
                      </Upload>
                    </div>
                  </Form.Item>
                  <Form.Item
                    label="Summary"
                    name="summary"
                    className="w-full px-2"
                  >
                    <Input.TextArea
                      rules={[
                        {
                          required: true,
                          message: "Hint Reveal Cost is required!",
                        },
                      ]}
                      rows={4}
                      minLength={4}
                      placeholder="Summary"
                    />
                  </Form.Item>
                </div>
                {view ? null : (
                  <Form.Item
                    label="Attach Challenges (Max 5)"
                    name="challengeIds"
                    className="w-full px-2"
                  >
                    <MultiSelectDropdown
                      disabled={view}
                      allChallenges={remainingChallenges}
                      selectedChallenge={selectedChallenges}
                      setSelectedChallenge={setSelectedChallenges}
                    />
                  </Form.Item>
                )}

                {view ? (
                  <Typography.Text
                    style={{ fontWeight: "bold", fontSize: "16px" }}
                  >
                    Challenges
                  </Typography.Text>
                ) : null}

                <div className="relative z-10 w-full">
                  <SortableTable
                    columns={columns}
                    dataSource={tableData}
                    setDataSource={setTableData}
                  />
                </div>

                <Form.Item className="flex justify-end w-full">
                  <Button
                    className="mr-2"
                    onClick={() => {
                      navigate(-1);
                      // setIsModalOpen(true)
                    }}
                    size="large"
                  >
                    {view ? "Back" : "Cancel"}
                  </Button>
                  {view ? null : (
                    <Button size="large" htmlType="submit">
                      {isLoading ? (
                        <div className="flex items-center">
                          <Spin
                            className="mr-2"
                            indicator={
                              <LoadingOutlined
                                style={{
                                  fontSize: 18,
                                }}
                                spin
                              />
                            }
                          />
                          Save
                        </div>
                      ) : (
                        "Save"
                      )}
                    </Button>
                  )}
                </Form.Item>
              </div>
            </Form>
          </div>
        </>
      </Card>
      <DeleteModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        deleteAction={() => navigate("/admin/assessments")}
        title="Discard changes"
        actionText="Yes"
        text="Are you sure you want to discard?"
      />
    </div>
  );
};

export default EditAssessment;
