import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Include the Quill theme CSS

function RichtextEditor({ richtextValue, setRichtextValue, readOnly }) {
  const [value, setValue] = useState(richtextValue);

  useEffect(() => {
    setRichtextValue(value);
  }, [value]);
  //DONOT SAVE THIS FILE, IF SAVED THE ENCLOSED BELOW KEYS IN QUOTES " "
  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"], // toggled buttons
      ["blockquote", "code-block"],

      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ direction: "rtl" }], // text direction

      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
      // [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      // [{ "font": [] }],
      [{ align: [] }],

      ["clean"], // remove formatting button
      ["link"], // link
    ],
  };

  // Formats corresponding to the modules.toolbar
  const formats = [
    "font",
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "code-block",
    "list",
    "bullet",
    "script",
    "indent",
    "direction",
    "size",
    // "header",
    "color",
    "background",
    // "font",
    "align",
    "clean",
    "link",
    // "video",
  ];

  return (
    <div className="h-56 pb-10">
      <ReactQuill
        style={{ height: "100%" }}
        theme="snow"
        value={richtextValue}
        onChange={setRichtextValue}
        modules={modules}
        formats={formats}
        readOnly={readOnly}
      />
    </div>
  );
}

export default RichtextEditor;
