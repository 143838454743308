import { Button, Divider, Form, Input, message } from "antd";
import "./Auth.css";
import AuthBackground from "../../components/Backgrounds/AuthBackground";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { connect } from "react-redux";
import { getUserProfile } from "../../store/actions";
import { getCurrentUser, signInWithRedirect } from "aws-amplify/auth";
import useProvideAuth from "../../hooks/useAuth";

function Login({ authData, getUserProfile }) {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  let token = searchParams.get("token");
  const authProvider = useProvideAuth();
  useEffect(() => {
    // Clear input values on component mount
    form.setFieldsValue({
      email: "",
      password: "",
    });
  }, []);

  const handleLogin = async (values) => {
    setIsLoading(true);
    try {
      const { nextStep } = await authProvider.signInWithCognito(values);
      localStorage.setItem("assessmentToken", JSON.stringify(token));
      if (nextStep.signInStep === "DONE") {
        const currentUser = await getCurrentUser();
        localStorage.setItem("isSSO", false);
        getUserProfile(currentUser.userId);
        return;
      }

      if (nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_TOTP_CODE") {
        navigate("/otp?type=LOGIN");
        return;
      }
      if (nextStep.signInStep === "CONFIRM_SIGN_UP") {
        navigate("/otp?type=SIGNUP", {
          state: {
            email: values.email,
            password: values.password,
            returnPath: "/login",
          },
        });
        return;
      }
      message.warning(nextStep.signInStep);
    } catch (error) {
      message.error(
        error?.response?.data?.error || error?.message || "Something went wrong"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleIDPClick = async (provider) => {
    localStorage.setItem("assessmentToken", JSON.stringify(token));
    signInWithRedirect({
      provider: { custom: provider },
    });
  };

  return (
    <AuthBackground>
      <div className="w-full flex flex-col items-center gap-5 mt-2">
        <p className="title m-0 p-0">Welcome Back</p>
        <p className="description-2 m-0">Please enter your details</p>
        <div className="w-full">
          <Form
            form={form}
            layout="vertical"
            className="w-full"
            onFinish={handleLogin}
          >
            <Form.Item
              label="Email Address"
              name="email"
              className="w-full custom-form-item" // Add a custom class to the Form.Item
              rules={[
                {
                  required: true,
                  message: "Please enter email!",
                },
              ]}
            >
              <Input
                autoComplete="off"
                className="w-full input"
                size="large"
                style={{ borderColor: "#E6E6EB10" }}
                placeholder="Enter your email"
              />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              className="w-full custom-form-item"
              labelCol={{ style: { color: "#FFFFFF" } }}
              rules={[{ required: true, message: "Please enter password!" }]}
            >
              <Input.Password
                autoComplete="off"
                className="custom-input input password"
                size="large"
                style={{ borderColor: "#E6E6EB10" }}
                placeholder="Please enter your password"
              />
            </Form.Item>
            <div className="w-full flex justify-start">
              <Button
                type="link"
                className="text-left p-0"
                onClick={() => navigate("/reset-password")}
              >
                <p
                  style={{
                    color: "#FFFFFF",
                  }}
                >
                  Forgot password?
                </p>
              </Button>
            </div>
            <Button
              size="large"
              htmlType="submit"
              style={{
                height: "44px",
                fontSize: "16px",
                fontWeight: 500,
                border: "none",
              }}
              loading={isLoading}
              // disabled={isLoading}
              type="primary"
              className="w-full mt-6"
            >
              Sign in
            </Button>
            <div className="relative mt-8">
              <Divider
                className="absolute top-0"
                style={{ margin: 0, borderColor: "#E6E6EB", opacity: "10%" }}
              />
              <div
                style={{ marginTop: -11 }}
                className="absolute w-full text-center"
              >
                <div className="">
                  <span className="text-[#E6E6EB] bg-[#010c07] px-2">OR</span>
                </div>
              </div>
            </div>
            <SSOButton
              onClick={() => handleIDPClick("Google")}
              className="mt-16"
            >
              <img
                src="./assets/icons/google.webp"
                style={{ width: "22px" }}
                alt="google"
                className="mr-2"
              />
              Sign in with Google
            </SSOButton>
            <SSOButton
              onClick={() => handleIDPClick("Linkedin")}
              className="mt-8"
            >
              <img
                src="./assets/icons/linkedin.png"
                style={{ width: "28px" }}
                alt="google"
                className="mr-2"
              />
              Sign in with LinkedIn
            </SSOButton>
            {/* <SSOButton
              onClick={() => handleIDPClick("Microsoft")}
              className="mt-8"
            >
              <img
                src="./assets/icons/microsoft.png"
                style={{ width: "20px" }}
                alt="google"
                className="mr-2"
              />
              Sign in with Microsoft
            </SSOButton> */}
            {/* <div className="w-full flex justify-center items-center mt-8">
              <p className="description-2">Don't have an account?</p>
              <Button
                type="link"
                className="flex items-center p-0"
                onClick={() => navigate("/signup")}
                style={{ margin: 0 }}
              >
                <p
                  className="ml-1 underline"
                  style={{
                    color: "#FFFFFF",
                  }}
                >
                  Sign Up
                </p>
              </Button>
            </div> */}
          </Form>
        </div>
      </div>
    </AuthBackground>
  );
}

const mapStateToProps = (state) => ({ authData: state.auth });
const mapDispatchToProps = { getUserProfile };

export default connect(mapStateToProps, mapDispatchToProps)(Login);

const SSOButton = ({ children, onClick, className }) => (
  <Button
    size="large"
    onClick={onClick}
    style={{
      height: "44px",
      fontSize: "16px",
      fontWeight: 500,
      border: "none",
    }}
    className={`text-[#FFFFFF] w-full bg-[#343D39] flex items-center justify-center ${className}`}
  >
    {children}
  </Button>
);
