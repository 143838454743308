import { Button, Form, Input, message } from "antd";
import "./Auth.css";
import AuthBackground from "../../components/Backgrounds/AuthBackground";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { resetPassword, resendSignUpCode } from "@aws-amplify/auth";

function ResetPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    // Clear input values on component mount
    form.setFieldsValue({
      email: "",
      password: "",
    });
  }, []);

  const handleResetPassword = async (values) => {
    setIsLoading(true);
    try {
      await resetPassword({ username: values.email });
      message.success("A confirmation code has been sent to your email");
      navigate(`/new-password?email=${values.email}`);
    } catch (error) {
      if (error.message.includes("verified")) {
        await resendSignUpCode({ username: values.email });
        message.error(
          "Your email is not verified. A new verification code is sent to your email. Please confirm your email address."
        );
        navigate("/OTP?type=FORGOT_PASSWORD", {
          state: {
            email: values.email,
          },
        });
        return;
      }
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthBackground>
      <div className="w-full flex flex-col items-center mt-7">
        <pt className="title">Reset Your Password</pt>

        <div className="w-full mt-8">
          <Form
            form={form}
            layout="vertical"
            className="w-full"
            onFinish={handleResetPassword}
          >
            <Form.Item
              label="Email Address"
              name="email"
              className="w-full custom-form-item" // Add a custom class to the Form.Item
              rules={[
                {
                  type: "email",
                  message: "Email not valid!",
                },
                {
                  required: true,
                  message: " ",
                },
              ]}
            >
              <Input
                autoComplete="off"
                className="w-full input"
                size="large"
                style={{ borderColor: "#E6E6EB10" }}
                placeholder="Enter your email"
              />
            </Form.Item>

            <Button
              size="large"
              htmlType="submit"
              style={{
                height: "44px",
                fontSize: "16px",
                fontWeight: 500,
                border: "none",
              }}
              loading={isLoading}
              // disabled={isLoading}
              type="primary"
              className="w-full"
            >
              Reset Password
            </Button>
            <div className="w-full flex justify-center mt-8">
              <Button
                type="link"
                className="flex items-center"
                onClick={() => navigate("/login")}
              >
                <img
                  src="assets/icons/Back.png"
                  style={{ width: "18px", height: "12px" }}
                  alt="back to sign in"
                />
                <pt
                  className="ml-2"
                  style={{
                    color: "#FFFFFF",
                  }}
                >
                  Back to Sign In
                </pt>
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </AuthBackground>
  );
}

export default ResetPassword;
