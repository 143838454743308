import React from "react";
import Glass from "../assets/background/Glass.webp";
import CandidateDashboardHeader from "../components/Headers/CandidateDashboardHeader";

const CandidateDashboardLayout = ({ title, button, children }) => {
  return (
    <div>
      <CandidateDashboardHeader title={title} button={button} bgURL={Glass} />
      {children}
    </div>
  );
};

export default CandidateDashboardLayout;
