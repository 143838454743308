import React, { useState } from 'react';
import { message, Button } from 'antd';
import { Typography } from 'antd';
import UserService from "../../../../services/api/user";
import "./index.css"
import { connect } from 'react-redux';
import { ChallengeSlice } from '../../../../store/slices';

const { Text } = Typography;

const Hint = ({ assessment, selectedChallenge, currentQuestion, setCurrentScore, decrementHintsCount, hintRevealCost }) => {
    const [revealHint, setRevealHint] = useState(false)
    const [hintText, setHintText] = useState("")
    const resultId = assessment._id

    if (!selectedChallenge) {
        return <></>
    }

    const handleRedeemHint = async () => {
        try {
            const response = await UserService.assessmentChallenge.redeemHint(resultId, selectedChallenge.questions[currentQuestion]._id, selectedChallenge._id)
            setRevealHint(true)
            setHintText(response?.data?.result?.hint || "No hint found")
            setCurrentScore(hintRevealCost)
            decrementHintsCount()
        } catch (err) {
            message.error(err?.response?.data?.error || err?.message || "Something went wrong")
        }
    }
    return (
        <div style={{ display: "flex", padding: "30px 40px", flexDirection: "column", gap: 5, backgroundColor: "transparent", justifyContent: "center", alignItems: "center" }}>
            <p style={{
                fontSize: "24px",
                fontWeight: "600",
                lineHeight: "36px",
                textAlign: "center",
            }}>Hint</p>
            <p style={{
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
                textAlign: "center",
                color: "#888A8D",
            }}>When Redeeming the hint you will be prompted asking if you wish to redeem the hint. Upon agreeing, he points listed will be deducted from your score and the hint will be reveled.</p>


            {!revealHint ?

                <Button
                    size="large"
                    style={{
                        marginTop: "2%",
                        height: "46px",
                        padding: "8px 16px 8px 16px",
                        gap: "8px",
                        borderRadius: "8px",
                        background: "#070908",
                        fontSize: "16px",
                        fontWeight: "600",
                        lineHeight: "24px",
                        textAlign: "center",
                        width: "100%",
                        color: "white"

                    }}
                    onClick={handleRedeemHint}
                >
                    Reveal Technique
                    (Cost: {hintRevealCost || 10})
                </Button>
                :
                <div className="message mt-5">
                    <Text type="success">
                        {hintText}
                    </Text>
                </div>}
        </div>
    );
};

const mapStateToProps = (state) => ({
    assessment: state.challenge.assessment,
    selectedChallenge: state.challenge.selectedChallenge,

});
const mapDispatchToProps = {
    setCurrentScore: ChallengeSlice.actions.setCurrentScore,
    decrementHintsCount: ChallengeSlice.actions.decrementHintsCount,
};

export default connect(mapStateToProps, mapDispatchToProps)(Hint);
