import React, { useContext, useState } from "react";
import { Avatar, Badge, Dropdown } from "antd";
import {
  BellOutlined,
  LogoutOutlined,
  PhoneOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import Img from "./../../assets/placeholder.png";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/Auth";
import useProvideAuth from "../../hooks/useAuth";
import NotificationsModal from "../NotificationModal";
import { IoSettingsOutline } from "react-icons/io5";
import { connect } from "react-redux";
import { UserSlice } from "../../store/slices";

const UserProfileComponent = ({
  userName,
  userImage,
  notification,
  nameColor = "#1E1D1D",
  customProfileMenu,
  noLogout = false,
  resetUserState,
}) => {
  const navigate = useNavigate();
  const auth = useProvideAuth();

  const [showNotification, setShowNotification] = useState(false);

  const profileMenu = [
    {
      key: "1",
      label: (
        <div
          onClick={() => {
            navigate("/settings");
          }}
          className="flex items-center gap-2 py-2"
        >
          <IoSettingsOutline
            style={{
              color: "#070908",
            }}
          />
          <p className="text-blue-900">User Settings</p>
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div
          onClick={() => {
            window.open("https://support.cyber-hire.com");
          }}
          className="flex items-center gap-2 py-2"
        >
          <PhoneOutlined
            style={{
              color: "#070908",
            }}
          />
          <p className="text-blue-900">Support</p>
        </div>
      ),
    },
    // {
    //   key: "2",
    //   label: (
    //     <div
    //       onClick={() => {
    //         setIsOpenMFAModal(true);
    //       }}
    //     >
    //       <p className="text-blue-900">MFA</p>
    //     </div>
    //   ),
    // },
  ];

  if (!noLogout) {
    profileMenu.push({
      key: "2",
      label: (
        <div
          onClick={() => auth.signOut(resetUserState)}
          className="flex items-center gap-2 py-2"
        >
          <LogoutOutlined
            style={{
              color: "#070908",
            }}
          />
          <p className="text-blue-900">Logout</p>
        </div>
      ),
    });
  }

  return (
    <div className="user-profile">
      <div>
        <Badge
          className="cursor-pointer"
          onClick={() => setShowNotification(true)}
        >
          {/* <Avatar icon={<BellOutlined />} /> */}
          <div
            style={{
              backgroundColor: "#E0E0E0",
            }}
            className={"icon-style"}
          >
            <BellOutlined
              style={{
                color: "#888A8D",
                fontSize: 18,
              }}
            />
          </div>
        </Badge>
        {showNotification ? (
          <NotificationsModal handleClose={() => setShowNotification(false)} />
        ) : null}
      </div>

      <Dropdown
        trigger={["click"]}
        menu={{
          items: customProfileMenu ? customProfileMenu : profileMenu,
        }}
        overlayClassName="user-info-drop-down"
      >
        <div className="w-full flex items-center justify-between cursor-pointer">
          <img
            style={{
              width: "34px",
              height: "34px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
            src={userImage || Img}
            alt="Avatar"
          />
          <span style={{ color: nameColor }} className="user-name">
            {userName}
          </span>
        </div>
      </Dropdown>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = {
  resetUserState: UserSlice.actions.resetUserState,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfileComponent);
