import React from "react";
import { Button, Modal, Typography } from "antd";
import trashIcon from "../../assets/trash.jpg";

const { Text } = Typography;

const DeleteModal = ({
  isModalOpen,
  setIsModalOpen,
  deleteAction,
  title = "Delete Record",
  text = "Are you sure you want to delete?",
  actionText = "Delete",
  isDelete = false,
}) => {
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Modal open={isModalOpen} footer={false} onCancel={handleCancel}>
        <div
          style={{
            display: "flex",
            padding: "30px 40px",
            flexDirection: "column",
            gap: 5,
            backgroundColor: "transparent",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {isDelete ? (
            <img
              style={{ width: "56px", height: "56px" }}
              src={trashIcon}
              alt="trash"
            />
          ) : null}
          <p
            style={{
              fontSize: "24px",
              fontWeight: "600",
              lineHeight: "36px",
              textAlign: "center",
            }}
          >
            {title}
          </p>

          <div className="py-3">
            <Text
              style={{
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
                textAlign: "center",
                color: "#888A8D",
              }}
              className="text-lg"
            >
              {text}
            </Text>
          </div>

          <Button
            style={{
              marginTop: "2%",
              height: "46px",
              padding: "8px 16px 8px 16px",
              gap: "8px",
              borderRadius: "8px",
              background: "#070908",
              fontSize: "16px",
              fontWeight: "600",
              lineHeight: "24px",
              textAlign: "center",
              width: "100%",
              color: "white",
            }}
            size="large"
            onClick={deleteAction}
          >
            {actionText}
          </Button>
          <Button
            style={{
              height: "46px",
              padding: "8px 16px 8px 16px",
              gap: "8px",
              borderRadius: "8px",
              fontSize: "16px",
              fontWeight: "600",
              lineHeight: "24px",
              textAlign: "center",
              width: "100%",
              marginTop: "2%",
            }}
            size="large"
            onClick={() => setIsModalOpen(false)}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </>
  );
};
export default DeleteModal;
