import AdminLayoutComponent from "../components/AdminLayoutComponent";
import { LuLayoutDashboard, LuScrollText } from "react-icons/lu";
import { PiUsers } from "react-icons/pi";
import { GrUserAdmin } from "react-icons/gr";
import { RiAdminLine } from "react-icons/ri";
import { SiAwsorganizations } from "react-icons/si";
import { TbTags } from "react-icons/tb";
import { MdOutlineAssessment, MdOutlineFeedback } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Badge, Typography } from "antd";
import { useState, useEffect } from "react";
import { IoSettingsOutline } from "react-icons/io5";
import { LuLayoutTemplate } from "react-icons/lu";
import { LogoutOutlined } from "@ant-design/icons";
import useProvideAuth from "../hooks/useAuth";
import { connect } from "react-redux";
import { StyledLoader } from "../components/loader";
import { UserSlice } from "../store/slices";
import ProvideAuth from "../context/Auth";

const { Text } = Typography;

const allPages = (currentPath, auth, resetUserState) => [
  {
    key: "1",
    icon: (
      <div
        className={`icon-background ${
          currentPath.includes("admin/dashboard")
            ? "icon-background-selected"
            : "icon-background-unselected"
        }`}
      >
        <LuLayoutDashboard
          style={{
            color: currentPath.includes("/admin/dashboard")
              ? "black"
              : "#888A8D",
          }}
        />
      </div>
    ),
    label: (
      <Link to="/admin/dashboard">
        <div className="flex items-center justify-between">
          <Text
            style={{
              color: currentPath.includes("/admin/dashboard")
                ? "white"
                : "#888A8D",
              fontSize: "16px",
            }}
          >
            Dashboard
          </Text>{" "}
          <Badge
            className="site-badge-count-109"
            count={0}
            style={{ backgroundColor: "#c3ebda", color: "white" }}
          />
        </div>
      </Link>
    ),
    style: currentPath.includes("/admin/dashboard")
      ? { backgroundColor: "transparent" }
      : null,
  },
  {
    key: "2",
    icon: (
      <div
        className={`icon-background ${
          currentPath.includes("admin/company-admins") ||
          currentPath.includes("admin/super-admins")
            ? "icon-background-selected"
            : "icon-background-unselected"
        }`}
      >
        <PiUsers
          style={{
            color:
              currentPath.includes("/admin/company-admins") ||
              currentPath.includes("/admin/super-admins")
                ? "black"
                : "#888A8D",
          }}
        />
      </div>
    ),
    label: (
      <div className="flex items-center justify-between">
        <Text
          style={{
            color:
              currentPath.includes("/admin/company-admins") ||
              currentPath.includes("/admin/super-admins")
                ? "white"
                : "#888A8D",
            fontSize: "16px",
          }}
        >
          Users
        </Text>{" "}
        <Badge
          className="site-badge-count-109"
          count={0}
          style={{ backgroundColor: "#1420ff", color: "white" }}
        />
      </div>
    ),
    children: [
      {
        key: "2-1",
        icon: (
          <div
            className={`icon-background ${
              currentPath.includes("admin/company-admins")
                ? "icon-background-selected"
                : "icon-background-unselected"
            }`}
          >
            <GrUserAdmin
              style={{
                color: currentPath.includes("/admin/company-admins")
                  ? "black"
                  : "#888A8D",
              }}
            />
          </div>
        ),
        label: (
          <Link to="/admin/company-admins">
            <div className="flex items-center justify-between">
              <Text
                style={{
                  color: currentPath.includes("/admin/company-admins")
                    ? "white"
                    : "#888A8D",
                  fontSize: "16px",
                }}
              >
                Company Admins
              </Text>{" "}
              <Badge
                className="site-badge-count-109"
                count={0}
                style={{ backgroundColor: "#1420ff", color: "white" }}
              />
            </div>
          </Link>
        ),
        style: currentPath.includes("/admin/company-admins")
          ? { backgroundColor: "transparent" }
          : null,
      },
      {
        key: "2-2",
        icon: (
          <div
            className={`icon-background ${
              currentPath.includes("admin/super-admins")
                ? "icon-background-selected"
                : "icon-background-unselected"
            }`}
          >
            <RiAdminLine
              style={{
                color: currentPath.includes("/admin/super-admins")
                  ? "black"
                  : "#888A8D",
              }}
            />
          </div>
        ),
        label: (
          <Link to="/admin/super-admins">
            <div className="flex items-center justify-between">
              <Text
                style={{
                  color: currentPath.includes("/admin/super-admins")
                    ? "white"
                    : "#888A8D",
                  fontSize: "16px",
                }}
              >
                Super Admins
              </Text>{" "}
              <Badge
                className="site-badge-count-109"
                count={0}
                style={{ backgroundColor: "#1420ff", color: "white" }}
              />
            </div>
          </Link>
        ),
        style: currentPath.includes("/admin/super-admins")
          ? { backgroundColor: "transparent" }
          : null,
      },
    ],
    style: currentPath.includes("/admin/settings")
      ? { backgroundColor: "transparent" }
      : null,
  },
  {
    key: "4",
    icon: (
      <div
        className={`icon-background ${
          (currentPath.includes("/admin/companies") ||
            currentPath.includes("/admin/company")) &&
          !currentPath.includes("company-admins")
            ? "icon-background-selected"
            : "icon-background-unselected"
        }`}
      >
        <SiAwsorganizations
          style={{
            color:
              (currentPath.includes("/admin/companies") ||
                currentPath.includes("/admin/company")) &&
              !currentPath.includes("company-admins")
                ? "black"
                : "#888A8D",
          }}
        />
      </div>
    ),
    label: (
      <Link to="/admin/companies">
        <div className="flex items-center justify-between">
          <Text
            style={{
              color:
                (currentPath.includes("/admin/companies") ||
                  currentPath.includes("/admin/company")) &&
                !currentPath.includes("company-admins")
                  ? "white"
                  : "#888A8D",
              fontSize: "16px",
            }}
          >
            Companies
          </Text>{" "}
          <Badge
            className="site-badge-count-109"
            count={0}
            style={{ backgroundColor: "#1420ff", color: "white" }}
          />
        </div>
      </Link>
    ),
    style:
      (currentPath.includes("/admin/companies") ||
        currentPath.includes("/admin/company")) &&
      !currentPath.includes("company-admins")
        ? { backgroundColor: "transparent" }
        : null,
  },
  {
    key: "5",
    icon: (
      <div
        className={`icon-background ${
          currentPath.includes("/admin/tags")
            ? "icon-background-selected"
            : "icon-background-unselected"
        }`}
      >
        <TbTags
          style={{
            color: currentPath.includes("/admin/tags") ? "black" : "#888A8D",
          }}
        />
      </div>
    ),
    label: (
      <Link to="/admin/tags">
        <div className="flex items-center justify-between">
          <Text
            style={{
              color: currentPath.includes("/admin/tags") ? "white" : "#888A8D",
              fontSize: "16px",
            }}
          >
            Tags
          </Text>{" "}
          <Badge
            className="site-badge-count-109"
            count={0}
            style={{ backgroundColor: "#1420ff", color: "white" }}
          />
        </div>
      </Link>
    ),
    style: currentPath.includes("/admin/tags")
      ? { backgroundColor: "transparent" }
      : null,
  },
  {
    key: "6",
    icon: (
      <div
        className={`icon-background ${
          currentPath.includes("/admin/challenge")
            ? "icon-background-selected"
            : "icon-background-unselected"
        }`}
      >
        <LuScrollText
          style={{
            color: currentPath.includes("/admin/challenge")
              ? "black"
              : "#888A8D",
          }}
        />
      </div>
    ),
    label: (
      <Link to="/admin/challenges">
        <div className="flex items-center justify-between">
          <Text
            style={{
              color: currentPath.includes("/admin/challenge")
                ? "white"
                : "#888A8D",
              fontSize: "16px",
            }}
          >
            Challenges
          </Text>{" "}
          <Badge
            className="site-badge-count-109"
            count={0}
            style={{ backgroundColor: "#1420ff", color: "white" }}
          />
        </div>
      </Link>
    ),
    style: currentPath.includes("/admin/challenge")
      ? { backgroundColor: "transparent" }
      : null,
  },
  {
    key: "7",
    icon: (
      <div
        className={`icon-background ${
          currentPath.includes("/admin/assessment")
            ? "icon-background-selected"
            : "icon-background-unselected"
        }`}
      >
        <MdOutlineAssessment
          style={{
            color: currentPath.includes("/admin/assessment")
              ? "black"
              : "#888A8D",
          }}
        />
      </div>
    ),
    label: (
      <Link to="/admin/assessments">
        <div className="flex items-center justify-between">
          <Text
            style={{
              color: currentPath.includes("/admin/assessment")
                ? "white"
                : "#888A8D",
              fontSize: "16px",
            }}
          >
            Assessments
          </Text>{" "}
          <Badge
            className="site-badge-count-109"
            count={0}
            style={{ backgroundColor: "#1420ff", color: "white" }}
          />
        </div>
      </Link>
    ),
    style: currentPath.includes("/admin/assessment")
      ? { backgroundColor: "transparent" }
      : null,
  },
  {
    key: "8",
    icon: (
      <div
        className={`icon-background ${
          currentPath.includes("/admin/feedback")
            ? "icon-background-selected"
            : "icon-background-unselected"
        }`}
      >
        <MdOutlineFeedback
          style={{
            color: currentPath.includes("/admin/feedback")
              ? "black"
              : "#888A8D",
          }}
        />
      </div>
    ),
    label: (
      <Link to="/admin/feedback">
        <div className="flex items-center justify-between">
          <Text
            style={{
              color: currentPath.includes("/admin/feedback")
                ? "white"
                : "#888A8D",
              fontSize: "16px",
            }}
          >
            Feedback
          </Text>{" "}
          <Badge
            className="site-badge-count-109"
            count={0}
            style={{ backgroundColor: "#1420ff", color: "white" }}
          />
        </div>
      </Link>
    ),
    style: currentPath.includes("/admin/feedback")
      ? { backgroundColor: "transparent" }
      : null,
  },
  {
    key: "9",
    icon: (
      <div
        className={`icon-background ${
          currentPath.includes("/admin/settings")
            ? "icon-background-selected"
            : "icon-background-unselected"
        }`}
      >
        <IoSettingsOutline
          style={{
            color: currentPath.includes("/admin/settings")
              ? "black"
              : "#888A8D",
          }}
        />
      </div>
    ),
    label: (
      <Link to="/admin/settings">
        <div className="flex items-center justify-between">
          <Text
            style={{
              color: currentPath.includes("/admin/settings")
                ? "white"
                : "#888A8D",
              fontSize: "16px",
            }}
          >
            Settings
          </Text>{" "}
          <Badge
            className="site-badge-count-109"
            count={0}
            style={{ backgroundColor: "#1420ff", color: "white" }}
          />
        </div>
      </Link>
    ),
    style: currentPath.includes("/admin/settings")
      ? { backgroundColor: "transparent" }
      : null,
  },
  // {
  //   key: "10",
  //   icon: (
  //     <div
  //       className={`icon-background ${
  //         currentPath.includes("/admin/email-template")
  //           ? "icon-background-selected"
  //           : "icon-background-unselected"
  //       }`}
  //     >
  //       <LuLayoutTemplate
  //         style={{
  //           color: currentPath.includes("/admin/email-template")
  //             ? "black"
  //             : "#888A8D",
  //         }}
  //       />
  //     </div>
  //   ),
  //   label: (
  //     <Link to="/admin/email-template">
  //       <div className="flex items-center justify-between">
  //         <Text
  //           style={{
  //             color: currentPath.includes("/admin/email-template")
  //               ? "white"
  //               : "#888A8D",
  //           }}
  //         >
  //           Email Templates
  //         </Text>{" "}
  //         <Badge
  //           className="site-badge-count-109"
  //           count={0}
  //           style={{ backgroundColor: "#1420ff", color: "white" }}
  //         />
  //       </div>
  //     </Link>
  //   ),
  //   style: currentPath.includes("/admin/email-template")
  //     ? { backgroundColor: "transparent" }
  //     : null,
  // },
  {
    key: "11",
    icon: (
      <div
        className={`icon-background icon-background-unselected`}
        onClick={() => auth.signOut(resetUserState)}
      >
        <LogoutOutlined
          style={{
            color: "#888A8D",
          }}
        />
      </div>
    ),
    label: (
      <div
        onClick={() => auth.signOut(resetUserState)}
        className="flex items-center justify-between"
      >
        <Text style={{ color: "#888A8D", fontSize: "16px" }}>Logout</Text>
        <Badge
          className="site-badge-count-109"
          count={0}
          style={{ backgroundColor: "#1420ff", color: "white" }}
        />
      </div>
    ),
    style: { position: "absolute", backgroundColor: "transparent", bottom: 0 },
  },
];
const AdminLayout = ({ user, children, resetUserState }) => {
  const [pages, setPages] = useState([]);
  const [isAllowedPath, setIsAllowedPath] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const auth = useProvideAuth();

  useEffect(() => {
    if (!user.roles?.includes("SUPER_ADMIN")) {
      navigate(-1);
      return;
    }
    if (!isAllowedPath) {
      setIsAllowedPath(true);
    }

    setPages([...allPages(location.pathname, auth, resetUserState)]);
  }, [location.pathname]);

  return (
    <ProvideAuth>
      {!isAllowedPath ? (
        <StyledLoader />
      ) : (
        <AdminLayoutComponent
          children={children}
          pages={pages}
          defaultOpenKey={2}
        />
      )}
    </ProvideAuth>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});
const mapDispatchToProps = {
  resetUserState: UserSlice.actions.resetUserState,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminLayout);
