import React, { useState } from "react";
import { Modal, Button } from "antd";
import { BellFilled } from "@ant-design/icons";

const NotificationsModal = ({ handleClose }) => {
  return (
    <div>
      <Modal
        title="Notifications"
        open={true}
        onOk={handleClose}
        onCancel={handleClose}
        footer={null}
      >
        <div
          style={{
            textAlign: "center",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            gap: 5,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BellFilled style={{ fontSize: "100px", color: "#5E92F3" }} />
          <p style={{ fontSize: "18px" }}>You’ve no new notifications</p>
          <p>Check back again later.</p>
        </div>
      </Modal>
    </div>
  );
};

export default NotificationsModal;
