import React from "react";
import UserProfileComponent from "../../../components/UserProfileComponent";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

function TopBar({ user }) {
  const navigation = useNavigate();
  return (
    <div className="w-full flex justify-between items-center">
      <div className=" flex items-center">
        <img
          style={{
            height: "35px",
            cursor: "pointer",
          }}
          src="../../assets/logo-dark.png"
          alt="Cyber hire"
        />
        <p
          className="btnActive ml-12 cursor-pointer"
          onClick={() => navigation("/user/assessment-listing")}
        >
          Talent
        </p>
        <p
          className="btn ml-10 cursor-pointer"
          onClick={() =>
            window.open("https://support.cyber-hire.com", "_blank")
          }
        >
          Support
        </p>
      </div>
      {user && Object.keys(user)?.length ? (
        <div>
          <UserProfileComponent
            user={user}
            userName={`${user?.firstName || ""} ${user?.lastName || ""}`}
            userImage={user?.profilePic}
            notification={1}
            nameColor={"#FFFFFF"}
          />
        </div>
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = {
  // stopAssessment: ChallengeSlice.actions.stopAssessment,
};

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
