import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Spin,
  Typography,
  message,
} from "antd";
import UploadFile from "../../../components/Upload";
import { useState } from "react";
import ChallengeTags from "../../../components/Tags/ChallengeTags";
import { DeleteOutlined, LoadingOutlined } from "@ant-design/icons";
import { createChallenge } from "../../../services/api";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../../components/Modals/DeleteModal";
import { MdOutlineFileCopy } from "react-icons/md";
import { IoImageOutline } from "react-icons/io5";

const { Text } = Typography;
const AddChallenge = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [questionsList, setQuestionsList] = useState([
    {
      question: "",
      answer: "",
      hint: "",
      score: "",
    },
  ]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [resourcePathImage, setResourcePathImage] = useState(null);
  const [resourcePathAttachment, setResourcePathAttachment] = useState(null);
  const [resourcePathSSH, setResourcePathSSH] = useState(null);
  const [renderFilePath, setRenderFilePath] = useState(null);
  const [isUrlAdded, setIsUrlAdded] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (values) => {
    setIsLoading(true);
    if (questionsList.length) {
      try {
        const typeOfChallenge = [];
        resourcePathAttachment && typeOfChallenge.push("ATTACHMENT");
        resourcePathImage && typeOfChallenge.push("IMAGE");
        resourcePathSSH && typeOfChallenge.push("SSH");
        values.url && typeOfChallenge.push("URL");

        const resourcePath = [];
        resourcePathAttachment &&
          resourcePath.push({
            name: "ATTACHMENT",
            path: resourcePathAttachment[0],
          });
        resourcePathImage &&
          resourcePath.push({ name: "IMAGE", path: resourcePathImage[0] });
        renderFilePath &&
          resourcePath.push({ name: "RENDER_FILE", path: renderFilePath[0] });
        resourcePathSSH &&
          resourcePath.push({ name: "SSH", path: resourcePathSSH[0] });
        values.url && resourcePath.push({ name: "URL", path: values.url });
        if (typeOfChallenge.length) {
          const challengeData = {
            name: values?.name,
            typeOfChallenge,
            questions: questionsList,
            tags: selectedTags?.map((item) => item?._id),
            resourcePath,
            description: values?.description,
          };
          await createChallenge(challengeData);
          message.success("Challenge created!");
          navigate("/admin/challenges");
          setIsLoading(false);
        } else {
          message.error("Kindly add at least one resouse type!");
          setIsLoading(false);
        }
      } catch (error) {
        message.error(
          error?.response?.data?.error ||
            error.message ||
            "Something went wrong"
        );
        setIsLoading(false);
      }
    } else {
      message.error("Kindly add atleast 1 question");
      setIsLoading(false);
    }
  };

  const handleAddQuestion = () => {
    const arr = [...questionsList];
    arr.push({
      question: "",
      answer: "",
      hint: "",
      score: "",
    });
    setQuestionsList(arr);
  };

  const handleQuestionChange = (e, i, check) => {
    const arr = [...questionsList];
    if (check === "question") {
      arr[i].question = e.target.value;
      setQuestionsList(arr);
    } else if (check === "answer") {
      arr[i].answer = e.target.value;
      setQuestionsList(arr);
    } else if (check === "hint") {
      arr[i].hint = e.target.value;
      setQuestionsList(arr);
    } else if (check === "score") {
      arr[i].score = parseFloat(e.target.value);
      setQuestionsList(arr);
    }
  };

  const [form] = Form.useForm();
  const handleQuestionDelete = (i) => {
    const formValues = form.getFieldValue();
    const arr = [...questionsList];
    arr.splice(i, 1);
    setQuestionsList(arr);
    const questionsObject = arr.reduce((obj, item, i) => {
      obj[`question${i + 1}`] = item.question;
      obj[`answer${i + 1}`] = item.answer;
      obj[`score${i + 1}`] = item.score;
      return obj;
    }, {});

    const fieldsData = {
      ...formValues,
      ...questionsObject,
    };
    form.setFieldsValue(fieldsData);
  };

  // const handleMenuClick = (e) => {
  //   const index = selectedAssessments?.findIndex(
  //     (ind) => ind._id === allAssessments[e.key]._id
  //   );
  //   if (index < 0) {
  //     setSelectedAssessments([...selectedAssessments, allAssessments[e.key]]);
  //   } else {
  //     const arr = [...selectedAssessments];
  //     arr.splice(index, 1);
  //     setSelectedAssessments(arr);
  //   }
  // };

  // const menuProps = {
  //   items: [
  //     ...allAssessments?.map((item, i) => {
  //       const index = selectedAssessments.findIndex(
  //         (ind) => ind._id === item._id
  //       );
  //       return {
  //         label: (
  //           <Typography.Text>
  //             {item.title} {index < 0 ? null : <FaCheck className="ml-10" />}{" "}
  //           </Typography.Text>
  //         ),
  //         key: i,
  //       };
  //     }),
  //   ],
  //   onClick: handleMenuClick,
  // };

  return (
    <div>
      <Card style={{ width: "100%" }}>
        <Row>
          <div className="flex justify-between items-center px-3 py-4 w-full">
            <Text className="text-2xl font-semibold font-[#1f2232]">
              Create Challenge
            </Text>
          </div>
          <Form
            form={form}
            name="basic"
            layout="vertical"
            className="w-full p-3"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Row>
              <Col span={24}>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="Name"
                      name="name"
                      className=" px-2"
                      rules={[
                        {
                          required: true,
                          message: "Please input challenge's name!",
                        },
                      ]}
                    >
                      <Input size="large" />
                    </Form.Item>
                    <Form.Item
                      label="Select Tags"
                      name="tags"
                      className=" px-2"
                    >
                      <ChallengeTags
                        allChallenges={selectedTags}
                        selectedTags={selectedTags}
                        setSelectedTags={(val) => {
                          setSelectedTags(val);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Description"
                      name="description"
                      className=" px-2"
                      rules={[
                        {
                          required: true,
                          message: "Please input challenge's description!",
                        },
                      ]}
                    >
                      <Input.TextArea
                        style={{ height: 140 }}
                        className=""
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item
                  label="URL (Resource type)"
                  name="url"
                  className=" px-2"
                  rules={[
                    {
                      type: "url",
                      message: "Please enter a valid URL!",
                    },
                  ]}
                >
                  {/* setIsUrlAdded */}
                  <Input
                    onChange={({ target: { value } }) => {
                      if (value !== "") {
                        setIsUrlAdded(true);
                      } else {
                        setIsUrlAdded(false);
                      }
                    }}
                    disabled={resourcePathSSH !== null}
                    size="large"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Row>
                  <Col span={6}>
                    {resourcePathImage ? (
                      <Form.Item
                        label="Image (Resource type)"
                        name="image"
                        className=" px-2"
                      >
                        <Row justify={"center"}>
                          <div className="relative w-1/3">
                            <div
                              onClick={() => setResourcePathImage(null)}
                              className="cursor-pointer absolute top-0 right-0 rounded-full bg-red-500 p-1 flex justify-center items-center text-white"
                            >
                              <DeleteOutlined />
                            </div>
                            <IoImageOutline size={120} color="#58ad89" />
                          </div>
                        </Row>
                      </Form.Item>
                    ) : (
                      <Form.Item
                        label="Image (Resource type)"
                        name="image"
                        className=" px-2"
                      >
                        <UploadFile
                          accept="image/*"
                          setResourcePath={setResourcePathImage}
                          uploadAPI={`${process.env.REACT_APP_API_PREFIX}upload`}
                        />
                      </Form.Item>
                    )}
                  </Col>
                  <Col span={6}>
                    {resourcePathAttachment ? (
                      <Form.Item
                        label="Attachment (Resource type)"
                        name="attachment"
                        className=" px-2"
                      >
                        <Row justify={"center"}>
                          <div className="relative w-1/3">
                            <div
                              onClick={() => setResourcePathAttachment(null)}
                              className="cursor-pointer absolute top-0 right-0 rounded-full bg-red-500 p-1 flex justify-center items-center text-white"
                            >
                              <DeleteOutlined />
                            </div>
                            <MdOutlineFileCopy size={120} color="#58ad89" />
                          </div>
                        </Row>
                      </Form.Item>
                    ) : (
                      <Form.Item
                        label="Attachment (Resource type)"
                        name="attachment"
                        className=" px-2"
                      >
                        <UploadFile
                          accept="*/*"
                          setResourcePath={setResourcePathAttachment}
                          uploadAPI={`${process.env.REACT_APP_API_PREFIX}upload`}
                        />
                      </Form.Item>
                    )}
                  </Col>
                  <Col span={6}>
                    {renderFilePath ? (
                      <Form.Item
                        label="Viewer"
                        name="renderFile"
                        className=" px-2"
                      >
                        <Row justify={"center"}>
                          <div className="relative w-1/3">
                            <div
                              onClick={() => setRenderFilePath(null)}
                              className="cursor-pointer absolute top-0 right-0 rounded-full bg-red-500 p-1 flex justify-center items-center text-white"
                            >
                              <DeleteOutlined />
                            </div>
                            <MdOutlineFileCopy size={120} color="#58ad89" />
                          </div>
                        </Row>
                      </Form.Item>
                    ) : (
                      <Form.Item
                        label="Viewer"
                        name="renderFile"
                        className="px-2"
                      >
                        <UploadFile
                          accept=".pdf,.txt"
                          setResourcePath={setRenderFilePath}
                          uploadAPI={`${process.env.REACT_APP_API_PREFIX}upload`}
                          disabled={resourcePathSSH !== null || isUrlAdded}
                        />
                      </Form.Item>
                    )}
                  </Col>
                  <Col span={6}>
                    {resourcePathSSH ? (
                      <Form.Item
                        label="SSH (Resource type)"
                        name="ssh"
                        className=" px-2"
                      >
                        <Row justify={"center"}>
                          <div className="relative w-1/3">
                            <div
                              onClick={() => setResourcePathSSH(null)}
                              className="cursor-pointer absolute top-0 right-0 rounded-full bg-red-500 p-1 flex justify-center items-center text-white"
                            >
                              <DeleteOutlined />
                            </div>
                            <MdOutlineFileCopy size={120} color="#58ad89" />
                          </div>
                        </Row>
                      </Form.Item>
                    ) : (
                      <Form.Item
                        label="SSH (Resource type)"
                        name="ssh"
                        className="px-2"
                      >
                        <UploadFile
                          accept=".zip"
                          setResourcePath={setResourcePathSSH}
                          uploadAPI={`${process.env.REACT_APP_API_PREFIX}upload`}
                          disabled={isUrlAdded}
                        />
                      </Form.Item>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Divider style={{ margin: 0 }} />
            <div className="mt-6">
              {questionsList.length
                ? questionsList.map((item, i) => {
                    return (
                      <Row key={i} className="mt-3 px-2">
                        <Card className="w-full">
                          <Row justify={"end"}>
                            <DeleteOutlined
                              onClick={() => handleQuestionDelete(i)}
                            />
                          </Row>
                          <Form.Item
                            label={
                              <Text className="text-xl font-semibold font-[#1f2232]">
                                Question {i + 1}
                              </Text>
                            }
                            name={`question${i + 1}`}
                            className=" px-2"
                            rules={[
                              {
                                required: true,
                                message: `Please input question ${i + 1}!`,
                              },
                            ]}
                          >
                            <Input.TextArea
                              onChange={(e) =>
                                handleQuestionChange(e, i, "question")
                              }
                              style={{ minHeight: "150px" }}
                              className="w-full"
                            />
                          </Form.Item>
                          <Form.Item
                            label={
                              <Text className="text-lg font-semibold font-[#1f2232]">
                                Answer
                              </Text>
                            }
                            name={`answer${i + 1}`}
                            className=" px-2"
                            rules={[
                              {
                                required: true,
                                message: `Please input answer ${i + 1}!`,
                              },
                            ]}
                          >
                            <Input.TextArea
                              onChange={(e) =>
                                handleQuestionChange(e, i, "answer")
                              }
                              style={{ minHeight: "100px" }}
                              className="w-full"
                            />
                          </Form.Item>
                          <Form.Item
                            label={
                              <Text className="text-lg font-semibold font-[#1f2232]">
                                Hint
                              </Text>
                            }
                            name={`hint${i + 1}`}
                            className=" px-2"
                          >
                            <Input
                              size="large"
                              onChange={(e) =>
                                handleQuestionChange(e, i, "hint")
                              }
                              className="w-full"
                            />
                          </Form.Item>
                          <Form.Item
                            label={
                              <Text className="text-lg font-semibold font-[#1f2232]">
                                Score
                              </Text>
                            }
                            name={`score${i + 1}`}
                            className="w-full flex justify-start px-2"
                            rules={[
                              {
                                validator: (_, value) => {
                                  if (!value) {
                                    return Promise.reject(
                                      `Please input question ${i + 1}'s score!`
                                    );
                                  } else if (!Number.isInteger(Number(value))) {
                                    return Promise.reject(
                                      "Decimals are not allowed for score!"
                                    );
                                  } else if (Number(value) < 0) {
                                    return Promise.reject(
                                      "Negative Numbers are not accepted!"
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              type="number"
                              onChange={(e) => {
                                handleQuestionChange(e, i, "score");
                              }}
                            />
                          </Form.Item>
                        </Card>
                      </Row>
                    );
                  })
                : null}
            </div>
            <Row gutter={12} justify={"end"} className="mt-6">
              <Col>
                <Button onClick={() => setIsModalOpen(true)} size="large">
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button size="large" onClick={handleAddQuestion}>
                  Add Question +
                </Button>
              </Col>
              <Col>
                <Button size="large" htmlType="submit">
                  {isLoading ? (
                    <div className="flex items-center">
                      <Spin
                        className="mr-2"
                        indicator={
                          <LoadingOutlined
                            style={{
                              fontSize: 18,
                            }}
                            spin
                          />
                        }
                      />
                      Create
                    </div>
                  ) : (
                    "Create"
                  )}
                </Button>
              </Col>
            </Row>
          </Form>
        </Row>
      </Card>
      <DeleteModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        deleteAction={() => navigate("/admin/companies")}
        title="Discard changes"
        actionText="Yes"
        text="Are you sure you want to discard?"
      />
    </div>
  );
};

export default AddChallenge;
