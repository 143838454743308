import { useState, useEffect } from 'react'
import { Button, Input, Card, message, Form, Upload, Select, DatePicker } from 'antd'
import Table from '../../components/Tables/Table'
import { connect } from 'react-redux';
import { UploadOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { StyledLoader as Loader } from '../../components/loader'
import { fetchAllCandidates, inviteCandidate, getAllAssessmentsOfCompany } from '../../services/api';
import ModalWrapper from '../../components/Modals/ModalWrapper';
import moment from 'moment';
import { getCompanyDataAPI } from '../../store/actions';
import CSVDownload from '../../components/DownloadTemplate'
const { Option } = Select;

function CompanyAdminCandidate({ user, getCompanyDataAPI }) {
    const [assessments, setAssessments] = useState([])
    const [inviteModalVisible, setInviteModalVisible] = useState("")
    const [candidate, setCandidates] = useState([])
    const [sorter, setSorter] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [searchKey, setSearchKey] = useState("")
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0, // You might not know this until you fetch the first set of data
    });
    const navigate = useNavigate()

    useEffect(() => {
        handleTableChange(pagination, {}, {})
        // fetchCandidates()
        fetchAssessments()
    }, [])

    const fetchAssessments = async () => {
        if (user.companyIds.length) {
            try {
                const { data } = await getAllAssessmentsOfCompany(
                    user.companyIds[0],
                );
                setAssessments(data?.company?.flatMap(({ assessments }) => assessments) || [])
            } catch (error) {
                message.error(error.message || "Something went wrong")
            }
        }
    }
    const fetchCandidates = async (query, currentPage) => {
        if (user.companyIds.length) {
            try {
                setIsLoading(true)
                const { data } = await fetchAllCandidates(user?.companyIds[0], query)
                setCandidates(data?.invitations?.map((invitations) => {
                    const { inviteDetail } = invitations
                    return {
                        firstName: inviteDetail[0].userDetails ? inviteDetail[0].userDetails.firstName : "-",
                        lastName: inviteDetail[0].userDetails ? inviteDetail[0].userDetails.lastName : "-",
                        email: invitations._id,
                        createdAt: inviteDetail?.length ? moment(inviteDetail[0].createdAt).format('YYYY-MM-DD HH:mm ZZ') : "-",
                        invitations: inviteDetail.length,
                        key: invitations._id
                    }
                }))
                setPagination(() => ({
                    ...pagination, total: data?.count || 0, current: currentPage
                }))
            } catch (e) {
                message.error(e?.message || "Something went wrong")
            } finally {
                setIsLoading(false)
            }
        } else {
            message.error("No company Id found")
        }
    }

    const columns = [
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
            width: '20%',
            sorter: true,

        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
            width: '20%',
            sorter: true,
        },
        {
            title: 'email',
            dataIndex: 'email',
            key: 'email',
            width: '20%',
            sorter: true,
        },
        {
            title: 'created',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: true,
        },
        {
            title: 'Invitations',
            dataIndex: 'invitations',
            key: 'invitations'
        },
    ];

    const handleTableChange = (pagination, filters, sorter) => {
        let query = `pageNo=${pagination.current}&pageSize=${pagination.pageSize}`
        for (let key in filters) {
            query += `&${key}=${filters[key]}`
        }

        if (sorter.field && sorter.order) {
            query += `&sortBy=${sorter.field}&order=${sorter.order}`
        }
        setPagination(pagination)
        setSorter(sorter)
        fetchCandidates(query, pagination.current);
    };

    return <div>
        {isLoading ? <Loader /> : null}
        <div style={{ display: "flex", justifyContent: "flex-end", gap: 5, marginBottom: "10px" }}>
            <Button type="primary" size="large" onClick={() => setInviteModalVisible("individual")}>
                Invite
            </Button>
            <Button type="primary" size="large" onClick={() => setInviteModalVisible("bulk")}>
                Bulk Invite
            </Button>
        </div>
        <Card style={{ boxShadow: "0px 1px 2px 0px #000000 !important" }}>
            <Table
                columns={columns}
                data={candidate}
                pagination={pagination}
                onChange={(p, f, s) => handleTableChange({
                    current: 1,
                    pageSize: 10,
                    total: 0, // You might not know this until you fetch the first set of data
                }, !searchKey?.length ? {} : { searchKey }, s)}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {
                            navigate(`/company/admin/candidate/${record.key}`)
                        }
                    }
                }}
                style={{ cursor: "pointer" }}
                isSearch
                appendSearchKey={false}
                sorter={sorter}
                callback={(val, s, p) => {
                    handleTableChange(p, !val?.length ? {} : { searchKey: val }, s)
                    setSearchKey(val)
                }}
            />

        </Card>
        {inviteModalVisible.length ? <InviteFormModal getCompanyDataAPI={getCompanyDataAPI} user={user} isBulk={inviteModalVisible === "bulk"} assessments={assessments} fetchInvitations={fetchCandidates} onClose={setInviteModalVisible} /> : null}
    </div>
}

const mapStateToProps = (state) => ({
    user: state.auth.user
});
const mapDispatchToProps = {
    getCompanyDataAPI
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyAdminCandidate);



const InviteFormModal = ({ onClose, assessments, isBulk, user, fetchInvitations, getCompanyDataAPI }) => {
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState([]);
    const [isAssessmentSelected, setIsAssessmentSelected] = useState(false);
    const [emails, setEmails] = useState([]);

    const beforeUpload = (file) => {

        const isCSV = file.type === 'text/csv';
        if (!isCSV) {
            message.error(`${file.name} is not a CSV file`);
        }

        const reader = new FileReader();

        reader.onload = e => {
            const data = e.target.result.split('\n');
            data.shift()
            const emailsData = []
            data.forEach((userData) => {
                const splitedData = userData.split(",")
                if (splitedData.length === 2) {
                    const firstName = splitedData[0]
                    const email = splitedData[1]
                    if (!firstName?.length || !email?.length) {
                        return
                    }
                    emailsData.push({
                        firstName,
                        email: email?.trim()?.replace(/\r/g, ''),
                    })
                }

            })
            setEmails(emailsData)
        };
        reader.readAsText(file);

        return isCSV || Upload.LIST_IGNORE;

    };

    const onFinish = async (values) => {

        try {
            let bulkEmail
            if (isBulk) {
                if (!emails.length) {
                    message.error("Emails must be provided")
                    return
                }

                let seenEmails = new Set();
                bulkEmail = emails.filter(item => {
                    if (seenEmails.has(item.email)) {
                        return false;
                    } else {
                        seenEmails.add(item.email);
                        return true;
                    }
                })
            }
            await inviteCandidate(user.companyIds[0], values.assessment, {
                candidates: isBulk ? bulkEmail : [{ firstName: values.firstName, email: values.email.trim() }],
                expiryTime: values.date,
                invitedBy: user._id
            })
            await getCompanyDataAPI(user?.companyIds[0])
            message.success("Invitation successfully sent")
            onClose("")
            fetchInvitations()
        } catch (error) {
            message.error(error?.response?.data?.error || error?.message || "Something went wrong");
        }
    };

    const handleFileChange = (e) => {
        setFileList(e.fileList);
    };


    // Cancel handler
    const onCancel = () => {
        form.resetFields();
        onClose(false);
    };

    const handleDelete = () => {
        setFileList([])
    }

    return (
        <ModalWrapper
            title={"Invite Candidate"}
            handleClose={onCancel}
            footer={null} // No footer needed as buttons are inside form
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                initialValues={{
                    email: '',
                    date: null,
                    assessment: undefined
                }}
            >

                <Form.Item
                    label="Expiry Date"
                    name="date"
                    rules={[
                        { required: true, message: 'Please select a date!' },
                        () => ({
                            validator(_, value) {
                                if (!value || value.isAfter(moment())) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The date must be in the future!'));
                            },
                        }),
                    ]}

                >
                    <DatePicker
                        style={{ height: '48px', width: "100%" }}
                        placeholder="Select date"
                        disabledDate={(current) => {
                            let customDate = moment().format("YYYY-MM-DD");
                            return current && current < moment(customDate, "YYYY-MM-DD");
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label="Assessment"
                    name="assessment"
                    rules={[{ required: true, message: 'Please select an assessment!' }]}
                >
                    <Select
                        placeholder="Select Assessment"
                        onChange={(value) => {
                            setIsAssessmentSelected((prevValue) => !prevValue)
                        }}
                    >
                        {
                            assessments.map(({ _id, title }) => <Option value={_id}>{title}</Option>)
                        }
                    </Select>
                </Form.Item>


                {isBulk ?
                    <Form.Item
                        label="CSV File"
                        name="fileList"
                        valuePropName="fileList"
                        getValueFromEvent={handleFileChange}
                    // rules={[{ required: true, message: 'Please upload a CSV file!' }]}
                    >
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "start" }}>
                            <Upload
                                customRequest={({ onSuccess }) => {
                                    onSuccess("ok")
                                }}
                                multiple={false}
                                maxCount={1}
                                beforeUpload={beforeUpload}
                                onChange={handleFileChange}
                                fileList={fileList}
                                disabled={!isAssessmentSelected}
                                accept=".csv"
                                onRemove={handleDelete}
                            >
                                <Button icon={<UploadOutlined />}>Click to upload CSV</Button>
                            </Upload>
                            <CSVDownload isFirstName />
                        </div>
                    </Form.Item>
                    : <>

                        <Form.Item
                            label="First Name"
                            name="firstName"
                            rules={[
                                { required: true, message: 'Please input candidate’s First Namee!' },
                            ]}
                        >
                            <Input
                                placeholder="Enter candidate's First Name"
                                style={{ height: '48px' }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                { required: true, message: 'Please input candidate’s email!' },
                                { type: 'email', message: 'The input is not a valid email!' }
                            ]}
                        >
                            <Input
                                placeholder="Enter candidate's email"
                                style={{ height: '48px' }}
                            />
                        </Form.Item></>}


                <div style={{ display: "flex", justifyContent: "flex-end", gap: "8px" }}>
                    <Button onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button type="primary" htmlType="submit">
                        Continue
                    </Button>
                </div>
            </Form>
        </ModalWrapper>
    );
};