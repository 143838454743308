import { createSlice } from "@reduxjs/toolkit";
import { getChallengeDetails, setUserProfile } from "../actions/index";
import moment from "moment";
import { message } from "antd";

const initialState = {
  assessment: {},
  selectedChallenge: {},
  currentQuestion: 0,
  isAssessmentFetching: false,
  attempts: null,
  history: [],
  size: { width: 300, height: 300 },
  type: "",
  selectedChallengeIndex: null,
  completionPercentage: 0,
  currentScore: 0,
  feedback: "",
  difficulty: "TOO_EASY",
  hintRevealCost: 0,
  hintsRevealedByCandidate: 0,
  maximumHints: 0,
  isLoading: false,
  resourceData: {
    downloadableResources: [],
    renderResource: {},
  },
};

export const userSlice = createSlice({
  name: "challengeSlice",
  initialState,
  reducers: {
    resetState: (state) => (state = initialState),
    setHistory: (state, { payload }) => {
      state.history = payload;
    },
    setFeedback: (state, { payload }) => {
      state.feedback = payload;
    },
    stopAssessment: (state) => {
      state.attempts = undefined;
      const dummyHistory = [...state.history];
      const currentTime = moment();

      dummyHistory.push({
        title: "Challenge Completed.",
        time: currentTime.format("hh:mm:ss A"),
        icon: "clock",
        isQuestion: false,
      });
      state.history = [...dummyHistory];
    },
    setDifficulty: (state, { payload }) => {
      state.difficulty = payload;
    },
    decrementHintsCount: (state) => {
      if (state.maximumHints === state.hintsRevealedByCandidate) return;
      state.hintsRevealedByCandidate = state.hintsRevealedByCandidate + 1;
    },
    handleRightAnswer: (state, { payload }) => {
      try {
        const { score, questionId, answer, isTrueAnswer } = payload;
        state.assessment = {
          ...state.assessment,
          challenges: state.assessment.challenges.map((challenge) => {
            if (challenge._id === state.selectedChallenge._id) {
              return {
                ...challenge,
                rightAnswers: challenge.rightAnswers + 1,
                questions: challenge.questions.map((question) => {
                  if (question._id === questionId) {
                    return {
                      ...question,
                      givenAnswer: answer,
                      status: "ATTEMPTED",
                      isTrueAnswer: isTrueAnswer,
                    };
                  }
                  return question;
                }),
              };
            }
            return challenge;
          }),
        };
        state.currentScore = state.currentScore + score;
        state.completionPercentage = payload.completionPercentageToReturn;
      } catch (err) {
        message.error(err.message || "Something went wrong");
      }
    },
    setType: (state, { payload }) => {
      state.type = payload;
    },
    setAttempts: (state) => {
      state.attempts = state.attempts + -1;
    },
    getSelectedChallenge: (state, { payload }) => {
      const id = payload;
      const challenges = state.assessment?.challenges;
      if (!challenges?.length) {
        state.selectedChallenge = [];
        return state;
      }
      let selectedChallenge;
      if (id) {
        selectedChallenge = challenges.filter(
          (challenge) => challenge._id === id
        )[0];
      } else {
        selectedChallenge = challenges[0];
        state.selectedChallengeIndex = 0;
      }
      const dummyHistory = [];
      const currentTime = moment();
      dummyHistory.push({
        title: "You have started the Challenge for the first time.",
        time: currentTime.format("hh:mm:ss A"),
        icon: "clock",
        isQuestion: false,
      });
      const questions = selectedChallenge.questions;
      for (let index in questions) {
        const question = questions[index];
        if (question.status !== "UNATTEMPTED") {
          const currentTime = moment(question.answerTime);
          dummyHistory.push({
            title: question.isTrueAnswer
              ? "You Correctly answered a Question."
              : "You were unable to answer correctly",
            question: question.question,
            answer: question.givenAnswer,
            time: currentTime.format("hh:mm:ss A"),
            icon: question.isTrueAnswer ? "RIGHT" : "WRONG",
            isQuestion: true,
          });
          continue;
        }
        break;
      }

      const resourceData = {
        downloadableResources: [],
        renderResource: {},
      };
      selectedChallenge.resourcePath.forEach((resource, index) => {
        if (resource.name === "SSH") {
          resourceData.renderResource = {
            name: "IFRAME",
            type: resource.name,
            namespaceName: selectedChallenge?.sshDetails?.namespaceName,
            path: `https://terminal.cyber-hire.com/?terminal=${selectedChallenge?.sshDetails?.namespaceName}`,
          };
        } else if (resource.name === "RENDER_FILE" || resource.name === "URL") {
          resourceData.renderResource = {
            name: resource.name === "RENDER_FILE" ? "PDF" : "IFRAME",
            type: resource.name,
            path: resource.path,
          };
        } else {
          resourceData.downloadableResources.push({
            name: resource.fileName || "downloadable_file",
            url: resource.path,
          });
        }
      });
      state.history = [...dummyHistory];
      state.resourceData = resourceData;
      state.currentQuestion = 0;
      state.selectedChallenge = { ...selectedChallenge };
    },

    setCurrentScore: (state, { payload }) => {
      state.currentScore = state.currentScore - payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChallengeDetails.pending, (state, action) => {
        state.isLoading = true;
        state.authenticated = false;
        state.loggedIn = false;
      })
      .addCase(getChallengeDetails.fulfilled, (state, { payload }) => {
        if (payload === null) {
          message.error("Unable to fetch assessment details");
          window.location.replace("user/assessment-listing");
          return;
        }
        state.attempts = payload?.remainingAttempts;
        state.assessment = payload;
        state.currentScore = payload.candidateScore;
        state.completionPercentage = payload.completionPercentage;
        state.hintRevealCost = payload.hintRevealCost;
        state.hintsRevealedByCandidate = payload.hintsRevealedByCandidate;
        state.maximumHints = payload.maximumHints;
        state.isLoading = false;
      })
      .addCase(getChallengeDetails.rejected, (state, action) => {
        state.isLoading = false;
      });
    builder
      .addCase(setUserProfile.pending, (state, action) => {
        state.isLoading = true;
        state.authenticated = false;
        state.loggedIn = false;
      })
      .addCase(setUserProfile.fulfilled, (state, { payload }) => {
        state.user = payload.user;
        state.loggedIn = true;
        state.authenticated = true;
        state.isLoading = false;
      })
      .addCase(setUserProfile.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export default userSlice;
