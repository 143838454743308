import { Button } from "antd"
import trashIcon from '../../assets/trash.jpg'
import ModalWrapper from "./ModalWrapper"
const DeleteModal = ({ handleDelete, handleClose, heading, description }) => {
    return <ModalWrapper
        handleClose={handleClose}
        footer={null} // No footer needed as buttons are inside form
    >
        <div style={{ display: "flex", padding: "30px 40px", flexDirection: "column", gap: 5, backgroundColor: "transparent", justifyContent: "center", alignItems: "center" }}>
            <img style={{ width: "56px", height: "56px" }} src={trashIcon} alt="trash" />
            <p style={{
                fontSize: "24px",
                fontWeight: "600",
                lineHeight: "36px",
                textAlign: "center",
            }}>{heading}</p>
            <p style={{
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
                textAlign: "center",
                color: "#888A8D"
            }}>{description}</p>
            <Button
                size="large"
                style={{
                    marginTop: "2%",
                    height: "46px",
                    padding: "8px 16px 8px 16px",
                    gap: "8px",
                    borderRadius: "8px",
                    background: "#070908",
                    fontSize: "16px",
                    fontWeight: "600",
                    lineHeight: "24px",
                    textAlign: "center",
                    width: "100%",
                    color: "white"

                }}
                onClick={handleDelete}
            >
                Delete</Button>
            <Button
                style={{
                    height: "46px",
                    padding: "8px 16px 8px 16px",
                    gap: "8px",
                    borderRadius: "8px",
                    fontSize: "16px",
                    fontWeight: "600",
                    lineHeight: "24px",
                    textAlign: "center",
                    width: "100%",
                }}
                onClick={handleClose}
            >Cancel</Button>
        </div>

    </ModalWrapper>
}

export default DeleteModal