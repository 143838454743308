import { LoadingOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import {
    Button,
    Card,
    Col,
    Divider,
    Form,
    Image,
    Input,
    Row,
    Spin,
    Switch,
    Typography,
    Upload,
    message,
} from "antd";
import "./index.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { axiosInstance, getAllAssessmentsOfCompany } from "../../services/api";
import { StyledLoader } from "../../components/loader";

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const Organization = ({ user }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [fileList, setFileList] = useState([])
    const [form] = Form.useForm();
    const [url, setUrl] = useState("")
    const onFinish = async (values) => {

        let file = null;
        if (fileList.length) {
            if (fileList[0].status === "done") {
                file = fileList[0].response?.URLs[0];
            }
        } else {
            message.error("Logo Is Required")
            return
        }

        if (!user.companyIds.length) {
            message.error("No Company Found")
        }

        setIsLoading(true);
        try {
            await axiosInstance.patch(`${process.env.REACT_APP_API_PREFIX}company/updateBranding/${user.companyIds[0]}`, {
                ...values,
                backgroundColor: !values.backgroundColor ? "WHITE" : "BLACK",
                logo: file
            })
            message.success("Organization setup successful!");
        } catch (error) {
            message.error(error?.response?.data?.error || error?.message || "Something went wrong");

        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getOrganization()
    }, [])

    const getOrganization = async () => {
        setIsLoading(true)
        try {
            const { data } = await getAllAssessmentsOfCompany(user.companyIds[0])
            if (!data.company?.length) {
                message.error("No Company Found")
                return
            }
            const company = data.company[0]
            form.setFieldsValue({
                backgroundColor: company.backgroundColor !== "WHITE",
                description: company.description,
                langingPageDescription: company.langingPageDescription,
                langingPageHeading: company.langingPageHeading,
                name: company.name
            })
            setUrl(company.url)
            setFileList([
                {
                    uid: "-1",
                    name: "logo.png",
                    status: "done",
                    response: { "status": "success", URLs: [company.logo] },
                    url: company.logo,
                },
            ]);

        } catch (error) {
            message.error(error?.response?.data?.error || error?.message || "Something went wrong")
        } finally {
            setIsLoading(false)
        }
    }

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

    const uploadButton = (
        <button style={{ border: 0, background: "none" }} type="button">
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </button>
    );

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
    };

    return (
        <div className="w-full">
            {isLoading ? <StyledLoader /> : null}
            <div style={{ maxWidth: 1500, margin: "auto" }} className="px-10">
                <Card className="shadow-lg mb-10">
                    <Form
                        name="basic"
                        layout="vertical"
                        // initialValues={{
                        //     remember: true,
                        // }}
                        form={form}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Row>
                            <Col span={8}>
                                <div className="flex flex-col text-left">
                                    <Typography.Text className="text-2xl font-semibold">
                                        Organization
                                    </Typography.Text>
                                    <Typography.Text className="text-gray-500">
                                        Setup your Organization
                                    </Typography.Text>
                                </div>
                            </Col>
                            <Col span={16} className="flex flex-wrap">
                                <Form.Item
                                    label="Name"
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Name Is Required",
                                        },
                                    ]}
                                    className="w-full px-2"
                                >
                                    <Input
                                        className="input"
                                        placeholder="Name"
                                        size="large"
                                    />
                                </Form.Item>



                                <Form.Item
                                    label="Description"
                                    name="description"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Description Is Required",
                                        }
                                    ]}
                                    className="w-full px-2"
                                >
                                    <Input.TextArea
                                        className="input"
                                        placeholder="Description"
                                        size="large"
                                        rows={4}
                                        minLength={4}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Landing Page Heading"
                                    name="langingPageHeading"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Landing Page Heading Is Required",
                                        }
                                    ]}
                                    className="w-full px-2"
                                >
                                    <Input
                                        className="input"
                                        placeholder="Landing Page Heading"
                                        size="large"
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Landing Page Description"
                                    name="langingPageDescription"
                                    rules={[
                                        {
                                            required: true,
                                            message: "landing Page Description Is Required",
                                        }
                                    ]}
                                    className="w-full px-2"
                                >
                                    <Input.TextArea
                                        className="input"
                                        placeholder="Landing Page Description"
                                        size="large"
                                        rows={4}
                                        minLength={4}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Logo"
                                >

                                    <div className="w-full flex justify-start items-center gap-4">
                                        {url ? (
                                            <Image
                                                style={{
                                                    width: "100px",
                                                    height: "100px",
                                                    borderRadius: "50%",
                                                }}
                                                src={url}
                                            />
                                        ) : null}



                                        <Upload
                                            accept="image/png, image/jpeg"
                                            className="upload-profile-image"
                                            multiple={false}
                                            maxCount={1}
                                            action={`${process.env.REACT_APP_API_PREFIX}upload`}
                                            listType={url ? "" : "picture-circle"}
                                            fileList={fileList}
                                            onPreview={handlePreview}
                                            name="attachment"
                                            onChange={handleChange}
                                        >
                                            {url ? (
                                                <Button icon={<UploadOutlined />}>Edit</Button>
                                            ) : (
                                                uploadButton
                                            )}
                                        </Upload>
                                    </div>
                                </Form.Item>
                                <Form.Item
                                    name="backgroundColor"
                                    label="Background Color"
                                    className="w-full flex justify-start"
                                >
                                    <Switch className="w-max" checkedChildren="Black" unCheckedChildren="White" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider />
                        <Form.Item>
                            <Row justify={"end"}>
                                <Button type="primary" size="large" htmlType="submit">
                                    {isLoading ? (
                                        <div className="flex items-center">
                                            <Spin
                                                className="mr-2"
                                                indicator={
                                                    <LoadingOutlined
                                                        style={{
                                                            fontSize: 18,
                                                            color: "white",
                                                        }}
                                                        spin
                                                    />
                                                }
                                            />
                                            Save
                                        </div>
                                    ) : (
                                        "Save"
                                    )}
                                </Button>
                            </Row>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    user: state.auth.user,
});

export default connect(mapStateToProps, {})(Organization);