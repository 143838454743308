import React from 'react';
import { Result, Button } from 'antd';

const InvalidUrlPage = () => {
    return (
        <Result
            status="404"
            title="404"
            subTitle="Sorry, the URL you visited does not exist."
            extra={<Button type="primary" href="/login">Back Home</Button>}
        />
    );
}

export default InvalidUrlPage;
