import React from 'react';
import { Button, Card, Layout, Typography } from 'antd';
import './index.css';  // Import a CSS file for custom styling if needed.
import ChallengeIcon from "../../assets/icons/challenge.svg";
import { useNavigate } from 'react-router-dom';
import Stars from "../../assets/background/Stars.webp";
import logoDark from "../../assets/logo-dark.png"
import logo from "../../assets/logo.png"
import WhiteBackground from './background-line-white.png'
import Background from './background-line-black.png'
const { Content, Footer } = Layout;
const { Title, Text } = Typography;



const BrandedLandingPage = ({ data, assessment, startAssessment, resumeAssessment, user, id, companyId }) => {
    const navigate = useNavigate()

    const backGroundColor = data.backgroundColor === "WHITE"

    const handleRedirect = (type, id) => {
        if (user && Object.keys(user).length) {
            navigate(
                `/assessment-details/${assessment._id}/${companyId}`
            );
            return
        }

        if (type === "SIGNUP") {
            navigate(`/signup?token=${id}`)
        } else {
            navigate(`/login?token=${id}`)
        }
    }

    let instructions = assessment?.instructions
    if (assessment && Object.keys(assessment).length && data?.assessmentIdsWithTokens?.length) {
        const filteredArray = data?.assessmentIdsWithTokens?.filter(({ assessmentId }) => assessmentId === assessment?._id)
        if (filteredArray?.length) {
            instructions = filteredArray[0].invitePageInstructions
        }
    }
    return (
        <div style={{
            height: "100vh",
            background: `url(${backGroundColor ? WhiteBackground : Background})`,
            backgroundSize: "100% 100%",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
            backgroundPosition: "center center"
        }}
            className="flex flex-col gap-10 items-center justify-center"
        >
            <img src={Stars} style={{
                position: "absolute",
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
                height: "500px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundAttachment: "fixed",
            }} />

            <div style={{ width: "400px", }} className="flex flex-col gap-4 items-center">
                {data?.logo ? <img className="w-56" style={{
                    maxHeight: "100px", objectFit: "contain"
                }} src={data?.logo ? data?.logo : ChallengeIcon} alt="Cyber hire" /> : null}
                <p className="heading-landing " style={{ color: backGroundColor ? "#070908" : "#ffffff" }}>{data?.langingPageHeading || ""}</p>
                <p className="description-landing" style={{ color: backGroundColor ? "#070908" : "#888D89" }}>{data?.langingPageDescription || ""}</p>
                <Content style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 4, width: "100%" }}>
                    <Card
                        style={{
                            backgroundColor: backGroundColor ? "#E0E0E0" : "white", width: "100%",
                            boxShadow: "0px 2.87px 5.74px 0px #0000001A"
                        }}
                        bordered={false}
                    >
                        <img
                            width={146}
                            height={118}
                            src={assessment.image ? assessment.image : ChallengeIcon}
                            alt="Challenge"
                        />
                        <Title level={3} className="assessment-heading-landing">{assessment?.title || ""}</Title>
                        <div
                            style={{ color: backGroundColor ? "#070908" : "#888A8D" }}
                            className="text-left assessment-description-landing"
                            dangerouslySetInnerHTML={{
                                __html: instructions,
                            }}
                        />
                    </Card>
                </Content>
            </div>
            <div style={{ width: "100%" }} className="flex justify-center items-center gap-10">

                {false ? (
                    assessment?.status === "UNATTEMPTED" ? (
                        <Button type="primary" block className="landing-buttons" onClick={startAssessment}>
                            Start Challenge
                        </Button>
                    ) : assessment?.status === "IN_PROGRESS" ? (

                        <>
                            <Button type="primary" block className="landing-buttons" onClick={resumeAssessment}>
                                Resume Challenge
                            </Button>

                            <Button type="primary" block className="landing-buttons" onClick={resumeAssessment}>
                                Mark as done
                            </Button>
                        </>
                    ) : assessment?.status === "COMPLETED" ? (
                        <Button type="primary" block className="landing-buttons" onClick={resumeAssessment}>
                            Mark as done
                        </Button>
                    ) : (
                        <Button type="primary" block className="landing-buttons" onClick={startAssessment}>
                            Start Challenge
                        </Button>
                    )
                ) : (
                    <>
                        <Button type="primary" block className="landing-buttons" onClick={() => handleRedirect("SIGNUP", id)}>Create account</Button>
                        <Button type="primary" block className="landing-buttons" onClick={() => handleRedirect("LOGIN", id)}>Sign In</Button>
                    </>
                )}
            </div>
            <div style={{ width: "400px" }}>
                <Footer style={{ textAlign: 'center', padding: 0, background: "transparent", color: '#fff' }}>
                    <div className="flex flex-col gap-2 justify-center items-center">
                        <Text style={{
                            width: "max-content",
                            fontSize: "12px",
                            fontWeight: "500",
                            lineHeight: "22px",
                            textAlign: "center",
                            textTransform: "uppercase",
                            color: backGroundColor ? "#000000" : "#ffffff"
                        }} >Powered by</Text>
                        <img
                            style={{
                                height: "46px",
                                width: "145px"
                            }}
                            src={backGroundColor ? logo : logoDark}
                            alt="Cyber hire"
                        />
                    </div>
                    <Text style={{ color: backGroundColor ? "#888A8D" : "#ffffff" }}>Need support? <a href="https://support.cyber-hire.com" target="_blank" style={{ color: backGroundColor ? "green" : "#ffffff", fontWeight: 700 }}>Contact us</a></Text>
                </Footer>
            </div>
        </div>
        // </div>
    );
};

export default BrandedLandingPage;
