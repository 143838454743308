import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    LineElement

} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    LineElement
);

export default function BarChart({ color = "#FEA03F", payload }) {
    const { labels, data, numberOfCandidates } = payload

    const options = {
        responsive: true,
        scales: {
            x: {
                grid: {
                    display: false
                }
            },
            y: {
                grid: {
                    display: false
                }
            }
        },
        plugins: {
            legend: {
                position: 'left',
                display: false
            },
            title: {
                display: false,
                text: 'Chart.js Bar Chart',
            },
            tooltip: {
                callbacks: {
                    title: (xDatapoint) => {
                        return xDatapoint.raw
                    },
                    label: (yDatapoint) => {
                        return `${numberOfCandidates} Candidates`

                    }
                }
            },
        },
    };

    const dataOptions = {
        labels,
        datasets: [
            {
                data,
                backgroundColor: color,
                borderColor: color,
                borderWidth: 1,
            },
        ],
    };

    return <>{payload?.data?.length ? <>
        <p className="notes" style={{ marginBottom: "20px" }}>Score Distribution</p>
        <Bar options={options} data={dataOptions} />
    </> :
        <p className="notes" style={{ marginBottom: "20px" }}>Not enough data </p>
    }</>
}
