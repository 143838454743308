import React, { useState, useEffect } from "react";
import { AutoComplete, Typography } from "antd";

const CompanyDropdown = ({
  setShowCompanyDropDown,
  allCompanies,
  selectedCompany,
  setSelectedCompany,
}) => {
  const [value, setValue] = useState("");
  const [anotherOptions, setAnotherOptions] = useState([]);

  useEffect(() => {
    setAnotherOptions([
      ...allCompanies.map((item) => ({ value: item.name, _id: item._id })),
    ]);
  }, [allCompanies]);
  const getPanelValue = (searchText) => {
    if (!searchText) {
      return [
        ...allCompanies.map((item) => ({ value: item.name, _id: item._id })),
      ];
    }
    const filteredCompanies = allCompanies.filter((item) =>
      item.name.toLowerCase().includes(searchText.toLowerCase())
    );
    return filteredCompanies.map((item) => ({
      value: item.name,
      _id: item._id,
    }));
  };
  const onSelect = (data, e) => {
    const index = allCompanies.findIndex((item) => item._id === e._id);
    setSelectedCompany(allCompanies[index]);
    setShowCompanyDropDown(false);
    setAnotherOptions([
      ...allCompanies.map((item) => ({ value: item.name, _id: item._id })),
    ]);
    setValue(allCompanies[index]?.name);
  };
  const onChange = (data) => {
    setValue(data);
    const index = allCompanies.findIndex((ind) => ind.name === data);
    if (index < 0) {
      setSelectedCompany(null);
    }
  };

  useEffect(() => {
    if (selectedCompany) {
      setValue(selectedCompany?.name);
    }
  }, [allCompanies, selectedCompany]);

  return (
    <>
      <AutoComplete
        value={value}
        size="large"
        options={anotherOptions}
        style={{
          textAlign: "left",
        }}
        onSelect={(val, e) => onSelect(val, e)}
        onSearch={(text) => setAnotherOptions(getPanelValue(text))}
        onChange={onChange}
        placeholder="Search company..."
        className="w-full"
      />
    </>
  );
};

export default CompanyDropdown;
