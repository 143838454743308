import { Card, Divider, Layout, Flex, Segmented, message, Tooltip, Button, Progress, Input } from "antd";
import SplineChart from "../../components/charts/SplineChart";
import BarChart from "../../components/charts/BarChart";
import RadarChart from "../../components/charts/RadarChart";
import './index.css'
import { CloseCircleOutlined, EditOutlined, SaveOutlined, SendOutlined } from '@ant-design/icons';
import Table from '../../components/Tables/Table'
import { useEffect, useState } from "react";
import { getEvaluationsDetail, updateAssessmentInstructions, getGraphDataAPI } from '../../services/api';
import { useLocation, useParams } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import RichtextEditor from "../../components/RichtextEditor";
import { StyledLoader } from "../../components/loader";
import { convertMinutesToDaysOrHours, convertMinutes } from "../../utils";
import Envelope from '../../assets/icons/envelop.svg'
import RadarGraph from "../../components/Graphs/Radar";

const gradientColor = [
    {
        "50%": "#FE5B5B",
        "0%": "#760707",
    },
    {
        "50%": "#FFAD64",
        "0%": "#A4550F",
    },
    {
        "50%": "#5196F4",
        "0%": "#0A4089",
    },
    {
        "50%": "#34C788",
        "0%": "#217C55",
    },
    {
        "50%": "#C189F6",
        "0%": "#7329BB",

    },
]

const enumBarChartKeys = {
    "between0And10": "0%-10%",
    "between10And20": "10%-20%",
    "between20And30": "20%-30%",
    "between30And40": "30%-40%",
    "between40And50": "40%-50%",
    "between50And60": "50%-60%",
    "between60And70": "60%-70%",
    "between70And80": "70%-80%",
    "between80And90": "80%-90%",
    "between90And100": "90%-100%"
}

function CompanyAdminEvaluationDetails({ user }) {
    const [details, setDetails] = useState({
        "assessment": {
        },
        "invites": [
        ],
        "assessmentInvitesStats": {
        },
        "assessmentAverageStats": {
        }
    })
    const { id } = useParams()
    const [graphsData, setGraphData] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [emailInstruction, setEmailInstruction] = useState("")
    const [assessmentInstructions, setAssessmentInstructions] = useState("")
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0, // You might not know this until you fetch the first set of data
    });
    const [selectedTab, setSelectedTab] = useState("")

    useEffect(() => {
        handleTableChange(pagination, {}, {})
        getGraphData()
    }, [])

    const getGraphData = async () => {
        try {
            const { data: graphs } = await getGraphDataAPI(user.companyIds[0], id)
            const distributionGraph = graphs?.scoreDistribution
            let barChartKeys = Object.keys(distributionGraph || {})?.map((key) => enumBarChartKeys[key])?.filter((e) => e !== undefined)
            const numberOfCandidates = distributionGraph.numberOfCandidates
            delete distributionGraph._id
            delete distributionGraph.numberOfCandidates
            const data = {
                barCharData: {
                    labels: barChartKeys,
                    data: Object.keys(distributionGraph || {})?.map((key) => distributionGraph[key]),
                    numberOfCandidates: numberOfCandidates || 0
                },
                compositeChart: graphs.compositeGraph?.map(({ candidateScore, accuracy }) => {
                    return {
                        x: accuracy,
                        y: candidateScore,
                        r: 20
                    }
                }),
                radarChart: {
                    labels: graphs?.skillsTracker?.map((radar) => (radar._id)),
                    data: graphs?.skillsTracker?.map((radar) => (10))
                }
            }

            setGraphData({ ...graphs, ...data })
        } catch (error) {
            message.error(error.message || "Unable to fetch graphs data")
        }
    }
    const getEvaluationDetailsAPI = async (query) => {
        try {
            setIsLoading(true);
            const { data } = await getEvaluationsDetail(user.companyIds[0], id, query)
            const assessment = data?.assessment || {}
            setDetails({
                ...data,
                invites: data?.invites?.map((invite, index) => {
                    const currentDate = moment()
                    const date = moment(invite.createdAt)
                    return {
                        sr: index + 1,
                        firstName: invite?.userDetails?.firstName || "-",
                        lastName: invite?.userDetails?.lastName || "",
                        email: invite.email,
                        invitedBy: invite?.invitedByUser?.length ? `${invite?.invitedByUser[0].firstName} ${invite?.invitedByUser[0].lastName}` : "-",
                        invited: currentDate.diff(date, 'days'),
                        started: invite.assessmentStatus === "IN_PROGRESS" ? "Started" : invite.assessmentStatus === "UNATTAMPTED" ? "Not Started" : "Completed",
                        completed: invite.assessmentStatus === "COMPLETED" ? "Completed" : "Not Completed",
                        score: invite.candidateScore,
                        accuracy: invite.accuracy,
                        resultId: invite._id
                    }
                }),
                additionalDetails: {
                    duration: convertMinutesToDaysOrHours(assessment.duration),
                    challenges: assessment?.challengeList?.map((challenge) => challenge.name).toString() || "",
                }
            })
            setAssessmentInstructions(assessment.invitePageInstructions || "")
            setEmailInstruction(assessment.inviteEmailText || "")
        } catch (err) {
            message.error(err.message || "Something went wrong");
        } finally {
            setIsLoading(false);
        }
    }

    const handleTableChange = (pagination, filters, sorter) => {
        let query = ""
        if (sorter.field && sorter.order) {
            query += `&sortBy=${sorter.field}&order=${sorter.order}`
        }
        setPagination(pagination)
        getEvaluationDetailsAPI(query);
    };

    const columns = [
        {
            title: 'NO.',
            dataIndex: 'sr',
            width: "5%",
            key: 'sr'
        },
        {
            title: 'Name Surname',
            dataIndex: 'firstName',
            key: 'firstName',
            width: '20%',
            sorter: true,
            render: (_, item) => <div style={{ display: "flex", alignItems: "center", gap: 2 }}>
                <p style={{

                    fontSize: "12px",
                    fontWeight: "400",
                    lineHeight: "18px",
                    textAlign: "left",
                    color: "#070908"
                }}>{item.firstName + " " + item.lastName}</p>
            </div>
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: '10%',
            sorter: true,
        },
        {
            title: 'Invited By',
            dataIndex: 'invitedBy',
            key: 'invitedBy',
            width: '10%',
            sorter: true,
        },
        {
            title: 'Started',
            dataIndex: 'started',
            key: 'started',
            width: '20%',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Completed',
            dataIndex: 'completed',
            key: 'completed',
            width: '20%',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Score',
            dataIndex: 'score',
            key: 'score',
            width: '20%',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Accuracy',
            dataIndex: 'accuracy',
            key: 'accuracy',
            width: '20%',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
    ];

    const handleInstructionUpdate = async (name, value) => {
        try {
            setIsLoading(true);
            const data = {
                [name]: value
            }
            if (name === "inviteEmailText") {
                data.invitePageInstructions = assessmentInstructions
            } else {
                data.inviteEmailText = emailInstruction
            }

            await updateAssessmentInstructions(user?.companyIds[0], details.assessment._id, data)
            message.success("Instructions successfully updated")
            getEvaluationDetailsAPI()
        } catch (error) {
            message.error(error.message || "Something went wrong")
        } finally {
            setIsLoading(false);
        }
    }

    function copyTextToClipboard(link) {
        navigator.clipboard.writeText(link).then(
            () => message.success("Copied to clipboard")
        ).catch(() => message.error("Couldn't copy to clipboard"))
    }

    return <Layout>
        {details.assessment?.token ? <div className="flex gap-2 my-3">
            <Input style={{ width: "400px", height: "40px" }} value={`${window.location.host}/assessment/invitation/confirmation/${details.assessment?.token}`} />
            <Button style={{ height: "40px" }} onClick={() => copyTextToClipboard(`${window.location.host}/assessment/invitation/confirmation/${details.assessment?.token}`)} type="primary">Copy</Button>
        </div> : null}
        <p className="heading-platform">Invites</p>
        <div style={{ display: "flex", justifyContent: 'space-between' }}>
            <Tabs
                onChange={(value, param) => {
                    if (value === selectedTab) {
                        setSelectedTab("")
                        getEvaluationDetailsAPI()
                    } else {
                        setSelectedTab(value)
                        getEvaluationDetailsAPI(`${param}=${value}`)
                    }

                }
                }
                value={selectedTab}
                options={[
                    {
                        label: <SegmentOption Icon={<img src={Envelope} style={{ height: "25px", width: "25px" }} />} value="" title={`${details.assessmentInvitesStats?.invitedCount || 0} Invited`} />,
                        value: 'SENT',
                        param: 'status',
                    },
                    {
                        label: <SegmentOption Icon={<img src={Envelope} style={{ height: "25px", width: "25px" }} />} value="" title={`${details.assessmentInvitesStats?.inProgressCount || 0} In Progress`} />,
                        value: 'IN_PROGRESS',
                        param: 'assessmentStatus'
                    },
                    {
                        label: <SegmentOption Icon={<img src={Envelope} style={{ height: "25px", width: "25px" }} />} value="" title={`${details.assessmentInvitesStats?.completedCount || 0} Completed`} />,
                        value: 'COMPLETED',
                        param: 'assessmentStatus'
                    },
                    {
                        label: <SegmentOption Icon={<img src={Envelope} style={{ height: "25px", width: "25px" }} />} value="" title={`${details.assessmentInvitesStats?.qualifiedCount || 0} Qualified`} />,
                        value: 'QAULIFIED',
                        param: 'assessmentResult'
                    },
                ]} />
            <Tabs
                disabled
                options={[
                    {
                        label: <SegmentOption value={<Progress type="line" percent={details?.assessmentAverageStats?.averageScore ?? 0}
                            strokeColor={{
                                "0%": "#BAA0EF",
                                "50%": "#8938DB",
                            }}
                            strokeWidth={10}
                            format={(percent) => (
                                <>{percent}%</>
                            )}
                        />} title="Average Score" />,

                    },
                    {
                        label: <SegmentOption value={<Progress type="line" percent={details?.assessmentAverageStats?.averageAccuracy ?? 0}
                            strokeColor={{
                                "0%": "#BAA0EF",
                                "50%": "#8938DB",
                            }}
                            strokeWidth={10}
                            format={(percent) => (
                                <>{percent}%</>
                            )}
                        />} title="Average Accuracy" />,

                    },
                    {
                        label: <SegmentOption value={details?.assessmentAverageStats?.averageTime ? convertMinutes(details?.assessmentAverageStats?.averageTime) : "No Data"} title="Average Time" />,

                    }
                ]} />
        </div>

        <Card className="table-container">
            <Table columns={columns}
                data={details.invites}
                onChange={handleTableChange}
                pagination={false}
                style={{ cursor: "pointer" }}
                isSearch
                callback={getEvaluationDetailsAPI}
            />
        </Card>
        {/* {isLoading ? <StyledLoader /> : null} */}
        <Card className="bar-char-card" style={{ height: "500px", margin: "30px 0" }}>
            <p className="dashboard-heading" style={{ margin: "2% 0" }}>Candidate Performance Distribution</p>
            <div className="bar-chart-container">
                {graphsData.barCharData?.data?.map((percentage, index) => {
                    if (percentage === null) {
                        return
                    }
                    return <Progress percent={percentage ? Math.round((percentage / graphsData.barCharData?.numberOfCandidates) * 100) : 0} type="line" style={{ rotate: "270deg" }} strokeColor={gradientColor[index > 5 ? index - 6 : index]} strokeWidth={80} format={(percent) => (
                        <p style={{
                            fontFamily: "Poppins",
                            fontSize: "16px",
                            fontWeight: "500",
                            lineHeight: "24px",
                            textAlign: "left",
                            color: "#070908"
                        }}>{percent}%</p>
                    )} strokeLinecap="square" trailColor="#ffffff"
                    />
                }
                )}


            </div>
            <div className="headings-container">
                {graphsData.barCharData?.labels?.map((label) => <div className="heading-div">
                    <p>{label}</p>
                </div>)}
            </div>
        </Card>

        <div className="flex gap-5 w-full">
            <Card className="table-container" style={{ margin: "30px 0", height: "500px", width: "50%", flexGrow: 1 }}>
                <div style={{ height: "380px", width: "100%", marginBottom: "2%" }}>
                    <SplineChart data={graphsData?.compositeChart?.length ? graphsData?.compositeChart : []} />
                </div>
            </Card>
            <Card className="table-container" style={{ height: "500px", width: "50%", margin: "30px 0", flexGrow: 1 }} >
                <div style={{ height: "380px", width: "100%", marginBottom: "2%", }} className="flex justify-center items-center flex-col" >
                    {graphsData.radarChart?.data?.length ? <>
                        <p className="notes" style={{ marginBottom: "20px" }}>Skills Tracker</p>
                        <RadarGraph
                            data={graphsData.radarChart?.data?.map((item, index) => {
                                return {
                                    name: graphsData.radarChart?.labels[index]?.trim() || "",
                                    value: item
                                };
                            })}
                            color="white"
                        /></> : <p className="notes" style={{ marginBottom: "20px" }}>Not enough data </p>}
                </div>
            </Card>
        </div>





        <Divider />
        <Card
            style={{ margin: "30px 0" }}
        >
            <p style={{
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "24px",
                textAlign: "left",
                color: "#070908",
                margin: "20px 0"

            }}>Additional Details</p>

            <table >
                <TRow label="Duration" value={details?.additionalDetails?.duration || "N/A"} />
                <TRow label="Challenge Set" value={details?.additionalDetails?.challenges || ""} />
                {/* <TRow label="Invite Email Text" setValue={setEmailInstruction} name="inviteEmailText" handleUpdate={handleInstructionUpdate} isTextEditor={true} value={emailInstruction || ""} /> */}
                <TRow label="Invite Page Instructions" isTextEditor={true} name="invitePageInstructions" handleUpdate={handleInstructionUpdate} value={assessmentInstructions} setValue={setAssessmentInstructions} />
            </table>
        </Card>
    </Layout>
};

const mapStateToProps = (state) => ({ user: state.auth.user })

export default connect(mapStateToProps, null)(CompanyAdminEvaluationDetails)

const Tabs = ({ options, disabled, onChange, value }) => {

    const handleChange = (value) => {
        const foundIndex = options.findIndex((opt) => opt.value === value)
        if (foundIndex !== -1) {
            onChange(value, options[foundIndex].param)
        }
    }

    return (
        <Flex gap="small" align="flex-start" vertical>
            <Segmented
                value={value}
                onChange={handleChange}
                disabled={disabled}
                options={options}
            />
        </Flex>)
}


const SegmentOption = ({ Icon, title, value }) => {
    return <div style={{ display: "flex", alignItems: "center", gap: 5, padding: 5, borderRadius: "8px" }}>
        {Icon ? Icon : null}
        <div>
            <p style={{
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "24px",
                textAlign: "left",
                color: "#070908",
            }}>
                {title}
            </p>
            <p style={{
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "21px",
                textAlign: "center",
                color: "#4AC18E",
                margin: 0,
            }}>{value}</p>
        </div>
    </div>
}

const TRow = ({ label, value, setValue, name, handleUpdate, isTextEditor = false }) => {

    const [isEdit, setIsEdit] = useState(false)


    return <tr style={{ height: isTextEditor ? "280px" : "auto" }}>
        <td style={{ backgroundColor: "#F5F5F5", padding: "12px", textAlign: "left" }}>{label}</td>
        {isTextEditor ?
            <td style={{ backgroundColor: "white", minWidth: "200px", maxWidth: "1100px", height: "max-content", display: "flex", flexDirection: "column", gap: 5, alignItems: "end" }}>
                <div>
                    {!isEdit ? <Tooltip title="Edit">
                        <Button type="success" onClick={() => setIsEdit(true)} shape="circle" icon={<EditOutlined />} />
                    </Tooltip> :
                        <>
                            <Tooltip title="Save">
                                <Button type="success" onClick={() => {
                                    setIsEdit(false);
                                    handleUpdate(name, value)
                                }} shape="circle" icon={<SaveOutlined />} />
                            </Tooltip>
                            <Tooltip title="Cancel">
                                <Button type="danger" onClick={() => setIsEdit(false)} shape="circle" icon={<CloseCircleOutlined />} />
                            </Tooltip>
                        </>
                    }

                </div>
                <RichtextEditor
                    readOnly={!isEdit}
                    richtextValue={value}
                    setRichtextValue={setValue}
                />
            </td>
            : <td style={{ backgroundColor: "white", minWidth: "200px", maxWidth: "600px", textAlign: "left", padding: "12px" }}>{value}</td>}

    </tr>
}