import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { StyledLoader } from "../components/loader";
import ProvideAuth from "../context/Auth";
const AdminLayout = ({ user, children }) => {
  const navigate = useNavigate();
  const [isAllowedPath, setIsAllowedPath] = useState(false);

  useEffect(() => {
    if (!user.roles?.includes("USER")) {
      navigate(-1);
      return;
    }
    setIsAllowedPath(true);
  }, []);

  return (
    <ProvideAuth>
      {!isAllowedPath ? <StyledLoader /> : <>{children}</>}
    </ProvideAuth>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AdminLayout);
