import { Button, Divider, Skeleton, Typography } from "antd";
import { useState, useEffect } from "react";
import { getAllAssessmentsByUserId } from "../../services/api";
import { useNavigate } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import SubHeader from "../../components/Headers/SubHeader";
import { connect } from "react-redux";
import { getUserProfile } from "../../store/actions";

const CDashboard = ({ user, getUserProfile }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [allAssessments, setAllAssessments] = useState([]);
  const navigate = useNavigate();
  console.log({ isLoading });
  const getAllAssessmentsData = async () => {
    setIsLoading(true);
    try {
      // const user = JSON.parse(localStorage.getItem("user"));
      const { data } = await getAllAssessmentsByUserId(user._id);
      setAllAssessments(
        data.assessments.map((assessment) => ({
          companyId: assessment.companyId,
          ...assessment.assessment[0],
        }))
      );
      setIsLoading(false);
    } catch (error) {
      console.log({ error });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllAssessmentsData();
  }, []);

  return (
    <div className="w-full">
      <div className="mb-10">
        <SubHeader headerType="dashboard" />
      </div>
      <div style={{ maxWidth: 1500, margin: "auto" }} className="px-10">
        <div className="text-left px-10 flex flex-col space-y-3">
          <Typography.Text className="text-2xl">
            Challenge Invites
          </Typography.Text>
          <Typography.Text>
            Welcome to the cyber hire platform, below is a listing of all the
            challenges that you have accepted. Please click on an invite to
            proceed.
          </Typography.Text>
        </div>
        <Divider style={{ marginBottom: 0 }} />
        <div className="flex flex-wrap">
          <div className="w-full flex flex-wrap pl-10 pr-5">
            {allAssessments.map((item, i) => (
              <div
                key={i}
                style={{ background: "url('../assets/cover.jpg')" }}
                className="mt-6 w-full flex flex-col justify-center items-start bg-red-500 rounded-lg px-12 h-48"
              >
                {item.title ? (
                  <div className="w-full flex justify-between items-center">
                    <Typography.Text className="text-2xl font-semibold text-white">
                      {item.title}
                    </Typography.Text>
                    <Button
                      type="primary"
                      onClick={() =>
                        navigate(`/assessment/${item?._id}`, {
                          state: {
                            companyId: item.companyId,
                            assessmentId: item.assessmentId,
                          },
                        })
                      }
                      size="large"
                      className="flex items-center justify-between w-24 mt-3"
                    >
                      Enter
                      <FaArrowRight style={{ marginTop: "2px" }} size={14} />
                    </Button>
                  </div>
                ) : (
                  <>
                    <div className="w-32">
                      <Skeleton.Input active />
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
          <div className="w-2/6 flex flex-wrap pr-10 pl-5"></div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ authData: state.auth.user });
const mapDispatchToProps = { getUserProfile };

export default connect(mapStateToProps, mapDispatchToProps)(CDashboard);
