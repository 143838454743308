import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'bottom',
            display: false
        },
        title: {
            display: false,
            text: 'Chart.js Line Chart',
        },
    },
};


export default function App({ payload }) {
    const { data, labels } = payload



    const graphData = {
        labels,
        datasets: [
            {
                label: 'Score',
                data: data,
                fill: true,
                borderColor: '#37A2EB',
                lineTension: 0.3,
            },
        ],
    };

    return <><p className="notes" style={{ marginBottom: "20px" }}>Performance Timeline</p><Line options={options} data={graphData} /></>;
}
