import React, { useCallback, useState } from 'react';
import { Input, Table } from 'antd';
import './Tables.css'
import { debounce } from 'lodash';
const App = ({ data, columns, pagination, onChange, onRow, appendSearchKey = true, style, callback, isSearch = false, sorter }) => {
    const [searchValue, setSearchValue] = useState("")
    const debouncedHandleSearch = useCallback(
        debounce((searchValue, sorter, pagination) => {
            if (callback) {
                if (searchValue && appendSearchKey) {
                    callback(`searchKey=${searchValue}`, sorter, pagination)
                    return
                } else if (searchValue && !appendSearchKey) {
                    callback(searchValue, sorter, pagination)
                    return
                }
                callback(searchValue, sorter, pagination)
            }
        }, 1000),
        []
    );

    const handleSearch = (e) => {
        const searchValue = e.target.value;
        setSearchValue(searchValue);
        debouncedHandleSearch(searchValue, sorter, pagination);
    };
    return <div>
        {isSearch && <Input
            style={{ marginBottom: "10px" }}
            size="large"
            value={searchValue}
            onChange={handleSearch}
            placeholder="Search..."
        />}
        <Table className="table-component" columns={columns} onRow={onRow} style={style} onChange={onChange} pagination={pagination} dataSource={data} />
    </div>;
};
export default App;