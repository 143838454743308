import React, { useState, useEffect } from 'react';
import moment from 'moment';
import InfoDisplay from './InfoDisplay'
import { connect } from 'react-redux';
import { ChallengeSlice } from '../../../../store/slices';
import ModalWrapper from '../../../../components/Modals/ModalWrapper';
import { Button, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

const { Text } = Typography
const CountdownClock = ({ duration, stopAssessmentAPI }) => {
    const navigate = useNavigate()
    const [showModal, setShowModal] = useState(false)
    const [countdown, setCountdown] = useState("00:00:00:00");
    useEffect(() => {
        const targetTime = moment(duration);
        const currentTime = moment();
        const timeDifference = targetTime.diff(currentTime, 'minutes');

        const targetDate = moment().add(timeDifference, 'minutes');
        const interval = setInterval(() => {
            const currentDate = moment();
            const duration = moment.duration(targetDate.diff(currentDate));
            if (duration.asSeconds() <= 0) {
                clearInterval(interval);
                setShowModal(true)
            } else {
                const days = duration.days().toString().padStart(2, '0');
                const hours = duration.hours().toString().padStart(2, '0');
                const minutes = duration.minutes().toString().padStart(2, '0');
                const seconds = duration.seconds().toString().padStart(2, '0');
                setCountdown(`${days}:${hours}:${minutes}:${seconds}`);
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [duration]);

    const closeAssessment = () => {
        stopAssessmentAPI()
        navigate("/user/assessment-listing")
    }


    return (
        <>
            {showModal && <ModalWrapper footer={[
                <Button key="back" onClick={
                    closeAssessment}
                >
                    Continue
                </Button>,
            ]} title={"Assessment Ended"} handleClose={closeAssessment}>
                <div>
                    <Text>Your Assessment has Ended!</Text>
                </div>
            </ModalWrapper>}
            <InfoDisplay style={{ width: "150px" }} label="Time" value={countdown} />
        </>

    );
};

const mapStateToProps = (state) => ({
    duration: state.challenge.assessment?.expiresAt
});

const mapDispatchToProps = {};


export default connect(mapStateToProps, mapDispatchToProps)(CountdownClock);
