import { Button, Typography } from "antd";
import ModalWrapper from "./ModalWrapper";

const { Text } = Typography;

export default function EndAssessment({ onClose, onEnd }) {
  return (
    <ModalWrapper
      title={"End Assessment"}
      handleClose={() => onClose(false)}
      footer={[
        <Button key="back" onClick={() => onClose(false)}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={onEnd}>
          Continue
        </Button>,
      ]}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <Text type="secondary">
          Are you sure you want to complete the challenge?{" "}
        </Text>
        <Text type="warning">
          Note: If challenge is completed once than it can’t be resumed{" "}
        </Text>
      </div>
    </ModalWrapper>
  );
}
