import {
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Image,
  Input,
  Row,
  Space,
  Spin,
  Typography,
  Upload,
  message,
} from "antd";
import { SlEnvolope } from "react-icons/sl";
import "./Candidate.css";
import { PiUser } from "react-icons/pi";
import { useEffect, useState } from "react";
import SubHeader from "../../components/Headers/SubHeader";
import { updateUserProfile } from "../../store/actions";
import { connect } from "react-redux";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const ProfileComponent = ({ user, updateUserProfile }) => {
  const [url, setUrl] = useState("");
  const [fileList, setFileList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();
  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      let dataBody = {
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
      };
      if (url) {
        dataBody.profilePic = url;
      }
      await updateUserProfile({ data: dataBody, id: user._id });
      message.success("Profile updated!");
    } catch (error) {
      message.error(error.message || "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user.email,
    });
  }, [user, form]);

  const onFinishFailed = (errorInfo) => {
    message.error("Unable to upload the image please try again");
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
  };

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  const handleChange = ({ fileList: newFileList, file }) => {
    if (file?.status === "done") {
      setUrl(file.response.URLs[0]);
    }
    setFileList(newFileList);
  };

  return (
    <div className="w-full">
      <div style={{ maxWidth: 1500, margin: "auto" }} className="px-10">
        <Card className="shadow-lg mb-10">
          <Form
            form={form}
            name="basic"
            layout="vertical"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Row>
              <Col span={8}>
                <div className="flex flex-col text-left">
                  <Typography.Text className="text-2xl font-semibold">
                    Basic info
                  </Typography.Text>
                  <Typography.Text className="text-gray-500">
                    Help us get to know you
                  </Typography.Text>
                </div>
              </Col>
              <Col span={16} className="flex flex-wrap">
                <div className="w-full flex justify-start items-center gap-4">
                  {user.profilePic && !fileList?.length && (
                    <Image
                      style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "50%",
                      }}
                      src={user?.profilePic}
                    />
                  )}
                  <Upload
                    accept="image/png, image/jpeg"
                    className="upload-profile-image"
                    multiple={false}
                    maxCount={1}
                    action={`${process.env.REACT_APP_API_PREFIX}upload`}
                    listType={user.profilePic ? "" : "picture-circle"}
                    fileList={fileList}
                    onPreview={handlePreview}
                    name="attachment"
                    onChange={handleChange}
                  >
                    {user?.profilePic ? (
                      <Button icon={<UploadOutlined />}>Edit</Button>
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                </div>
                <Form.Item
                  label="First Name"
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: "Please input First Name!",
                    },
                    {
                      pattern: /^[a-zA-Z\s]+$/,
                      message: "First Name can only include letters.",
                    },
                  ]}
                  className="w-1/2 px-2 mt-2"
                >
                  <Input
                    className="input"
                    prefix={<PiUser className="mr-2" color="rgb(99, 98, 98)" />}
                    placeholder="First name"
                    size="large"
                    h
                  />
                </Form.Item>
                <Form.Item
                  label="Last Name"
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: "Please input Last Name!",
                    },
                    {
                      pattern: /^[a-zA-Z\s]+$/,
                      message: "Last Name can only include letters.",
                    },
                  ]}
                  className="w-1/2 px-2 mt-2"
                >
                  <Input
                    className="input"
                    prefix={<PiUser className="mr-2" color="rgb(99, 98, 98)" />}
                    placeholder="Last name"
                    size="large"
                  />
                </Form.Item>
                <Space.Compact style={{ width: "100%" }}>
                  <Input
                    value={user?.email}
                    disabled
                    className="input"
                    prefix={
                      <SlEnvolope className="mr-2" color="rgb(99, 98, 98)" />
                    }
                    placeholder="Email"
                    size="large"
                  />
                  <Button disabled size="large" type="primary">
                    Verify
                  </Button>
                </Space.Compact>
              </Col>
            </Row>
            <Divider />
            <Form.Item>
              <Row justify={"end"}>
                <Button type="primary" size="large" htmlType="submit">
                  {isLoading ? (
                    <div className="flex items-center">
                      <Spin
                        className="mr-2"
                        indicator={
                          <LoadingOutlined
                            style={{
                              fontSize: 18,
                              color: "white",
                            }}
                            spin
                          />
                        }
                      />
                      Update Profile
                    </div>
                  ) : (
                    "Update Profile"
                  )}
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = {
  updateUserProfile: updateUserProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileComponent);
