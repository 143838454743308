import React from 'react';
import { Divider, Layout, Typography, Input, Radio, Space, Button } from 'antd';
import "./index.css"
import UserService from '../../../../services/api/user';
import { message } from "antd";
import { connect } from 'react-redux';
import { ChallengeSlice } from '../../../../store/slices';

const { Content } = Layout;
const { Text } = Typography;
const { TextArea } = Input;

const Feedback = ({ userId, companyId, selectedKey, assessmentId, selectedChallenge, feedback, difficulty, setFeedback, setDifficulty }) => {
    const [isLoading, setIsLoading] = React.useState(false)
    const onChange = (e) => {
        setDifficulty(e.target.value);
    };
    const handleSubmit = (e) => {
        if (!selectedChallenge || !Object.keys(selectedChallenge).length) {
            message.error("No Challenge is selected")
            return
        }
        const data = {
            difficultyLevel: difficulty,
            comments: feedback,
            userId,
            companyId,
            challengeId: selectedChallenge._id,
            assessmentId
        }
        try {
            setIsLoading(true)
            UserService.assessmentChallenge.sendFeedback(data)
            message.success("Feedback successfully submitted")
            selectedKey("challenges")
        } catch (e) {
            message.error(e?.message || "Something went wrong")
        } finally {
            setIsLoading(false)
        }

    }
    return (
        <Layout className="main-layout-feedback bg-transparent" style={{ background: "transparent" }}>
            <Content className="feedback-user bg-transparent p-0"  >
                <Divider />
                <div className="mb-5">
                    <Text type="secondary" style={{ color: "black" }}>How difficult was this challenge?</Text>
                </div>
                <Radio.Group onChange={onChange} value={difficulty}>
                    <Space direction="vertical" className='mt-5'>
                        <Radio style={{ color: "black" }} value={"TOO_EASY"}>Too Easy</Radio>
                        <Radio style={{ color: "black" }} value={"JUST_RIGHT"}>Just right</Radio>
                        <Radio style={{ color: "black" }} value={"TOO_HARD"}>Too Hard</Radio>
                    </Space>
                </Radio.Group>
                <Divider />
                <TextArea
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    placeholder="Any Other Thoughts?"
                    autoSize={{ minRows: 3, maxRows: 5 }}
                />

                <Button className="mt-5" disabled={isLoading} onClick={handleSubmit} type="primary">Submit</Button>
            </Content>
        </Layout>
    );
};

const mapStateToProps = (state) => ({
    selectedChallenge: state.challenge.selectedChallenge,
    feedback: state.challenge.feedback,
    difficulty: state.challenge.difficulty,
});
const mapDispatchToProps = {
    setType: ChallengeSlice.actions.setType,
    setDifficulty: ChallengeSlice.actions.setDifficulty,
    setFeedback: ChallengeSlice.actions.setFeedback,
};

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);

