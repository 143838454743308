import { ConfigProvider } from "antd";
import React, { useState, createContext } from "react";
export const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState("light");

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#34C788",
          },
          components: {
            Modal: {
              contentBg: theme === "dark" ? "#001529" : "white",
              headerBg: theme === "dark" ? "#001529" : "white",
              titleColor: theme === "dark" ? "white" : "black",
            },
            Timeline: {
              dotBg: theme === "dark" ? "#001529" : "white",
              tailColor: "#49C18E",
            },
          },
        }}
      >
        {children}
      </ConfigProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
