import moment from "moment";
import { useEffect, useState } from "react";
import CalenderIcon from "../../assets/icons/calendar1.svg";

export default function AssessmentCounter({ duration = new Date(), isCompleted }) {
    const [countDown, setCountDown] = useState({
        days: "00",
        hours: "00",
        minutes: "00",
        seconds: "00"
    })
    useEffect(() => {
        const targetTime = moment(duration);
        const currentTime = moment();
        const timeDifference = targetTime.diff(currentTime, 'minutes');

        const targetDate = moment().add(timeDifference, 'minutes');
        const interval = setInterval(() => {
            const currentDate = moment();
            const duration = moment.duration(targetDate.diff(currentDate));
            if (duration.asSeconds() <= 0) {
                clearInterval(interval);

            } else {
                const days = duration.days().toString().padStart(2, '0');
                const hours = duration.hours().toString().padStart(2, '0');
                const minutes = duration.minutes().toString().padStart(2, '0');
                const seconds = duration.seconds().toString().padStart(2, '0');
                setCountDown({
                    days,
                    hours,
                    minutes,
                    seconds
                });
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [duration]);

    if (duration == 0) {
        return <></>
    }

    if (isCompleted) {
        return <p className="timer-text align-center flex gap-1">
            <img src={CalenderIcon} alt="calendar" />
            <span style={{ color: "#34c788", textTransform: "uppercase" }}>
                Assessment Has Ended
            </span>
        </p>
    }

    return <p className="timer-text align-center flex gap-1">
        <img src={CalenderIcon} alt="calendar" />
        <span style={{ color: "#34c788", textTransform: "uppercase" }}>
            Ending in:
        </span>

        {countDown?.days || 0} days, {countDown?.hours || 0} hours,{" "}
        {countDown?.minutes || 0} minutes, {countDown?.seconds || 0}{" "}
        seconds at{" "}
        {moment(duration).format(
            "YYYY-MM-DD HH:MM"
        )}

    </p>
}