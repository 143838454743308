import React, { useState } from "react";
import { Divider, Layout, Menu } from "antd";
import "./index.css"; // Import the CSS file
import ChallengesIcon from "../../assets/icons/challenges.svg";
import ChallengesActiveIcon from "../../assets/icons/challenges-active.svg";
import FeedbackIcon from "../../assets/icons/feedback.svg";
import FeedbackActiveIcon from "../../assets/icons/feedback-active.svg";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { FilePdfOutlined, FormOutlined, LogoutOutlined, MacCommandOutlined, RedEnvelopeOutlined, SettingOutlined, TabletOutlined, UserOutlined } from "@ant-design/icons";
import useProvideAuth from "../../hooks/useAuth";
import { UserSlice } from "../../store/slices";

const { Sider } = Layout;

const SiderComponent = ({ user, resetUserState }) => {
    const [selectedKey, setSelectedKey] = useState("challenges"); // The key for "Penetration Testing"
    const navigate = useNavigate()
    const location = useLocation()
    const auth = useProvideAuth()

    return (

        <Sider
            className="relative h-screen"
            style={{
                backgroundColor: "#000",
            }}
            width={275}
        >
            <div style={{ padding: "30px" }} className="h-full">
                <div
                    className="flex justify-start"
                    style={{
                        height: "58px",
                    }}
                >
                    <img
                        style={{
                            height: "48px",
                            width: "135px",
                        }}
                        onClick={() => navigate("/company/admin/dashboard")}
                        src="../../assets/logo-dark.png"
                        alt="Cyber hire"
                    />
                </div>
                <Divider style={{ margin: 0, borderColor: "#888A8D" }} />
                <Menu
                    theme="dark"
                    mode="inline"
                    selectedKeys={[selectedKey]}
                    onClick={(e) => {
                        setSelectedKey(e.key);
                    }}
                    style={{
                        backgroundColor: "transparent",
                        color: "white",
                        marginTop: "32px",
                    }}
                    className="menu"
                >
                    <Menu.Item
                        onClick={(e) => { navigate("/company/admin/dashboard") }}
                        className="text-left"
                        key="dashboard"
                        icon={
                            <div
                                className={`icon-background ${location.pathname.includes("dashboard")
                                    ? "icon-background-selected"
                                    : "icon-background-unselected"
                                    }`}
                            >
                                {location.pathname.includes("dashboard") ? (
                                    <img src={ChallengesActiveIcon} alt="dashboard" />
                                ) : (
                                    <img src={ChallengesIcon} alt="dashboard" />
                                )}
                            </div>
                        }
                        style={{
                            color: location.pathname.includes("dashboard") ? "white" : "#888A8D",
                            fontSize: "16px"
                        }}
                    >
                        Dashboard
                    </Menu.Item>
                    {/* <Menu.Item
                        onClick={(e) => { navigate("/company/admin/assessments") }}
                        className="text-left"
                        key="assessments"
                        icon={
                            <div
                                className={`icon-background ${location.pathname.includes("assessments")
                                    ? "icon-background-selected"
                                    : "icon-background-unselected"
                                    }`}
                            >
                                {location.pathname.includes("assessments") ? (
                                    <FilePdfOutlined style={{ color: "#000000" }} />
                                ) : (
                                    <FilePdfOutlined />
                                )}
                            </div>
                        }
                        style={{
                            color: location.pathname.includes("assessments") ? "white" : "#888A8D",
                        }}
                    >
                        Assessments
                    </Menu.Item> */}
                    {/* <Menu.Item
                        onClick={(e) => { navigate("/company/admin/invites") }}
                        className="text-left"
                        key="invites"
                        icon={
                            <div
                                className={`icon-background ${location.pathname.includes("invites")
                                    ? "icon-background-selected"
                                    : "icon-background-unselected"
                                    }`}
                            >
                                {location.pathname.includes("invites") ? (
                                    <RedEnvelopeOutlined style={{ color: "#000000" }} />
                                ) : (
                                    <RedEnvelopeOutlined />
                                )}
                            </div>
                        }
                        style={{
                            color: location.pathname.includes("invites") ? "white" : "#888A8D",
                        }}
                    >
                        Invites
                    </Menu.Item> */}
                    {user?.roles?.includes("COMPANY_ADMIN") ? <Menu.Item
                        className="text-left"
                        key="userss"
                        onClick={() => { navigate("/company/admin/users") }}
                        icon={
                            <div
                                className={`icon-background ${location.pathname.includes("users")
                                    ? "icon-background-selected"
                                    : "icon-background-unselected"
                                    }`}
                            >
                                {location.pathname.includes("users") ? (
                                    <MacCommandOutlined style={{ color: "#000000" }} />
                                ) : (
                                    <MacCommandOutlined />
                                )}
                            </div>
                        }
                        style={{
                            color: location.pathname.includes("users") ? "white" : "#888A8D",
                            fontSize: "16px"
                        }}
                    >
                        Account Management
                    </Menu.Item> : null}
                    <Menu.Item
                        className="text-left"
                        key="evaluation"
                        onClick={() => { navigate("/company/admin/evaluation") }}
                        icon={
                            <div
                                className={`icon-background ${location.pathname.includes("evaluation")
                                    ? "icon-background-selected"
                                    : "icon-background-unselected"
                                    }`}
                            >
                                {location.pathname.includes("evaluation") ? (
                                    <TabletOutlined style={{ color: "#000000" }} />
                                ) : (
                                    <TabletOutlined />
                                )}
                            </div>
                        }
                        style={{
                            color: location.pathname.includes("evaluation") ? "white" : "#888A8D",
                            fontSize: "16px"
                        }}
                    >
                        Evaluation
                    </Menu.Item>
                    <Menu.Item
                        className="text-left"
                        key="candidate"
                        onClick={() => { navigate("/company/admin/candidate") }}
                        icon={
                            <div
                                className={`icon-background ${location.pathname.includes("candidate")
                                    ? "icon-background-selected"
                                    : "icon-background-unselected"
                                    }`}
                            >
                                {location.pathname.includes("candidate") ? (
                                    <UserOutlined style={{ color: "#000000" }} />
                                ) : (
                                    <UserOutlined />
                                )}
                            </div>
                        }
                        style={{
                            color: location.pathname.includes("candidate") ? "white" : "#888A8D",
                            fontSize: "16px"
                        }}
                    >
                        Candidate
                    </Menu.Item>
                    <Menu.Item
                        className="text-left"
                        key="settings"
                        onClick={() => { navigate("/company/admin/profile") }}
                        icon={
                            <div
                                className={`icon-background ${location.pathname.includes("profile") || location.pathname.includes("password")
                                    ? "icon-background-selected"
                                    : "icon-background-unselected"
                                    }`}
                            >
                                {location.pathname.includes("profile") || location.pathname.includes("password") ? (
                                    <SettingOutlined style={{ color: "#000000" }} />
                                ) : (
                                    <SettingOutlined />
                                )}
                            </div>
                        }
                        style={{
                            color: location.pathname.includes("profile") || location.pathname.includes("password") ? "white" : "#888A8D",
                            fontSize: "16px"
                        }}
                    >
                        Settings
                    </Menu.Item>

                    <Menu.Item

                        className="text-left"
                        key="logout"
                        onClick={() => auth.signOut(resetUserState)}
                        icon={
                            <div className="icon-background icon-background-unselected" onClick={() => auth.signOut(resetUserState)}>
                                <LogoutOutlined

                                    style={{
                                        color: "white",
                                        fontSize: 18,
                                    }}
                                />
                            </div>
                        }
                        style={{
                            color: "#888A8D",
                            position: "absolute",
                            bottom: 0,
                            fontSize: "16px"
                        }}
                    >
                        Logout
                    </Menu.Item>
                </Menu>
            </div>
        </Sider>


    );
};

const mapStateToProps = (state) => ({
    user: state.auth.user
});
const mapDispatchToProps = {
    resetUserState: UserSlice.actions.resetUserState
};

export default connect(mapStateToProps, mapDispatchToProps)(SiderComponent);
