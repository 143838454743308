import {
  Button,
  Card,
  Col,
  Divider,
  Input,
  Row,
  Select,
  Space,
  Switch,
  Typography,
  message,
} from "antd";
import AdminsTable from "../../../components/Tables/AdminsTable";
import { useEffect, useState, useCallback } from "react";
import {
  addCompanyInvites,
  associatCompaniesAndAssessments,
  blockCompany,
  deleteCompany,
  getAllAssessments,
  getAllCompaniesData,
  unBlockCompany,
} from "../../../services/api";
import { useNavigate } from "react-router-dom";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { StyledLoader } from "../../../components/loader";
import DeleteModal from "../../../components/Modals/DeleteModal";
import InvitesModal from "../../../components/Modals/InvitesModal";
import AssessmentsModal from "../../../components/Modals/AssessmentsModal";
import { debounce } from "lodash";

const { Text } = Typography;
const Companies = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [showAssociateAssessments, setShowAssociateAssessments] =
    useState(false);
  const [allCompanies, setAllCompanies] = useState([]);
  const [allAssessments, setAllAssessments] = useState([]);
  const [deleteCompanyId, setDeleteCompanyID] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState({});
  const [selectedAssessments, setSelectedAssessments] = useState([]);
  const [resetIsDisabled, setResetIsDisabled] = useState(true);
  const [selectedStatusFilter, setSelectedStatusFilter] = useState(null);
  const [searchKey, setSearchKey] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [checkedCompanies, setCheckedCompanies] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0, // You might not know this until you fetch the first set of data
  });
  const navigate = useNavigate();

  const onAccessChange = async (isBlocked, id) => {
    try {
      message.success(isBlocked ? "Company unblocked!" : "Company blocked!");
      const index = allCompanies.findIndex((ind) => ind._id === id);
      allCompanies[index].isBlocked = !isBlocked;
      setAllCompanies([...allCompanies]);
      if (isBlocked) {
        await unBlockCompany(id);
      } else {
        await blockCompany(id);
      }
    } catch (error) {
      console.log({ error });
      message.error("Error updating Blocked status");
    }
  };

  const handleDeleteModal = (id) => {
    setDeleteCompanyID(id);
    setIsModalOpen(true);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <Text>{text}</Text>,
      sorter: true,
    },
    {
      title: "Total Invites",
      dataIndex: "totalInvites",
      key: "totalInvites",
      render: (text) => <Text>{text}</Text>,
      sorter: true,
    },
    {
      title: "Used Invites",
      dataIndex: "usedInvites",
      key: "usedInvites",
      sorter: true,
    },
    {
      title: "Remaining Invites",
      dataIndex: "remainingInvites",
      key: "remainingInvites",
      sorter: true,
    },
    {
      title: "Access",
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        return (
          <div>
            <Switch
              checked={record?.status}
              defaultChecked={record?.status}
              onChange={() => onAccessChange(record?.status, record?._id)}
              style={{
                backgroundColor: record?.status ? "#ed5353" : "#4ac18e",
              }}
            />
          </div>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <Space size="middle">
            <EditOutlined
              className="hover:text-blue-400"
              onClick={() => navigate(`/admin/company/edit?id=${record?._id}`)}
            />
            <DeleteOutlined
              className="hover:text-blue-400"
              onClick={() => handleDeleteModal(record?._id)}
            />
          </Space>
        );
      },
    },
  ];
  const data = [
    ...allCompanies.map((item, i) => {
      return {
        _id: item?._id,
        key: i + 1,
        name: item?.name,
        totalInvites: item?.totalInvites,
        usedInvites: item?.usedInvites,
        remainingInvites: item?.remainingInvites,
        status: item?.isBlocked,
        // tags: ['nice', 'developer'],
      };
    }),
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    getAllCompanies(
      pagination?.current,
      pagination?.pageSize,
      sorter.order ? sorter.field : null,
      sorter.order
    );
  };

  const getAllCompanies = async (
    page = 1,
    limit = 10,
    sortBy,
    order,
    isBlocked,
    searchKey
  ) => {
    setSortBy(sortBy);
    setSortOrder(order);

    const isBlockedQueryString = isBlocked
      ?.map((status) => {
        if (status === "Blocked") {
          return "isBlocked=true";
        } else if (status === "Un Blocked") {
          return "isBlocked=false";
        }
        return null;
      })
      .filter(Boolean)
      .join("&");

    const queryParams = {
      role: "SUPER_ADMIN",
      page,
      limit,
      sortBy,
      order,
      searchKey,
    };

    const queryString = Object.entries(queryParams)
      .reduce((acc, [key, value]) => {
        if (value !== undefined && value !== null) {
          acc.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
        }
        return acc;
      }, [])
      .concat(isBlockedQueryString ? [isBlockedQueryString] : [])
      .join("&");

    setIsLoading(true);
    try {
      const { data } = await getAllCompaniesData(queryString);
      setAllCompanies(data?.companies);
      setPagination((prev) => ({ ...prev, total: data?.count, current: page }));
      setIsLoading(false);
    } catch (error) {
      console.log({ error });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllCompanies();
  }, []);

  const handleDeleteCompany = async () => {
    setIsModalOpen(false);
    try {
      const index = allCompanies.findIndex(
        (ind) => ind._id === deleteCompanyId
      );
      const arr = [...allCompanies];
      arr.splice(index, 1);
      setAllCompanies(arr);
      message.success("Company deleted!");

      await deleteCompany(deleteCompanyId);
      setDeleteCompanyID(null);
    } catch (error) {
      console.log({ error });
      getAllCompanies();
      message.success("Error deleting company!");
    }
  };

  const addInvites = async (values) => {
    setIsInviteModalOpen(false);
    try {
      setIsLoading(true);
      const index = allCompanies.findIndex(
        (ind) => ind._id === selectedCompany?._id
      );

      await addCompanyInvites(
        { invites: parseInt(values?.invites), expiryDate: values?.expiryDate },
        selectedCompany?._id
      );
      message.success("Invites added!");
      getAllCompanies();
      setSelectedCompany({});
    } catch (error) {
      getAllCompanies();
      message.error(
        error?.response?.data?.error || error.message || "Error adding invites!"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getAllAssessmentsData = async () => {
    try {
      const { data } = await getAllAssessments();
      setAllAssessments(data?.assessments);
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    getAllAssessmentsData();
  }, []);

  const associatCompanies = async () => {
    setIsLoading(true);
    try {
      const companyIds = checkedCompanies.map((item) => item._id);
      const assessmentsIds = selectedAssessments.map((item) => item._id);
      await associatCompaniesAndAssessments({
        companyIds,
        assessmentsIds,
      });
      setIsLoading(false);
      setShowAssociateAssessments(false);
      setSelectedAssessments([]);
      setCheckedCompanies([]);
      message.success("Assessments assigned successfully");
    } catch (error) {
      console.log({ error });
      setIsLoading(false);
    }
  };

  const handleStatusSelectChange = (value) => {
    setResetIsDisabled(false);
    setSelectedStatusFilter([value]);
    getAllCompanies(
      1,
      pagination?.pageSize,
      sortBy,
      sortOrder,
      [value],
      searchKey
    );
  };

  const resetFilters = () => {
    setResetIsDisabled(true);
    setSelectedStatusFilter(null);
    setSearchKey(null);
    setSortOrder(null);
    setSortBy(null);
    getAllCompanies();
  };

  const debouncedHandleSearch = useCallback(
    debounce((searchValue) => {
      getAllCompanies(
        pagination?.current,
        pagination?.pageSize,
        sortBy,
        sortOrder,
        selectedStatusFilter,
        searchValue
      );
    }, 1000),
    []
  );

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchKey(searchValue);
    setResetIsDisabled(false);
    debouncedHandleSearch(searchValue?.trim());
  };

  return (
    <div>
      <Card style={{ width: "100%" }}>
        <div className="flex justify-between items-center pl-3 pb-4 w-full">
          <Text className="text-2xl font-semibold font-[#1f2232]">
            Companies
          </Text>
          <Row gutter={12}>
            <Col>
              <Button
                size="large"
                style={{
                  fontSize: "16px",
                  lineHeight: "24px",
                  textAlign: "left",
                  color: "#070908",
                  marginBottom: "10px",
                }}
                onClick={() => setIsInviteModalOpen(true)}
              >
                Add Invites +
              </Button>
            </Col>
            <Col>
              <Button
                style={{
                  fontSize: "16px",
                  lineHeight: "24px",
                  textAlign: "left",
                  color: "#070908",
                  marginBottom: "10px",
                }}
                size="large"
                onClick={() => navigate("/admin/company/add")}
              >
                Add New +
              </Button>
            </Col>
          </Row>
        </div>
        <Divider style={{ margin: 0 }} />
        <Row gutter={12} justify={"space-between"} className="my-2">
          <Col span={8}>
            <Row gutter={12}>
              <Col span={24}>
                <Input
                  size="large"
                  value={searchKey}
                  onChange={(e) => handleSearch(e)}
                  placeholder="Search company..."
                />
              </Col>
            </Row>
          </Col>
          <Col span={16}>
            <Row justify={"end"} gutter={12}>
              <Col>
                <Select
                  placeholder="Filter Status"
                  size="large"
                  style={{
                    width: 130,
                    fontSize: "16px",
                    lineHeight: "24px",
                    textAlign: "left",
                    color: "#070908",
                    marginBottom: "10px",
                  }}
                  onChange={handleStatusSelectChange}
                  value={selectedStatusFilter ? selectedStatusFilter[0] : null}
                  options={[
                    {
                      value: "Blocked",
                      label: "Blocked",
                    },
                    {
                      value: "Un Blocked",
                      label: "UnBlocked",
                    },
                  ]}
                />
              </Col>
              <Col>
                <Button
                  disabled={resetIsDisabled}
                  size="large"
                  onClick={resetFilters}
                >
                  Reset Filters
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider style={{ margin: 0 }} />
        {isLoading ? <StyledLoader /> : null}
        <AdminsTable
          onChange={handleTableChange}
          columns={columns}
          data={data}
          pagination={pagination}
        />
        <DeleteModal
          title="Delete record"
          text="Are you sure you want to delete this record?"
          deleteAction={handleDeleteCompany}
          isModalOpen={isModalOpen}
          isDelete
          setIsModalOpen={setIsModalOpen}
        />
        <InvitesModal
          onFinish={addInvites}
          selectedCompany={selectedCompany}
          setSelectedCompany={setSelectedCompany}
          isModalOpen={isInviteModalOpen}
          setIsModalOpen={setIsInviteModalOpen}
        />
        <AssessmentsModal
          selectedAssessments={selectedAssessments}
          setSelectedAssessments={setSelectedAssessments}
          selectedCompanies={checkedCompanies}
          allAssessments={allAssessments}
          setAllAssessments={setAllAssessments}
          title="Select Assessments"
          action={associatCompanies}
          isModalOpen={showAssociateAssessments}
          setIsModalOpen={setShowAssociateAssessments}
        />
      </Card>
    </div>
  );
};

export default Companies;
