import { LoadingOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, Spin, Typography, message } from "antd";
import { useState } from "react";
import { createSuperAdmin } from "../../../services/api";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../../components/Modals/DeleteModal";

const { Text } = Typography;
const AddSuperAdmins = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      await createSuperAdmin({
        ...values,
        email: values.email?.trim(),
      });
      message.success("Super admin created!");
      navigate("/admin/super-admins");
      setIsLoading(false);
    } catch (error) {
      console.log({ error });
      message.error(error.response.data.error);
      setIsLoading(false);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div>
      <Card style={{ width: "100%" }}>
        <>
          <div className="flex justify-between items-center px-3 py-4 w-full">
            <Text className="text-2xl font-semibold font-[#1f2232]">
              Create Super Admin
            </Text>
          </div>
          <div className="p-3">
            <Form
              name="basic"
              layout="vertical"
              className="w-full"
              labelCol={{
                span: 8,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <div className="flex flex-wrap">
                <Form.Item
                  label="First Name"
                  name="firstName"
                  className="w-1/2 px-2"
                  rules={[
                    {
                      required: true,
                      message: "Please input admin's First Name!",
                    },
                    {
                      pattern: /^[a-zA-Z\s]+$/,
                      message: "Name can only include letters.",
                    },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>

                <Form.Item
                  label="Last Name"
                  name="lastName"
                  className="w-1/2 px-2"
                  rules={[
                    {
                      required: true,
                      message: "Please input admin's Last Name!",
                    },
                    {
                      pattern: /^[a-zA-Z\s]+$/,
                      message: "Name can only include letters.",
                    },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>

                <Form.Item
                  label="Email"
                  name="email"
                  className="w-1/2 px-2"
                  rules={[
                    {
                      required: true,
                      message: "Please input admin's Email!",
                    },
                    // {
                    //   type: "email",
                    //   message: "Please enter a valid Email!",
                    // },
                    {
                      pattern:
                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\s*$/,
                      message: "Please enter a valid Email!",
                    },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>

                {/* <Form.Item
                  label="Password"
                  name="password"
                  className="w-1/2 px-2"
                  rules={[
                    {
                      required: true,
                      message: "Please input admin's password!",
                    },
                    {
                      pattern:
                        /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+}{"':;?/>.<,])(?=.{8,})/,
                      message:
                        "Password must contain at least one capital letter and one symbol and 8 characters.",
                    },
                  ]}
                >
                  <Input.Password size="large" />
                </Form.Item> */}

                {/* <Form.Item
                                    label="Company"
                                    name="companyIds"
                                    className='w-1/2 px-2'
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input admin's password!",
                                        },
                                    ]}
                                >
                                    <Dropdown menu={menuProps}>
                                        <Button size="large"className="w-full">
                                            <Space className="flex items-center justify-between">
                                                {selectedCompany?.name || "Select"}
                                                <DownOutlined />
                                            </Space>
                                        </Button>
                                    </Dropdown>
                                </Form.Item> */}

                <Form.Item className="flex justify-end w-full">
                  <Button
                    className="mr-2"
                    onClick={() => setIsModalOpen(true)}
                    size="large"
                  >
                    Cancel
                  </Button>
                  <Button size="large" htmlType="submit">
                    {isLoading ? (
                      <div className="flex items-center">
                        <Spin
                          className="mr-2"
                          indicator={
                            <LoadingOutlined
                              style={{
                                fontSize: 18,
                              }}
                              spin
                            />
                          }
                        />
                        Create
                      </div>
                    ) : (
                      "Create"
                    )}
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </>
      </Card>
      <DeleteModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        deleteAction={() => navigate("/admin/super-admins")}
        title="Discard changes"
        actionText="Yes"
        text="Are you sure you want to discard?"
      />
    </div>
  );
};

export default AddSuperAdmins;
